import styled from "styled-components";
// import React from 'react'

export const H1 = styled.h1`
    margin-block-start: 0;
    font-family: ${(props) => props.theme.font.h1["font-family"]};
    font-style: ${(props) => props.theme.font.h1["font-style"]};
    font-weight: ${(props) => props.theme.font.h1["font-weight"]};
    font-size: ${(props) => props.theme.font.h1["font-size"]};
    line-height: ${(props) => props.theme.font.h1["line-height"]};
    text-transform: ${(props) => props.theme.font.h1["text-transform"]};
    color: ${(props) =>
        props.color
            ? props.color
            : props.$second
            ? props.theme.text.color.second
            : props.theme.text.color.main};
`;

export default H1;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import ReactModal from "react-modal";
import styled from "styled-components";

import { useOnlineStatus } from "./useOnlineStatus";
import { T, TB, H1 } from "../../components/texts";
import { ErrorIcon } from "../../components/icons";
import LoadingSpinner from "../../components/layout/LoadingSpinner";

const OfflineModal = () => {
    const [hasBeenOffline, setHasBeenOffline] = useState(false);
    const isOnline = useOnlineStatus();
    const { t } = useTranslation();

    // Display success toast when connection comes back up
    useEffect(() => {
        if (!hasBeenOffline && !isOnline) setHasBeenOffline(true);
        if (hasBeenOffline && isOnline) {
            setHasBeenOffline(false);
            toast.success("Nå virker nettet igjen, og du kan fortsette der du slapp!", {
                style: { textAlign: "center" },
            });
        }
    }, [isOnline]);

    return !isOnline ? (
        <ModalStyled
            className="Modal"
            overlayClassName="ModalOverlay"
            isOpen={true}
            onRequestClose={null}
        >
            <Content>
                <Heading>
                    <ErrorIcon />
                    <H1>{t("lost_internet")}</H1>
                </Heading>

                <T>{t("mainflow_has_problems_with_internet")}</T>
                <T>{t("try_checking_internet")}</T>

                <SpinnerContainer>
                    <TB>{t("we_continue_checking_internet")}</TB>
                    <LoadingSpinner light />
                </SpinnerContainer>
            </Content>
        </ModalStyled>
    ) : null;
};

export default OfflineModal;

const ModalStyled = styled(ReactModal)`
    height: max-content;
    top: 50%;
    transform: translateY(-50%);
    max-width: 35rem;
    padding: 2rem;

    opacity: 0;
    animation: fadeIn 200ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const Content = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Heading = styled.span`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-right: 2rem;

    svg {
        max-height: 2rem;
        position: relative;
        bottom: 0.15rem;
    }
`;

const SpinnerContainer = styled.section`
    margin-top: 2rem;

    ${TB} {
        display: block;
        margin-bottom: 1rem;
    }
`;

import { url2data, fetchData } from "../fetch";

const getRoles = async () => {
    return fetchData(`core/authorization/roles`);
};

const getRole = async (id) => {
    return fetchData(`core/authorization/role/${id}`);
};

const updateRole = async (id, permissions) => {
    return url2data(`core/authorization/role/${id}/permissions`, permissions);
};

const createRole = async (id, data) => {
    return url2data(`core/authorization/role`, data);
};

const getPermissions = async () => {
    return fetchData(`core/authorization/permissions`);
};

const getPermission = async (id) => {
    return fetchData(`core/authorization/permission/${id}`);
};

const getUserRoles = async (id) => {
    return fetchData(`core/authorization/user/${id}/roles`);
};

const updatePermission = async (data) => {
    if (data?.id) return putPermission(data);
    return postPermission(data);
};

const postPermission = async (data) => {
    return url2data(`core/authorization/role`, data);
};

const putPermission = async (data) => {
    const { id, ...newData } = data;
    return url2data(`core/authorization/role/${id}`, newData, "PUT");
};

export default {
    getRoles,
    getRole,
    getPermissions,
    getPermission,
    updateRole,
    createRole,
    getUserRoles,
    updatePermission,
};

import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 20px;
    height: 20px;
`;

export const ClockIcon = () => {
    return (
        <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
                d="M17.5995 4.40072C21.2446 8.04524 21.2446 13.9544 17.5995 17.5993C13.9547 21.2447 8.04516 21.2447 4.40034 17.5993C0.755224 13.9547 0.755224 8.04554 4.40034 4.40072C8.04516 0.755301 13.9544 0.755301 17.5995 4.40072Z"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path d="M11 4.97849V11L13.7097 13.7097" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M2.56982 11H3.77413" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M18.2256 11H19.4299" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M11 19.4301V18.2258" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M11 3.77419V2.56989" stroke="#38558D" strokeMiterlimit="10" />
        </Svg>
    );
};

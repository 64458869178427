import { useMemo } from "react";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { TaskStatus } from "../../../api/core/taskAPI";
import taskAPI from "../../../api/core/taskAPI";
import { DateTime } from "luxon";

export const useScheduledTasksBetween = (fromDate, toDate, workshopId) => {
    const scheduledStatusList = [
        TaskStatus.SCHEDULED,
        TaskStatus.SCHEDULED_DRAFT,
        TaskStatus.STARTED,
        TaskStatus.COMPLETED,
        TaskStatus.INVOICED,
    ];
    const host = Number(workshopId); // Ensure workshopId is a number
    const params = `?from=${fromDate}&to=${toDate}&status=${scheduledStatusList}&host_id=${host}&attributes=id&attributes=start&attributes=end&attributes=mechanic_id&attributes=status&attributes=host_id&attributes=duration&attributes=description&attributes=priority&attributes=from_customer&attributes=createdAt&attributes=unit.id&attributes=unit.int_id&attributes=unit.manufacturer&attributes=unit.type&attributes=unit.defect&attributes=customer.name&attributes=customer.contact_person&attributes=owner.first_name&attributes=owner.last_name&attributes=parts.part_id&attributes=parts.status&attributes=parts.eta&attributes=part.stock&attributes=type_id`;

    // Define the query key
    const queryKey = ["tasks", "websocket-hooked", "scheduled", host, fromDate, toDate];
    const query = useQuery(queryKey, () => taskAPI.getTasksWithConnectedWorkshops(params), {
        enabled: !!host, // Enable query only if host is truthy
        refetchOnWindowFocus: false,
        meta: {
            filterFn: (task) => {
                if (DateTime.fromISO(fromDate) >= DateTime.fromISO(task.start)) {
                    return false;
                }
                if (DateTime.fromISO(toDate) <= DateTime.fromISO(task.end)) {
                    return false;
                }
                return scheduledStatusList.includes(task.status);
            },
        },
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Fetching task error: ", error.response?.data?.message || error.message);
        },
    });

    // Memoize the filtered data
    const data = useMemo(
        () => query.data?.filter((item) => item.host_id === host),
        [query.data, host]
    );

    return { ...query, data };
};

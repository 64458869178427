import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import extraAPI from "../../../api/core/extraAPI";

export const useDeleteExtra = ({ onSuccess }) => {
    const queryClient = useQueryClient();

    return useMutation((id) => extraAPI.deleteExtra(id), {
        onSuccess: () => {
            if (onSuccess instanceof Function) onSuccess();

            toast.success("Tillegg slettet");
            queryClient.invalidateQueries("extras");
        },
        onError: (error) => {
            toast.error(
                `Klarte ikke slette tillegget. Det oppsto en feil: ${error.message || "Ukjent feil"}`
            );
            console.log("Delete error: ", error.message);
        },
    });
};

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { cloneDeep, orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { T, TB } from "../texts";
import { SortIcon } from "../icons";
import { CleanButton } from "../buttons";

const fieldInRow = (row, field) => {
    const fields = field.split(".");
    let result = row[fields[0]];
    for (let pos = 1; pos < fields.length; pos++) {
        if (result === null) break;
        result = fields[pos] in result ? result[fields[pos]] : null;
    }
    return result;
};

const LegacyTable = ({ def, data, actions, search = "", max = 10, className }) => {
    const [inData, setInData] = useState(cloneDeep(data));
    const [searchedData, setSearchedData] = useState([]);
    const [visibleRows, setVisibleRows] = useState([]);
    const [dataSorted, setDataSorted] = useState([]);
    const [sortOrder, setSortOrder] = useState("");
    const [sort, setSort] = useState(null);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);

    const { t } = useTranslation();

    const orders = ["", "asc", "desc"];

    useEffect(() => {
        setInData(cloneDeep(data));
    }, [data]);

    const updateSort = (field) => {
        let order = orders[(orders.indexOf(sortOrder) + 1) % orders.length];
        setSortOrder(order);
        setSort(field);
    };

    const returnPageRows = (rows) => {
        let start = (page - 1) * max;
        if (start < 0) start = 0;
        return rows.slice(start, start + max);
    };

    useEffect(() => {
        if (sortOrder !== "") {
            const newData = orderBy(
                searchedData,
                [(row) => (typeof row[sort] === "number" ? row[sort] : row[sort]?.toLowerCase())],
                sortOrder
            );
            setDataSorted(newData);
        } else {
            setDataSorted(searchedData);
        }
    }, [sort, sortOrder, searchedData]);

    useEffect(() => {
        if (!inData || inData.length === 0) return;

        if (search === "") {
            setSearchedData(inData);
        } else if (search) {
            let s = search.toLowerCase();
            let keys = Object.keys(inData[0]);
            if (s.indexOf("=") >= 0) {
                const sa = s.split("=");
                keys = [sa[0]];
                s = sa[1];
            }
            const newData = inData?.filter((row) => {
                return keys.some((field) => {
                    const v = fieldInRow(row, field);
                    if (v) {
                        if (typeof v === "number" && v === Number(s)) return true;
                        if (typeof v === "string" && v.toLowerCase().indexOf(s) !== -1) return true;
                    }
                    return false;
                });
            });
            setSearchedData(newData);
        }
    }, [search, inData]);

    useEffect(() => {
        if (dataSorted) {
            const mp = Math.floor((dataSorted.length - 1) / max) + 1;
            setMaxPage(mp);
            if (mp < page && mp > 0) setPage(mp);
        }
    }, [dataSorted]);

    useEffect(() => {
        if (dataSorted) setVisibleRows(returnPageRows(dataSorted));
    }, [dataSorted, page]);

    return (
        <TableContainer className={className}>
            <table>
                <thead>
                    <tr>
                        {def?.map((header) => {
                            const sortable = header?.sortable || !("sortable" in header);
                            return (
                                <th
                                    scope="col"
                                    key={header.field}
                                    className={sortable && "sortable"}
                                    onClick={() => sortable && updateSort(header.field)}
                                >
                                    <TB>{t(header.label)}</TB>
                                    {sortable && (
                                        <SortIcon
                                            {...(header.field === sort ? { sort: sortOrder } : {})}
                                        />
                                    )}
                                </th>
                            );
                        })}
                        {actions && <td></td>}
                    </tr>
                </thead>
                <tbody>
                    {visibleRows?.map((row) => (
                        <tr key={row.id}>
                            {def.map((header) => (
                                <td
                                    {...(header?.header ? { scope: "col" } : {})}
                                    key={header.field}
                                >
                                    {row?._links?.filter((l) => l.field === header.field).length >
                                    0 ? (
                                        <NavLink
                                            to={
                                                row?._links?.find((l) => l.field === header.field)
                                                    .link
                                            }
                                        >
                                            <TB $link>{fieldInRow(row, header.field)}</TB>
                                        </NavLink>
                                    ) : (
                                        <T>{fieldInRow(row, header.field)}</T>
                                    )}
                                </td>
                            ))}
                            {actions && (
                                <td className={"actions"}>
                                    {actions.map((action) => (
                                        <TB
                                            key={action.label[0]}
                                            $link
                                            onClick={() => action.onClick(row.id)}
                                        >
                                            {t(action.label)}
                                        </TB>
                                    ))}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            {visibleRows.length !== inData.length && maxPage > 1 && (
                <section className={"pagination"}>
                    <CleanButton
                        disabled={page === 1}
                        onClick={() => setPage(page > 1 ? page - 1 : page)}
                    >
                        <TB $link={page > 1} className={"previous"}>
                            {t("previous")}
                        </TB>
                    </CleanButton>
                    {page > 3 && (
                        <>
                            <CleanButton onClick={() => setPage(1)}>
                                <TB $link className={"number"}>
                                    1
                                </TB>
                            </CleanButton>
                            <div className="dots">...</div>
                        </>
                    )}
                    {[page - 2, page - 1].map(
                        (n) =>
                            n > 0 && (
                                <CleanButton key={n} onClick={() => setPage(n)}>
                                    <TB $link className={"number"}>
                                        {n}
                                    </TB>
                                </CleanButton>
                            )
                    )}
                    <TB className={"number current"}>{page}</TB>
                    {[page + 1, page + 2].map(
                        (n) =>
                            n > 0 &&
                            n <= maxPage && (
                                <CleanButton key={n} onClick={() => setPage(n)}>
                                    <TB $link className={"number"}>
                                        {n}
                                    </TB>
                                </CleanButton>
                            )
                    )}
                    {page < maxPage - 2 && (
                        <>
                            <TB className="dots">...</TB>
                            <CleanButton onClick={() => setPage(maxPage)}>
                                <TB $link className={"number"}>
                                    {maxPage}
                                </TB>
                            </CleanButton>
                        </>
                    )}
                    <CleanButton
                        disabled={page === maxPage}
                        onClick={() => setPage(page < maxPage ? page + 1 : page)}
                    >
                        <TB $link={page !== maxPage} className={"next"}>
                            {t("next")}
                        </TB>
                    </CleanButton>
                </section>
            )}
        </TableContainer>
    );
};

export default LegacyTable;

const TableContainer = styled.div`
    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            text-align: left;
            border-bottom: 2px solid ${(props) => props.theme.color.neutral.xlight};
            height: 40px;

            .SortIcon {
                margin-left: 5px;
            }
        }

        a {
            text-decoration: none;
        }

        td.actions {
            text-align: right;
            > span {
                cursor: pointer;
            }
        }

        th.sortable {
            cursor: pointer;
        }
    }

    .pagination {
        padding-top: 10px;
        display: flex;
        flex-direction: row;

        span.number {
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .previous {
            height: 44px;
            display: flex;
            align-items: center;
            padding-right: 10px;
        }

        .dots {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .next {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-left: 10px;
        }

        .current {
            background-color: ${(props) => props.theme.color.primary.xlight};
        }
    }
`;

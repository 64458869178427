import styled from "styled-components";

import LoadingSpinner from "../../components/layout/LoadingSpinner";

const LoggingInSpinner = () => {
    return (
        <Container>
            <Content>
                <LoadingSpinner light />
            </Content>
        </Container>
    );
};

export default LoggingInSpinner;

const Container = styled.article`
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.color.primary.base};

    position: absolute;
    display: grid;
`;

const Content = styled.section`
    place-self: center;
    text-align: center;

    min-height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

import { fetchData, deleteEntry, updateData } from "../fetch";

const getCategory = async (id) =>
    fetchData(`core/periodic-technical-inspections/checkpoints/categories/${id}`);
const getCategories = async () =>
    fetchData(`core/periodic-technical-inspections/checkpoints/categories`);
const getCategoriesForCustomer = async (id) =>
    fetchData(`core/periodic-technical-inspections/checkpoints/categories?customer_id=${id}`);
const getCategoriesTemplates = async () =>
    fetchData(`core/periodic-technical-inspections/checkpoints/categories/templates`);

const updateCategory = async (data) =>
    updateData(data, `core/periodic-technical-inspections/checkpoints/categories`);
const deleteCategory = async (id) =>
    deleteEntry(id, `core/periodic-technical-inspections/checkpoints/categories`);

const getCheckpoint = async (id) =>
    fetchData(`core/periodic-technical-inspections/checkpoints/${id}`);
const getCheckpoints = async () => fetchData(`core/periodic-technical-inspections/checkpoints`);
const getCheckpointsForCustomer = async (id) =>
    fetchData(`core/periodic-technical-inspections/checkpoints/${id}`);

const updateCheckpoint = async (data) =>
    updateData(data, `core/periodic-technical-inspections/checkpoints`);
const deleteCheckpoint = async (id) =>
    deleteEntry(id, `core/periodic-technical-inspections/checkpoints`);

const getConfig = async (id) =>
    fetchData(`core/periodic-technical-inspections/configurations/${id}`);
const getConfigs = async () => fetchData(`core/periodic-technical-inspections/configurations`);
const getConfigTemplates = async () =>
    fetchData(`core/periodic-technical-inspections/configurations/templates`);
const getConfigsForCustomer = async (id) =>
    fetchData(`core/periodic-technical-inspections/configurations?customer_id=${id}`);

const updateConfig = async (data) =>
    updateData(data, `core/periodic-technical-inspections/configurations`);
const deleteConfig = async (id) =>
    deleteEntry(id, `core/periodic-technical-inspections/configurations`);

export default {
    getCategory,
    getCategories,
    getCategoriesForCustomer,
    getCategoriesTemplates,
    updateCategory,
    deleteCategory,

    getCheckpoint,
    getCheckpoints,
    getCheckpointsForCustomer,
    updateCheckpoint,
    deleteCheckpoint,

    getConfig,
    getConfigs,
    getConfigTemplates,
    getConfigsForCustomer,
    updateConfig,
    deleteConfig,
};

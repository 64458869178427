import { useQuery, useQueryClient } from "react-query";
import partAPI from "../../../api/core/partAPI";

export const useExternalWithdrawParts = () => {
  const queryClient = useQueryClient();

  return useQuery("externalwithdrawparts", partAPI.getExternalWithdrawParts, {
    onSuccess: (data) => {
      data.forEach((item) => queryClient.setQueryData(["externalwithdrawparts", item.id], item));
    },
  });
};

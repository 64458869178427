import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { useMedia } from "../../media/hooks/useMedia";

import LoadingSpinner from "../../../components/layout/LoadingSpinner";
import { CleanButton } from "../../../components/buttons";
import { TB } from "../../../components/texts";

const UnitDocument = ({ documentId, showDeleteButton = true }) => {
    const { data: documentData, isLoading } = useMedia(documentId);
    const { t } = useTranslation();
    const { getValues, setValue } = useFormContext();

    function handleRemoveDocument() {
        const wantsToRemove = window.confirm(t("confirm_removing_photo"));

        if (wantsToRemove) {
            const existingDocuments = getValues("data.documents");
            const newDocumentsArray = existingDocuments.filter(
                (document) => document !== documentId
            );
            setValue("data.documents", newDocumentsArray, { shouldDirty: true });
        }
    }

    return isLoading ? (
        <LoadingSpinner small light />
    ) : documentData.mimetype === "image/jpeg" ? (
        <DocumentContainerImg>
            <a href={documentData?.uri} target="_blank" rel="noreferrer noopener">
                <DocumentImg src={`${documentData?.uri}_thumbnail`} width={88} height={88} />
            </a>
            {showDeleteButton && (
                <RemoveDocumentButton type="button" onClick={handleRemoveDocument}>
                    <TB $link>x</TB>
                </RemoveDocumentButton>
            )}
        </DocumentContainerImg>
    ) : (
        <DocumentContainer>
            <a href={documentData?.uri} target="_blank" rel="noreferrer noopener">
                <DocumentInnerContainer>
                    <Document>{documentData?.title || documentData?.org_name}</Document>
                </DocumentInnerContainer>
            </a>
            {showDeleteButton && (
                <RemoveDocumentButton
                    type="button"
                    style={{ top: 0 }}
                    onClick={handleRemoveDocument}
                >
                    <TB $link>x</TB>
                </RemoveDocumentButton>
            )}
        </DocumentContainer>
    );
};

export default UnitDocument;

const DocumentContainer = styled.section`
    position: relative;
    margin-right: 0.3rem;
`;

const DocumentInnerContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Document = styled.div`
    display: inline-block;
    width: 100%;
    max-width: 11rem;
    color: #38558d;
    overflow-wrap: break-word;
`;

const DocumentContainerImg = styled.section`
    position: relative;
    width: 5.5rem;
    height: 5.5rem;
    margin-right: 0.3rem;
`;

const DocumentImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const RemoveDocumentButton = styled(CleanButton)`
    position: absolute;
    background: #fff;
    right: 0rem;
    padding: 0 0.3rem;

    ${TB} {
        font-size: 0.8rem;
    }
`;

import styled from "styled-components";

import { useMedia } from "../media/hooks/useMedia";
import QueryWrapper from "../../components/application/QueryWrapper";

import { TB } from "../../components/texts";
import { PDFIcon } from "../../components/icons";

const ReportPdfDownload = ({ media }) => {
    const mediaData = useMedia(media.media_id);

    return (
        <QueryWrapper data={mediaData}>
            <DownloadPDFLink href={mediaData?.data?.uri}>
                <IconContainer>
                    <PDFIcon />
                </IconContainer>
                <TB $link $underline>
                    {`${mediaData?.data?.title}.pdf`}
                </TB>
            </DownloadPDFLink>
        </QueryWrapper>
    );
};

export default ReportPdfDownload;

const DownloadPDFLink = styled.a`
    display: flex;
    align-items: baseline;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
`;

const IconContainer = styled.section`
    position: relative;
    top: 0.7rem;
    margin-right: 0.2rem;
`;

import { useMemo } from "react";
import {
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useServiceConfigTemplates } from "./hooks/useServiceConfigTemplates";
import { useServiceConfigsForCustomer } from "./hooks/useServiceConfigsForCustomer";

import { T, TB } from "../../../components/texts";
import { Input } from "../../../components/inputs";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const ConfigList = ({ customerId }) => {
    const { t } = useTranslation();

    const configTemplates = useServiceConfigTemplates();
    const configs = useServiceConfigsForCustomer(customerId);

    const data = useMemo(
        () =>
            configs?.data && configTemplates?.data
                ? customerId === 1
                    ? configs.data
                    : [...configTemplates.data, ...configs.data]
                : [],
        [configs?.data, configTemplates?.data]
    );

    const columns = useMemo(
        () => [
            {
                header: "Navn",
                accessorKey: "name",
                cell: ({ getValue, row }) =>
                    row.original?.customer_id === 1
                        ? `${getValue()} (${t("system_template")})`
                        : getValue(),
            },
            {
                header: "Intervall",
                accessorKey: "intervals",
                cell: ({ getValue }) =>
                    getValue()
                        .map((item) => item.interval)
                        .sort((a, b) => a - b)
                        .join(", "),
                enableSorting: false,
            },
            {
                id: "edit",
                cell: ({ row }) =>
                    row.original?.customer_id === 1 && customerId !== 1 ? null : (
                        <EditConfigLink to={`service/${row.original.id}`}>
                            <TB $link>{t("edit")}</TB>
                        </EditConfigLink>
                    ),
                enableSorting: false,
            },
        ],
        [customerId]
    );

    const initialState = useMemo(() => ({ pagination: { pageSize: 20 } }), []);

    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <>
            <Header>
                <SearchContainer>
                    <label htmlFor="configSearch">
                        <T>{t("search_for_service_config")}</T>
                    </label>

                    <Input
                        type="text"
                        name="configSearch"
                        id="configSearch"
                        onChange={(e) => table.setGlobalFilter(e.target.value)}
                    />
                </SearchContainer>

                <NewConfigLink to="service/0">{t("add_new_config")}</NewConfigLink>
            </Header>

            <TableStyled>
                <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
            </TableStyled>

            <TablePaginationNav
                pageCount={table.getPageCount()}
                previousPage={table.previousPage}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                canNextPage={table.getCanNextPage()}
                pageOptions={table.getPageOptions()}
                gotoPage={table.setPageIndex}
                pageIndex={table.getState().pagination.pageIndex}
            />
        </>
    );
};

export default ConfigList;

const TableStyled = styled(StandardTableStyle)`
    max-width: 50rem;

    tr {
        th:last-child,
        td:last-child {
            width: 5rem;
            padding-left: 1.5rem;
        }
    }
`;

const Header = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin-bottom: 2rem;
`;

const SearchContainer = styled.section`
    margin-right: 2rem;
    margin-bottom: 1rem;
`;

const NewConfigLink = styled(Link)`
    height: min-content;
    font-weight: 600;
    padding: 0.9rem 2rem;
    margin-bottom: 1rem;
    text-decoration: none;
    color: ${({ theme }) => theme.color.neutral.xxxlight};
    background: ${({ theme }) => theme.color.primary.base};
    transition: transform 50ms ease;

    &:hover {
        background: ${({ theme }) => theme.color.primary.light};
    }

    &:active {
        transform: scale(0.97);
    }
`;

const EditConfigLink = styled(Link)`
    text-decoration: none;
`;

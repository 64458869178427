import { useQuery } from "react-query";
import toast from "react-hot-toast";
import kitboxAPI from "../../../../api/core/kitboxAPI";

export const useKitbox = (id) =>
    useQuery(["kitbox", id], () => kitboxAPI.getKitbox(id), {
        enabled: !!id,
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
            );
            console.log("Loading error: ", error.message);
        },
    });

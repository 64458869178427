import { useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import inspectionAPI from "../../../../api/core/inspectionAPI";

export const useInspectionCategoriesForCustomer = (customerId) => {
    const queryClient = useQueryClient();
    const query = useQuery(
        ["inspection", "category", "customer", customerId],
        () => inspectionAPI.getCategoriesForCustomer(customerId),
        {
            enabled: !!customerId,
            onSucces: (data) => {
                data.forEach((item) =>
                    queryClient.setQueryData(["inspection", "category", data.id], item)
                );
            },
        }
    );

    return {
        ...query,
        data: useMemo(
            () =>
                query.data
                    ?.map((item) => ({
                        checkpoints: item.checkpoints?.sort((a, b) => a.id - b.id),
                        ...item,
                    }))
                    .sort((a, b) => a.id - b.id),
            [query.data]
        ),
    };
};

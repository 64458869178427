import { deleteEntry, fetchData, updateData, updateDataPatch } from "../fetch";

const getTaskPreChecks = async (customer_id) => fetchData(`core/task-pre-checks?customer_id=${customer_id}`);
const getTaskPreCheck = async (id) => fetchData(`core/task-pre-checks/${id}`);
const postTaskPreCheck = async (data) => updateData(data, "core/task-pre-checks", "POST");
const patchTaskPreCheck = async (id, data) => updateData(data, `core/task-pre-checks/${id}`, "PATCH");
const deleteCheckpoint = async (customerId, id) => deleteEntry(id, `core/task-pre-checks/${customerId}/checkpoints`);
const updateCheckpoint = async (customerId, data) => updateDataPatch(data, `core/task-pre-checks/${customerId}/checkpoints`)

export default {
    getTaskPreChecks,
    getTaskPreCheck,
    postTaskPreCheck,
    patchTaskPreCheck,
    deleteCheckpoint,
    updateCheckpoint,
};

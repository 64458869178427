export const HOST_URL = window.location.hostname;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL
    : window.location.hostname.includes("localhost")
      ? "http://localhost:3000/"
      : "https://" + window.location.hostname + "/api/";
export const SOCKET_BASE_URL = API_BASE_URL + "socket/";
const socket = new URL(SOCKET_BASE_URL);
export const SOCKET_HOSTNAME_URL =
    socket.protocol + "//" + socket.hostname + (socket.port ? ":" + socket.port : "");
export const SOCKET_PATH = socket.pathname + "socket.io/";
export const API_AUTH_BASE_URL = process.env.REACT_APP_API_AUTH_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL
    : window.location.hostname.includes("localhost")
      ? "http://localhost:3000/api/"
      : "https://" + window.location.hostname + "/api/";

export const INSPECTION_RESULT_TYPES = ["0", "1", "2", "3", "U", "-"];

export const TASK_TYPES = [
    { id: 1, name: "uk", active: false },
    { id: 2, name: "service", active: true },
    { id: 3, name: "periodic_technical_inspection", active: true },
    { id: 4, name: "ac/heating", active: true },
    { id: 5, name: "axles/hubs", active: true },
    { id: 6, name: "battery", active: true },
    { id: 7, name: "brakes", active: true },
    { id: 8, name: "chassis", active: true },
    { id: 9, name: "tires", active: true },
    { id: 10, name: "electric", active: true },
    { id: 11, name: "instrument/radio", active: true },
    { id: 12, name: "lights/camera/sensors", active: true },
    { id: 13, name: "mast/aggregate/forks/lifting_chain", active: true },
    { id: 14, name: "engine/exhaust/radiator", active: true },
    { id: 15, name: "pumps/gears/hydraulics", active: true },
    { id: 16, name: "cleaning", active: true },
    { id: 17, name: "chair", active: true },
    { id: 18, name: "steering/levers/steering_wheel", active: true },
    { id: 19, name: "cylinder", active: true },
    { id: 20, name: "windows/wipers/washers/mirrors", active: true },
    { id: 21, name: "waiting", active: true },
];

export const TASK_CAUSES = [
    { id: 1, name: "wrong_maintenance", active: true, order: 3 },
    { id: 2, name: "not_detectable", active: true, order: 3 },
    { id: 3, name: "component_failure", active: true, order: 3 },
    { id: 4, name: "upgrade", active: true, order: 3 },
    { id: 5, name: "inflicted_damage/user_influenced", active: true, order: 3 },
    { id: 6, name: "wear_and_tear", active: true, order: 3 },
    { id: 7, name: "preventive_maintenance", active: true, order: 2 },
    { id: 8, name: "administrative", active: true, order: 1 },
];

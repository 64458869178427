import { useState, useEffect } from "react";
import { NavLink, Route, useLocation, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PERMISSIONS } from "../../auth/permissions";
import { useCustomersWithPermission } from "../customers/hooks/useCustomersWithPermission";

import { device } from "../../../theme";
import QueryWrapper from "../../../components/application/QueryWrapper";
import ServiceSetupCustomerSelect from "./ServiceSetupCustomerSelect";
import MainArea from "../../../components/layout/MainArea";
import { H1 } from "../../../components/texts";
import ConfigList from "./ConfigList";
import Categories from "./Categories";
import ConfigSetup from "./ConfigSetup";

const ServiceSetup = () => {
    const [customerId, setCustomerId] = useState(null);
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const customers = useCustomersWithPermission({ permission: PERMISSIONS.service_admin.name });

    useEffect(() => {
        if (customers?.data?.length === 1) {
            setCustomerId(customers.data[0]?.id);
        }
    }, [customers?.data]);

    return (
        <MainArea>
            <Header>
                <PageTitle>
                    {pathname === "/administration/setup/service/categories"
                        ? t("categories_and_checkpoints")
                        : t("service_config_setup_link")}
                </PageTitle>

                <QueryWrapper data={customers}>
                    {customers?.data?.length > 1 ? (
                        <SelectWrapper>
                            <ServiceSetupCustomerSelect onChange={(e) => setCustomerId(e.value)} />
                        </SelectWrapper>
                    ) : null}
                </QueryWrapper>
            </Header>

            {customerId ? (
                <>
                    <TabNav>
                        <NavLink end to="">
                            {t("configurations")}
                        </NavLink>

                        <NavLink end to="categories">
                            {t("categories_and_checkpoints")}
                        </NavLink>

                        <hr />
                    </TabNav>

                    <Routes>
                        <Route path="categories" element={<Categories customerId={customerId} />} />
                        <Route
                            path="service/:serviceId"
                            element={<ConfigSetup customerId={customerId} />}
                        />
                        <Route index element={<ConfigList customerId={customerId} />} />
                    </Routes>
                </>
            ) : null}
        </MainArea>
    );
};

export default ServiceSetup;

const Header = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const PageTitle = styled(H1)`
    margin: 1rem 0;
    margin-right: 1rem;
`;

const SelectWrapper = styled.section`
    width: min(25rem, 100%);
`;

const TabNav = styled.nav`
    position: relative;
    margin: 2rem 0 4rem;

    a {
        position: relative;
        text-decoration: none;
        font-weight: bold;
        color: ${(p) => p.theme.color.primary.base};
        background: ${(p) => p.theme.color.primary.xlight};
        display: inline-block;
        padding: 0.8rem 1.3rem;
        margin-right: 0.4rem;
        border: 2px solid ${(p) => p.theme.color.primary.light};
        border-bottom: none;

        &.active {
            background: #fff;
            border-bottom: none;
            z-index: 1;
        }
    }

    hr {
        display: block;
        border: none;
        border-bottom: 2px solid ${(p) => p.theme.color.primary.light};
        margin: 0;
        position: absolute;
        bottom: 0;

        /* These values are based on the padding defined in the MainArea component */
        width: calc(100% + 5.625rem);
        transform: translate(-3.125rem);

        @media ${device.mobile} {
            width: calc(100% + 1.25rem);
            transform: translateX(-0.625rem);
        }
    }
`;

import { useCustomers } from "./useCustomers";
import { usePermissions, checkForPermission } from "../../../user/hooks/usePermissions";
import { useEffect, useState } from "react";

export const useCustomersWithPermission = ({ permission = null }) => {
    const customers = useCustomers();
    const permissions = usePermissions();
    const [state, setState] = useState({
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: null,
        data: null,
    });

    useEffect(() => {
        setState({
            isLoading: customers?.isLoading || permissions?.isLoading,
            isSuccess: customers?.isSuccess && permissions?.isSuccess,
            isError: customers?.isError || permissions?.isError,
            error: customers?.error,
            data:
                customers?.data && permissions?.data
                    ? customers?.data?.filter((item) =>
                          checkForPermission({
                              dataToCheck: permissions?.data,
                              permission,
                              customer_id: item.id,
                          })
                      )
                    : null,
        });
    }, [customers?.data, permissions?.data]);

    return state;
};

import { useMemo } from "react";
import { useCustomer } from "../admin/customers/hooks/useCustomer";
import { TaskStatus } from "../../api/core/taskAPI";

const TasksOverviewRowCost = ({
    customer_id,
    invoice_total,
    duration,
    partsCost,
    status,
    overtime,
    additionalExpensesCost,
}) => {
    const { data: customerData } = useCustomer(customer_id);

    const hoursCost = useMemo(() => {
        if (!customerData || !duration) return 0;

        let hourlyRate = customerData?.price_0;

        if (+overtime === 100) {
            hourlyRate = customerData?.price_100;
        } else if (+overtime === 50) {
            hourlyRate = customerData?.price_50;
        }

        return +duration * (hourlyRate || 0);
    }, [customerData, overtime, duration]);

    const totalSum = (hoursCost || 0) + (partsCost || 0) + (additionalExpensesCost || 0);

    function formatCostForDisplay(cost) {
        return cost ? `${cost.toLocaleString("no")} kr` : "–";
    }

    return status === TaskStatus.INVOICED
        ? formatCostForDisplay(invoice_total)
        : formatCostForDisplay(totalSum);
};

export default TasksOverviewRowCost;

import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ReactModal from "react-modal";
// import { ReactQueryDevtools } from "react-query/devtools";

import { addSavedUser } from "./app/localStorage";
import { useMyUser } from "./features/admin/users/hooks/useMyUser";
import { useAuth } from "./features/auth/hooks/useAuth";

import Reload from "./features/reload/Reload";
import PrivateApp from "./PrivateApp";
import PublicApp from "./PublicApp";
import OfflineModal from "./features/online-status/OfflineModal";
import NotificationContainer from "./features/notifications/NotificationContainer";
import ScrollToTop from "./components/application/ScrollToTop";
import WorkshopUnitsDisplay from "./features/display/workshop-units/WorkshopUnitsDisplay";
import { socket } from "./features/socket/SocketContext";
import DriverApp from "./features/display/drivers/DriverApp";
import DriverPublicApp from "./features/display/drivers/DriverPublicApp";

ReactModal.setAppElement("#root");

const App = () => {
    const { jwt, authUser } = useAuth();
    const userId = authUser?.sub;

    const user = useMyUser();

    useEffect(() => {
        if (jwt) {
            socket.io.opts.query = {
                token: jwt,
            };
            socket.connect();
        }
    }, [jwt]);

    useEffect(() => {
        if (!user?.data) return;

        addSavedUser(
            user.data.first_name + " " + user.data.last_name,
            user.data.username || user.data.email
        );
    }, [user?.data]);

    return (
        <>
            <ScrollToTop />
            <OfflineModal />
            <Reload />

            <Routes>
                {/* PUBLIC DISPLAY */}
                <Route
                    path={"/display/workshop-units/:customerId"}
                    element={<WorkshopUnitsDisplay />}
                />

                {/* DISPLAY FOR DRIVERS */}
                <Route
                    path="/display/drivers/public/:uuid"
                    element={<DriverPublicApp userId={userId} />}
                />
                <Route
                    path="/display/drivers/:id/*"
                    element={userId ? <DriverApp /> : <PublicApp />}
                />

                <Route path="*" element={userId ? <PrivateApp /> : <PublicApp />} />
            </Routes>
            <NotificationContainer />

            {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </>
    );
};

export default App;

import { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { add, endOfDay, isSameDay, startOfDay, sub } from "date-fns";

import { useUnitAreasPublic } from "../../unit/hooks/useUnitAreasPublic";

import { LogoIcon } from "../../../components/icons";
import { T, TB } from "../../../components/texts";
import Footer from "../../../components/layout/Footer";
import UnitsUpcomingTable from "./UnitsUpcomingTable";
import UnitsInProgressTable from "./UnitsInProgressTable";
import UnitsDoneTable from "./UnitsDoneTable";
import { usePublicTasks } from "../../task/hooks/usePublicTasks";
import { TaskStatus } from "../../../api/core/taskAPI";

const WorkshopUnitsDisplay = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const { t } = useTranslation();

    const location = useLocation();
    const routerParams = useParams();
    const queryParams = new URLSearchParams(location.search);

    const customerId = Number(routerParams.customerId);
    const selectedAreaIds = getAreaIdsFromQuery();
    const areas = useUnitAreasPublic();

    const fromDate = startOfDay(sub(currentDate, { days: 28 }));
    const toDate = endOfDay(add(currentDate, { days: 28 }));

    const tasks = usePublicTasks(
        customerId,
        fromDate.toISOString(),
        toDate.toISOString(),
        selectedAreaIds
    );

    const upcomingTimeTable = {
        fromDate: startOfDay(currentDate),
        toDate: endOfDay(add(currentDate, { days: 7 })),
    };
    const inProgressTimeTable = {
        fromDate: startOfDay(sub(currentDate, { days: 7 })),
        toDate: endOfDay(currentDate),
    };
    const doneTimeTable = {
        fromDate: sub(currentDate, { hours: 24 }),
        toDate: endOfDay(currentDate),
    };

    const sortedDataWithAreas = useMemo(
        () =>
            tasks?.data?.length
                ? tasks.data
                      .sort((a, b) => new Date(a.end) - new Date(b.end))
                      .map(addAreaNameToTask)
                : [],
        [tasks?.data, areas?.data]
    );

    const data = useMemo(
        () =>
            sortedDataWithAreas.length
                ? (() => {
                      const reducedData = sortedDataWithAreas.reduce(reduceTasksForTables, {
                          upcoming: [],
                          inProgress: [],
                          done: [],
                          unitsWithDefectWaitingForHighPriorityTask: new Set(),
                          unitsWithDefectFixed: new Map(),
                      });
                      return {
                          upcoming: reducedData.upcoming.sort(
                              (a, b) => new Date(a.start) - new Date(b.start)
                          ),
                          inProgress: reducedData.inProgress,
                          done: reducedData.done.sort((a, b) => new Date(b.end) - new Date(a.end)),
                      };
                  })()
                : {
                      upcoming: [],
                      inProgress: [],
                      done: [],
                  },
        [sortedDataWithAreas]
    );

    function addAreaNameToTask(task) {
        if (!areas?.data?.length) return task;

        const taskAreaName = areas.data.find((area) => area.id === task.unit?.area_id)?.name;
        return { ...task, area_name: taskAreaName || "–" };
    }

    function reduceTasksForTables(accumulator, currentTask) {
        if (currentTask.status === TaskStatus.DENIED) return accumulator;
        if (currentTask.status === TaskStatus.REQUEST) return accumulator;
        if (currentTask.status === TaskStatus.SCHEDULED_DRAFT) return accumulator;

        const isDone = filterTasksForDoneTable(accumulator, currentTask);
        if (isDone) {
            return accumulator;
        }

        const isInProgress = filterTasksForInProgressTable(accumulator, currentTask);
        if (isInProgress) {
            return accumulator;
        }

        if (new Date(currentTask.start) > upcomingTimeTable.toDate) return accumulator;
        if (new Date(currentTask.end) < upcomingTimeTable.fromDate) return accumulator;

        const isUpcoming =
            currentTask.status === TaskStatus.SCHEDULED &&
            !accumulator.inProgress.find((task) => task.unit_id === currentTask.unit_id);

        // Push task into upcoming if it's scheduled and there is no other task for the same unit in progress
        if (isUpcoming) {
            accumulator.upcoming.push(currentTask);
        }

        return accumulator;
    }

    function filterTasksForDoneTable(accumulator, currentTask) {
        if (new Date(currentTask.start) > doneTimeTable.toDate) return false;
        if (new Date(currentTask.end) < doneTimeTable.fromDate) return false;

        const isTaskDone =
            currentTask.status === TaskStatus.COMPLETED ||
            currentTask.status === TaskStatus.INVOICED;
        if (!isTaskDone) return false;

        if (currentTask.priority !== "high" && currentTask.unit.defect) return false;

        const isTaskUnitInProgressOrUpcoming = tasks.data.some((item) => {
            if (new Date(item.start) > doneTimeTable.toDate) return false;
            if (new Date(item.end) < doneTimeTable.fromDate) return false;
            const isSameTask = item.id === currentTask.id;
            const hasSameUnit = item.unit_id === currentTask.unit_id;
            const isStarted = item.status === TaskStatus.STARTED;
            const isScheduled = item.status === TaskStatus.SCHEDULED;

            return !isSameTask && hasSameUnit && (isStarted || isScheduled);
        });

        if (!isTaskUnitInProgressOrUpcoming) {
            const existingTaskForUnitThatDay = accumulator.done.findIndex(
                (element) =>
                    element.unit_id === currentTask.unit_id &&
                    isSameDay(new Date(element.end), new Date(currentTask.end))
            );
            if (existingTaskForUnitThatDay !== -1) {
                // Replace existing task with current value
                accumulator.done.splice(existingTaskForUnitThatDay, 1, currentTask);
                return true;
            }

            accumulator.done.push(currentTask);
            return true;
        }

        return false;
    }

    function filterTasksForInProgressTable(accumulator, currentTask) {
        if (currentTask.unit.defect && currentTask.priority === "high" && currentTask.end) {
            if (
                !accumulator.unitsWithDefectFixed.has(currentTask.unit_id) &&
                accumulator.unitsWithDefectWaitingForHighPriorityTask.has(currentTask.unit_id)
            ) {
                accumulator.inProgress = accumulator.inProgress.filter((element) => {
                    if (element.unit_id !== currentTask.unit_id) return true;
                    if (element.priority === "high") return true;

                    return false;
                });
                if (currentTask.end > currentTask.unit.defected_at) {
                    accumulator.inProgress.push(currentTask);
                    accumulator.unitsWithDefectWaitingForHighPriorityTask.delete(
                        currentTask.unit_id
                    );
                    accumulator.unitsWithDefectFixed.set(currentTask.unit_id, currentTask.end);
                    return true;
                }
            } else {
                if (
                    !accumulator.unitsWithDefectFixed.has(currentTask.unit_id) &&
                    currentTask.end > currentTask.unit.defected_at
                ) {
                    accumulator.unitsWithDefectFixed.set(currentTask.unit_id, currentTask.end);
                }

                const existingTaskForUnitThatDay = accumulator.inProgress.findIndex(
                    (element) =>
                        element.unit_id === currentTask.unit_id &&
                        isSameDay(new Date(element.end), new Date(currentTask.end))
                );
                if (existingTaskForUnitThatDay !== -1) {
                    // Replace existing task with current value
                    accumulator.inProgress.splice(existingTaskForUnitThatDay, 1, currentTask);
                    return true;
                }
            }
        }

        if (new Date(currentTask.start) > inProgressTimeTable.toDate) return false;
        if (new Date(currentTask.end) < inProgressTimeTable.fromDate) return false;

        if (currentTask.unit.defect && currentTask.priority !== "high") {
            const defectFixedAt = accumulator.unitsWithDefectFixed.get(currentTask.unit_id);
            if (defectFixedAt) {
                if (currentTask.end == null || currentTask.end < defectFixedAt) {
                    currentTask = {
                        ...currentTask,
                        end: defectFixedAt,
                    };
                }
                const existingInPgoressTaskForUnit = accumulator.inProgress.findIndex(
                    (element) =>
                        element.unit_id === currentTask.unit_id &&
                        new Date(element.end) >= new Date(currentTask.end)
                );
                if (existingInPgoressTaskForUnit === -1) {
                    accumulator.inProgress.push(currentTask);
                }

                return true;
            } else {
                for (const inProgress of accumulator.inProgress) {
                    if (inProgress.unit_id !== currentTask.unit_id) continue;
                    if (inProgress.end != null) continue;

                    return true;
                }

                currentTask = {
                    ...currentTask,
                    end: null,
                };

                accumulator.inProgress.push(currentTask);
                accumulator.unitsWithDefectWaitingForHighPriorityTask.add(currentTask.unit_id);
                return true;
            }
        }

        const existingInPgoressTaskForUnit = accumulator.inProgress.findIndex(
            (element) =>
                element.unit_id === currentTask.unit_id &&
                new Date(element.end) < new Date(currentTask.end)
        );
        if (existingInPgoressTaskForUnit !== -1) {
            // Replace existing task with current value
            accumulator.inProgress.splice(existingInPgoressTaskForUnit, 1, currentTask);
            return true;
        } else if (currentTask.status === TaskStatus.STARTED) {
            const existingInPgoressTaskForUnit = accumulator.inProgress.findIndex(
                (element) =>
                    element.unit_id === currentTask.unit_id &&
                    new Date(element.end) >= new Date(currentTask.end)
            );
            if (existingInPgoressTaskForUnit === -1) {
                accumulator.inProgress.push(currentTask);
            }
            return true;
        }

        return false;
    }

    // Update current date every hour
    useEffect(() => {
        const dateUpdater = window.setInterval(
            () => {
                updateCurrentDate();
            },
            60 * 60 * 1000
        );

        return () => window.clearInterval(dateUpdater);
    }, []);

    // Refresh the page every 3 hours
    // This fixes a strange "white page" issue for some displays
    useEffect(() => {
        const pageRefresher = window.setInterval(
            () => {
                window.location.reload();
            },
            3 * 60 * 60 * 1000
        );

        return () => window.clearInterval(pageRefresher);
    }, []);

    function updateCurrentDate() {
        setCurrentDate(new Date());
    }

    const areaNamesList = useMemo(() => {
        if (!areas?.data?.length || !selectedAreaIds?.length) return "–";

        const names = selectedAreaIds.map(
            (areaId) => areas.data.find((area) => area.id === areaId)?.name
        );

        return names?.join(", ");
    }, [areas?.data]);

    function getAreaIdsFromQuery() {
        const areaParams = queryParams.getAll("area");

        if (areaParams && areaParams.length) {
            return areaParams?.map((id) => +id)?.filter(Boolean);
        } else {
            return undefined;
        }
    }

    return (
        <>
            <Header>
                <LogoIcon />
            </Header>

            <AreaInfoContainer>
                <T>{t("useAreas")}: </T>
                <AreaNames>{areaNamesList}</AreaNames>
            </AreaInfoContainer>

            <TablesContainer>
                <UnitsUpcomingTable currentDate={currentDate} tasks={data.upcoming} areas={areas} />

                <SmallTables>
                    <UnitsInProgressTable
                        currentDate={currentDate}
                        tasks={data.inProgress}
                        areas={areas}
                    />
                    <UnitsDoneTable currentDate={currentDate} tasks={data.done} areas={areas} />
                </SmallTables>
            </TablesContainer>

            <Footer />
        </>
    );
};

export default WorkshopUnitsDisplay;

const Header = styled.header`
    background-color: ${(props) => props.theme.header.background.color};
    height: 3rem;
    padding-left: 3.125rem;
    display: flex;
    align-items: center;
`;

const AreaNames = styled(TB)`
    opacity: 0;
    animation: fadeIn 500ms 200ms ease forwards;
`;

const AreaInfoContainer = styled.section`
    background-color: ${(props) => props.theme.color.primary.xlight};
    padding: 1.25rem 3.125rem;
`;

const TablesContainer = styled.main`
    width: 100%;
    min-height: calc(100vh - calc(${(props) => props.theme.footer.height} + 7rem));
    padding: 0rem 3.125rem 3rem;
    display: flex;
    flex-wrap: wrap;
`;

const SmallTables = styled.section`
    flex-grow: 1;
`;

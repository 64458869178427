// Sourced mostly from https://levelup.gitconnected.com/useonlinestatus-a-react-hook-to-know-when-your-app-is-offline-2d06e4e536a

import { useState, useEffect, useContext, createContext } from "react";

const PING_FILE = "/ping.json";
const TIMEOUT_MS = 3000;
const POLLING_INTERVAL_MS = 10000;
const OnlineStatusContext = createContext(true);

export const useOnlineStatus = () => useContext(OnlineStatusContext);

export const OnlineStatusProvider = ({ children }) => {
    const [onlineStatus, setOnlineStatus] = useState(true);

    useEffect(() => {
        window.addEventListener("offline", () => setOnlineStatus(false));

        const id = setInterval(() => checkStatus(), POLLING_INTERVAL_MS);

        return () => {
            window.removeEventListener("offline", () => setOnlineStatus(false));
            clearInterval(id);
        };
    }, []);

    async function checkStatus() {
        const status = await checkOnlineStatus();
        setOnlineStatus(status);
    }

    return (
        <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
    );
};

function promiseTimeOut(time, promise) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error("Timeout"));
        }, time);

        promise.then(resolve, reject);
    });
}

async function checkOnlineStatus() {
    const controller = new AbortController();
    const { signal } = controller;

    if (!navigator.onLine) return navigator.onLine;

    try {
        await promiseTimeOut(
            TIMEOUT_MS,
            fetch(PING_FILE, {
                method: "GET",
                signal,
            })
        );
        return true;
    } catch (error) {
        console.error(error);
        controller.abort();
    }

    return false;
}

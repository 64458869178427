import { fetchData, updateData, fetchResponse, postData } from "../fetch";

const getPart = async (id) => fetchData(`core/part/${id}`);
const getPartByBarcode = async (barcode) => fetchData(`core/part/barcode/${barcode}`);
const getAll = async () => fetchData(`core/part`);
const getAllWithParams = async (params) => fetchResponse(`core/part${params || ""}`);
const updatePart = async (data) => updateData(data, `core/part`);
const postCustomerWithdraw = async (data) => postData(`core/part/withdraw`, data);
const getExternalWithdrawParts = async () => fetchData(`core/part/withdraw`);
const updateExternalPart = async (data) => updateData(data, `core/part/withdraw`, "PATCH");

export default {
    getPart,
    getPartByBarcode,
    getAll,
    updatePart,
    getAllWithParams,
    postCustomerWithdraw,
    getExternalWithdrawParts,
    updateExternalPart,
};

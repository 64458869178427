import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { TB } from "../texts";
import { LesserThanIcon } from "../icons";

const NavBarSubMenu = ({ module }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <Container>
            <SectionButton
                onClick={() => setIsSubMenuOpen((prev) => !prev)}
                className={!isSubMenuOpen ? "sub__closed" : ""}
            >
                <TB $second>{t(module.name)}</TB>
                <ChevronIcon second size="20" className={isSubMenuOpen ? "sub__open" : ""} />
            </SectionButton>

            <SubList className={isSubMenuOpen ? "sub__open" : ""}>
                {module.sub.map((item, index) => (
                    <li key={item.path + index}>
                        <StyledNavLink to={item.link ? item.link : item.path}>
                            {t(item.name)}
                        </StyledNavLink>
                    </li>
                ))}
            </SubList>
        </Container>
    );
};

export default NavBarSubMenu;

const Container = styled.section``;

const SubList = styled.ul`
    list-style: none;
    margin: 0;
    background: ${(props) => props.theme.color.primary.xlight};
    visibility: hidden;
    max-height: 0;
    overflow-y: hidden;
    padding: 0rem 0.625rem;
    transition: 150ms ease;

    > li {
        color: ${(props) => props.theme.text.color.link};
        padding: 0.5rem 0;

        &:not(:last-child):after {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            background: white;
            margin-top: 0.8rem;
        }
    }

    &.sub__open {
        visibility: initial;
        max-height: 20rem;
        padding: 0.5rem 0.625rem;
        transition: 300ms ease;
    }
`;

const SectionButton = styled.button`
    padding: 0.5rem 0;
    margin: 0 0.625rem;
    background: none;
    border: none;
    width: calc(100% - (0.625rem * 2));
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid;
    border-color: #fff0;

    &.sub__closed {
        border-color: #fff;
        transition: 200ms ease;
    }
`;

const StyledNavLink = styled(NavLink)`
    color: ${(props) => props.theme.text.color.link};
    font-weight: ${(props) => props.theme.font.tb["font-weight"]};
    font-family: ${(props) => props.theme.font.tb["font-family"]};
    text-decoration: none;
    display: block;
`;

const ChevronIcon = styled(LesserThanIcon)`
    transform: rotate(-90deg);
    transition: 300ms ease;

    &.sub__open {
        transform: rotate(90deg);
    }
`;

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";

import StandardTableStyle from "../../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../../components/application/StandardTableContent";

const TimeOffTable = ({ absences }) => {
    const { t } = useTranslation();

    const data = useMemo(
        () =>
            absences?.length ? absences.filter((absence) => absence.type === "avspasering") : [],
        [absences]
    );

    const columns = useMemo(
        () => [
            {
                header: t("time_off"),
                accessorKey: "from",
                cell: ({ getValue }) => formatDateForDisplay(getValue()) || "–",
            },
            {
                header: () => {
                    const sumOfAbsencesDuration = data.reduce(
                        (acc, absence) => acc + absence.durationInMinutes,
                        0
                    );

                    return `${sumOfAbsencesDuration / 60} ${t("hours_unit_symbol")}`;
                },
                enableSorting: false,
                accessorKey: "durationInMinutes",
                cell: ({ getValue }) => `${getValue() / 60} ${t("hours_unit_symbol")}`,
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    function formatDateForDisplay(date) {
        return date
            ? new Date(date).toLocaleDateString("no", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
              })
            : "";
    }

    return rowModel.rows.length ? (
        <Container>
            <TableStyled>
                <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
            </TableStyled>
        </Container>
    ) : null;
};

export default TimeOffTable;

const Container = styled.section`
    max-width: 35rem;
    margin-bottom: 3rem;
`;

const TableStyled = styled(StandardTableStyle)`
    th {
        border-bottom: 2px solid ${(p) => p.theme.color.neutral.base};

        > span {
            font-weight: normal;
        }
    }

    td:last-child,
    th:last-child {
        text-align: right;
        padding-right: 0;
    }
`;

import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import taskPreChecksAPI from '../../../../api/core/taskPreChecksAPI';

export const useSaveTaskPreCheckCheckpoint = ({ onSuccess }) => {
    const queryClient = useQueryClient();

    return useMutation(({customerId, data}) => taskPreChecksAPI.updateCheckpoint(customerId, data), {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["task-pre-checks"]);

            if (onSuccess instanceof Function) onSuccess(data,  variables, context);
        },
        onError: (error) => {
            toast.error(`Error: ${error.response?.data?.message || error.message || "unknown"}`);
            console.log("Saving error: ", error);
        },
    });
};

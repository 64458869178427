import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DownArrowIcon } from "../icons";
import { TB } from "../texts";

export const ExpandableSection = ({ label, expandedByDefault, children, expanded }) => {
    const [isExpanded, setIsExpanded] = useState(expandedByDefault ? true : false);

    const { t } = useTranslation();
    const formContext = useFormContext();
    const formState = formContext?.formState;

    useEffect(() => {
        if (typeof expanded === "boolean") setIsExpanded(expanded);
    }, [expanded]);

    // Automatically expand sections that have validation errors
    useEffect(() => {
        if (label === t("task") && errorsInclude("title")) setIsExpanded(true);

        if (label === t("task_details")) {
            if (errorsInclude("duration") || errorsInclude("mechanic_id")) setIsExpanded(true);
        }

        function errorsInclude(name) {
            return name in formState.errors;
        }
    }, [formState]);

    return (
        <Wrapper>
            <HeaderButton
                type="button"
                onClick={() => setIsExpanded((prev) => !prev)}
                className={isExpanded ? "expanded" : ""}
            >
                <TB>{label || ""}</TB>
                <DownArrowIcon className={isExpanded ? "expanded" : ""} />
            </HeaderButton>

            <InputArea className={isExpanded ? "expanded" : ""}>
                <Padding>{children}</Padding>
            </InputArea>
        </Wrapper>
    );
};

export default ExpandableSection;

const Wrapper = styled.article`
    background: ${(p) => p.theme.color.primary.xxlight};
    margin: 0.5rem 0;
    overflow: auto;
`;

const HeaderButton = styled.button`
    width: 100%;
    background: ${(p) => p.theme.color.primary.xlight};
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    transition: background 500ms ease;

    &.expanded {
        background: none;
        transition: background 50ms ease;
    }

    ${TB} {
        color: ${(p) => p.theme.color.primary.base};
    }

    ${DownArrowIcon} {
        height: 100%;
        transform: rotate(-90deg);
        transition: 300ms ease;

        &.expanded {
            transform: rotate(90deg);
        }
    }
`;

const InputArea = styled.section`
    display: none;
    overflow: hidden;
    max-height: 0;
    opacity: 0;

    &.expanded {
        display: block;
        max-height: 200rem;
        overflow: visible;
        opacity: 1;
    }
`;

const Padding = styled.div`
    padding: 0rem 1rem 2rem;
    overflow-y: auto;
`;

import toast from "react-hot-toast";
import { useQuery } from "react-query";
import sensorAPI from "../../../api/core/sensorAPI";

export const useSensor = (id) =>
    useQuery(["sensors", id], () => sensorAPI.getSensor(id), {
        enabled: !!id,
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
            );
            console.log("Loading error: ", error.message);
        },
    });

import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import { useCustomersForWorkshop } from "../admin/customers/hooks/useCustomersForWorkshop";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import { TASK_TYPES } from "../../constants";

import UnitSelect from "../unit/UnitSelect";
import ServerSelect from "../../components/application/ServerSelect";
import TaskStatusSelect from "./TaskStatusSelect";
import MechanicSelect from "../../components/application/MechanicSelect";
import {
    CheckBoxOneChoice,
    DateTimeInputForm,
    InputWithLabel,
    SelectForm,
} from "../../components/inputs";
import { shouldTaskAppearInCalendar } from "../scheduler/helpers";
import { T, TB } from "../../components/texts";
import { FlexGap } from "../../components/helpers/FlexGap";

const TaskEditMainSection = ({ selectedTask, isInMechanicView, workshopId }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();

    const status = watch("status");
    const host_id = watch("host_id");
    const customer_id = watch("customer_id");
    const task_check_template = watch("task_check_template");
    const workorder_id = selectedTask?.workorder?.id;

    const priorityOptions = [
        { value: "low", label: t("priority-low") },
        { value: "normal", label: t("priority-normal") },
        { value: "high", label: t("priority-high") },
    ];

    const taskTypeOptions = TASK_TYPES.filter((type) => type.active).map((type) => ({
        value: type.id,
        label: t(`task_type.${type.name}`),
    }));

    return (
        <MainSection>
            <Title>{t("activity")}</Title>

            {workorder_id ? <T>{`${t("order_no")} ${workorder_id}`}</T> : null}
            <MainActivityGrid>
                <GridBlock $columns={2}>
                    <UnitSelect
                        label={t("vehicle")}
                        filter={customer_id?.value ? { customer_id: customer_id.value } : null}
                        validate={{ required: t("unit_required") }}
                        isDisabled={Boolean(workorder_id)}
                    />
                </GridBlock>

                <GridBlock $columns={2}>
                    <ServerSelect
                        name="customer_id"
                        label={t("customer")}
                        selectUse={workshopId ? useCustomersForWorkshop : useCustomers}
                        selectKey={workshopId}
                        placeholder={t("select")}
                        validate={{ required: t("customer_required") }}
                        isDisabled={Boolean(workorder_id)}
                    />
                </GridBlock>

                <GridBlock>
                    <TaskStatusSelect isDisabled={isInMechanicView && !!selectedTask} />
                </GridBlock>

                <GridBlock>
                    <SelectForm
                        name="priority"
                        label={t("priority")}
                        options={priorityOptions}
                        placeholder={t("select")}
                        isDisabled={isInMechanicView && !!selectedTask}
                    />
                </GridBlock>
                <GridBlock $columns={2}>
                    <InputWithLabel
                        name={"customer_ref"}
                        label={t("customer_ref")}
                        disabled={isInMechanicView && !!selectedTask}
                    />
                </GridBlock>

                <GridBlock $columns={4} $rows={2}>
                    <InputWithLabel
                        label={t("task_description")}
                        name={"description"}
                        rows={7}
                        disabled={isInMechanicView && !!selectedTask}
                    />
                </GridBlock>

                <GridBlock>
                    <DateTimeInputForm
                        name={"start"}
                        label={t("start_time")}
                        required={shouldTaskAppearInCalendar(status?.value)}
                        hideErrorLabel
                    />
                </GridBlock>
                <GridBlock>
                    <InputWithLabel
                        label={`${t("duration")} (${t("hours_unit_symbol")})`}
                        name="duration"
                        type="number"
                        step="0.25"
                        min={0}
                        validate={
                            // Require user to specify duration if task is going to be inside calendar
                            shouldTaskAppearInCalendar(status?.value)
                                ? {
                                      required: t("duration_required"),
                                      min: 0.25,
                                  }
                                : false
                        }
                    />
                </GridBlock>
                <GridBlock $columns={2}>
                    <SelectForm
                        name="type_id"
                        label={t("category")}
                        options={taskTypeOptions}
                        placeholder={t("select")}
                        isDisabled={
                            (isInMechanicView && !!selectedTask) ||
                            // Disable type selection if task already has service data
                            task_check_template?.type === "Service" ||
                            task_check_template?.type === "PeriodicTechnicalInspection"
                        }
                        isClearable
                    />
                </GridBlock>

                <GridBlock $columns={2}>
                    <MechanicSelect
                        customer_id={
                            workshopId || selectedTask?.host_id || host_id.value || host_id || null
                        }
                        label={t("mechanic_responsible")}
                        validate={
                            // Require user to choose a mechanic if task is going to be inside calendar
                            shouldTaskAppearInCalendar(status?.value)
                                ? { required: t("mechanic_required") }
                                : false
                        }
                        isDisabled={isInMechanicView && !!selectedTask}
                    />
                </GridBlock>

                <GridBlock $columns={2}>
                    <RequestedByStyled>
                        <T>{t("requested_by")}:</T>

                        <T>
                            {selectedTask?.owner
                                ? `${selectedTask.owner.first_name || ""} ${
                                      selectedTask.owner.last_name || ""
                                  }`
                                : `–`}
                        </T>
                    </RequestedByStyled>
                </GridBlock>
            </MainActivityGrid>

            <FlexGap $count={3}>
                <CheckBoxOneChoice
                    name="overtime"
                    label={t("overtime")}
                    options={[
                        { value: "50", label: "50%" },
                        { value: "100", label: "100%" },
                    ]}
                />

                <CheckBoxOneChoice
                    name="living_expenses"
                    label={t("living_expenses")}
                    options={[
                        { value: "9.5t", label: "9,5 t" },
                        { value: "12t", label: "12 t" },
                        {
                            value: "overnatting",
                            label: "Overnatting",
                        },
                    ]}
                />

                <CheckBoxOneChoice
                    name="extras"
                    label={t("extras")}
                    options={[
                        {
                            value: "extra_callout",
                            label: "Utkalling",
                            independent: true,
                        },
                        { value: "extra_out", label: "Ute" },
                        { value: "extra_hall", label: "Hall" },
                        {
                            value: "extra_shift",
                            label: "Skift",
                            independent: true,
                        },
                    ]}
                />
            </FlexGap>
        </MainSection>
    );
};

export default TaskEditMainSection;

const Title = styled(TB)`
    display: inline-block;
    margin-bottom: 1rem;
`;

const MainSection = styled.article`
    background: ${(p) => p.theme.color.primary.xxlight};
    overflow: auto;
    margin: 0.5rem 0;
    padding: 1rem;
    padding-bottom: 3rem;

    ${Title} {
        padding-right: 1.5rem;
    }
`;

const MainActivityGrid = styled.section`
    display: grid;
    grid-template-columns: repeat(8, minmax(120px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 1.5rem;

    @media (max-width: 1400px) {
        grid-template-columns: repeat(4, minmax(120px, 1fr));
    }
`;

const GridBlock = styled.div`
    grid-column: span ${(p) => p.$columns || 1};
    grid-row: span ${(p) => p.$rows || 1};
`;

const RequestedByStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 61%;
`;

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useSaveImage } from "../media/hooks/useSaveImage";
import { TB } from "../../components/texts";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import SignatureImagePreview from "./SignatureImagePreview";

const SignatureInput = () => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();

    const { mutate: saveImage, isLoading: isSavingImage } = useSaveImage();
    const signature_media_id = watch("signature_media_id");

    function handleAddSignature(imageFile) {
        if (!imageFile) return;

        const formData = new FormData();

        formData.append("module", "user:signature");
        formData.append("customer_id", watch("customer_id"));
        formData.append("file", imageFile);

        saveImage(formData, {
            onSuccess: (response) => {
                setValue("signature_media_id", response.data?.id, { shouldDirty: true });
            },
        });
    }

    return (
        <Container>
            <TB>{t("signature")}</TB>

            {signature_media_id ? <SignatureImagePreview imageId={signature_media_id} /> : null}

            <HiddenFileInput
                id="signature-input"
                onChange={(e) => handleAddSignature(e.target.files[0])}
            />

            <AddImageLabel htmlFor="signature-input">
                {isSavingImage ? (
                    <LoadingSpinner small light />
                ) : (
                    <TB $link $underline>
                        {signature_media_id ? t("replace_signature") : `+ ${t("add_signature")}`}
                    </TB>
                )}
            </AddImageLabel>
        </Container>
    );
};

export default SignatureInput;

const Container = styled.section`
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

const AddImageLabel = styled.label`
    minwidth: max-content;
    align-self: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
`;

const HiddenFileInput = styled.input.attrs({ type: "file" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:focus-visible + ${AddImageLabel} {
        outline: 2px solid ${(p) => p.theme.color.primary.light};
    }
`;

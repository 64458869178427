import { useQuery } from "react-query";
import unitAPI from "../../../api/core/unitAPI";

export const useUnitsLastUsed = () =>
    useQuery(["units", { type: "lastUsed" }], async () =>
        unitAPI.getLastUsed().then((data) =>
            data
                ?.reverse()
                .reduce((arr, item) => [...arr.filter((i) => i.unit.id !== item.unit.id), item], [])
                .sort((a, b) => new Date(b.stopped) - new Date(a.stopped))
        )
    );

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { usePermission } from "../../features/user/hooks/usePermission";
import { useUnit } from "../../features/unit/hooks/useUnit";
import { useUnitTakeMutation } from "../../features/unit/mutations/useUnitTakeMutation";
import { PERMISSIONS } from "../../features/auth/permissions";

import CleanButton from "./CleanButton";
import Button from "./Button";
import { TB } from "../texts";
import { useAuth } from "../../features/auth/hooks/useAuth";

export const ChooseUnitButton = ({ id, text }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { authUser } = useAuth();
    const userId = authUser?.sub;

    const { data: unit } = useUnit(id);
    const permission = usePermission(PERMISSIONS.unit_use.name, unit?.customer_id);

    const { mutate: takeUnit } = useUnitTakeMutation();

    function handleUnit() {
        if (id) {
            if (unit?.user_id === userId) {
                navigate(`/unit/finish/${unit?.id}`);
            } else {
                if (unit?.user) {
                    takeUnit(id);
                } else {
                    navigate(`/unit/${unit?.id}`);
                }
            }
        }
    }

    return text ? (
        <CleanButton disabled={!id || unit?.defect || !permission} onClick={() => handleUnit()}>
            <TB $underline $link>
                {unit?.user
                    ? unit?.user_id === userId
                        ? t("finished")
                        : t("takeover")
                    : t("choose_vehicles")}
            </TB>
        </CleanButton>
    ) : (
        <Button disabled={!id || unit?.defect || !permission} onClick={() => handleUnit()}>
            {unit?.user
                ? unit?.user_id === userId
                    ? t("finished")
                    : t("takeover")
                : t("choose_vehicles")}
        </Button>
    );
};

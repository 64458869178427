import { useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import taskPostChecksAPI from "../../../../api/core/taskPostChecksAPI";

export const useTaskPostChecks = (customerId) => {
    const queryClient = useQueryClient();

    return useQuery(
        ["task-post-checks", customerId],
        () => taskPostChecksAPI.getTaskPostChecks(customerId),
        {
            enabled: !!customerId,
            onSuccess: (data) => {
                data?.forEach((item) =>
                    queryClient.setQueryData(["task-post-check", item.id], item)
                );
            },
            onError: (error) => {
                toast.error(
                    `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
                );
                console.log("Loading error: ", error.message);
            },
        }
    );
};

import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MainArea from "../../../components/layout/MainArea";
import { Button } from "../../../components/buttons";
import { H1, TB } from "../../../components/texts";
import { InputWithLabel, InputCheckbox } from "../../../components/inputs";
import { Horizontal } from "../../../components/layout/FlexGrid";
import Spacer from "../../../components/helpers/Spacer";
import CustomerUsers from "./CustomerUsers";
import EmployeeUsers from "./EmployeeUsers";

const Users = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const methods = useForm({ mode: "onChange" });

    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const userTypeFromUrl = query.get("type");

    const search = methods.watch("search");
    const showEmployees = methods.watch("show-employees");
    const showInactive = methods.watch("show-inactive");

    // Clear search input when changing tables
    useEffect(() => {
        methods.setValue("search", "");
    }, [showEmployees]);

    useEffect(() => {
        if (userTypeFromUrl === "employee") methods.setValue("show-employees", true);
    }, [userTypeFromUrl]);

    return (
        <MainArea>
            <Horizontal>
                <H1>{t("users_and_access")}</H1>
                <Spacer />
                <Button
                    onClick={() =>
                        navigate(
                            showEmployees
                                ? `/administration/users/0/edit?type=employee`
                                : `/administration/users/0/edit`
                        )
                    }
                >
                    <TB $second>{t("create_new_user")}</TB>
                </Button>
            </Horizontal>

            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        label={t("search_users")}
                        name={"search"}
                        autoComplete="off"
                        style={{ maxWidth: "27rem" }}
                    />

                    <Checkboxes>
                        <InputCheckbox name="show-employees" label={t("show_employees")} />
                        <InputCheckbox name="show-inactive" label={t("show_inactive_users")} />
                    </Checkboxes>

                    {showEmployees ? (
                        <EmployeeUsers showInactive={showInactive} search={search} />
                    ) : (
                        <CustomerUsers showInactive={showInactive} search={search} />
                    )}
                </form>
            </FormProvider>
        </MainArea>
    );
};

export default Users;

const Checkboxes = styled.section`
    display: flex;
    flex-wrap: wrap;

    > div {
        margin-right: 2rem;
    }
`;

import React, { Fragment, useEffect, useState } from "react";
import { H1, TB, T } from "../../components/texts";
import { useTranslation } from "react-i18next";
import UnitSearchInput from "../../components/application/unit/UnitSearchInput";
import { Col, Row } from "../../components/layout/FlexGrid";
import Underline from "../../components/helpers/Underline";
import { useTheme } from "styled-components";
import UnitUseIcon from "../../components/application/unit/UnitUseIcon";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import MainArea from "../../components/layout/MainArea";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import useMedia from "../../components/helpers/hooks/useMedia";

const EntryStyled = styled.div`
    min-height: 20px;
    padding-top: 8px;
    padding-bottom: 11px;
    cursor: pointer;
`;

const SearchUnit = () => {
    const { t } = useTranslation();
    const [units, setUnits] = useState(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const methods = useForm();
    const { isMobile } = useMedia();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <MainArea back>
            <H1>{t("unit", "Enhet")}</H1>
            <FormProvider {...methods}>
                <UnitSearchInput onChange={(u) => setUnits(u)} />
            </FormProvider>
            {isMobile && (
                <Row>
                    <Col size={1}></Col>
                    <Col size={3}>
                        <TB>{t("id", "ID")}</TB>
                    </Col>
                    <Col size={4}>
                        <TB>{t("group", "Group")}</TB>
                    </Col>
                    <Col size={4}>
                        <TB>{t("type", "Type")}</TB>
                    </Col>
                </Row>
            )}
            {!isMobile && (
                <Row>
                    <Col size={1}></Col>
                    <Col size={2}>
                        <TB>{t("id", "ID")}</TB>
                    </Col>
                    <Col size={3}>
                        <TB>{t("group", "Group")}</TB>
                    </Col>
                    <Col size={3}>
                        <TB>{t("type", "Type")}</TB>
                    </Col>
                </Row>
            )}
            <Underline color={theme.color.neutral.xlight} />
            {!units && <LoadingSpinner />}
            {units &&
                units.map((unit) => {
                    return (
                        <Fragment key={unit.id}>
                            <EntryStyled onClick={() => navigate(`/unit/${unit.id}`)}>
                                {isMobile && (
                                    <Row key={unit.id + "m"}>
                                        <Col size={1}>
                                            <UnitUseIcon unit={unit} />
                                        </Col>
                                        <Col size={3}>
                                            <T color={theme.color.primary.base}>{unit.int_id}</T>
                                        </Col>
                                        <Col size={4}>
                                            <T>{unit.group?.name}</T>
                                        </Col>
                                        <Col size={4}>
                                            <T>{unit.manufacturer}</T>
                                        </Col>
                                    </Row>
                                )}
                                {!isMobile && (
                                    <Row key={unit.id + "d"}>
                                        <Col size={1}>
                                            <UnitUseIcon unit={unit} />
                                        </Col>
                                        <Col size={2}>
                                            <T color={theme.color.primary.base}>{unit.int_id}</T>
                                        </Col>
                                        <Col size={3}>
                                            <T>{unit.group?.name}</T>
                                        </Col>
                                        <Col size={3}>
                                            <T>{`${unit.manufacturer} ${unit.type || ""} ${
                                                unit.custom_name ? `(${unit.custom_name})` : ``
                                            }`}</T>
                                        </Col>
                                    </Row>
                                )}
                            </EntryStyled>
                            <Underline color={theme.color.neutral.xlight} />
                        </Fragment>
                    );
                })}
        </MainArea>
    );
};

export default SearchUnit;

import { updateData, fetchData, patchData, deleteEntry } from "../fetch";

const getRecurringTasks = async () => fetchData(`core/recurring-tasks`);
const getRecurringTask = async (id) => fetchData(`core/recurring-tasks/${id}`);

const saveRecurringTask = async (data) => {
    if (data.id) {
        const { id, ...newData } = data;
        return patchData(`core/recurring-tasks/${id}`, newData);
    } else {
        return updateData(data, `core/recurring-tasks`);
    }
};

const deleteRecurringTask = async (id) => deleteEntry(id, `core/recurring-tasks`);

export default {
    getRecurringTasks,
    getRecurringTask,
    saveRecurringTask,
    deleteRecurringTask,
};

import { useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getExpandedRowModel,
} from "@tanstack/react-table";
import styled from "styled-components";

import { useEmployeesForCustomer } from "../hooks/useEmployeesForCustomer";
import QueryWrapper from "../../../components/application/QueryWrapper";

import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";
import { CleanButton } from "../../../components/buttons";
import { LesserThanIcon } from "../../../components/icons";
import EmployeeDetails from "./EmployeeDetails/EmployeeDetails";

const EmployeesHoursTable = ({ customer_id, absences, extras, tasks }) => {
    const { t } = useTranslation();

    const employees = useEmployeesForCustomer(customer_id);

    const prepareEmployeeRowData = useCallback(
        (employees) => {
            if (!tasks || !absences) return employees;

            const updatedEmployees = employees.reduce((updatedEmployees, employee) => {
                const tasksForEmployee = tasks.filter((task) => task.mechanic_id === employee.id);
                if (tasksForEmployee.length === 0 && !employee.active) return updatedEmployees;

                const absencesForEmployee = absences.filter(
                    (absence) => absence.user_id === employee.id
                );

                const totalWorkHours = sumUpTasksDuration(tasksForEmployee);
                const ordinaryWorkHours = sumUpTasksDuration(
                    tasksForEmployee?.filter((task) => !task.overtime)
                );
                const workHoursWithOvertime50 = sumUpTasksDuration(
                    tasksForEmployee?.filter((task) => task.overtime === 50)
                );
                const workHoursWithOvertime100 = sumUpTasksDuration(
                    tasksForEmployee?.filter((task) => task.overtime === 100)
                );
                const numberOfTasksWithDiet95 =
                    tasksForEmployee?.filter((task) => task.living_expenses === "9.5t")?.length ||
                    "-";
                const numberOfTasksWithDiet12 =
                    tasksForEmployee?.filter((task) => task.living_expenses === "12t")?.length ||
                    "-";
                const numberOfTasksWithDietOvernight =
                    tasksForEmployee?.filter((task) => task.living_expenses === "overnatting")
                        ?.length || "-";
                const numberOfTasksWithExtraCallout =
                    tasksForEmployee?.filter((task) => task.extra_callout)?.length || "-";
                const workHoursWithExtraHall = sumUpTasksDuration(
                    tasksForEmployee?.filter((task) => task.extra_hall)
                );
                const workHoursWithExtraOut = sumUpTasksDuration(
                    tasksForEmployee?.filter((task) => task.extra_out)
                );
                const workHoursWithExtraShift = sumUpTasksDuration(
                    tasksForEmployee?.filter((task) => task.extra_shift)
                );
                const numberOfTasksWithExtraOnWatch = extras.filter(
                    (extra) => extra.user_id === employee.id && extra.type === "on_watch"
                ).length;
                const totalAbsenceHours = sumUpAbsencesDuration(absencesForEmployee) / 60;

                updatedEmployees.push({
                    ...employee,
                    totalWorkHours,
                    ordinaryWorkHours,
                    workHoursWithOvertime50,
                    workHoursWithOvertime100,
                    numberOfTasksWithDiet95,
                    numberOfTasksWithDiet12,
                    numberOfTasksWithDietOvernight,
                    numberOfTasksWithExtraCallout,
                    workHoursWithExtraHall,
                    workHoursWithExtraOut,
                    workHoursWithExtraShift,
                    numberOfTasksWithExtraOnWatch,
                    totalAbsenceHours,
                });

                return updatedEmployees;
            }, []);

            return updatedEmployees;

            function sumUpTasksDuration(tasks) {
                return tasks?.reduce((acc, task) => acc + task.duration, 0) || 0;
            }

            function sumUpAbsencesDuration(absences) {
                return absences?.reduce((acc, absence) => acc + absence.durationInMinutes, 0) || 0;
            }
        },
        [employees?.data, tasks, absences]
    );

    const data = useMemo(
        () => (employees?.data ? prepareEmployeeRowData(employees.data) : []),
        [employees?.data, tasks, absences]
    );

    const columns = useMemo(
        () => [
            {
                header: () => null,
                id: "group_1",
                columns: [
                    {
                        header: t("employee"),
                        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
                    },
                    {
                        header: `${t("ordinary")} (${t("hours_unit_symbol")})`,
                        accessorKey: "ordinaryWorkHours",
                        cell: ({ getValue }) => getValue() || "-",
                    },
                    {
                        header: `50 % (${t("hours_unit_symbol")})`,
                        accessorKey: "workHoursWithOvertime50",
                        cell: ({ getValue }) => getValue() || "-",
                    },
                    {
                        header: `100 % (${t("hours_unit_symbol")})`,
                        accessorKey: "workHoursWithOvertime100",
                        cell: ({ getValue }) => getValue() || "-",
                    },
                ],
            },
            {
                header: t("diet"),
                id: "group_2",
                className: "blue-background border-around",
                columns: [
                    {
                        header: `9,5 ${t("hours_unit_symbol")}`,
                        accessorKey: "numberOfTasksWithDiet95",
                        className: "blue-background border-left",
                    },
                    {
                        header: `12 ${t("hours_unit_symbol")}`,
                        accessorKey: "numberOfTasksWithDiet12",
                        className: "blue-background",
                    },
                    {
                        header: t("overnight"),
                        accessorKey: "numberOfTasksWithDietOvernight",
                        className: "blue-background",
                    },
                ],
            },
            {
                header: t("extras"),
                id: "group_3",
                className: "blue-background border-around",
                columns: [
                    {
                        header: t("callout"),
                        accessorKey: "numberOfTasksWithExtraCallout",
                        className: "blue-background border-left",
                        cellClassName: "border-left",
                    },
                    {
                        header: `${t("hall")} (${t("hours_unit_symbol")})`,
                        accessorKey: "workHoursWithExtraHall",
                        className: "blue-background",
                        cell: ({ getValue }) => getValue() || "-",
                    },
                    {
                        header: `${t("out")} (${t("hours_unit_symbol")})`,
                        accessorKey: "workHoursWithExtraOut",
                        className: "blue-background",
                        cell: ({ getValue }) => getValue() || "-",
                    },
                    {
                        header: `${t("shift")} (${t("hours_unit_symbol")})`,
                        accessorKey: "workHoursWithExtraShift",
                        className: "blue-background",
                        cell: ({ getValue }) => getValue() || "-",
                    },
                    {
                        header: t("on_watch"),
                        accessorKey: "numberOfTasksWithExtraOnWatch",
                        className: "blue-background border-right",
                        cell: ({ getValue }) => getValue() || "-",
                    },
                ],
            },
            {
                header: () => null,
                id: "group_4",
                columns: [
                    {
                        header: `${t("absence")} (${t("hours_unit_symbol")})`,
                        accessorFn: (row) => row.totalAbsenceHours || "-",
                    },
                    {
                        header: t("hours_total"),
                        accessorFn: (row) => row.totalAbsenceHours + row.totalWorkHours || "-",
                    },
                    {
                        header: () => null,
                        id: "expandButton",
                        cell: ({ row }) => {
                            return row.getCanExpand() ? (
                                <CleanButton onClick={row.getToggleExpandedHandler()}>
                                    <Chevron $isExpanded={row.getIsExpanded()} />
                                </CleanButton>
                            ) : null;
                        },
                    },
                ],
            },
        ],
        []
    );

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <tr>
                <td colSpan={row.getVisibleCells().length}>
                    <EmployeeDetails
                        employeeId={row.original.id}
                        tasks={tasks}
                        absences={absences}
                        extras={extras}
                    />
                </td>
            </tr>
        ),
        [tasks, absences, extras]
    );

    const [expanded, setExpanded] = useState({});
    const table = useReactTable({
        columns,
        data,
        state: {
            expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: (row) =>
            row.totalAbsenceHours || row.totalWorkHours || row.numberOfTasksWithExtraOnWatch
                ? [{ id: row.id }]
                : [],
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <Container>
            <QueryWrapper data={[employees]}>
                {rowModel.rows.length > 0 ? (
                    <TableStyled>
                        <StandardTableContent
                            headerGroups={headerGroups}
                            rowModel={rowModel}
                            renderRowSubComponent={renderRowSubComponent}
                        />
                    </TableStyled>
                ) : (
                    <NothingToShow>{t("no_employees_to_show")}</NothingToShow>
                )}
            </QueryWrapper>
        </Container>
    );
};

export default EmployeesHoursTable;

const Container = styled.article`
    margin-top: 3rem;
    min-height: 10rem;
    overflow: auto;
`;

const TableStyled = styled(StandardTableStyle)`
    > thead > tr > th {
        &:not(:first-child) {
            text-align: center;
        }

        &.blue-background {
            background: ${(p) => p.theme.color.primary.xxlight};
        }
        &.border-around {
            border: 2px solid ${(p) => p.theme.color.neutral.xlight};
        }
        &.border-left {
            border-left: 2px solid ${(p) => p.theme.color.neutral.xlight};
        }
        &.border-right {
            border-right: 2px solid ${(p) => p.theme.color.neutral.xlight};
        }

        &:last-child {
            padding-right: 0;
        }
    }

    > tbody > tr > td {
        &:not(:first-child) {
            text-align: center;
        }

        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(13) {
            border-left: 2px solid ${(p) => p.theme.color.neutral.xlight};
        }

        &:last-child {
            min-width: 0;
            padding-right: 0;
        }
    }
`;

const Chevron = styled(LesserThanIcon)`
    transform: rotate(${(p) => (p.$isExpanded ? "90deg" : "-90deg")});
    transition: transform 0.4s ease;
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

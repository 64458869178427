import { useMemo, useCallback } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePartsWithParams } from "./hooks/usePartsWithParams";
import { useSearchParams } from "../application/hooks/useSearchParams";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import { usePermissions, checkForPermission } from "../user/hooks/usePermissions";
import { PERMISSIONS } from "../auth/permissions";
import useMedia from "../../components/helpers/hooks/useMedia";
import QueryWrapper from "../../components/application/QueryWrapper";

import StandardTableContent from "../../components/application/StandardTableContent";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import MainArea from "../../components/layout/MainArea";
import Spacer from "../../components/helpers/Spacer";
import { Horizontal } from "../../components/layout/FlexGrid";
import { H1, TB, T } from "../../components/texts";
import { Button } from "../../components/buttons";
import { Input } from "../../components/inputs";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";

const Parts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobile } = useMedia();

    const { getParam, setParam } = useSearchParams();

    const querySearch = getParam("search") || "";
    const queryPage = getParam("page") || 1;
    const pageSize = 25;

    const customers = useCustomers();
    const permissions = usePermissions();
    const partsWithParams = usePartsWithParams(
        `?take=${pageSize}&page=${queryPage}&search=${encodeURIComponent(querySearch)}`
    );

    const debounceSearch = useCallback(
        debounce((value) => {
            setParam("page", 1);
            setParam("search", value);
        }, 200),
        []
    );

    const data = useMemo(
        () =>
            partsWithParams?.data?.data && customers?.data
                ? partsWithParams.data.data.map((part) => ({
                      ...part,
                      customer:
                          customers.data.find((item) => item.id === part.customer_id)?.name || "–",
                  }))
                : [],
        [partsWithParams?.data?.data, customers?.data]
    );

    const columns = useMemo(
        () =>
            isMobile
                ? [
                      {
                          header: t("part_name"),
                          accessorKey: "name",
                          cell: ({ getValue, row }) =>
                              canEditPart(row.original) ? (
                                  <EditLink to={`/materials/parts/${row.original?.id}/edit`}>
                                      <TB $link>{getValue()}</TB>
                                  </EditLink>
                              ) : (
                                  <T>{getValue()}</T>
                              ),
                      },
                      {
                          header: t("supplier"),
                          accessorKey: "supplier_id",
                      },
                  ]
                : [
                      {
                          header: t("part_name"),
                          accessorKey: "name",
                          cell: ({ getValue, row }) =>
                              canEditPart(row.original) ? (
                                  <EditLink to={`/materials/parts/${row.original?.id}/edit`}>
                                      <TB $link>{getValue()}</TB>
                                  </EditLink>
                              ) : (
                                  <T>{getValue()}</T>
                              ),
                      },
                      {
                          header: t("description_2"),
                          accessorKey: "description",
                      },
                      {
                          header: t("sales_price"),
                          accessorKey: "price",
                          cell: ({ getValue }) => {
                              const value = getValue();
                              return value && !isNaN(value) ? (
                                  <T>
                                      {new Intl.NumberFormat("no-NO", {
                                          style: "currency",
                                          currency: "NOK",
                                      }).format(value)}
                                  </T>
                              ) : (
                                  value || "–"
                              );
                          },
                      },
                      {
                          header: t("supplier"),
                          accessorKey: "supplier_id",
                      },
                      {
                          header: t("warehouse_status"),
                          accessorKey: "stock",
                          cell: ({ getValue }) => (getValue() ? t("stock_item") : t("order_item")),
                      },
                      {
                          header: t("customer"),
                          accessorKey: "customer",
                      },
                  ],
        [permissions?.data, isMobile]
    );

    const hasPermissionToAddParts = useMemo(
        () =>
            permissions?.data
                ? !checkForPermission({
                      dataToCheck: permissions.data,
                      permission: PERMISSIONS.part_admin.name,
                  })
                : true,
        [permissions?.data]
    );

    function canEditPart(part) {
        if (!permissions?.data || permissions.data.length === 0) return false;

        return checkForPermission({
            dataToCheck: permissions.data,
            permission: PERMISSIONS.part_admin.name,
            customer_id: part?.customer_id,
        });
    }

    const table = useReactTable({
        data,
        columns,
        state: {
            pagination: {
                pageSize,
                pageIndex: queryPage - 1,
            },
        },
        manualPagination: true,
        pageCount: partsWithParams?.data?.meta?.pageCount ?? -1,
        onPaginationChange: (cb) => {
            const newPageState = cb({
                pageIndex: queryPage - 1,
            });

            setParam("page", newPageState.pageIndex + 1);
        },
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <MainArea>
            <QueryWrapper data={permissions}>
                <Horizontal>
                    <H1>{t("parts_overview")}</H1>
                    <Spacer />
                    <Button
                        onClick={() => navigate("/materials/parts/0/edit")}
                        disabled={hasPermissionToAddParts}
                        $bold
                    >
                        {t("add_new_part")}
                    </Button>
                </Horizontal>

                <SearchContainer>
                    <label htmlFor="partSearch">
                        <T>{t("search_parts")}</T>
                    </label>
                    <Input
                        type="text"
                        id="partSearch"
                        name="partSearch"
                        onChange={(e) => debounceSearch(e.target.value)}
                    />
                </SearchContainer>

                <QueryWrapper data={[partsWithParams, customers]}>
                    {rowModel.rows.length > 0 ? (
                        <TableContainer>
                            <TableStyled>
                                <StandardTableContent
                                    headerGroups={headerGroups}
                                    rowModel={rowModel}
                                />
                            </TableStyled>
                        </TableContainer>
                    ) : (
                        <NoParts>
                            <TB>{t("no_parts_to_show")}</TB>
                        </NoParts>
                    )}
                    <TablePaginationNav
                        pageCount={table.getPageCount()}
                        previousPage={table.previousPage}
                        canPreviousPage={table.getCanPreviousPage()}
                        nextPage={table.nextPage}
                        canNextPage={table.getCanNextPage()}
                        pageOptions={table.getPageOptions()}
                        gotoPage={table.setPageIndex}
                        pageIndex={table.getState().pagination.pageIndex}
                    />
                </QueryWrapper>
            </QueryWrapper>
        </MainArea>
    );
};

export default Parts;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:nth-child(3),
        td:nth-child(3) {
            text-align: right;
            padding-right: 3rem;
        }
        td:nth-child(3) {
            padding-right: 4rem;
        }
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.p}px) {
        min-width: 70rem;
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const NoParts = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const EditLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const SearchContainer = styled.section`
    max-width: 27rem;
`;

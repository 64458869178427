import { useQuery, useQueryClient } from "react-query";
import customerAPI from "../../../../api/core/customerAPI";

export const useCustomersForWorkshop = (workshopId) => {
    const queryClient = useQueryClient();

    return useQuery(
        ["customers", "workshop", workshopId],
        () => customerAPI.getCustomersForWorkshop(workshopId),
        {
            onSuccess: (data) => {
                data.forEach((item) => queryClient.setQueryData(["customers", item.id], item));
            },
        }
    );
};

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import mapboxgl, { Popup } from "mapbox-gl";

import { H1, TB } from "../../components/texts";
import { useEffect, useMemo, useRef, useState } from "react";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { useMultipleUnitSensorDataLast } from "../unit/hooks/useMultipleUnitSensorDataLast";
import { NavLink } from "react-router-dom";

mapboxgl.accessToken =
    "pk.eyJ1IjoibHN0cm9jaWFrIiwiYSI6ImNsNnAzN2Q3YjA2d2ozYmxwZWJ2a3lmbWUifQ.I0YRhD7jWyP5uTbNlXmSJQ";

const UnitStatusMap = ({ units }) => {
    const { t } = useTranslation();
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const sensorDataList = useMultipleUnitSensorDataLast(units.map((unit) => unit.id));
    const unitMap = useMemo(() => {
        const map = new Map();
        if (units == null) return map;
        for (const unit of units) {
            map.set(unit.id, unit);
        }
        return map;
    }, [units]);
    const [inUseCount, setInUseCount] = useState(0);
    const [notInUseCount, setNotInUseCount] = useState(0);
    const [defectCount, setDefectCount] = useState(0);

    // Initiate map
    useEffect(() => {
        if (mapRef.current) return;
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/lstrociak/cl6p3ee5l002314n04f4xw4t7",
            center: [13, 65],
            zoom: 4.2,
        });
        mapRef.current = map;

        mapRef.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

        mapRef.current.on("load", () => {
            mapRef.current.addSource("units", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });

            mapRef.current.addLayer({
                id: "units",
                type: "circle",
                source: "units",
                paint: {
                    "circle-radius": 5,
                    "circle-color": [
                        "match",
                        ["get", "status"],
                        "in-use",
                        "#62c370",
                        "not-in-use",
                        "#ffcd11",
                        "defected",
                        "#e23630",
                        "#000",
                    ],
                    "circle-stroke-color": "#38558d",
                    "circle-stroke-width": 2,
                },
            });

            mapRef.current.addLayer({
                id: "unit-names",
                type: "symbol",
                source: "units",
                layout: {
                    "text-field": ["format", ["get", "intId"], { "font-scale": 0.75 }],
                    "text-variable-anchor": ["bottom", "top", "left", "right"],
                    "text-radial-offset": 0.75,
                    "text-justify": "center",
                },
            });

            const popup = new Popup({
                closeButton: false,
                closeOnClick: false,
                className: "map-popup",
            });

            mapRef.current.on("mouseenter", "units", (e) => {
                mapRef.current.getCanvas().style.cursor = "pointer";

                const coordinates = e.features[0].geometry.coordinates.slice();
                const description = e.features[0].properties.description;

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                popup.setLngLat(coordinates).setHTML(description).addTo(mapRef.current);
            });

            mapRef.current.on("mouseleave", "units", (e) => {
                mapRef.current.getCanvas().style.cursor = "";
                popup.remove();
            });
        });
    }, []);

    useEffect(() => {
        if (!mapRef.current) return;
        if (!mapRef.current.loaded) return;

        const features = [];
        const mapDisplayBounds = new mapboxgl.LngLatBounds();
        let inUse = 0;
        let notInUse = 0;
        let defect = 0;
        for (const sensorData of sensorDataList) {
            if (sensorData.data == null) continue;

            const existingUnit = unitMap.get(sensorData.data.unit_id);
            if (existingUnit == null) continue;

            const longitude = Number(sensorData.data.data["position.longitude"]);
            if (isNaN(longitude)) continue;
            const latitude = Number(sensorData.data.data["position.latitude"]);
            if (isNaN(latitude)) continue;

            let status = "not-in-use";
            if (existingUnit.defect) {
                defect++;
                status = "defected";
            } else if (sensorData.data.data["engine.ignition.status"]) {
                inUse++;
                status = "in-use";
            } else {
                notInUse++;
            }

            const coordinates = [longitude, latitude];
            mapDisplayBounds.extend(coordinates);
            features.push({
                type: "Feature",
                properties: {
                    unitId: sensorData.data.unit_id,
                    intId: existingUnit.int_id,
                    engineIgnitionStatus: sensorData.data.data["engine.ignition.status"],
                    status,
                    description: `<p>${existingUnit.int_id}</p><hr /><p>${existingUnit.manufacturer} ${existingUnit.type}</p>`,
                },
                geometry: {
                    type: "Point",
                    coordinates,
                },
            });
        }

        if (features.length > 0) {
            mapRef.current.fitBounds(mapDisplayBounds, {
                padding: 80,
                maxZoom: 16,
            });
        }

        let onUnitSourceLoaded;
        const existingUnitsSource = mapRef.current.getSource("units");
        if (existingUnitsSource) {
            existingUnitsSource.setData({
                type: "FeatureCollection",
                features,
            });
        } else {
            onUnitSourceLoaded = (e) => {
                if (e.sourceId !== "units") return;
                if (!e.isSourceLoaded) return;

                mapRef.current.off("sourcedata", onUnitSourceLoaded);
                const unitSource = mapRef.current.getSource("units");
                unitSource.setData({
                    type: "FeatureCollection",
                    features,
                });
            };
            mapRef.current.on("sourcedata", onUnitSourceLoaded);
        }

        setInUseCount(inUse);
        setNotInUseCount(notInUse);
        setDefectCount(defect);

        if (onUnitSourceLoaded != null) {
            return () => mapRef.current.off("sourcedata", onUnitSourceLoaded);
        }
    }, [mapRef.current, mapRef.current?.loaded, sensorDataList, unitMap]);

    const isSensorDataLoading = false;
    return (
        <Flexbox>
            <VerticalFlexboxContainer>
                <H1NoMargin>{t("unit_status")}</H1NoMargin>
                <NavLink to={"/units/status"}>
                    <TB $link>{t("show_map")} ►</TB>
                </NavLink>
                <VerticalFlexbox>
                    <GreenDot />
                    {t("in_use_capitalized")} ({inUseCount})
                </VerticalFlexbox>
                <VerticalFlexbox>
                    <YellowDot />
                    {t("not_in_use")} ({notInUseCount})
                </VerticalFlexbox>
                <VerticalFlexbox>
                    <RedDot />
                    {t("defect")} ({defectCount})
                </VerticalFlexbox>
            </VerticalFlexboxContainer>
            <MapContainerStyled ref={mapContainerRef} className={"map-container"}>
                {isSensorDataLoading ? (
                    <SpinnerContainer>
                        <LoadingSpinner />
                    </SpinnerContainer>
                ) : null}
            </MapContainerStyled>
        </Flexbox>
    );
};

export default UnitStatusMap;

const GreenDot = styled.div`
    background: #62c370;
    width: 12px;
    height: 12px;
    border: 1.5px solid #38558d;
    border-radius: 50%;
`;

const YellowDot = styled.div`
    background: #ffcd11;
    width: 12px;
    height: 12px;
    border: 1.5px solid #38558d;
    border-radius: 50%;
`;

const RedDot = styled.div`
    background: #e23630;
    width: 12px;
    height: 12px;
    border: 1.5px solid #38558d;
    border-radius: 50%;
`;

const Flexbox = styled.div`
    display: flex;
    flex-direction: column;
`;

const H1NoMargin = styled(H1)`
    margin: 0;
`;

const VerticalFlexboxContainer = styled.div`
    display: flex;
    gap: 2rem;
    margin-bottom: 0.5rem;
    align-items: center;
`;

const VerticalFlexbox = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

const MapContainerStyled = styled.article`
    min-height: 400px;
    height: 100%;
    opacity: 0;
    animation: fadeIn 3000ms 200ms ease forwards;

    .map-popup .mapboxgl-popup-content {
        padding: 0.3rem 0;
        border-radius: 0;

        p {
            font-family: ${(props) => props.theme.font.t["font-family"]};
            font-size: ${(props) => props.theme.font.t["font-size"]};
            line-height: ${(props) => props.theme.font.t["line-height"]};
            margin: 0;
            padding-left: 0.5rem;
            padding-right: 1rem;

            &.hour-counter {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 0.3rem;
                }
            }
        }

        hr {
            margin: 0.3rem 0;
        }
    }
`;

const SpinnerContainer = styled.article`
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.5;
`;

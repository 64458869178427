import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCustomers } from "../customers/hooks/useCustomers";
import { useRoles } from "./hooks/useRoles";
import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import Spacer from "../../../components/helpers/Spacer";
import LegacyTable from "../../../components/application/LegacyTable";
import { H1, TB } from "../../../components/texts";
import { Horizontal } from "../../../components/layout/FlexGrid";
import { Button } from "../../../components/buttons";
import { InputWithLabel } from "../../../components/inputs";

const tableLayout = [
    {
        label: ["role"],
        field: "name",
    },
    {
        label: ["customer"],
        field: "customer",
    },
];

const Roles = () => {
    const [data, setData] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const methods = useForm();

    const roles = useRoles();
    const customers = useCustomers();

    const search = methods.watch("search");

    useEffect(() => {
        if (roles.data && customers.data) {
            setData(
                roles.data.map((role) => {
                    return {
                        ...role,
                        customer:
                            customers.data.find((item) => item.id === role?.customer_id)?.name ||
                            "Ukjent",
                    };
                })
            );
        }
    }, [roles?.data, customers?.data]);

    return (
        <MainArea>
            <Horizontal>
                <H1>{t("roles")}</H1>
                <Spacer />
                <Button onClick={() => navigate(`/administration/roles/0/edit`)}>
                    <TB $second>{t("new_role")}</TB>
                </Button>
            </Horizontal>

            <QueryWrapper data={[roles, customers]}>
                <section className={"role-table"}>
                    <FormProvider {...methods}>
                        <form>
                            <InputWithLabel
                                label={"Søk i roller"}
                                name={"search"}
                                style={{ maxWidth: "27rem" }}
                            />
                        </form>
                    </FormProvider>

                    <LegacyTable
                        def={tableLayout}
                        data={data}
                        search={search}
                        actions={[
                            {
                                label: ["edit", "Edit"],
                                onClick: (id) => navigate(`/administration/roles/${id}/edit`),
                            },
                        ]}
                    />
                </section>
            </QueryWrapper>
        </MainArea>
    );
};

export default Roles;

import { useState, useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import { usePart } from "../materials/hooks/usePart";
import { usePermissions, checkForPermission } from "../user/hooks/usePermissions";
import { PERMISSIONS } from "../auth/permissions";

import PartSelect from "../materials/PartSelect";
import { FlexGap } from "../../components/helpers/FlexGap";
import { T, TB } from "../../components/texts";
import { Button, CleanButton } from "../../components/buttons";
import { Input } from "../../components/inputs";
import { DownArrowIcon, TrashIcon } from "../../components/icons";

const TaskPartsSelector = ({ setPartsCost, hostId, kittingBox }) => {
    const [isAddingNewPart, setIsAddingNewPart] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    const { t } = useTranslation();
    const { watch, control, setValue } = useFormContext();
    const queryClient = useQueryClient();

    const permissions = usePermissions();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "parts",
        keyName: "fieldId",
    });

    const selectedPart = watch("part-select");
    const allSelectedPartValues = watch("parts");
    const partsCost =
        allSelectedPartValues?.reduce(
            (acc, taskPart) =>
                acc +
                ((taskPart?.count != null ? Number(taskPart.count) : 0) || 0) *
                    (taskPart?.price ?? taskPart?.part?.price ?? 0),
            0
        ) ?? 0;
    useEffect(() => {
        if (setPartsCost == null) return;
        setPartsCost(partsCost);
    }, [setPartsCost, partsCost]);

    useEffect(() => {
        if (isAddingNewPart && selectedPart) {
            const part =
                queryClient.getQueryData(["parts", selectedPart.value]) ||
                queryClient.getQueryData(["parts", selectedPart.id]) ||
                queryClient.getQueryData(["parts", selectedPart.part_id]);

            append(part);

            setIsAddingNewPart(false);
            setValue("part-select", "");
        }
    }, [selectedPart]);

    function addPart() {
        setIsAddingNewPart(true);
    }

    function handleRemovePart(id) {
        remove(id);
    }

    function handleSearchInputChange(input, { action }) {
        if (action !== "input-blur" && action !== "input-clear" && action !== "menu-close")
            setSearchInput(input);
    }

    function addCustomPart() {
        const newPart = {
            name: searchInput,
            custom: true,
        };
        append(newPart);
        setSearchInput("");
    }

    function canEditParts() {
        if (!permissions?.data || permissions.data.length === 0) return false;

        return checkForPermission({
            dataToCheck: permissions.data,
            permission: PERMISSIONS.part_admin.name,
            customer_id: hostId,
        });
    }

    return (
        <>
            <Header>
                <TB>{t("part_name_or_number")}</TB>
                <TB>{t("description_2")}</TB>
                <TB>{t("warehouse_status")}</TB>
                <TB>{t("location_2")}</TB>
                <TB>{t("empty")}</TB>
                <TB>{t("price")}</TB>
                <TB>{t("quantity")}</TB>
                <TB>{t("sum")}</TB>
                <div />
            </Header>

            {fields &&
                fields.map((field, index) => (
                    <Row
                        key={field.fieldId}
                        removePart={() => handleRemovePart(index)}
                        part={field}
                        index={index}
                        kittingBox={kittingBox}
                    />
                ))}

            {isAddingNewPart ? (
                <PartSearchSection>
                    <PartSelectWrapper>
                        <PartSelect
                            name="part-select"
                            onInputChange={handleSearchInputChange}
                            inputValue={searchInput}
                            // Disable parts that are already in the list
                            isOptionDisabled={(option) =>
                                fields.some((item) =>
                                    item.part_id
                                        ? item.part_id === option.value
                                        : item.id === option.value
                                )
                            }
                        />
                    </PartSelectWrapper>

                    {searchInput && canEditParts() ? (
                        <RegisterPartButton type="button" onClick={addCustomPart}>
                            {t("register_part")}
                        </RegisterPartButton>
                    ) : null}
                </PartSearchSection>
            ) : (
                <AddPartButton type="button" onClick={addPart}>
                    <TB $link $underline>
                        + {t("add_part")}
                    </TB>
                </AddPartButton>
            )}
        </>
    );
};

export default TaskPartsSelector;

const Header = styled.section`
    display: flex;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};
    min-width: 40rem;

    > :nth-child(1) {
        width: 25%;
        margin-right: 0.5rem;
    }
    > :nth-child(2) {
        width: 20%;
        margin-right: 0.5rem;
    }
    > :nth-child(3) {
        width: 12%;
        margin-right: 1rem;
    }
    > :nth-child(4) {
        width: 13%;
        margin-right: 0.5rem;
    }
    > :nth-child(5) {
        width: 2.5rem;
    }
    > :nth-child(6) {
        width: 7%;
        margin-right: 1.5rem;
        text-align: right;
    }
    > :nth-child(7) {
        width: 5%;
        margin-right: 0.5rem;
    }
    > :nth-child(8) {
        width: 9%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        text-align: right;
        min-width: 4.25rem;
    }
    > :nth-child(9) {
        width: 2.5rem;
        display: block;
    }
`;

const AddPartButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 2rem 0 1rem;
`;

const RegisterPartButton = styled(Button)`
    margin-bottom: 1rem;
`;

const PartSearchSection = styled.section`
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
`;

const PartSelectWrapper = styled.section`
    min-width: 25rem;
    margin-right: 0.5rem;
`;

const Row = ({ part, removePart, index, kittingBox }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedPart, setSelectedPart] = useState(null);
    const { register, watch } = useFormContext();

    const { data: partFromDatabase } = usePart(part.part_id || part.id);

    const { t } = useTranslation();
    const partCount = watch(`parts.${index}.count`);

    useEffect(() => {
        if (part.custom) {
            setSelectedPart(part);
        } else if (partFromDatabase) {
            setSelectedPart(partFromDatabase);
        }
    }, [part, partFromDatabase]);

    function formatNumberToCurrency(number) {
        return new Intl.NumberFormat("no-NO", {
            style: "currency",
            currency: "NOK",
            maximumFractionDigits: 0,
        }).format(number);
    }

    return (
        <RowStyled>
            {selectedPart && (
                <>
                    <RowHeader>
                        {part.custom ? (
                            <PartInputContainer>
                                <Input
                                    defaultValue={part.name}
                                    {...register(`parts.${index}.name`)}
                                />
                            </PartInputContainer>
                        ) : (
                            <ButtonExpand
                                type="button"
                                onClick={() => setIsExpanded((prev) => !prev)}
                            >
                                <ArrowContainer>
                                    <DownArrowIcon className={isExpanded ? "expanded" : ""} />
                                </ArrowContainer>

                                <T>{selectedPart.name}</T>
                            </ButtonExpand>
                        )}

                        {part.custom ? (
                            <PartInputContainer>
                                <Input {...register(`parts.${index}.description`)} />
                            </PartInputContainer>
                        ) : (
                            <T>{selectedPart.description || "–"}</T>
                        )}

                        <T>
                            {part.custom
                                ? "–"
                                : selectedPart.stock
                                  ? t("stock_item")
                                  : t("order_item")}
                        </T>

                        <T>
                            {kittingBox && !selectedPart.stock
                                ? `${t("kitbox")} ${kittingBox.name}`
                                : selectedPart.location || "–"}
                        </T>

                        <T>
                            {selectedPart.stock ? (
                                <Input
                                    defaultValue={false}
                                    value={true}
                                    style={{ width: "50%" }}
                                    type="checkbox"
                                    {...register(`parts.${index}.empty`, {})}
                                />
                            ) : (
                                "–"
                            )}
                        </T>

                        <T>
                            {selectedPart.price ? formatNumberToCurrency(selectedPart.price) : "–"}
                        </T>

                        <Input
                            defaultValue={1}
                            type="number"
                            min={1}
                            {...register(`parts.${index}.count`)}
                            style={{ paddingRight: "0.3rem", paddingLeft: "0.3rem" }}
                        />

                        <T>
                            {selectedPart.price && partCount
                                ? formatNumberToCurrency(selectedPart.price * Number(partCount))
                                : "–"}
                        </T>

                        <ButtonRemove
                            type="button"
                            onClick={() => {
                                removePart(part);
                            }}
                        >
                            <TrashIcon />
                        </ButtonRemove>
                    </RowHeader>

                    <RowDetails className={isExpanded ? "expanded" : ""}>
                        <Padding>
                            <FlexGap $count={4}>
                                <T>
                                    <TB>{t("supplier")}:</TB>
                                    {selectedPart.supplier_id || "–"}
                                </T>

                                <T>
                                    <TB>{t("barcode")}:</TB> {selectedPart.barcode || "–"}
                                </T>
                            </FlexGap>
                        </Padding>
                    </RowDetails>
                </>
            )}
        </RowStyled>
    );
};

const RowStyled = styled.section`
    padding: 0.5rem 0 0.2rem;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};
    min-width: 40rem;
`;

const RowHeader = styled.div`
    display: flex;
    align-items: center;

    > :nth-child(1) {
        width: 25%;
        margin-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(2) {
        width: 20%;
        margin-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(3) {
        width: 12%;
        margin-right: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(4) {
        width: 13%;
        margin-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(5) {
        width: 2.5rem;
    }
    > :nth-child(6) {
        width: 7%;
        margin-right: 1.5rem;
        text-align: right;
    }
    > :nth-child(7) {
        width: 5%;
        text-align: right;
        margin-right: 0.5rem;
    }
    > :nth-child(8) {
        width: 9%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        text-align: right;
        min-width: 4.25rem;
    }
    > :nth-child(9) {
        width: 2.5rem;
        display: block;
    }
`;

const ButtonExpand = styled(CleanButton)`
    display: flex;
    align-items: center;

    ${T} {
        text-align: left;
    }
`;

const PartInputContainer = styled.section`
    padding-right: 1.5rem;
`;

const ArrowContainer = styled.span`
    margin-right: 1rem;

    > svg {
        height: 80%;
        transform: rotate(270deg);
        transition: transform 300ms ease;

        &.expanded {
            transform: rotate(90deg);
        }
    }
`;

const ButtonRemove = styled(CleanButton)``;

const RowDetails = styled.div`
    overflow: hidden;
    max-height: 0;
    transition: 500ms ease;

    &.expanded {
        max-height: 15rem;
    }
`;

const Padding = styled.div`
    padding: 1rem 0 1.5rem;
`;

import ServerSelect from "./ServerSelect";
import { useMechanics } from "./hooks/useMechanics";
import { useTranslation } from "react-i18next";

const MechanicSelect = ({ customer_id, label, ...props }) => {
    const { t } = useTranslation();

    return (
        <ServerSelect
            name="mechanic_id"
            label={label ? label : t("mechanic")}
            selectUse={useMechanics}
            selectKey={customer_id}
            placeholder={t("select")}
            {...props}
        />
    );
};

export default MechanicSelect;

import { useTranslation } from "react-i18next";
import { TaskStatus } from "../../api/core/taskAPI";
import { SelectForm } from "../../components/inputs";

const TaskStatusSelect = ({ ...props }) => {
    const { t } = useTranslation();

    const options = Object.keys(TaskStatus).map((key) => ({
        value: TaskStatus[key],
        label: t(`status-${TaskStatus[key]}`),
    }));

    return <SelectForm name="status" label={t("status")} options={options} {...props} />;
};

export default TaskStatusSelect;

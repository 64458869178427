import { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import styled from "styled-components";

import { useRecurringTask } from "./hooks/useRecurringTask";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import { useDeleteRecurringTask } from "./hooks/useDeleteRecurringTask";
import { useSaveRecurringTask } from "./hooks/useSaveRecurringTask";
import { useSavePart } from "../materials/hooks/useSavePart";
import { useUnit } from "../unit/hooks/useUnit";
import { TASK_TYPES } from "../../constants";
import { convertDateToMilitary, convertMilitaryToDate } from "../admin/setup/helpers";

import { H1, TB } from "../../components/texts";
import {
    DateTimeInputForm,
    ExpandableSection,
    InputWithLabel,
    SelectForm,
} from "../../components/inputs";
import { CleanButton } from "../../components/buttons";
import { GridBlock } from "../../components/layout/Grid";
import UnitSelect from "../unit/UnitSelect";
import ServerSelect from "../../components/application/ServerSelect";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import MechanicSelect from "../../components/application/MechanicSelect";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import TaskPartsSelector from "../task/TaskPartsSelector";
import TaskAdditionalExpenses from "../task/TaskAdditionalExpenses";
import RecurringTaskServiceSelectSection from "./RecurringTaskServiceSelectSection";
import ConfirmModal from "../../components/application/ConfirmModal";

const RecurringTaskEdit = ({ taskId, isOpen, onClose, workshopId }) => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const { t } = useTranslation();

    const methods = useForm();

    const { data: recurringTask, isLoading } = useRecurringTask(taskId);
    const { mutate: saveTask, isLoading: isSavingTask } = useSaveRecurringTask();
    const { mutateAsync: savePart, isLoading: isSavingPart } = useSavePart();
    const { mutate: deleteTask, isLoading: isDeletingTask } = useDeleteRecurringTask({
        onSuccess: onClose,
    });

    useEffect(() => {
        if (!recurringTask) return;

        methods.reset({
            ...recurringTask,
            start: recurringTask.start ? new Date(recurringTask.start) : null,
            end: recurringTask.end ? new Date(recurringTask.end) : null,
            task_start_time: recurringTask.task_start_time
                ? convertMilitaryToDate(recurringTask.task_start_time)
                : null,
            type_id: recurringTask.type_id
                ? taskTypeOptions.find((option) => option.value === recurringTask.type_id)
                : null,
            recurrence: recurringTask.recurrence?.days
                ? recurrenceOptions.find((option) => option.value === recurringTask.recurrence.days)
                : null,
            parts: recurringTask.parts || [],
            additional_expenses: recurringTask.additional_expenses || [],
            task_check_template: recurringTask.task_check_template
                ? JSON.parse(recurringTask.task_check_template)
                : null,
        });
    }, [recurringTask]);

    const customer_id = methods.watch("customer_id");
    const unit_id = methods.watch("unit_id");
    const type_id = methods.watch("type_id");
    const selectedParts = methods.watch("parts");
    const startDate = methods.watch("start");
    const endDate = methods.watch("end");

    const selectedUnit = useUnit(unit_id?.value);

    // Set customer to unit owner automatically on unit change
    useEffect(() => {
        if (selectedUnit?.data) {
            methods.setValue("customer_id", selectedUnit.data.customer_id);
        }
    }, [selectedUnit?.data]);

    // Reset unit when a different customer is selected
    useEffect(() => {
        if (
            customer_id?.value &&
            selectedUnit?.data &&
            customer_id.value !== selectedUnit.data.customer_id
        ) {
            methods.setValue("unit_id", null);
        }
    }, [customer_id]);

    async function onSubmit(data) {
        const post = {
            host_id: workshopId,
            customer_id: data.customer_id?.value,
            unit_id: data.unit_id?.value,
            description: data.description || "",
            internal_note: data.internal_note || "",
            duration: +data.duration || 0,
            invoicable: true,
            start: data.start || null,
            end: data.end || null,
            task_start_time: data.task_start_time
                ? convertDateToMilitary(data.task_start_time)
                : null,
            recurrence: data.recurrence?.value ? { days: data.recurrence.value } : null,
            type_id: data.type_id?.value || null,
            parts: [],
            additional_expenses: data.additional_expenses || [],
            priority: "normal",
            pre_check_template: JSON.stringify({}),
            task_check_template: JSON.stringify({}),
            post_check_template: JSON.stringify({}),
        };

        if (data.mechanic_id?.value) {
            post.mechanic_id = data.mechanic_id.value;
        }
        if (
            data.type_id?.value === 2 &&
            (data.task_check_template || data.selected_service_interval?.template)
        ) {
            post.task_check_template = data.selected_service_interval?.template
                ? JSON.stringify(data.selected_service_interval.template)
                : JSON.stringify(data.task_check_template);
        }

        const selectedParts = data.parts?.filter((part) => part.part_id || part.id);
        const newCustomParts = data.parts?.filter((part) => part.custom);

        if (selectedParts?.length) {
            post.parts = selectedParts.map((part) => ({
                part_id: part.part_id || part.id,
                count: Number(part.count) || 1,
            }));
        }

        // Create any new custom parts in the database before adding them to the post object
        if (newCustomParts?.length) {
            await Promise.all(
                newCustomParts.map((newPart) =>
                    savePart({
                        name: newPart.name,
                        description: newPart.description,
                        customer_id: data.customer_id?.value,
                        stock: false,
                    }).then((response) => {
                        post.parts.push({
                            part_id: response.data?.id,
                            count: Number(newPart.count),
                        });
                    })
                )
            );
        }

        if (data.id) {
            post.id = data.id;
            delete post.customer_id;
        }

        saveTask(post, { onSuccess: onClose });
    }

    const recurrenceOptions = useMemo(
        () => [
            { value: 7, label: `${t("every_week")} (7 ${t("days")})` },
            { value: 14, label: `${t("every_other_week")} (14 ${t("days")})` },
            { value: 21, label: `${t("every_third_week")} (21 ${t("days")})` },
            { value: 28, label: `${t("every_month")} (28 ${t("days")})` },
            { value: 56, label: `${t("every_other_month")} (56 ${t("days")})` },
            { value: 182, label: `${t("every_half_year")} (182 ${t("days")})` },
            { value: 365, label: `${t("every_year")} (365 ${t("days")})` },
        ],
        []
    );

    const taskTypeOptions = TASK_TYPES.filter((type) => type.active).map((type) => ({
        value: type.id,
        label: t(`task_type.${type.name}`),
    }));

    function handleDeleteTask() {
        if (taskId) deleteTask(taskId);
    }

    function askToRemoveTask() {
        setIsConfirmModalOpen(true);
    }

    return (
        <ModalStyled
            isOpen={isOpen}
            onRequestClose={onClose}
            className="Modal"
            overlayClassName="ModalOverlay"
            shouldCloseOnOverlayClick={false}
        >
            <TitleStyled>
                {taskId ? t("edit_recurring_task") : t("add_new_recurring_task")}
            </TitleStyled>

            {isLoading ? (
                <SpinnerContainer>
                    <LoadingSpinner light />
                </SpinnerContainer>
            ) : (
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <MainSection>
                            <MainSectionGrid>
                                <GridBlock $columns={2}>
                                    <UnitSelect
                                        label={t("vehicle")}
                                        filter={
                                            customer_id?.value
                                                ? { customer_id: customer_id.value }
                                                : null
                                        }
                                        validate={{ required: t("unit_required") }}
                                    />
                                </GridBlock>
                                <GridBlock $columns={2}>
                                    <ServerSelect
                                        name="customer_id"
                                        label={t("customer")}
                                        selectUse={useCustomers}
                                        validate={{ required: t("customer_required") }}
                                    />
                                </GridBlock>
                                <GridBlock $columns={4} />

                                <GridBlock $columns={2}>
                                    <SelectForm
                                        name="type_id"
                                        label={t("category")}
                                        options={taskTypeOptions}
                                        placeholder={t("select")}
                                        isClearable
                                    />
                                </GridBlock>

                                <GridBlock $columns={4}>
                                    {type_id?.value === 2 ? (
                                        <RecurringTaskServiceSelectSection />
                                    ) : null}
                                </GridBlock>
                                <GridBlock $columns={2} />

                                <GridBlock $columns={4} $rows={2}>
                                    <InputWithLabel
                                        label={t("description_2")}
                                        name="description"
                                        rows={7}
                                    />
                                </GridBlock>
                                <GridBlock $columns={2}>
                                    <SelectForm
                                        name="recurrence"
                                        label={t("recurrence")}
                                        options={recurrenceOptions}
                                        placeholder={t("select")}
                                        validate={{ required: t("required") }}
                                    />
                                </GridBlock>
                                <GridBlock $columns={2} />
                                <GridBlock $columns={4} />

                                <GridBlock>
                                    <InputWithLabel
                                        name="duration"
                                        label={`${t("duration")} (${t("hours_unit_symbol")})`}
                                        type="number"
                                        step="0.25"
                                        min={0}
                                    />
                                </GridBlock>
                                <GridBlock $columns={2}>
                                    <DateTimeInputForm
                                        name="start"
                                        label={t("start_date")}
                                        maxDate={endDate}
                                        autoComplete="off"
                                        showDateOnly
                                        required
                                    />
                                </GridBlock>
                                <GridBlock>
                                    <DateTimeInputForm
                                        name="task_start_time"
                                        label={t("time")}
                                        showTimeSelectOnly
                                        required
                                    />
                                </GridBlock>

                                <GridBlock $columns={2}>
                                    <DateTimeInputForm
                                        name="end"
                                        label={t("end_date")}
                                        minDate={startDate}
                                        autoComplete="off"
                                        showDateOnly
                                        required
                                    />
                                </GridBlock>
                                <GridBlock $columns={2} />

                                <GridBlock $columns={2}>
                                    <MechanicSelect
                                        customer_id={workshopId}
                                        label={t("mechanic_responsible")}
                                    />
                                </GridBlock>
                            </MainSectionGrid>
                        </MainSection>

                        {/* MATERIALS/PARTS SECTION */}
                        <ExpandableSection
                            label={`${t("materials")} (${selectedParts?.length || 0} ${
                                selectedParts?.length === 1 ? t("part") : t("parts_lowercase")
                            })`}
                        >
                            <TaskPartsSelector customerId={customer_id?.value} />
                            <TaskAdditionalExpenses />
                        </ExpandableSection>

                        <ButtonsContainer>
                            <SaveOrAbortStyled
                                save={
                                    isSavingTask || isSavingPart || isDeletingTask
                                        ? "saving"
                                        : "save"
                                }
                                onAbort={onClose}
                                saving={isSavingTask || isSavingPart || isDeletingTask}
                            />

                            {taskId ? (
                                <DeleteButton onClick={askToRemoveTask}>
                                    <TB $underline $link>
                                        {t("delete_task")}
                                    </TB>
                                </DeleteButton>
                            ) : null}
                        </ButtonsContainer>
                    </form>
                </FormProvider>
            )}

            {isConfirmModalOpen && (
                <ConfirmModal
                    isModalOpen={isConfirmModalOpen}
                    closeModal={() => setIsConfirmModalOpen(false)}
                    onConfirm={handleDeleteTask}
                    text={t("sure_you_want_to_remove_task")}
                    isLoading={isDeletingTask}
                />
            )}
        </ModalStyled>
    );
};

export default RecurringTaskEdit;

const ModalStyled = styled(ReactModal)`
    height: max-content;
    max-height: 90vh;
`;

const SpinnerContainer = styled.article`
    height: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TitleStyled = styled(H1)`
    padding-left: 1rem;
`;

const MainSection = styled.article`
    background: ${(p) => p.theme.color.primary.xxlight};
    overflow: auto;
    margin: 0.5rem 0;
    padding: 1rem;
`;

const MainSectionGrid = styled.section`
    display: grid;
    grid-template-columns: repeat(8, minmax(120px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 1.5rem;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(4, minmax(120px, 1fr));
    }

    @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
    }
`;

const SaveOrAbortStyled = styled(SaveOrAbort)`
    padding-top: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

const ButtonsContainer = styled.section`
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const DeleteButton = styled(CleanButton).attrs({ type: "button" })`
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

import styled from "styled-components";
import { useMedia } from "../../media/hooks/useMedia";
import LoadingSpinner from "../../../components/layout/LoadingSpinner";

const UnitDocument = ({ documentId }) => {
    const { data: documentData, isLoading } = useMedia(documentId);

    if (isLoading) {
        return <LoadingSpinner small light />;
    }

    return documentData.mimetype === "image/jpeg" ? (
        <DocumentContainerImg>
            <a href={documentData?.uri} target="_blank" rel="noreferrer noopener">
                <DocumentImg src={`${documentData?.uri}_thumbnail`} width={88} height={88} />
            </a>
        </DocumentContainerImg>
    ) : (
        <DocumentContainer>
            <a href={documentData?.uri} target="_blank" rel="noreferrer noopener">
                <DocumentInnerContainer>
                    <Document>{documentData?.title || documentData?.org_name}</Document>
                </DocumentInnerContainer>
            </a>
        </DocumentContainer>
    );
};

export default UnitDocument;

const DocumentContainer = styled.section`
    position: relative;
    margin-right: 0.3rem;
`;

const DocumentInnerContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Document = styled.div`
    display: inline-block;
    color: #38558d;
    overflow-wrap: break-word;
`;

const DocumentContainerImg = styled.section`
    position: relative;
    width: 5.5rem;
    height: 5.5rem;
    margin-right: 0.3rem;
`;

const DocumentImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

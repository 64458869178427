import { useEffect, useMemo, useState } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getLastUsedWorkshopId, saveLastUsedWorkshopId } from "../../app/localStorage";
import { useRecurringTasks } from "./hooks/useRecurringTasks";
import { standardDateOnly } from "../../common/date";
import QueryWrapper from "../../components/application/QueryWrapper";

import { useSearchParams } from "../application/hooks/useSearchParams";
import StandardTableContent from "../../components/application/StandardTableContent";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import MainArea from "../../components/layout/MainArea";
import Spacer from "../../components/helpers/Spacer";
import { Horizontal } from "../../components/layout/FlexGrid";
import { TB } from "../../components/texts";
import { Button, CleanButton } from "../../components/buttons";
import { InputWithLabel } from "../../components/inputs";
import RecurringTaskEdit from "./RecurringTaskEdit";
import WorkshopSelect from "../../components/application/workshop/WorkshopSelect";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";

const RecurringTasks = () => {
    const [isRecurringTaskEditorOpen, setIsRecurringTaskEditorOpen] = useState(false);
    const [editRecurringTaskId, setEditRecurringTaskId] = useState(null);

    const { getParam, setParam } = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const recurringTasks = useRecurringTasks();

    const methods = useForm({ mode: "onChange" });
    const search = methods.watch("search");
    const workshop_id = getParam("workshop_id");

    useEffect(() => {
        if (search !== undefined && search !== "") {
            methods.setValue("search", "");
        }

        if (workshop_id) {
            saveLastUsedWorkshopId(workshop_id);
        } else {
            tryRedirectingToLastUsedWorkshop();
        }
    }, [workshop_id]);

    async function tryRedirectingToLastUsedWorkshop() {
        const lastUsedWorkshopId = await getLastUsedWorkshopId();
        if (lastUsedWorkshopId) navigate(`/scheduler/recurring?workshop_id=${lastUsedWorkshopId}`);
    }

    const data = useMemo(
        () => recurringTasks?.data?.filter((task) => task.host_id === +workshop_id) || [],
        [recurringTasks.data, workshop_id]
    );

    const columns = useMemo(
        () => [
            {
                header: t("task"),
                accessorKey: "id",
                cell: ({ getValue, row }) => (
                    <CleanButton type="button" onClick={() => openEditorForTask(row.original.id)}>
                        <TB $link>{getValue()}</TB>
                    </CleanButton>
                ),
            },
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
            },
            {
                header: t("customer"),
                accessorKey: "customer.name",
            },
            {
                header: t("description_2"),
                accessorKey: "description",
            },
            {
                header: t("recurrence"),
                accessorKey: "recurrence.days",
                cell: ({ getValue }) => {
                    switch (getValue()) {
                        case 7:
                            return `${t("every_week")} (7 ${t("days")})`;
                        case 14:
                            return `${t("every_other_week")} (14 ${t("days")})`;
                        case 21:
                            return `${t("every_third_week")} (21 ${t("days")})`;
                        case 28:
                            return `${t("every_month")} (28 ${t("days")})`;
                        case 56:
                            return `${t("every_other_month")} (56 ${t("days")})`;
                        case 182:
                            return `${t("every_half_year")} (182 ${t("days")})`;
                        case 365:
                            return `${t("every_year")} (365 ${t("days")})`;
                        default:
                            return "";
                    }
                },
            },
            {
                header: t("start_date"),
                accessorKey: "start",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? standardDateOnly(value) : "";
                },
            },
            {
                header: t("start_time"),
                accessorKey: "task_start_time",
            },
            {
                header: t("duration"),
                accessorKey: "duration",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? `${value} ${t("hours_unit_symbol")}` : null;
                },
            },
            {
                header: t("end_date"),
                accessorKey: "end",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? standardDateOnly(value) : "";
                },
            },
            {
                header: t("mechanic_responsible"),
                accessorFn: (row) =>
                    `${row.mechanic?.first_name || ""} ${row.mechanic?.last_name || ""}`,
            },
        ],
        []
    );
    const initialState = useMemo(() => ({ pagination: { pageSize: 30 } }), []);

    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function openEditorForTask(taskId) {
        setEditRecurringTaskId(taskId);
        setIsRecurringTaskEditorOpen(true);
    }

    function closeTaskEditor() {
        setEditRecurringTaskId(null);
        setIsRecurringTaskEditorOpen(false);
    }

    function createNewTask() {
        setEditRecurringTaskId(null);
        setIsRecurringTaskEditorOpen(true);
    }

    return (
        <MainArea>
            <Container>
                <Header>
                    <CustomerSelectContainer>
                        <WorkshopSelect
                            onChange={(e) => setParam("workshop_id", e.value)}
                            defaultWorkshopId={workshop_id}
                        />
                    </CustomerSelectContainer>

                    <Spacer />

                    {!!workshop_id && (
                        <AddNewTaskButton $bold onClick={createNewTask}>
                            {t("add_new_recurring_task")}
                        </AddNewTaskButton>
                    )}
                </Header>

                <FormProvider {...methods}>
                    <form>
                        <InputWithLabel
                            label={t("search_tasks")}
                            name="search"
                            style={{ maxWidth: "27rem" }}
                        />

                        <QueryWrapper data={recurringTasks}>
                            {rowModel.rows.length > 0 ? (
                                <TableContainer>
                                    <TableStyled>
                                        <StandardTableContent
                                            headerGroups={headerGroups}
                                            rowModel={rowModel}
                                        />
                                    </TableStyled>
                                </TableContainer>
                            ) : (
                                <NoTasks>
                                    <TB>{t("no_tasks_to_show")}</TB>
                                </NoTasks>
                            )}
                            <TablePaginationNav
                                pageCount={table.getPageCount()}
                                previousPage={table.previousPage}
                                canPreviousPage={table.getCanPreviousPage()}
                                nextPage={table.nextPage}
                                canNextPage={table.getCanNextPage()}
                                pageOptions={table.getPageOptions()}
                                gotoPage={table.setPageIndex}
                                pageIndex={table.getState().pagination.pageIndex}
                            />
                        </QueryWrapper>
                    </form>
                </FormProvider>

                {isRecurringTaskEditorOpen && (
                    <RecurringTaskEdit
                        taskId={editRecurringTaskId}
                        isOpen={isRecurringTaskEditorOpen}
                        onClose={closeTaskEditor}
                        workshopId={workshop_id}
                    />
                )}
            </Container>
        </MainArea>
    );
};

export default RecurringTasks;

const Container = styled.div`
    opacity: 0;
    animation: fadeIn 300ms ease forwards;
`;

const TableStyled = styled(StandardTableStyle)`
    min-width: 90rem;

    tr {
        td:nth-child(1),
        td:nth-child(7),
        td:nth-child(8) {
            text-align: center;
        }
    }
`;

const Header = styled(Horizontal)`
    margin-top: 0.8rem;
    margin-bottom: 1rem;

    align-items: center;
    flex-wrap: wrap;
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const NoTasks = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
`;

const CustomerSelectContainer = styled.section`
    min-width: 20rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
`;

const AddNewTaskButton = styled(Button)`
    margin-bottom: 0.5rem;
`;

import { useMemo } from "react";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { TaskStatus } from "../../../api/core/taskAPI";
import taskAPI from "../../../api/core/taskAPI";
import { DateTime } from "luxon";

export const useScheduledTasksForMechanicBetween = ({ mechanicId, fromDate, toDate }) => {
    const scheduledStatusList = [
        TaskStatus.SCHEDULED,
        TaskStatus.SCHEDULED_DRAFT,
        TaskStatus.STARTED,
        TaskStatus.COMPLETED,
        TaskStatus.INVOICED,
    ];

    const params = `?from=${fromDate}&to=${toDate}&mechanic_id=${mechanicId}&status=${scheduledStatusList}&attributes=id&attributes=start&attributes=end&attributes=mechanic_id&attributes=status&attributes=host_id&attributes=duration&attributes=description&attributes=priority&attributes=from_customer&attributes=createdAt&attributes=unit.id&attributes=unit.int_id&attributes=unit.manufacturer&attributes=unit.type&attributes=unit.defect&attributes=customer.name&attributes=customer.contact_person&attributes=owner.first_name&attributes=owner.last_name&attributes=parts.part_id&attributes=parts.status&attributes=parts.eta&attributes=part.stock`;

    // Define the query key
    const queryKey = [
        "tasks",
        "websocket-hooked",
        "scheduled",
        "mechanic",
        mechanicId,
        fromDate,
        toDate,
    ];

    const query = useQuery(queryKey, () => taskAPI.getTasksWithConnectedWorkshops(params), {
        enabled: typeof mechanicId === "number" && !isNaN(mechanicId) && !!fromDate && !!toDate,
        refetchOnWindowFocus: false,
        meta: {
            filterFn: (task) => {
                if (fromDate && DateTime.fromISO(fromDate) >= DateTime.fromISO(task.start))
                    return false;
                if (toDate && DateTime.fromISO(toDate) <= DateTime.fromISO(task.end)) return false;
                return scheduledStatusList.includes(task.status);
            },
        },
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Fetching task error: ", error.response?.data?.message || error.message);
        },
    });

    return {
        ...query,
        data: useMemo(
            () => query.data?.filter((task) => task.mechanic_id === mechanicId),
            [query.data, mechanicId]
        ),
    };
};

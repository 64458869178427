import { useContext, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useQueries, useQueryClient } from "react-query";

import { useAuth } from "../../../auth/hooks/useAuth";
import { WebSocketContext } from "../../../socket/SocketContext";
import unitAPI from "../../../../api/core/unitAPI";

export const useLiveSensorValuesForUnits = (units) => {
    const webSocket = useContext(WebSocketContext);
    const { jwt } = useAuth();

    const queryClient = useQueryClient();

    useEffect(() => {
        const callbacks = units.map((unit) => (payload) => {
            queryClient.setQueryData(
                ["units", "sensordata", "live", unit.unit_id, unit.customer_id],
                payload
            );
        });

        units.forEach((unit, index) => {
            webSocket.addSensorListener({
                unit_id: unit.unit_id,
                customer_id: unit.customer_id,
                callback: callbacks[index],
                token: jwt,
            });
        });

        return () => callbacks.forEach((callback) => webSocket.removeSensorListener(callback));
    }, [units]);

    const queries = useMemo(
        () =>
            units?.map((unit) => ({
                queryKey: ["units", "sensordata", "live", unit.unit_id, unit.customer_id],
                queryFn: () =>
                    unitAPI.getUnitSensorDataLastBefore({
                        unitId: unit.unit_id,
                        timestamp: new Date().toISOString(),
                    }),
                enabled: !!unit.unit_id && !!unit.customer_id,
                staleTime: Infinity,
                select: (response) => {
                    const data = response?.data || response;

                    return {
                        ...data,
                        unit_id: data?.unit_id || unit?.unit_id,
                        customer_id: data?.customer_id || unit?.customer_id,
                    };
                },
                onError: (error) => {
                    toast.error(
                        `Klarte ikke hente data. Det oppsto en feil: ${
                            error.message || "Ukjent feil"
                        }`
                    );
                    console.log("Loading error: ", error.message);
                },
            })),
        [units]
    );

    return useQueries(queries);
};

import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { T } from "../texts";

export const RadioSelect = ({ label, name, options, defaultValue, ...props }) => {
    const { register, setValue } = useFormContext();

    return (
        <>
            <T>{label || ""}</T>

            {options && (
                <OptionsContainer>
                    {options.map((item, index) => (
                        <Option key={`${name}-${item.value}-${index}}`}>
                            <input
                                type="radio"
                                id={`${name}-${item.value}`}
                                name={name}
                                value={item.value}
                                defaultChecked={item.value === defaultValue}
                                {...register(name)}
                            />

                            <label
                                htmlFor={`${name}-${item.value}`}
                                onClick={() => {
                                    setValue(name, item.value, { shouldDirty: true });
                                }}
                            >
                                <T>{item.label}</T>
                            </label>
                        </Option>
                    ))}
                </OptionsContainer>
            )}
        </>
    );
};

const OptionsContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
`;

const Option = styled.div`
    display: flex;
    align-items: center;
    height: 2.75rem;
    padding-right: 2rem;

    label {
        user-select: none;
    }

    > input[type="radio"] {
        position: absolute;
        opacity: 0;

        + label {
            cursor: pointer;

            &:before {
                display: inline-block;
                box-sizing: border-box;
                content: "";
                width: 1.25rem;
                height: 1.25rem;
                position: relative;
                top: 0.25rem;
                background: #fff;
                border-radius: 100%;
                border: 2px solid ${(p) => p.theme.color.primary.light};
                margin-right: 0.4rem;
                transition: 100ms ease;
            }
        }

        &:checked {
            + label {
                &:before {
                    background-color: ${(p) => p.theme.color.primary.base};
                    box-shadow: inset 0 0 0 1px #fff;
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    transform: translate(8px, -14px);
                    width: 4px;
                    height: 4px;
                    background: #fff;
                    border-radius: 100%;
                }
            }
        }

        &:focus-visible {
            + label {
                outline: 2px solid blue;
                border-radius: 3px;
            }
        }

        &:disabled {
            + label {
                &:before {
                    box-shadow: inset 0 0 0 1px #fff;
                    border-color: ${(p) => p.theme.color.neutral.xlight};
                    background: ${(p) => p.theme.color.neutral.xlight};
                }
            }
        }
    }
`;

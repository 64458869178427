import { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { debounce } from "lodash";
import styled from "styled-components";

import { usePartsWithParams } from "./hooks/usePartsWithParams";

import { SelectForm } from "../../components/inputs";
import { T } from "../../components/texts";

const PartSelect = ({ name, ...props }) => {
    const [searchValue, setSearchValue] = useState("");

    const { t } = useTranslation();

    const { data: parts, isLoading } = usePartsWithParams(
        `?take=5&search=${encodeURIComponent(searchValue)}`
    );

    const options = useMemo(() => {
        if (isLoading) return [{ value: false, label: `${t("loading")}...` }];
        if (!parts?.data || !parts.data.length) return [];

        const options = parts.data.map((part) => ({
            value: part.id,
            label: part.name,
            org_id: part.org_id,
            description: part.description,
            stock: part.stock,
        }));

        if (parts.data.length !== parts.meta.itemCount) {
            options.push({
                value: false,
                label: `${t("showing")} ${parts.data.length} ${t("first_matching_items_out_of")} ${
                    parts.meta.itemCount
                }`,
            });
        }

        return options;
    }, [parts]);

    function handleInputChange(input, { action }) {
        if (action !== "input-blur" && action !== "input-clear" && action !== "menu-close") {
            debounceSearch(input);
        }

        if (props.onInputChange) {
            props.onInputChange(input, { action });
        }
    }

    const debounceSearch = useCallback(
        debounce((value) => {
            setSearchValue(value);
        }, 200),
        []
    );

    return (
        <div>
            <label htmlFor={name}>
                <T>{t("search_and_choose_part")}</T>
            </label>
            <SelectFormStyled
                name={name}
                id={name}
                options={options}
                placeholder={t("select")}
                {...props}
                onInputChange={handleInputChange}
                // Disable default filtering
                filterOption={() => true}
                // Disable all options disabled from props + extra empty option that shows number of found items
                isOptionDisabled={(option) =>
                    (!!props.isOptionDisabled && props.isOptionDisabled(option)) || !option.value
                }
                // Extra styling for empty option that shows number of found items
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    option: (styles, { data, isDisabled }) => ({
                        ...styles,
                        fontWeight: "600",
                        color: !isDisabled ? "#333" : isDisabled && !data.value ? "#333" : "#bbb",
                        borderTop: isDisabled && !data.value ? "1px solid #A7B9DC" : "none",
                    }),
                }}
                components={{ Option }}
            />
        </div>
    );
};

const SelectFormStyled = styled(SelectForm)`
    margin-bottom: 1rem;
`;

const Option = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <components.Option {...props}>
                {props.children}

                {props.value === false ? null : (
                    <PartOptionDetailsContainer isDisabled={props.isDisabled}>
                        <ul>
                            <li>
                                <T>{t("description_2")}: </T>
                                <T>{props.data?.description || "–"}</T>
                            </li>
                            <li>
                                <T>{t("warehouse_status")}: </T>
                                <T>{props.data?.stock ? t("stock_item") : t("order_item")}</T>
                            </li>
                        </ul>
                    </PartOptionDetailsContainer>
                )}
            </components.Option>
        </div>
    );
};

export default PartSelect;

const PartOptionDetailsContainer = styled.div`
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 0.3rem;

        li > span {
            font-size: 0.9rem;
            color: ${(p) => (p.isDisabled ? "#bbb" : "#333")};
        }
    }
`;

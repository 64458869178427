import axios from "axios";
import { API_BASE_URL } from "../constants";

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!error.response) {
            // network error
            return Promise.reject({ response: { data: { message: "Error: Network Error" } } });
        } else {
            return Promise.reject(error);
        }
    }
);

export const setBearer = (token) => {
    axios.defaults.headers.common = { Authorization: `bearer ${token}` };
};

export const setBaseUrl = (url) => {
    axios.defaults.baseURL = url;
};

export const fetchData = async (url) => {
    return axios.get(url).then((response) => response.data?.data);
};

export const fetchResponse = async (url) => {
    return axios.get(url).then((response) => response.data);
};

export const patchData = async (url, data) => axios.patch(url, data);

export const postData = async (url, data) => axios.post(url, data);

export const url2data = async (url, params, method) => {
    let response;
    if (params) {
        if (method === "PUT") {
            response = await axios.put(url, params);
        } else if (method === "PATCH") {
            response = await axios.patch(url, params);
        } else {
            response = await axios.post(url, params);
        }
    } else {
        response = await duplicateGetStopper.get(url);
    }
    const { data } = response;
    return data;
};

export const updateData = (data, url, method) => {
    if (data?.id) {
        const { id, ...newData } = data;
        if (id === 0) return url2data(url, newData);
        else return url2data(`${url}/${id}`, newData, method || "PUT");
    }
    return url2data(url, data, method || "POST");
};

export const updateDataPatch = (data, url, method) => {
    if (data?.id) {
        const { id, ...newData } = data;
        if (id === 0) return url2data(url, newData);
        else return url2data(`${url}/${id}`, newData, method || "PATCH");
    }
    return url2data(url, data, method || "POST");
};

export const deleteEntry = (id, url) => axios.delete(`${url}/${id}`);

class DuplicateGetStopper {
    constructor() {
        this.cache = {};
    }

    get(url) {
        if (!url) return Promise.reject(new Error("Url not passed"));
        if (this.cache[url]) return this.cache[url];

        this.cache[url] = axios
            .get(url)
            .then((res) => {
                delete this.cache[url];
                return res;
            })
            .catch((err) => {
                delete this.cache[url];
                return err;
            });
        return this.cache[url];
    }
}

const duplicateGetStopper = new DuplicateGetStopper();

export default axios;

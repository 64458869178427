import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import QueryWrapper from "../../components/application/QueryWrapper";

import MainArea from "../../components/layout/MainArea";
import UnitUsing from "../../components/application/unit/UnitUsing";
import { H1, S, T, TB } from "../../components/texts";
import Underline from "../../components/helpers/Underline";
import { Col, Row, Vertical } from "../../components/layout/FlexGrid";
import { ClockIcon, ServiceIcon, ClockNextIcon, ErrorIcon } from "../../components/icons";
import { device } from "../../theme";
import { standardDate } from "../../common/date";
import UnitPlannedMaintenanceTablePublic from "./UnitPlannedMaintenanceTablePublic";
import UnitMaintenanceHistoryTablePublic from "./UnitMaintenanceHistoryTablePublic";

const UnitPublic = ({ unitUuid, unit }) => {
    const { t } = useTranslation();

    const nextService = useMemo(() => {
        const nextServiceTask = unit.data?.tasks.reduce((acc, curr) => {
            if (acc == null) return curr;
            if (new Date(curr.start) < new Date(acc.start)) return curr;
            return acc;
        }, null);

        if (nextServiceTask) {
            return new Date(nextServiceTask.end).toLocaleString("no", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            });
        }

        if (!unit.data?.hour_counter || !Array.isArray(unit.data?.data?.service_intervals))
            return t("unknown");
        if (!(unit.data?.data?.service_intervals.length > 0)) return t("unknown");

        const sortedServiceIntervals = unit.data.data.service_intervals.sort((a, b) => {
            if (a.interval > b.interval) return 1;
            if (b.interval > a.interval) return -1;
            return 0;
        });
        const reducedInterval = Math.round(
            (unit.data.data.service_intervals.reduce((acc, curr) => {
                if (curr.interval === 0) return acc;
                return acc % curr.interval;
            }, unit.data.hour_counter) *
                100) /
                100
        );
        const nextInterval = sortedServiceIntervals.find(
            ({ interval }) => interval > reducedInterval
        );
        const remainingIntervalHours =
            (nextInterval ?? unit.data.data.service_intervals[0]).interval - reducedInterval;
        return `${remainingIntervalHours.toFixed(1)}${t("hours_unit_symbol")}`;
    }, [unit?.data]);

    return (
        <MainArea back={false}>
            <QueryWrapper data={unit}>
                <H1>
                    {unit.data?.int_id} {unit.data?.group?.name} {unit.data?.name} {unit.data?.type}
                </H1>
                <Underline $second />

                <EntryStyled>
                    <UnitUsing unit={unit?.data} />
                </EntryStyled>
                <Underline $second />

                <EntryStyled>
                    <Row>
                        <Col size={1}>
                            <ClockIcon />
                        </Col>
                        <Col size={8}>
                            <T>{t("timecounter")}</T>
                        </Col>
                        <Col size={3}>
                            <T>
                                {unit.data?.hour_counter
                                    ? (Math.round(unit.data.hour_counter * 100) / 100).toFixed(1)
                                    : t("unknown")}
                            </T>
                        </Col>
                    </Row>
                </EntryStyled>
                <Underline $second />

                <EntryStyled>
                    <Row>
                        <Col size={1}>
                            <ServiceIcon />
                        </Col>
                        <Col size={8}>
                            <T>{t("nextservice")}</T>
                        </Col>
                        <Col size={3}>
                            <T>{nextService}</T>
                        </Col>
                    </Row>
                </EntryStyled>
                <Underline $second />

                <EntryStyled>
                    <Row>
                        <Col size={1}>
                            <ClockNextIcon />
                        </Col>
                        <Col size={8}>
                            <T>{t("nextcontrol")}</T>
                        </Col>
                        <Col size={3}>
                            <T>
                                {unit.data?.next_periodic_technical_inspection_date
                                    ? new Date(
                                          unit.data?.next_periodic_technical_inspection_date
                                      ).toLocaleDateString("no", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                      })
                                    : "–"}
                            </T>
                        </Col>
                    </Row>
                </EntryStyled>
                <Underline $second style={{ marginBottom: "2rem" }} />

                <div>
                    {unit?.data != null && unit.data.comments.length > 0 ? (
                        <>
                            <Header>
                                <TB>{t("needfolllowup")}</TB>
                            </Header>
                            {unit.data.comments.map((comment) => (
                                <CommentStyled $status={comment.status}>
                                    <StatusLine $status={comment.status} />
                                    {comment.status === 3 && (
                                        <ErrorIconContainer>
                                            <ErrorIcon />
                                        </ErrorIconContainer>
                                    )}
                                    <Vertical style={{ marginLeft: "0.5rem" }}>
                                        <T style={{ marginBottom: "0.3rem" }}>
                                            <TB style={{ fontWeight: 700 }}>{`${
                                                comment.label || t("post_check")
                                            }: `}</TB>
                                            {comment.comment || "–"}
                                        </T>
                                        <S>
                                            {comment.user.first_name} {comment.user.last_name},{" "}
                                            {standardDate(comment.createdAt)}
                                        </S>
                                    </Vertical>
                                </CommentStyled>
                            ))}
                        </>
                    ) : null}
                </div>
            </QueryWrapper>

            <UnitPlannedMaintenanceTablePublic unitUuid={unitUuid} />
            <UnitMaintenanceHistoryTablePublic unitUuid={unitUuid} />
        </MainArea>
    );
};

export default UnitPublic;

const EntryStyled = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 0.65rem;
`;

const Header = styled.div`
    margin-bottom: 0.3rem;
`;

const CommentStyled = styled.div`
    display: flex;
    margin-bottom: 0.2rem;
    padding: 0.5rem 1.5rem 1rem 0.5rem;
    max-width: 62rem;

    background: ${({ theme, $status }) =>
        $status === 3 ? theme.color.error.xxlight : theme.color.warning.xxlight};

    @media only screen and ${device.mobile} {
        flex-direction: column;
    }
`;

const StatusLine = styled.div`
    border-left: 2px solid
        ${({ theme, $status }) =>
            $status === 1
                ? theme.color.ok.base
                : $status === 2
                  ? theme.color.warning.base
                  : theme.color.error.base};
    padding-right: 5px;
`;

const ErrorIconContainer = styled.div`
    margin-top: 0.3rem;

    svg {
        width: 1.2rem;
        height: 1.2rem;
    }
`;

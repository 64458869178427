import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import recurringTasksAPI from "../../../api/core/recurringTasksAPI";

export const useDeleteRecurringTask = (props) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const onSuccess = props?.onSuccess;

    return useMutation(recurringTasksAPI.deleteRecurringTask, {
        onSuccess: () => {
            if (onSuccess instanceof Function) onSuccess();
            toast.success(t("task_removed"));
            queryClient.invalidateQueries(["tasks", "recurring"]);
        },
        onError: (error) =>
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            ),
    });
};

import { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUnit } from "../../unit/hooks/useUnit";
import { useSaveUnit } from "./hooks/useSaveUnit";
import { useUnitGroup } from "../../unit/hooks/useUnitGroup";
import { useParts } from "../../materials/hooks/useParts";
import { useFeatureFlagsForCurrentUser } from "../../feature-flag/hooks/useFeatureFlagsForCurrentUser";
import { useServiceConfigsForCustomer } from "../service/hooks/useServiceConfigsForCustomer";
import { useServiceConfigTemplates } from "../service/hooks/useServiceConfigTemplates";
import { useServiceConfig } from "../service/hooks/useServiceConfig";
import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import { H1, TB } from "../../../components/texts";
import {
    InputWithLabel,
    InputCheckbox,
    SelectForm,
    RadioSelect,
    DateTimeInputForm,
    ExpandableSection,
} from "../../../components/inputs";
import { Grid, GridBlock } from "../../../components/layout/Grid";
import PreCheckListSelect from "./PreCheckListSelect";
import UnitAreas from "../../../components/application/unit/UnitAreas";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import CustomerSelect from "../../application/components/role/CustomerSelect";
import UnitServiceInterval from "./UnitServiceInterval";
import ServiceConfigSelect from "./ServiceConfigSelect";
import UnitSensors from "./UnitSensors";
import InspectionConfigSelect from "./InspectionConfigSelect";
import UnitGroupsSelect from "./UnitGroupsSelect";
import UnitDocument from "./UnitDocument";
import LoadingSpinner from "../../../components/layout/LoadingSpinner";
import { useSaveImage } from "../../media/hooks/useSaveImage";
import { standardDate } from "../../../common/date";

const drivelineOptions = ["diesel", "el", "hydrogen", "none"];

const UnitEdit = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const unitId = Number(params.id);

    const methods = useForm({ mode: "onChange" });
    const unit = useUnit(unitId);
    const { mutate: saveUnit, isLoading: isSavingUnit } = useSaveUnit();
    const customer = methods.watch("customer_id");
    const group_id = methods.watch("group_id");
    const customer_id = methods.watch("customer_id")?.value;
    const service_configuration_id = methods.watch("service_configuration_id");
    const data = methods.watch("data");

    const allParts = useParts();
    const group = useUnitGroup(group_id?.value);
    const serviceConfigTemplates = useServiceConfigTemplates();
    const serviceConfigs = useServiceConfigsForCustomer(customer_id);
    const selectedServiceConfig = useServiceConfig(
        service_configuration_id?.value || service_configuration_id
    );
    const featureFlags = useFeatureFlagsForCurrentUser();
    const { mutate: saveDocument, isLoading: isSavingDocument } = useSaveImage();

    useEffect(() => {
        if (unitId === 0)
            methods.reset({
                int_id: "",
                group_id: null,
                manufacturer: "",
                type: "",
                pre_check_list_id: null,
                area_id: null,
                custom_name: "",
                active: true,
                customer_id: null,
            });
    }, [unitId]);

    useEffect(() => {
        if (unit?.data) {
            methods.reset(unit.data);

            if (unit.data.data?.driveline) {
                methods.setValue("data.driveline", {
                    value: unit.data.data?.driveline,
                    label: t(unit.data.data?.driveline),
                });
            }
            if (unit.data.next_periodic_technical_inspection_date) {
                methods.setValue(
                    "periodic_technical_inspection_next_date",
                    new Date(unit.data.next_periodic_technical_inspection_date)
                );
            }
            methods.setValue("group_id", unit.data.group?.id);
        }
    }, [unit?.data]);

    useEffect(() => {
        if (group.data && (!unitId || (unit?.data && group.data.id !== unit.data.group?.id))) {
            methods.setValue("pre_check_list_id", group.data.pre_check_list_id);
        }
    }, [group?.data, unit?.data]);

    useEffect(() => {
        if (!selectedServiceConfig?.data) return;

        setValuesForServiceIntervals();
    }, [selectedServiceConfig?.data, unit?.data, unitId]);

    function setValuesForServiceIntervals() {
        if (unitId === 0) {
            methods.setValue("data.service_intervals", selectedServiceConfig.data.intervals);
        } else if (unit?.data) {
            const unitConfigId = unit.data.service_configuration_id;
            const selectedId = selectedServiceConfig.data.id;

            if (selectedId === unitConfigId) {
                const existingIntervalsMap = unit.data.data?.service_intervals.reduce(
                    (acc, curr) => {
                        acc.set(curr.interval_id, curr);
                        return acc;
                    },
                    new Map()
                );
                const intervals = selectedServiceConfig.data.intervals?.map((item) => {
                    const existingInterval = existingIntervalsMap.get(item.interval_id || item.id);
                    if (existingInterval) {
                        return existingInterval;
                    }

                    return item;
                });
                methods.setValue("data.service_intervals", intervals);
            } else {
                const intervals = selectedServiceConfig.data.intervals?.map((item) => ({
                    interval_id: item.interval_id || item.id,
                    interval: item.interval,
                    parts: [],
                }));
                methods.setValue("data.service_intervals", intervals);
            }
        }
    }

    const hasFlag = useCallback(
        (flagName) =>
            featureFlags?.data ? featureFlags.data.some((flag) => flag.name === flagName) : false,
        [featureFlags?.data]
    );

    function onSubmit(data) {
        const post = {
            int_id: data.int_id,
            group_id: data.group_id?.value || data.group_id,
            manufacturer: data.manufacturer,
            type: data.type,
            area_id: data.area_id?.value,
            custom_name: data.custom_name || "",
            user_id: data.user_id,
            defect: data.defect,
            pre_check_list_id: data.pre_check_list_id?.value || data.pre_check_list_id,
            customer_id: data.customer_id?.value,
            comment: data.comment || "",
            active: data.active,
            contact_person_id: data.contact_person_id,
            service_configuration_id: Number(data.service_configuration_id?.value) || null,
            periodic_technical_inspection_id:
                Number(data.periodic_technical_inspection_id?.value) || null,
            next_periodic_technical_inspection_date:
                data.periodic_technical_inspection_next_date || null,
            hour_counter: data.hour_counter,
            data: data.data,
            disable_automatic_service_task_generation:
                data.disable_automatic_service_task_generation,
            disable_hour_counter: data.disable_hour_counter,
        };
        post.data.driveline = data.data.driveline?.value || "";
        post.data.rotator = Number(data.data.rotator) ? true : false;
        post.data.sideskift = Number(data.data.sideskift) ? true : false;
        post.data.spreder = Number(data.data.spreder) ? true : false;
        post.data.model_year = Number(data.data.model_year) || null;

        const serviceIntervals = methods.getValues("data.service_intervals");

        if (post.service_configuration_id && data.data.service_intervals?.length) {
            post.data.service_intervals = serviceIntervals.map((interval) => {
                const formattedInterval = {
                    interval_id: interval.interval_id || interval.id,
                    interval: Number(interval.interval),
                };
                if (interval.parts && interval.parts.length) {
                    formattedInterval.parts = interval.parts.map((part) => ({
                        part_id: part.part_id || part.id,
                        count: Number(part.count),
                    }));
                }
                return formattedInterval;
            });
        } else {
            delete post.data.service_intervals;
        }

        if (data.id) post.id = data.id;

        saveUnit(post, {
            onSuccess: () => navigate(-1),
        });
    }

    function handleAddDocument(documentFile) {
        const formData = new FormData();

        formData.append("module", "unit:document");
        formData.append("customer_id", customer_id);
        formData.append("file", documentFile);

        saveDocument(formData, {
            onSuccess: (response) => {
                const existingDocuments = methods.getValues("data.documents");

                const newDocumentsArray = Array.isArray(existingDocuments)
                    ? [...existingDocuments, response.data?.id]
                    : [response.data?.id];

                methods.setValue("data.documents", newDocumentsArray, {
                    shouldDirty: true,
                });
            },
        });
    }

    return (
        <MainArea>
            <H1>{unitId === 0 ? t("register_vehicle") : t("change_vehicle")}</H1>

            <QueryWrapper data={unitId !== 0 ? [unit, allParts] : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid>
                            <CustomerSelect isDisabled={unit?.data?.customer_id ? true : false} />

                            {typeof customer === "object" && customer !== null && (
                                <UnitAreas
                                    label={t("useArea")}
                                    name={"area_id"}
                                    customer_id={customer.value}
                                    validate={{ required: t("area_required") }}
                                />
                            )}
                        </Grid>

                        <CheckboxSection>
                            <InputCheckbox name={"active"} label={t("active")} />
                            <InputCheckbox name={"defect"} label={t("defect")} />
                            <InputCheckbox
                                name={"disable_automatic_service_task_generation"}
                                label={t("disable_automatic_service_task_generation")}
                            />
                            <InputCheckbox
                                name={"disable_hour_counter"}
                                label={t("disable_hour_counter")}
                            />
                        </CheckboxSection>

                        {typeof customer === "object" && customer !== null && (
                            <>
                                {/* Main vehicle info */}
                                <ExpandableSection label={t("vehicle")} expandedByDefault>
                                    <Grid>
                                        <UnitGroupsSelect
                                            name={"group_id"}
                                            label={t("vehicle_group")}
                                            customer_id={customer.value}
                                            validate={{
                                                required: t("vehicle_group_required"),
                                            }}
                                        />
                                        <InputWithLabel
                                            name={"manufacturer"}
                                            label={t("brand")}
                                            validate={{ required: t("brand_required") }}
                                        />
                                        <InputWithLabel
                                            name={"type"}
                                            label={t("type")}
                                            validate={{ required: t("type_required") }}
                                        />
                                        <InputWithLabel
                                            label={t("internal_number")}
                                            name={"int_id"}
                                            validate={{
                                                required: t("id_required"),
                                                minLength: {
                                                    value: 3,
                                                    message: t("id_has_to_be_min_3_characters"),
                                                },
                                            }}
                                        />

                                        <Grid $templateColumns={2}>
                                            <InputWithLabel
                                                name={"data.serial_number"}
                                                label={t("serial_number")}
                                            />
                                            <InputWithLabel
                                                name={"data.model_year"}
                                                label={t("production_year")}
                                                type="number"
                                            />
                                        </Grid>

                                        <Grid $templateColumns={2}>
                                            <div>
                                                <InputWithLabel
                                                    name={"hour_counter"}
                                                    label={t("timecounter")}
                                                    type="number"
                                                    step="any"
                                                />
                                                {unit?.data?.hour_counter_updated_at ? (
                                                    <div>
                                                        {t("last_updated_at")}:{" "}
                                                        {standardDate(
                                                            unit.data.hour_counter_updated_at
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <InputWithLabel
                                                name={"data.certificate_number"}
                                                label={t("certificate_number")}
                                                type="string"
                                            />
                                        </Grid>
                                    </Grid>
                                </ExpandableSection>

                                {unitId !== 0 && (
                                    <ExpandableSection label={t("sensors")} expandedByDefault>
                                        <UnitSensors unitId={unitId} />
                                    </ExpandableSection>
                                )}

                                {/* Maintenance */}
                                <ExpandableSection label={t("maintenance")} expandedByDefault>
                                    <QueryWrapper
                                        data={[
                                            serviceConfigs,
                                            serviceConfigTemplates,
                                            featureFlags,
                                        ]}
                                    >
                                        <Grid $templateColumns={3}>
                                            <GridBlock>
                                                <PreCheckListSelect
                                                    name={"pre_check_list_id"}
                                                    label={t("precheck")}
                                                    customer_id={customer.value}
                                                />

                                                {hasFlag("extended") && (
                                                    <>
                                                        <section
                                                            style={{
                                                                marginTop: "0.3rem",
                                                                marginBottom: "2rem",
                                                            }}
                                                        >
                                                            <ServiceConfigSelect
                                                                customer_id={customer_id}
                                                                name="service_configuration_id"
                                                                label={t("service_configuration")}
                                                            />
                                                        </section>
                                                    </>
                                                )}
                                            </GridBlock>

                                            {hasFlag("extended") && (
                                                <>
                                                    <InspectionConfigSelect
                                                        name="periodic_technical_inspection_id"
                                                        label={t("periodic_technical_inspection")}
                                                        customer_id={customer_id}
                                                        unitId={unitId}
                                                    />

                                                    <DateInputWrapper>
                                                        <DateTimeInputForm
                                                            name="periodic_technical_inspection_next_date"
                                                            label={t(
                                                                "date_of_next_technical_inspection"
                                                            )}
                                                            showDateOnly
                                                        />
                                                    </DateInputWrapper>
                                                </>
                                            )}
                                        </Grid>

                                        {hasFlag("extended") && (
                                            <>
                                                <ServiceIntervalsContainer>
                                                    {selectedServiceConfig?.data?.intervals?.map(
                                                        (interval, index) => (
                                                            <UnitServiceInterval
                                                                key={`${interval.customer_id}-${interval.configuration_id}-${interval.id}-${index}`}
                                                                interval={interval}
                                                                intervalIndex={index}
                                                            />
                                                        )
                                                    )}
                                                </ServiceIntervalsContainer>

                                                <Grid $templateColumns={3}>
                                                    <InputWithLabel
                                                        label={t("vehicle_maintenance_tips")}
                                                        name={"comment"}
                                                        rows={6}
                                                        hideErrorLabel
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </QueryWrapper>
                                </ExpandableSection>

                                {/* Technical Info */}
                                <ExpandableSection label={t("technical_info")} expandedByDefault>
                                    <Grid>
                                        <SelectForm
                                            name="data.driveline"
                                            label={t("drivetrain")}
                                            options={drivelineOptions.map((item) => ({
                                                value: item,
                                                label: t(item),
                                            }))}
                                            isClearable
                                        />
                                        <InputWithLabel
                                            name="data.motor_type"
                                            label={t("engine_type")}
                                        />
                                        <InputWithLabel name="data.fork" label={t("forks_l_w_h")} />
                                        <InputWithLabel
                                            name="data.tyre_front"
                                            label={t("tyres_front_info")}
                                        />
                                        <InputWithLabel
                                            name="data.tyre_back"
                                            label={t("tyres_rear_info")}
                                        />
                                        <InputWithLabel name="data.wll" label={t("wll")} />
                                    </Grid>

                                    <RadioContainer>
                                        <div>
                                            <RadioSelect
                                                name="data.rotator"
                                                label={t("rotator")}
                                                options={[
                                                    { value: 1, label: t("yes") },
                                                    { value: 0, label: t("no") },
                                                ]}
                                                defaultValue={unit?.data?.data?.rotator ? 1 : 0}
                                            />
                                        </div>

                                        <div>
                                            <RadioSelect
                                                name="data.sideskift"
                                                label={t("sideshifter")}
                                                options={[
                                                    { value: 1, label: t("yes") },
                                                    { value: 0, label: t("no") },
                                                ]}
                                                defaultValue={unit?.data?.data?.sideskift ? 1 : 0}
                                            />
                                        </div>

                                        <div>
                                            <RadioSelect
                                                name="data.spreder"
                                                label={t("spreader")}
                                                options={[
                                                    { value: 1, label: t("yes") },
                                                    { value: 0, label: t("no") },
                                                ]}
                                                defaultValue={unit?.data?.data?.spreder ? 1 : 0}
                                            />
                                        </div>
                                    </RadioContainer>
                                </ExpandableSection>

                                {/* Documents */}
                                <ExpandableSection label={t("documents_title")} expandedByDefault>
                                    {data?.documents?.length > 0 ? (
                                        <DocumentsContainer>
                                            {data?.documents?.map((document) => (
                                                <UnitDocument
                                                    key={`image-${document}`}
                                                    documentId={document}
                                                />
                                            ))}
                                        </DocumentsContainer>
                                    ) : (
                                        <NothingToShow>
                                            <TB>{t("no_documents_to_show")}</TB>
                                        </NothingToShow>
                                    )}
                                    <>
                                        <HiddenFileInput
                                            type="file"
                                            id={`add-document`}
                                            onChange={(e) => handleAddDocument(e.target.files[0])}
                                            disabled={isSavingDocument}
                                        />
                                        <AddDocumentLabel htmlFor={`add-document`}>
                                            {isSavingDocument ? (
                                                <LoadingSpinner small light />
                                            ) : (
                                                <TB $link>+ {t("add_document")}</TB>
                                            )}
                                        </AddDocumentLabel>
                                    </>
                                </ExpandableSection>

                                <SaveOrAbort saving={isSavingUnit} />
                            </>
                        )}
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default UnitEdit;

const DateInputWrapper = styled.section`
    max-width: 17rem;
`;

const CheckboxSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0 1.5rem;

    > * {
        margin-right: 3rem;
    }
`;

const RadioContainer = styled.section`
    display: flex;
    flex-wrap: wrap;

    > div {
        margin-right: 3rem;
        margin-bottom: 1rem;
    }
`;

const ServiceIntervalsContainer = styled.section`
    margin-bottom: 1rem;
`;

const DocumentsContainer = styled.div`
    display: flex;
`;

const NothingToShow = styled.section`
    min-height: 10rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const AddDocumentLabel = styled.label`
    padding: 0 1rem;
    minwidth: max-content;
    align-self: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;

const HiddenFileInput = styled.input.attrs({ type: "file" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    :focus-visible + ${AddDocumentLabel} {
        outline: 2px solid ${(p) => p.theme.color.primary.light};
    }
`;

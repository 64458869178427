import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 20px;
    height: 21px;
`;

export const HelpIcon = ({ className, stroke, ...props }) => {
    const theme = useContext(ThemeContext);
    stroke = stroke ? stroke : theme.color.primary.base;
    return (
        <Svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="10" cy="10" r="9" stroke="white" strokeWidth="2" />
            <path
                d="M9.48744 13.056C9.4341 13.056 9.40744 13.0347 9.40744 12.992V12.128C9.40744 11.7013 9.50344 11.2747 9.69544 10.848C9.8981 10.4107 10.1701 10.0213 10.5114 9.68C10.8314 9.36 11.0661 9.11467 11.2154 8.944C11.3648 8.76267 11.4608 8.59733 11.5034 8.448C11.5461 8.29867 11.5674 8.12267 11.5674 7.92C11.5674 7.54667 11.4554 7.23733 11.2314 6.992C11.0074 6.736 10.7248 6.608 10.3834 6.608C10.0421 6.608 9.7541 6.736 9.51944 6.992C9.29544 7.23733 9.18344 7.54667 9.18344 7.92V9.088C9.18344 9.13067 9.1621 9.152 9.11944 9.152H7.31144C7.26877 9.152 7.24744 9.13067 7.24744 9.088V7.92C7.24744 7.32267 7.3861 6.77867 7.66344 6.288C7.95144 5.79733 8.3301 5.408 8.79944 5.12C9.27944 4.832 9.80744 4.688 10.3834 4.688C10.9594 4.688 11.4821 4.832 11.9514 5.12C12.4314 5.408 12.8101 5.79733 13.0874 6.288C13.3648 6.77867 13.5034 7.32267 13.5034 7.92C13.5034 8.272 13.4661 8.58667 13.3914 8.864C13.3168 9.13067 13.1781 9.41333 12.9754 9.712C12.7728 10 12.4741 10.3627 12.0794 10.8C11.8128 11.0667 11.6208 11.3067 11.5034 11.52C11.3968 11.7333 11.3434 11.968 11.3434 12.224V12.992C11.3434 13.0347 11.3168 13.056 11.2634 13.056H9.48744ZM9.48744 16C9.4341 16 9.40744 15.9733 9.40744 15.92V14.144C9.40744 14.1013 9.4341 14.08 9.48744 14.08H11.2634C11.3168 14.08 11.3434 14.1013 11.3434 14.144V15.92C11.3434 15.9733 11.3168 16 11.2634 16H9.48744Z"
                fill="white"
            />
        </Svg>
    );
};

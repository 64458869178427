import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 15px;
    height: 15px;
`;

export const StarIcon = () => {
    return (
        <Svg width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
                d="M8 3.23607L8.84506 5.83688L9.06957 6.52786H9.79611H12.5308L10.3184 8.13525L9.7306 8.56231L9.95511 9.25329L10.8002 11.8541L8.58778 10.2467L8 9.81966L7.41221 10.2467L5.19983 11.8541L6.04489 9.25329L6.2694 8.56231L5.68162 8.13525L3.46923 6.52786H6.20389H6.93043L7.15494 5.83688L8 3.23607Z"
                fill="#38558D"
                stroke="#38558D"
                strokeWidth="2"
            />
        </Svg>
    );
};

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { useKitbox } from "./hooks/useKitbox";
import { useSaveKitbox } from "./hooks/useSaveKitbox";
import { useCustomersWithPermission } from "../customers/hooks/useCustomersWithPermission";
import { PERMISSIONS } from "../../auth/permissions";
import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import { H1 } from "../../../components/texts";
import { InputCheckbox, InputWithLabel } from "../../../components/inputs";
import ServerSelect from "../../../components/application/ServerSelect";
import SaveOrAbort from "../../../components/application/SaveOrAbort";

const KitboxEdit = () => {
    const { t } = useTranslation();

    const methods = useForm();
    const params = useParams();
    const kitboxId = Number(params.id);
    const navigate = useNavigate();

    const kitbox = useKitbox(kitboxId);
    const { mutate: saveKitbox, isLoading: isSavingKitbox } = useSaveKitbox();

    useEffect(() => {
        if (kitboxId === 0) {
            methods.reset({ active: true });
        } else if (kitbox?.data) {
            methods.reset({
                name: kitbox.data.name,
                customer_id: kitbox.data.customer_id,
                active: kitbox.data.active,
            });
        }
    }, [kitboxId, kitbox?.data]);

    function onSubmit(data) {
        const post = {
            name: data.name,
            active: Boolean(data.active),
        };

        if (kitboxId) {
            post.id = kitboxId;
        } else {
            post.id = 0;
            post.customer_id = data.customer_id.value;
        }

        saveKitbox(post, { onSuccess: navigateToKitboxList });
    }

    function navigateToKitboxList() {
        navigate("/administration/setup/kitbox");
    }

    return (
        <MainArea>
            <Heading>{kitboxId === 0 ? t("add_new_kitbox") : t("edit_kitbox")}</Heading>

            <QueryWrapper data={kitboxId !== 0 ? kitbox : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <CustomerSelect
                            name="customer_id"
                            label={t("choose_customer")}
                            selectUse={useCustomersWithPermission}
                            selectKey={{ permission: PERMISSIONS.part_admin.name }}
                            validate={{ required: t("customer_required") }}
                            isDisabled={!!kitboxId}
                        />
                        <InputWithLabelStyled
                            name="name"
                            label={t("name")}
                            validate={{ required: t("name_required") }}
                        />

                        <InputCheckbox name={"active"} label={t("active")} />

                        <SaveOrAbort saving={isSavingKitbox} />
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default KitboxEdit;

const Heading = styled(H1)`
    margin-bottom: 2rem;
`;

const InputWithLabelStyled = styled(InputWithLabel)`
    max-width: 30rem;
`;

const CustomerSelect = styled(ServerSelect)`
    max-width: 30rem;
`;

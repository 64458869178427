import React from "react";
import ReactDOM from "react-dom/client";

import AppProviders from "./AppProviders";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import "./index.scss";
import "mapbox-gl/dist/mapbox-gl.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AppProviders>
            <App />
        </AppProviders>
    </React.StrictMode>
);

serviceWorker.unregister();

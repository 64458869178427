import { useState, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import QueryWrapper from "../../../components/application/QueryWrapper";
import { useServiceCategoriesForCustomer } from "./hooks/useServiceCategoriesForCustomer";
import { useServiceCategoriesTemplates } from "./hooks/useServiceCategoriesTemplates";

import { CleanButton } from "../../../components/buttons";
import { Input, SelectForm } from "../../../components/inputs";
import { FlexGap } from "../../../components/helpers/FlexGap";
import { TB, T } from "../../../components/texts";
import { DownArrowIcon } from "../../../components/icons";
import ConfigIntervalCategory from "./ConfigIntervalCategory";
import ConfirmModal from "../../../components/application/ConfirmModal";

const ConfigInterval = ({
    customerId,
    removeInterval,
    fieldKey,
    intervalIndex,
    intervalOptions,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const categories = useServiceCategoriesForCustomer(customerId);
    const templateCategories = useServiceCategoriesTemplates();

    const { t } = useTranslation();
    const {
        watch,
        register,
        setValue,
        formState: { errors },
    } = useFormContext();

    const intervalHours = watch(`intervals.${intervalIndex}.hours`);

    const allCategories = useMemo(() => {
        if (!categories?.data || !templateCategories?.data) return null;

        const categoriesSorted = [...categories.data].sort((a, b) => a.order - b.order);
        const templateCategoriesSorted = [...templateCategories.data].sort(
            (a, b) => a.order - b.order
        );

        const allCategories =
            customerId === 1 ? categoriesSorted : categoriesSorted.concat(templateCategoriesSorted);

        return allCategories;
    }, [categories?.data, templateCategories?.data]);

    function askToRemoveInterval() {
        setIsModalOpen(true);
    }

    function setCheckpointsFromInterval(option) {
        setValue(
            `intervals.${intervalIndex}.checkpoints`,
            option.checkpoints.map((item) => String(item))
        );
    }

    return (
        <>
            <Container>
                <Header $count={3} gap={"1.5rem"}>
                    <InputContainer>
                        {/* Interval hours */}
                        {intervalHours === 0 && intervalIndex === 0 ? (
                            <BaseSetupTitleContainer>
                                <TB $second>{t("base_setup")}</TB>
                            </BaseSetupTitleContainer>
                        ) : (
                            <section>
                                <label htmlFor={`hours-${fieldKey}`}>
                                    <T>{t("time_interval")} *</T>
                                </label>

                                <InputWithUnit>
                                    <Input
                                        {...register(`intervals.${intervalIndex}.hours`, {
                                            required: t("hours_required"),
                                            validate: (value) =>
                                                !isNaN(value) || t("has_to_be_number"),
                                        })}
                                        id={`hours-${fieldKey}`}
                                        autoComplete="off"
                                        type="number"
                                        step="0.25"
                                        min={0.25}
                                    />

                                    <span>{t("hours")}</span>
                                </InputWithUnit>

                                {errors?.intervals && errors.intervals[intervalIndex]?.hours ? (
                                    <IntervalError role="error">
                                        {errors.intervals[intervalIndex].hours.message ||
                                            t("error")}
                                    </IntervalError>
                                ) : null}
                            </section>
                        )}

                        {/* Estimated workhours */}
                        <section>
                            <label htmlFor={`workhours-${fieldKey}`}>
                                <T>{t("estimated_duration")}</T>
                            </label>

                            <InputWithUnit>
                                <Input
                                    {...register(`intervals.${intervalIndex}.workhours`, {
                                        validate: (value) => !isNaN(value) || t("has_to_be_number"),
                                    })}
                                    id={`workhours-${fieldKey}`}
                                    autoComplete="off"
                                    type="number"
                                    step="0.25"
                                    min={0.25}
                                />

                                <span>{t("hours")}</span>
                            </InputWithUnit>

                            {errors?.intervals && errors.intervals[intervalIndex]?.workhours ? (
                                <IntervalError role="error">
                                    {errors.intervals[intervalIndex].workhours.message ||
                                        t("error")}
                                </IntervalError>
                            ) : null}
                        </section>
                    </InputContainer>

                    <SelectContainer>
                        <SelectForm
                            name="service-template"
                            label={t("get_from")}
                            options={intervalOptions}
                            onChange={setCheckpointsFromInterval}
                        />
                    </SelectContainer>

                    <ButtonsContainer>
                        <DeleteButton type="button" onClick={askToRemoveInterval}>
                            <TB $link $underline>
                                {intervalHours === 0 && intervalIndex === 0
                                    ? t("remove_base_setup")
                                    : t("remove_config_for_interval")}
                            </TB>
                        </DeleteButton>

                        <ExpandButton type="button" onClick={() => setIsExpanded((prev) => !prev)}>
                            <DownArrowIcon className={isExpanded ? "expanded" : ""} />
                        </ExpandButton>
                    </ButtonsContainer>
                </Header>

                <Body className={isExpanded ? "expanded" : ""}>
                    <QueryWrapper data={[categories, templateCategories]}>
                        {allCategories?.map((category, index) => (
                            <ConfigIntervalCategory
                                key={`${fieldKey}-${category.id}-${index}`}
                                categoryData={category}
                                intervalIndex={intervalIndex}
                                customerId={customerId}
                            />
                        ))}
                    </QueryWrapper>
                </Body>
            </Container>

            {isModalOpen && (
                <ConfirmModal
                    isModalOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    onConfirm={removeInterval}
                    text={t("sure_you_want_to_remove_interval")}
                />
            )}
        </>
    );
};

export default ConfigInterval;

const Container = styled.article`
    border: 2px solid ${({ theme }) => theme.color.primary.base};
    width: calc(100% + 2rem);
    position: relative;
    left: -1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
`;

const Header = styled(FlexGap)`
    width: 100%;
    margin: 0;
    background: ${({ theme }) => theme.color.primary.base};
    color: #fff;
    display: flex;
    align-items: baseline;

    > * {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }
`;

const InputContainer = styled.section`
    display: flex;
    flex-direction: row;
    padding-right: 1rem;
    min-width: 20rem;

    > section {
        margin-right: 3rem;
        margin-top: 0.4rem;
    }

    label > span {
        color: #fff;
    }
`;

const InputWithUnit = styled.section`
    display: flex;
    align-items: center;

    input {
        min-width: 5rem;
        max-width: 7rem;
        margin-right: 0.5rem;
        text-align: right;
        padding-right: 0.5rem;
    }
`;

const BaseSetupTitleContainer = styled.section`
    flex-grow: 1;
    min-width: 8rem;
    max-width: 10rem;
`;

const IntervalError = styled.span`
    margin-top: 0.25rem;
`;

const SelectContainer = styled.section`
    padding-right: 1rem;

    label > span {
        color: #fff;
    }
`;

const ButtonsContainer = styled.section`
    align-self: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 0.3rem;

    ${TB} {
        color: #fff;
    }

    ${DownArrowIcon} {
        height: 100%;

        path {
            stroke: #fff;
        }

        transform: rotate(-90deg);
        transition: 200ms ease;

        &.expanded {
            transform: rotate(90deg);
        }
    }
`;
const ExpandButton = styled(CleanButton)`
    padding: 0.5rem;
`;

const DeleteButton = styled(CleanButton)`
    margin-right: 2rem;
    white-space: nowrap;
`;

const Body = styled.section`
    padding: 0 1rem;
    height: 0;
    overflow: hidden;
    display: none;

    &.expanded {
        display: block;
        margin: 0.5rem 0;
        height: auto;
    }
`;

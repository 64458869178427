import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 30px;
    height: 30px;
`;

export const CalendarIcon = () => {
    return (
        <Svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M21.5625 4.6875H8.4375"
                    stroke="#38558D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M5.625 4.6875H0.46875V27.1875H29.5312V4.6875H24.375"
                    stroke="#38558D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M8.4375 2.8125H5.625V5.5625H8.4375V2.8125Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M24.375 2.8125H21.5625V5.5625H24.375V2.8125Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M9.375 11.25H4.6875V15.9375H9.375V11.25Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M9.375 19.6875H4.6875V24.375H9.375V19.6875Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M25.3125 11.25H20.625V15.9375H25.3125V11.25Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M25.3125 19.6875H20.625V24.375H25.3125V19.6875Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M17.3438 11.25H12.6562V15.9375H17.3438V11.25Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M17.3438 19.6875H12.6562V24.375H17.3438V19.6875Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path
                    d="M0.46875 8.4375H29.5312"
                    stroke="#38558D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
            </g>
        </Svg>
    );
};

import { useMemo } from "react";
import {
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { useInspectionConfigTemplates } from "./hooks/useInspectionConfigTemplates";
import { useInspectionConfigsForCustomer } from "./hooks/useInspectionConfigsForCustomer";

import { T, TB } from "../../../components/texts";
import { Input } from "../../../components/inputs";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const InspectionConfigList = () => {
    const { t } = useTranslation();
    const { customerId } = useParams();

    const configTemplates = useInspectionConfigTemplates(customerId);
    const configs = useInspectionConfigsForCustomer(customerId);

    const data = useMemo(
        () =>
            configs?.data && configTemplates?.data
                ? customerId === "1"
                    ? configs.data
                    : [...configTemplates.data, ...configs.data]
                : [],
        [configs?.data, configTemplates?.data]
    );

    const columns = useMemo(
        () => [
            {
                header: t("name"),
                accessorKey: "name",
                cell: ({ getValue, row }) =>
                    row.original?.customer_id === 1
                        ? `${getValue()} (${t("system_template")})`
                        : getValue(),
            },
            {
                id: "edit",
                cell: ({ row }) =>
                    row.original?.customer_id === 1 && Number(customerId) !== 1 ? null : (
                        <EditConfigLink to={row.original.id.toString()}>
                            <TB $link>{t("edit")}</TB>
                        </EditConfigLink>
                    ),
                enableSortBy: false,
            },
        ],
        [customerId]
    );

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 20,
            },
        }),
        []
    );
    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <>
            <Header>
                <SearchContainer>
                    <label htmlFor="configSearch">
                        <T>{t("search_for_inspection_config")}</T>
                    </label>

                    <Input
                        type="text"
                        name="configSearch"
                        id="configSearch"
                        onChange={(e) => table.setGlobalFilter(e.target.value)}
                    />
                </SearchContainer>

                <NewConfigLink to="0">{t("add_new_config")}</NewConfigLink>
            </Header>

            {rowModel.rows.length > 0 ? (
                <TableStyled>
                    <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                </TableStyled>
            ) : (
                <NoConfigs>
                    <TB>{t("no_configs_to_show")}</TB>
                </NoConfigs>
            )}

            <TablePaginationNav
                pageCount={table.getPageCount()}
                previousPage={table.previousPage}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                canNextPage={table.getCanNextPage()}
                pageOptions={table.getPageOptions()}
                gotoPage={table.setPageIndex}
                pageIndex={table.getState().pagination.pageIndex}
            />
        </>
    );
};

export default InspectionConfigList;

const NoConfigs = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
`;

const TableStyled = styled(StandardTableStyle)`
    max-width: 50rem;

    tr {
        th:last-child,
        td:last-child {
            width: 5rem;
            padding-left: 1.5rem;
        }
    }
`;

const Header = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin-bottom: 2rem;
`;

const SearchContainer = styled.section`
    margin-right: 2rem;
    margin-bottom: 1rem;
`;

const NewConfigLink = styled(Link)`
    height: min-content;
    font-weight: 600;
    padding: 0.9rem 2rem;
    margin-bottom: 1rem;
    text-decoration: none;
    color: ${({ theme }) => theme.color.neutral.xxxlight};
    background: ${({ theme }) => theme.color.primary.base};
    transition: transform 50ms ease;

    &:hover {
        background: ${({ theme }) => theme.color.primary.light};
    }

    &:active {
        transform: scale(0.97);
    }
`;

const EditConfigLink = styled(Link)`
    text-decoration: none;
`;

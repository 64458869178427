import styled from "styled-components";
import Text from "./Text";

export const TB = styled(Text)`
    font-family: ${(props) => props.theme.font.tb["font-family"]};
    font-style: ${(props) => props.theme.font.tb["font-style"]};
    font-weight: ${(props) => props.theme.font.tb["font-weight"]};
    font-size: ${(props) => props.theme.font.tb["font-size"]};
    line-height: ${(props) => props.theme.font.tb["line-height"]};
`;

export default TB;

import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";
import {DateTime} from 'luxon';

export const usePublicTasks = (customerId, from, to, areaIds) => {
    let params = `?customer_id=${customerId}`;
    if (areaIds && areaIds.length)
        areaIds.forEach((id) => {
            params += `&area_id=${id}`;
        });

    // Define the query key
    const queryKey = ["tasks", "websocket-hooked", "public", customerId, areaIds];
    if (from) {
        params += `&from=${from}`;
        queryKey.push(from);
    }
    if (to) {
        params += `&to=${to}`;
        queryKey.push(to);
    }

    return useQuery(
        queryKey,
        () => taskAPI.getPublicTasks(params),
        {
            enabled: !!customerId,
            meta: {
                filterFn: (task) => {
                    if (task.customer_id !== customerId) return false;
                    if (areaIds && !areaIds.includes(task.unit.area_id)) return false;
                    if (from && DateTime.fromISO(from) >= DateTime.fromISO(task.start)) return false;
                    if (to && DateTime.fromISO(to) <= DateTime.fromISO(task.end)) return false;
                    return true;
                }
            },

            // Refresh the data every minute, even when site is in background
            refetchInterval: 1000 * 5,
            refetchIntervalInBackground: true,
        }
    );
};

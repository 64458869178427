import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import { usePart } from "../../materials/hooks/usePart";
import QueryWrapper from "../../../components/application/QueryWrapper";

import { T } from "../../../components/texts";
import { Input } from "../../../components/inputs";
import { CleanButton } from "../../../components/buttons";
import { TrashIcon } from "../../../components/icons";

const UnitServiceIntervalPart = ({ partId, fieldIndex, intervalIndex, handleRemovePart }) => {
    const { register } = useFormContext();

    const part = usePart(partId);

    return (
        <QueryWrapper data={part}>
            <Row>
                <T>{part.data?.name}</T>
                <T>{part.data?.description}</T>
                <T>{part.data?.location}</T>
                <QuantityInputContainer>
                    <Input
                        name="quantity"
                        type="number"
                        defaultValue={1}
                        min={1}
                        {...register(
                            `data.service_intervals.${intervalIndex}.parts.${fieldIndex}.count`
                        )}
                    />
                </QuantityInputContainer>

                <CleanButton type="button" onClick={() => handleRemovePart(fieldIndex)}>
                    <TrashIcon />
                </CleanButton>
            </Row>
        </QueryWrapper>
    );
};

export default UnitServiceIntervalPart;

const Row = styled.section`
    display: flex;
    align-items: center;
    min-width: 35rem;
    padding: 0.5rem 0;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};

    > :nth-child(1) {
        flex-basis: 35%;
    }
    > :nth-child(2) {
        flex-basis: 20%;
    }
    > :nth-child(3) {
        flex-basis: 20%;
    }
    > :nth-child(4) {
        flex-basis: 20%;
    }
    > :nth-child(5) {
        flex-basis: 5%;
    }
`;

const QuantityInputContainer = styled.section`
    > ${Input} {
        max-width: 5rem;
        text-align: right;
        padding: 0.5rem;
    }
`;

import { updateData, fetchData, deleteEntry } from "../fetch";

const fetchAbsence = async (id) => fetchData(`core/absence/${id}`);
const fetchAbsences = async (params) => fetchData(`core/absence${params || ""}`);
const updateAbsence = async (data) => updateData(data, `core/absence`);
const deleteAbsence = async (id) => deleteEntry(id, `core/absence`);

export default {
    fetchAbsence,
    fetchAbsences,
    updateAbsence,
    deleteAbsence,
};

import ReactModal from "react-modal";

import AbsenceEditContainer from "./AbsenceEditContainer";

const AbsenceEdit = ({
    isOpen,
    onClose,
    workshopId,
    absenceId,
    extraId,
    currentlySelectedIndex,
    setCurrentlySelectedIndex,
}) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={"Modal"}
            overlayClassName={"ModalOverlay"}
            shouldCloseOnOverlayClick={false}
        >
            <AbsenceEditContainer
                absenceId={absenceId}
                extraId={extraId}
                onClose={onClose}
                workshopId={workshopId}
                currentlySelectedIndex={currentlySelectedIndex}
                setCurrentlySelectedIndex={setCurrentlySelectedIndex}
            />
        </ReactModal>
    );
};

export default AbsenceEdit;

import { useEffect, useState } from "react";
import { usePermissions, checkForPermission } from "../../../user/hooks/usePermissions";
import { useMyUser } from "../../users/hooks/useMyUser";
import { useCustomers } from "./useCustomers";

export const useCustomersWithPermissions = ({ permissionsToCheck = [] }) => {
    const [state, setState] = useState({
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: null,
        data: null,
    });

    const customers = useCustomers();
    const permissions = usePermissions();
    const user = useMyUser();

    useEffect(() => {
        setState({
            isLoading: customers?.isLoading || permissions?.isLoading,
            isSuccess: customers?.isSuccess && permissions?.isSuccess,
            isError: customers?.isError || permissions?.isError,
            error: customers?.error,
            data:
                customers?.data && permissions?.data && user?.data
                    ? customers?.data?.filter((customer) =>
                          permissionsToCheck?.length
                              ? permissionsToCheck
                                    ?.map(
                                        (permission) =>
                                            checkForPermission({
                                                dataToCheck: permissions?.data,
                                                permission,
                                                customer_id: customer.id,
                                            }) || customer.id === user.data.customer_id
                                    )
                                    ?.some((check) => check === true)
                              : true
                      )
                    : null,
        });
    }, [customers?.data, permissions?.data, user?.data]);

    return state;
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomersWithPermissions } from "../../admin/customers/hooks/useCustomersWithPermissions";
import { PERMISSIONS } from "../../auth/permissions";
import QueryWrapper from "../../../components/application/QueryWrapper";
import styled from "styled-components";
import SJACustomerSelect from "./SJACustomerSelect";
import MainArea from "../../../components/layout/MainArea";
import { H1, T, TB } from "../../../components/texts";
import { TaskStatus } from "../../../api/core/taskAPI";
import { useCompletedPreOrPostCheckTasks } from "../../task/hooks/useCompletedPreOrPostCheckTasks";
import {
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import StandardTableContent from "../../../components/application/StandardTableContent";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import { CleanButton } from "../../../components/buttons";
import { LesserThanIcon } from "../../../components/icons";
import { standardDateOnly } from "../../../common/date";
import TaskPreCheck from "../../task/TaskPreCheck";
import TaskPostCheck from "../../task/TaskPostCheck";

const SJAReports = () => {
    const { t } = useTranslation();
    const [customerId, setCustomerId] = useState(null);
    const customers = useCustomersWithPermissions({
        permission: PERMISSIONS.task_pre_check_admin.name,
    });
    useEffect(() => {
        if (customers?.data?.length === 1) {
            setCustomerId(customers.data[0]?.id);
        }
    }, [customers?.data]);

    const columns = useMemo(
        () => [
            {
                header: t("date"),
                accessorKey: "end",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? standardDateOnly(value) : "";
                },
                enableSorting: false,
            },
            {
                header: t("order"),
                accessorKey: "id",
                enableSorting: false,
            },
            {
                header: t("type"),
                accessorKey: "type",
                cell: ({ getValue }) => t(getValue()),
                enableSorting: false,
            },
            {
                header: t("unit"),
                accessorKey: "unit.int_id",
                cell: ({ getValue, row }) => (
                    <EditLink to={`/units/${row.original.id}/edit`}>
                        <TB $link>{getValue()}</TB>
                    </EditLink>
                ),
                enableSorting: false,
            },
            {
                header: `${t("brand")} - ${t("type")}`,
                accessorFn: (row) => `${row.unit.manufacturer} ${row.unit.type}`,
                enableSorting: false,
            },
            {
                header: t("mechanic"),
                accessorKey: "mechanic",
                cell: ({ getValue }) => {
                    const { first_name, last_name } = getValue();
                    return `${first_name} ${last_name}`;
                },
                enableSorting: false,
            },
            {
                header: () => null,
                id: "expandButton",
                cell: ({ row }) => {
                    return row.getCanExpand() ? (
                        <CleanButton onClick={row.getToggleExpandedHandler()}>
                            <Chevron $isExpanded={row.getIsExpanded()} />
                        </CleanButton>
                    ) : null;
                },
            },
        ],
        []
    );

    const tasks = useCompletedPreOrPostCheckTasks(customerId, [
        TaskStatus.COMPLETED,
        TaskStatus.INVOICED,
    ]);
    const data = useMemo(() => {
        const taskData = tasks?.data;
        if (!taskData) return [];
        return taskData.map((taskData) => ({
            ...taskData,
            type: "pre_check_type",
        }));
    }, [tasks?.data]);

    const renderRowSubComponent = useCallback(({ row }) => {
        const { pre_check_template, post_check_template } = row.original;
        const preCheck = !!pre_check_template ? JSON.parse(pre_check_template) : {};
        const postCheck = !!post_check_template ? JSON.parse(post_check_template) : {};
        const isValidPreCheck = preCheck?.type === "PreCheck" && !!preCheck?.done;
        const isValidPostCheck = postCheck?.type === "PostCheck" && !!postCheck?.done;
        return (
            <tr>
                <td colSpan={row.getVisibleCells().length}>
                    <SJAContainer>
                        {isValidPreCheck ? (
                            <>
                                <TitleContainer>
                                    <Title>{t("pre_check_type")}</Title>
                                </TitleContainer>
                                <TaskPreCheck
                                    preCheckTemplate={preCheck}
                                    setPreCheckTemplate={() => undefined}
                                    disabled={true}
                                />
                            </>
                        ) : null}
                        {isValidPostCheck ? (
                            <>
                                <TitleContainer>
                                    <Title>{t("post_check_type")}</Title>
                                </TitleContainer>
                                <TaskPostCheck
                                    postCheckTemplate={postCheck}
                                    setPostCheckTemplate={() => undefined}
                                    disabled={true}
                                />
                            </>
                        ) : null}
                    </SJAContainer>
                </td>
            </tr>
        );
    }, []);

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 20,
            },
        }),
        []
    );
    const [expanded, setExpanded] = useState({});
    const table = useReactTable({
        columns,
        data,
        initialState,
        state: {
            expanded,
        },
        autoResetPageIndex: false,
        onExpandedChange: setExpanded,
        getSubRows: (row) => {
            if (!!row.isSubRow) return [];
            const hasPreCheckTemplate = !!row?.pre_check_template;
            const hasPostCheckTemplate = !!row?.post_check_template;
            if (!hasPreCheckTemplate && !hasPostCheckTemplate) return [];

            return [
                {
                    isSubRow: true,
                    pre_check_template: row.pre_check_template,
                    post_check_template: row.post_check_template,
                },
            ];
        },
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <>
            <MainArea>
                <H1>{t("sja")}</H1>

                <QueryWrapper data={customers}>
                    <label>
                        <T>{t("customer")}</T>
                    </label>
                    {customers?.data?.length > 1 ? (
                        <SelectWrapper>
                            <SJACustomerSelect onChange={(e) => setCustomerId(e.value)} />
                        </SelectWrapper>
                    ) : null}

                    {rowModel.rows.length > 0 ? (
                        <TableContainer>
                            <TableStyled>
                                <StandardTableContent
                                    headerGroups={headerGroups}
                                    rowModel={rowModel}
                                    renderRowSubComponent={renderRowSubComponent}
                                />
                            </TableStyled>
                        </TableContainer>
                    ) : customerId != null ? (
                        <NoSJA>
                            <TB>{t("no_sja_to_show")}</TB>
                        </NoSJA>
                    ) : null}

                    {customerId != null ? (
                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={table.previousPage}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={table.nextPage}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={table.setPageIndex}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    ) : null}
                </QueryWrapper>
            </MainArea>
        </>
    );
};

export default SJAReports;

const Chevron = styled(LesserThanIcon)`
    transform: rotate(${(p) => (p.$isExpanded ? "90deg" : "-90deg")});
    transition: transform 0.4s ease;
`;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:first-child,
        td:first-child {
            max-width: 6rem;
        }

        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
        }
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const NoSJA = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const EditLink = styled(Link)`
    text-decoration: none;
`;

const SelectWrapper = styled.section`
    width: min(25rem, 100%);
`;

const SJAContainer = styled.div`
    margin: 2rem;
`;

const TitleContainer = styled.div`
    text-align: center;
`;

const Title = styled.span`
    font-size: 1.2rem;
`;

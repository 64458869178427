import { useQuery } from "react-query";
import userAPI from "../../../../api/core/userAPI";
import { useAuth } from "../../../auth/hooks/useAuth";

export const useMyUser = () => {
    const { authUser } = useAuth();
    const userId = authUser?.sub;

    return useQuery(["users", "me"], userAPI.getMe, { enabled: !!userId });
};

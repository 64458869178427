import { Toaster } from "react-hot-toast";

import theme from "../../theme";
import { ErrorIcon } from "../../components/icons";

const NotificationContainer = () => (
    <Toaster
        position="bottom-center"
        toastOptions={{
            duration: 6000,
            style: {
                fontFamily: theme.font.t["font-family"],
                fontWeight: "bold",
                background: theme.color.warning.xxlight,
                color: theme.color.neutral.base,
                borderRadius: 0,
                padding: "1rem",
            },
            error: {
                icon: <ErrorIcon />,
            },
        }}
    />
);

export default NotificationContainer;

import styled from "styled-components";

export const Checkbox = ({ className, checked, size = 14, onClick, disabled, ...props }) => {
    return (
        <CheckboxContainer
            className={className}
            onClick={() => !disabled && onClick()}
            disabled={disabled}
            size={size}
        >
            <HiddenCheckbox
                checked={checked}
                onChange={() => !disabled && onClick()}
                disabled={disabled}
                {...props}
            />
            <StyledCheckbox checked={checked} size={size}>
                <Icon width={size - 6} height={size - 6} viewBox="0 0 12 13" size={size - 6}>
                    <path d="M1 6 L5.5 10.5 L10.5 2" stroke="white" strokeWidth="2" />
                </Icon>
            </StyledCheckbox>
        </CheckboxContainer>
    );
};
const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: sub;
    cursor: pointer;

    width: ${(props) => (props.size ? props.size : "14")}px;
    height: ${(props) => (props.size ? props.size : "14")}px;
`;

const Icon = styled.svg`
    fill: none;
    stroke: ${(props) => props.theme.color.primary.light};
    stroke-width: 2px;
    vertical-align: top;
    width: ${(props) => (props.size ? props.size : "14")}px;
    height: ${(props) => (props.size ? props.size : "14")}px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div`
    display: inline-block;
    width: ${(props) => (props.size ? props.size : 14)}px;
    height: ${(props) => (props.size ? props.size : 14)}px;
    background: ${(props) => (props.checked ? props.theme.color.primary.base : "white")};
    border: 2px solid ${(props) => props.theme.color.primary.light};
    transition: all 100ms;
    background-clip: content-box;
    padding: 1px;
    pointer-events: none;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px lightgrey;
    }

    ${Icon} {
        visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    }
`;

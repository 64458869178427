import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { TaskStatus } from "../../../api/core/taskAPI";
import taskAPI from "../../../api/core/taskAPI";
import { DateTime } from "luxon";

const scheduledStatusList = [
    TaskStatus.SCHEDULED,
    TaskStatus.SCHEDULED_DRAFT,
    TaskStatus.STARTED,
    TaskStatus.COMPLETED,
    TaskStatus.INVOICED,
];
export const useTasksForUnit = (fromDate, toDate, unitId, statusList = scheduledStatusList) => {
    const params = `?unit_id=${unitId}&from=${fromDate}&to=${toDate}&status=${statusList}`;

    // Define the query key
    const queryKey = ["tasks", "websocket-hooked", "scheduled", fromDate, toDate, unitId];
    const query = useQuery(queryKey, () => taskAPI.getTasksWithConnectedWorkshops(params), {
        enabled: !!fromDate && !!toDate && !!unitId,
        refetchOnWindowFocus: false,
        meta: {
            filterFn: (task) => {
                if (task.unit_id !== unitId) return false;
                if (DateTime.fromISO(fromDate) >= DateTime.fromISO(task.start)) return false;
                if (DateTime.fromISO(toDate) <= DateTime.fromISO(task.end)) return false;
                return statusList.includes(task.status);
            },
        },
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Fetching task error: ", error.response?.data?.message || error.message);
        },
    });

    return query;
};

import { useQuery } from "react-query";
import absenceAPI from "../../../api/core/absenceAPI";

export const useAbsencesBetween = ({ fromDate, toDate, customerId }) => {
    const params = `?from=${fromDate}&to=${toDate}&customer_id=${customerId}`;

    return useQuery(
        ["absence", customerId, fromDate, toDate],
        () => absenceAPI.fetchAbsences(params),
        {
            enabled: !!fromDate && !!toDate && !!customerId,
        }
    );
};

import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import styled from "styled-components";

import StandardTableStyle from "../../../components/application/StandardTableStyle";
import { CleanButton } from "../../../components/buttons";
import { TB, T } from "../../../components/texts";
import EditableCell from "./EditableCell";
import ConfirmModal from "../../../components/application/ConfirmModal";
import { useDeleteTaskPreCheckCheckpoint } from "./mutations/useDeleteTaskPreCheckCheckpoint";
import { useSaveTaskPreCheckCheckpoint } from "./mutations/useSaveTaskPreCheckCheckpoint";
import StandardTableContent from "../../../components/application/StandardTableContent";

const TaskPreChecksEditTable = ({ checkpoints, customerId }) => {
    const [tableData, setTableData] = useState([]);
    const [rowToDelete, setRowToDelete] = useState(null);
    const [isCheckpointModalOpen, setIsModalOpen] = useState(false);
    const { mutate: saveCheckpoint } = useSaveTaskPreCheckCheckpoint({});

    const { t } = useTranslation();

    const { mutate: deleteCheckpoint, isLoading: isDeletingCheckpoint } =
        useDeleteTaskPreCheckCheckpoint({ onSuccess: closeModal });

    useEffect(() => {
        if (checkpoints) setTableData(checkpoints);
    }, [checkpoints]);

    function handleAddCheckpoint() {
        setTableData((prev) => [
            ...prev,
            {
                name: "",
                description: "",
                order:
                    (prev.reduce((acc, curr) => (curr.order > acc ? curr.order : acc), 0) ?? 0) + 1,
            },
        ]);
    }

    function askToRemoveCheckpoint(row) {
        setRowToDelete(row);
        setIsModalOpen(true);
    }

    function closeModal() {
        setIsModalOpen(false);
        setRowToDelete(null);
    }

    function removeCheckpoint() {
        const id = rowToDelete?.original?.id;
        if (id) {
            deleteCheckpoint({
                customerId,
                id: rowToDelete?.original?.id,
            });
        } else {
            setTableData((prev) => prev.filter((row) => row.id));
            setIsModalOpen(false);
            setRowToDelete(null);
        }
    }

    const columns = useMemo(
        () => [
            {
                header: t("pre_check_order"),
                accessorKey: "order",
                cell: EditableCell,
                enableSorting: false,
            },
            {
                header: t("checkpoint"),
                accessorKey: "name",
                cell: EditableCell,
                enableSorting: false,
            },
            {
                header: t("helptext"),
                accessorKey: "description",
                cell: EditableCell,
                enableSorting: false,
            },
            {
                id: "delete",
                cell: ({ row }) => (
                    <CleanButton onClick={() => askToRemoveCheckpoint(row)}>
                        <TB $link>{t("delete")}</TB>
                    </CleanButton>
                ),
                enableSorting: false,
            },
        ],
        []
    );

    const data = useMemo(() => tableData || [], [tableData]);

    const table = useReactTable({
        columns,
        data,
        meta: {
            updateData: (id, fieldName, value) => {
                setTableData((prev) => {
                    let newCheckpoint = null;
                    const newTableData = prev.map((checkpoint) => {
                        if (checkpoint.id === id) {
                            newCheckpoint = { ...checkpoint, [fieldName]: value };
                            return newCheckpoint;
                        }

                        return checkpoint;
                    });

                    if (newCheckpoint !== null) {
                        const { customer_id, createdAt, updatedAt, ...post } = newCheckpoint;
                        saveCheckpoint({
                            customerId,
                            data: post,
                        });
                    }

                    return newTableData;
                });
            },
        },
        getCoreRowModel: getCoreRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <>
            {rowModel.rows.length > 0 ? (
                <TableStyled>
                    <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                </TableStyled>
            ) : (
                <NoCheckpoints>{t("no_checkpoints_found")}</NoCheckpoints>
            )}

            <Buttons>
                <ActionButton onClick={handleAddCheckpoint}>
                    <TB $link $underline>
                        {t("add_new_checkpoint")}
                    </TB>
                </ActionButton>
            </Buttons>

            {isCheckpointModalOpen && (
                <ConfirmModal
                    isModalOpen={isCheckpointModalOpen}
                    closeModal={closeModal}
                    onConfirm={removeCheckpoint}
                    text={
                        <>
                            <TB>
                                {`${t("sure_you_want_to_remove_checkpoint")}${
                                    rowToDelete?.original?.name
                                        ? ` "${rowToDelete?.original?.name}"?`
                                        : "?"
                                }`}
                            </TB>
                            <br />
                            <br />
                            <T>{t("remove_checkpoint_warning")}</T>
                        </>
                    }
                    isLoading={isDeletingCheckpoint}
                />
            )}
        </>
    );
};

export default TaskPreChecksEditTable;

const TableStyled = styled(StandardTableStyle)`
    margin-bottom: 1.5rem;

    th,
    td {
        padding: 0;
    }

    td {
        padding: 1rem 0;
    }

    tr {
        th:first-child,
        td:first-child {
            width: 20rem;
        }

        th:last-child,
        td:last-child {
            width: 5rem;
            padding-left: 1.5rem;
        }
    }
`;

const NoCheckpoints = styled.section`
    height: 3rem;
`;

const Buttons = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const ActionButton = styled(CleanButton)`
    margin-right: 2rem;
`;

import { useUnitAreas } from "../../../features/unit/hooks/useUnitAreas";
import ServerSelect from "../ServerSelect";

const UnitAreas = ({ customer_id = null, ...props }) => {
    return (
        <ServerSelect
            selectUse={useUnitAreas}
            {...props}
            filter={customer_id ? { customer_id } : null}
        />
    );
};

export default UnitAreas;

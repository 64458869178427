import styled from "styled-components";
import Button from "./Button";

export const DarkButton = styled(Button)`
    background-color: ${(props) => props.theme.color.secondary.base};

    &:hover {
        background-color: ${(props) => props.theme.color.secondary.base};
        color: ${(props) => props.theme.color.neutral.base};
    }
`;

export default DarkButton;

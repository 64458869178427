import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 88px;
    height: 67px;
`;

export const TruckIcon = () => (
    <Svg width="88" height="67" viewBox="0 0 88 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M58 57V37.5H48M20 37.5V30.5H1V57H6M20 37.5H48M20 37.5V6H36L48 37.5M24 57.5H34M52 57.5H58"
            stroke="#38558D"
            strokeWidth="2"
        />
        <path d="M58 2V57.5H87.5" stroke="#38558D" strokeWidth="2" />
        <circle cx="15" cy="57" r="9" stroke="#38558D" strokeWidth="2" />
        <circle cx="43" cy="57" r="9" stroke="#38558D" strokeWidth="2" />
        <rect x="63" y="38" width="18" height="18" fill="#6D8AC5" />
        <rect x="63" width="18" height="18" fill="#6D8AC5" />
        <rect x="66" y="19" width="18" height="18" fill="#38558D" />
    </Svg>
);

import { useMemo } from "react";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useSensorsForUnit } from "../../sensor/hooks/useSensorsForUnit";
import QueryWrapper from "../../../components/application/QueryWrapper";

import { TB } from "../../../components/texts";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";
import TablePaginationNav from "../../../components/application/TablePaginationNav";

const UnitSensors = ({ unitId }) => {
    const { t } = useTranslation();

    const sensors = useSensorsForUnit(unitId);

    const columns = useMemo(
        () => [
            {
                header: t("sensor_id"),
                accessorKey: "sensor.ident",
            },
            {
                header: t("manufacturer"),
                accessorKey: "sensor.manufacturer",
            },
            {
                header: t("type"),
                accessorKey: "sensor.type",
            },
        ],
        []
    );

    const data = useMemo(
        () => (sensors?.data && sensors.data.length > 0 ? sensors.data : []),
        [sensors?.data]
    );

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <QueryWrapper data={sensors}>
            {rowModel.rows.length > 0 ? (
                <TableContainer>
                    <StandardTableStyle>
                        <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                    </StandardTableStyle>
                </TableContainer>
            ) : (
                <NothingToShow>
                    <TB>{t("no_sensors_to_show")}</TB>
                </NothingToShow>
            )}

            <TablePaginationNav
                pageCount={table.getPageCount()}
                previousPage={table.previousPage}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                canNextPage={table.getCanNextPage()}
                pageOptions={table.getPageOptions()}
                gotoPage={table.setPageIndex}
                pageIndex={table.getState().pagination.pageIndex}
            />
        </QueryWrapper>
    );
};

export default UnitSensors;

const TableContainer = styled.article`
    overflow: auto;
    max-width: 50rem;
`;

const NothingToShow = styled.section`
    min-height: 10rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 16px;
    height: 16px;
`;

export const HamburgerIcon = ({ stroke }) => {
    const theme = useContext(ThemeContext);
    stroke = stroke ? stroke : theme.color.primary.base;

    return (
        <Svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="16" y2="1" stroke={stroke} strokeWidth="2" />
            <line y1="8" x2="16" y2="8" stroke={stroke} strokeWidth="2" />
            <line y1="15" x2="16" y2="15" stroke={stroke} strokeWidth="2" />
        </Svg>
    );
};

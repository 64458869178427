import styled from "styled-components";

export const H2 = styled.h2`
    margin-block-start: 0;
    font-family: ${(props) => props.theme.font.h2["font-family"]};
    font-style: ${(props) => props.theme.font.h2["font-style"]};
    font-weight: ${(props) => props.theme.font.h2["font-weight"]};
    font-size: ${(props) => props.theme.font.h2["font-size"]};
    line-height: ${(props) => props.theme.font.h2["line-height"]};
    color: ${(props) =>
        props.color
            ? props.color
            : props.$second
            ? props.theme.text.color.second
            : props.theme.text.color.main};
`;

export default H2;

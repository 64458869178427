import { useQuery } from "react-query";
import inspectionAPI from "../../../../api/core/inspectionAPI";

export const useInspectionConfigsForCustomer = (customerId) =>
    useQuery(
        ["inspection", "config", "customer", customerId],
        () => inspectionAPI.getConfigsForCustomer(customerId),
        {
            enabled: !!customerId,
        }
    );

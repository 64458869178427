import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,

        lng: "no",
        fallbackLng: "en",
        whitelist: ["en", "no"],

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            requestOptions: {
                cache: "no-cache",
            },
        },
    });

export default i18n;

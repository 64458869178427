import styled from "styled-components";
import NavBar from "./NavBar";
import { Route, Routes, useParams } from "react-router";
import Unit from "../../unit/Unit";
import UnitControls from "../../unit/UnitControls";
import PreCheckCheckout from "../../pre-check/PreCheckCheckout";
import UnitFinished from "../../unit/UnitFinished";
import UnitPreChecklistAnswer from "../../unit/UnitPreChecklistAnswer";
import PageNotFound from "../../page-not-found/PageNotFound";

const DriverApp = () => {
    const params = useParams();
    const unitId = Number(params.id);

    return (
        <>
            <NavBar />
            <MainArea>
                <Routes>
                    <Route path="/checks" element={<UnitControls />} />
                    <Route path="/checks/:id/answer" element={<UnitPreChecklistAnswer />} />
                    <Route
                        path="/pre-checks"
                        element={
                            <PreCheckCheckout
                                onCancelNavigateTo={`/display/drivers/${unitId}`}
                                onSaveNavigateTo={`/display/drivers/${unitId}`}
                            />
                        }
                    />
                    <Route
                        path="/finish"
                        element={<UnitFinished onFinishNavigateTo={`/display/drivers/${unitId}`} />}
                    />
                    <Route
                        path="/"
                        element={
                            <Unit
                                noBackButton={true}
                                noPreviousChecksButton={true}
                                handleUnitFinishPrefix="/display/drivers/"
                                handleUnitFinishSuffix="/finish"
                                preCheckPrefix="/display/drivers/"
                                preCheckSuffix="/pre-checks"
                                onReturnHomeNavigateTo={`/display/drivers/${unitId}`}
                            />
                        }
                    />

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </MainArea>
        </>
    );
};

export default DriverApp;

const MainArea = styled.div`
    min-height: calc(100vh - calc(${(props) => props.theme.footer.height} + 3rem));
    width: 100%;
    background-clip: content-box;
    padding-bottom: 3.125rem;
`;

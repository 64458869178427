import MainArea from "../../components/layout/MainArea";
import React, { useEffect } from "react";
import { H1 } from "../../components/texts";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import EmailInput from "../application/components/user/Email";
import { useMyUser } from "../admin/users/hooks/useMyUser";

const Email = () => {
    const { t } = useTranslation();
    const methods = useForm({ mode: "onChange" });
    const user = useMyUser();

    const onSubmit = (data) => console.log(data);

    useEffect(() => {
        if (user?.data) methods.reset({ ...user.data });
    }, [user?.data]);

    return (
        <MainArea>
            <H1>{t("email", "Email")}</H1>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <EmailInput />
                    <SaveOrAbort save={"save_info"} />
                </form>
            </FormProvider>
        </MainArea>
    );
};

export default Email;

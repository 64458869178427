import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import unitAPI from "../../../api/core/unitAPI";

export const useUnitStartMutation = () => {
    const queryClient = useQueryClient();

    return useMutation((id) => unitAPI.getStart(id), {
        onSuccess: () => {
            queryClient.invalidateQueries("units");
            queryClient.invalidateQueries("prechecks");
        },
        onError: (error) => {
            toast.error(error.message ? error.message : `Det oppsto en feil`);
        },
    });
};

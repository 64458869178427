import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { usePermission } from "../../features/user/hooks/usePermission";
import { useUnit } from "../../features/unit/hooks/useUnit";
import { useUnitStartMutation } from "../../features/unit/mutations/useUnitStartMutation";
import { useUnitTakeMutation } from "../../features/unit/mutations/useUnitTakeMutation";
import { PERMISSIONS } from "../../features/auth/permissions";

import CleanButton from "./CleanButton";
import Button from "./Button";
import { TB } from "../texts";
import { useAuth } from "../../features/auth/hooks/useAuth";

export const UseUnitButton = ({
    id,
    text,
    handleUnitFinishPrefix = "/unit/finish/",
    handleUnitFinishSuffix = "",
    preCheckPrefix = "/pre-check/check-out/",
    preCheckSuffix = "",
    onReturnHomeNavigateTo = "/",
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { authUser } = useAuth();
    const userId = authUser?.sub;

    const { data: unit } = useUnit(id, true);
    const permission = usePermission(PERMISSIONS.unit_use.name, unit?.customer_id);

    const { mutate: startUnit } = useUnitStartMutation();
    const { mutate: takeUnit } = useUnitTakeMutation();

    function onStartUnitSuccess(data) {
        if (data?.pre_check) {
            navigate(`${preCheckPrefix}${data.unit.id}${preCheckSuffix}`);
        } else {
            navigate(onReturnHomeNavigateTo);
            toast.success(t("you_can_use_vehicle"));
        }
    }

    function handleUnit() {
        if (id) {
            if (unit?.user_id === userId) {
                navigate(`${handleUnitFinishPrefix}${unit?.id}${handleUnitFinishSuffix}`);
            } else {
                if (unit?.user) {
                    takeUnit(id);
                } else {
                    startUnit(id, {
                        onSuccess: onStartUnitSuccess,
                    });
                }
            }
        }
    }

    return text ? (
        <CleanButton disabled={!id || unit?.defect || !permission} onClick={() => handleUnit()}>
            <TB $underline $link>
                {unit?.user ? (unit?.user_id === userId ? t("finished") : t("takeover")) : t("use")}
            </TB>
        </CleanButton>
    ) : (
        <Button disabled={!id || unit?.defect || !permission} onClick={() => handleUnit()}>
            {unit?.user ? (unit?.user_id === userId ? t("finished") : t("takeover")) : t("use")}
        </Button>
    );
};

import MainArea from "../../components/layout/MainArea";
import H1 from "../../components/texts/H1";
import styled from "styled-components";
import {Horizontal, Vertical} from "../../components/layout/FlexGrid";
import {Input} from "../../components/inputs";
import {FlexGap} from "../../components/helpers/FlexGap";
import {CleanButton, SecondaryButton} from "../../components/buttons";
import {useCallback, useRef, useState} from "react";
import {TrashIcon} from "../../components/icons";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import {useTranslation} from "react-i18next";
import {usePartsWithParams} from "./hooks/usePartsWithParams";
import {debounce} from "lodash";
import {useSearchParams} from "../application/hooks/useSearchParams";
import toast from "react-hot-toast";


import partApi from "../../api/core/partAPI";

const InventoryWithdrawalCustomer = () => {

  const [WithdrawData, setWithdrawData] = useState({objectNr: "", workorderNr: ""});
  const [formPartnr, setFormPartnr] = useState("");
  const [formBarcode, setFormBarcode] = useState("");
  const [formWithdrawnRow, setFormWithdrawnRow] = useState([]);
  const [SuggestionOpen, setSuggestionOpen] = useState(false);
  const [foundSuggestions, setFoundSuggestions] = useState([]);

  const {t} = useTranslation();

  const {getParam, setParam} = useSearchParams();
  const querySearch = getParam("search") || "";
  const queryTake = getParam("take") || "";
  const queryLength = 2;

  const barcodeInputRef = useRef(null);

  const foundParts = usePartsWithParams(
          `?take=${queryTake}&search=${encodeURIComponent(querySearch)}`,
          formPartnr.length > queryLength
  );

  const debounceSearch = useCallback(
          debounce((value) => {
            setParam("take", 5);
            setParam("search", value);
            setFoundSuggestions(foundParts?.data?.data || []);
          }, 200),
          [formPartnr]
  );

  //TODO:
  // Considering creating a new endpoint for foundParts search @ backend,
  // so it can get spesific values from spesific columns
  // Also it keeps fetching data on page load..


  const handleChangeObjectNr = (e) => {
    setWithdrawData((prevData) => ({
      ...prevData,
      objectNr: e.target.value,
    }));
  };

  const handleChangeWorkorderNr = (e) => {
    setWithdrawData((prevData) => ({
      ...prevData,
      workorderNr: e.target.value,
    }));
  };

  const handlePartInputChange = (input, e) => {
    if (input === 'barcode') {
      setFormBarcode(e.target.value);
    }
    if (input === 'manual') {
      setFormPartnr(e.target.value);
      debounceSearch(e.target.value);
      setSuggestionOpen(e.target.value.length > queryLength);
    }
  }

  const handleDelete = (i) => {
    const delRow = [...formWithdrawnRow];
    delRow.splice(i, 1);
    setFormWithdrawnRow(delRow);
  };

  const handleBarcodeKeyPress = async (e) => {
    let code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault();
      const foundPart = await partApi.getPartByBarcode(formBarcode);
      if (foundPart) {
        handleAddRowFromBarcode(foundPart);
        setFormBarcode("");
      } else {
        toast.error(
                `${t('barcode_not_found')}`
        )
      }
    }
  }

  const handleAddRowFromBarcode = (part) => {
    let NewRow = {
      partnr: part.name,
      partname: part.description,
      amount: 1,
    };
    setFormWithdrawnRow([...formWithdrawnRow, NewRow]);
    setFormBarcode("");
  };

  const handleAddRowFromPopup = (part) => {
    let NewRow = {
      partnr: part.name,
      partname: part.description,
      amount: 1,
    };
    setFormWithdrawnRow([...formWithdrawnRow, NewRow]);
    setSuggestionOpen(false);
    setFormPartnr("");
    barcodeInputRef.current.focus()
  };

  const handleSaveWithdraw = () => {

    if (WithdrawData.objectNr.length < 2 || WithdrawData.workorderNr.length < 2) {
      toast.error(
              `${t('wo_or_vehicle_empty_error')}`
      )
    } else if (formWithdrawnRow.length < 1) {
      toast.error(
              `${t('no_rows_withdrawn_error')}`
      )
    } else {
      const saveWithdraw = formWithdrawnRow.map((row) => ({
        vehicle_nr: WithdrawData.objectNr,
        order_nr: WithdrawData.workorderNr,
        part_nr: row.partnr,
        part_name: row.partname,
        amount: row.amount,
        invoiced: false
      }));
      /* TODO: Add together the amount of dublicate parts before sending */
      const save = partApi.postCustomerWithdraw(saveWithdraw);
      if (save) {
        setWithdrawData({objectNr: "", workorderNr: ""});
        setFormWithdrawnRow([]);
        setFoundSuggestions([]);
        toast.success(
                `${t('withdraw_save_success')}`
        )
      } else {
        toast.error(
                `${t('withdraw_save_error')}`
        )
      }

    }
  }

  return (
          <MainArea>
            <Header>
              <H1>{t("inventory_withdrawal_customer")}</H1>
            </Header>

            <ContainerBorder>

              <Input
                      value={WithdrawData.objectNr}
                      name="vehicle"
                      validate="off"
                      autoComplete="off"
                      placeholder={t(`vehicle_or_technical_location`)}
                      onChange={(e) => handleChangeObjectNr(e)}
                      autoFocus
                      required/>

              <Input
                      value={WithdrawData.workorderNr}
                      name="workorder"
                      validate="off"
                      autoComplete="off"
                      placeholder={t(`order_number_or_workorder`)}
                      onChange={(e) => handleChangeWorkorderNr(e)}
                      required/>
            </ContainerBorder>

            <FlexGap>&nbsp;</FlexGap>

            <Vertical>

              {
                <ContainerNoBorder>
                  <ShortInput
                          value={formBarcode}
                          name="barcode"
                          validate="off"
                          autoComplete="off"
                          placeholder={t(`barcode`)}
                          autoFocus
                          ref={barcodeInputRef}
                          onChange={(e) => handlePartInputChange('barcode', e)}
                          onKeyUp={(e) => handleBarcodeKeyPress(e)}
                  />
                  <ManualPartnrInputContainer>
                    <ShortInput
                            value={formPartnr}
                            name="partnr"
                            type="text"
                            id="partSearch"
                            validate="off"
                            autoComplete="off"
                            placeholder={t(`part_name_or_number`)}
                            onChange={(e) => handlePartInputChange('manual', e)}
                    />
                    <PartnrPopup
                            style={{display: SuggestionOpen && foundSuggestions.length > 0 ? 'block' : 'none'}}>
                      <table>
                        <thead>
                        <tr>
                          <th>Delenr</th>
                          <th>{t(`part_name`)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          foundSuggestions.map((part, i) => (
                                  <tr key={i} style={{cursor: 'pointer'}}
                                      onClick={() => handleAddRowFromPopup(part)}>
                                    <td>{part.name}</td>
                                    <td>{part.description}</td>
                                  </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </PartnrPopup>
                  </ManualPartnrInputContainer>

                </ContainerNoBorder>
              }

            </Vertical>

            <FlexGap>&nbsp;</FlexGap>

            <Vertical>

              <StandardTableStyle>
                <thead>
                <TableHeader>
                  <th>{t(`part_name`)}</th>
                  <th>{t(`description_2`)}</th>
                  <th>{t(`quantity`)}</th>
                  <th>action</th>
                </TableHeader>
                </thead>
                <tbody>
                {formWithdrawnRow.length < 1 ?
                        <tr>
                          <td colSpan="4" style={{textAlign: "center"}}>
                            {t(`no_parts_to_show`)}
                          </td>
                        </tr> :
                        formWithdrawnRow.map((row, i) =>
                                <tr key={i}>
                                  <td>
                                    {row.partnr}
                                  </td>
                                  <td>{row.partname}</td>
                                  <td>{row.amount ? row.amount : 1}</td>
                                  <td>
                                    <CleanButton onClick={() => handleDelete(i)}><TrashIcon
                                            stroke="red"/></CleanButton>
                                  </td>
                                </tr>
                        )
                }
                </tbody>
              </StandardTableStyle>

            </Vertical>
            <FlexGap>&nbsp;</FlexGap>
            <SaveButton onClick={handleSaveWithdraw}>Lagre Uttak</SaveButton>
          </MainArea>
  )
}

export default InventoryWithdrawalCustomer;

const Header = styled(Horizontal)`
  align-items: center;
  flex-wrap: wrap;
`;

const ContainerBorder = styled(Horizontal)`
  border-bottom: gray solid 0.1rem;
  padding: 0.3rem;
  gap: 0.3rem;
`;

const ContainerNoBorder = styled(Horizontal)`
  padding: 0.3rem;
  gap: 0.3rem;
`;

const ShortInput = styled(Input)`
  width: 20rem;
  max-width: 30rem;
`;

const ManualPartnrInputContainer = styled.div`
  text-align: end;
  max-width: 30rem;
`;

const PartnrPopup = styled.div`
  background-color: lightgray;
  position: absolute;
  border: black dotted 0.1rem;
  text-align: start;
  min-width: 20rem;
  max-width: 30rem;
  padding: 0.1rem;
  z-index: 90;
`;

const SaveButton = styled(SecondaryButton)`
  width: 100%;
`;

const TableHeader = styled.tr`
  text-align: left;
`;

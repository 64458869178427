import { useEffect } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Category from "./PrecheckListCategory";
import { cloneDeep } from "lodash";
import { swapArrayLocs } from "../../../common/common";
import { TB } from "../../../components/texts";
import { useFormContext } from "react-hook-form";

const Container = styled.div`
    .new {
        display: block;
    }
`;

const PrecheckListCategories = ({ data, setData }) => {
    const { unregister, register, setValue } = useFormContext();

    useEffect(() => {
        register("data");
    }, []);

    const onDragEnd = ({ destination, source, type, draggableId, reason, mode, combine }) => {
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        const newData = cloneDeep(data);
        if (type === "category") {
            swapArrayLocs(newData, source.index, destination.index);
            setData(newData);
            setValue("data", newData, { shouldDirty: true });
            return;
        }
        if (type === "checkpoint") {
            if (destination.droppableId === source.droppableId) {
                const category = newData.find(
                    (category) => category.name === destination.droppableId
                );
                swapArrayLocs(category.checkpoints, source.index, destination.index);
                setData(newData);
                setValue("data", newData, { shouldDirty: true });
                return;
            } else {
                const checkpoint = newData
                    .find((category) => category.name === source.droppableId)
                    .checkpoints.splice(source.index, 1);
                newData
                    .find((category) => category.name === destination.droppableId)
                    .checkpoints.splice(destination.index, 0, checkpoint[0]);
                setData(newData);
                setValue("data", newData, { shouldDirty: true });
                return;
            }
        }
    };

    const addCategory = () => {
        const maxCategoryId = data.reduce(
            (acc, val) => (Number(val.name.substr(9)) > acc ? Number(val.name.substr(9)) : acc),
            0
        );
        const id = maxCategoryId + 1;
        const newData = cloneDeep(data);
        newData.push({
            label: ``,
            name: `category_${id}`,
            checkpoints: [{ label: ``, name: `category_${id}_checkpoint_1` }],
        });
        setData(newData);
        setValue("data", newData, { shouldDirty: true });
    };

    const deleteCategory = (index) => {
        const newData = cloneDeep(data);
        const category = newData.splice(index, 1)[0];
        for (const checkpoint of category.checkpoints) {
            unregister(checkpoint.name);
        }
        unregister(category.name);
        setData(newData);
        setValue("data", newData, { shouldDirty: true });
    };

    const addCheckpoint = (category) => {
        const newData = cloneDeep(data);
        const cat = newData.find((c) => c.name === category.name);
        const maxCheckpointId = cat.checkpoints.reduce((acc, val) => {
            const id = Number(/checkpoint_(\d+)/.exec(val.name)[1]);
            return id > acc ? id : acc;
        }, 0);
        const id = maxCheckpointId + 1;
        cat.checkpoints.push({
            label: `Sjekkpunkt ${id}`,
            name: `${category.name}:checkpoint_${id}`,
        });
        setData(newData);
        setValue("data", newData, { shouldDirty: true });
    };

    const deleteCheckpoint = (category, index) => {
        const newData = cloneDeep(data);
        const cat = newData.find((c) => c.name === category.name);
        const checkpoint = cat.checkpoints.splice(index, 1)[0];
        unregister(checkpoint.name);
        setData(newData);
        setValue("data", newData, { shouldDirty: true });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"all-categories"} type={"category"}>
                {(provided) => (
                    <Container {...provided.droppableProps} ref={provided.innerRef}>
                        {data.map((category, index) => (
                            <Category
                                key={category.name}
                                category={category}
                                index={index}
                                addCheckpoint={addCheckpoint}
                                deleteCheckpoint={deleteCheckpoint}
                                deleteCategory={deleteCategory}
                            />
                        ))}
                        {provided.placeholder}
                        <div className={"new"}>
                            <TB $link $underline onClick={() => addCategory()}>
                                Legg til kategori
                            </TB>
                        </div>
                    </Container>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default PrecheckListCategories;

import { useQuery } from "react-query";
import unitAPI from "../../../api/core/unitAPI";
import { TaskStatus } from "../../../api/core/taskAPI";

const scheduledStatusList = [
    TaskStatus.SCHEDULED,
    TaskStatus.SCHEDULED_DRAFT,
    TaskStatus.STARTED,
    TaskStatus.COMPLETED,
    TaskStatus.INVOICED,
];
export const useUnitByUuid = (uuid, fromDate, toDate, statusList = scheduledStatusList) => {
    const params = [`status=${statusList.join(",")}`];
    if (fromDate != null) {
        params.push(`from=${fromDate}`);
    }

    if (toDate != null) {
        params.push(`to=${toDate}`);
    }

    const paramsStr = `?${params.join("&")}`;
    return useQuery(["units", uuid, paramsStr], () => unitAPI.getUnitByUuid(uuid, paramsStr), {
        enabled: !!uuid,
    });
};

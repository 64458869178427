import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { isEqual, startOfMinute, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CleanButton } from "../../../components/buttons";
import { T } from "../../../components/texts";
import { DateTimeInputForm } from "../../../components/inputs";
import UnitStatusDataDisplay from "./UnitStatusDataDisplay";

const UnitStatusHistory = ({ selectedUnits, setSelectedUnits }) => {
    const methods = useForm();
    const { t } = useTranslation();

    useEffect(() => {
        methods.setValue("to", new Date());
        methods.setValue("from", sub(new Date(), { days: 1 }));
    }, []);

    const fromDate = methods.watch("from");
    const toDate = methods.watch("to");

    const selectedTimeRangePeriod = useMemo(() => {
        let period = "custom";

        const fromDate = startOfMinute(new Date(methods.getValues("from")));
        const toDate = startOfMinute(new Date(methods.getValues("to")));
        const dateNow = startOfMinute(new Date());

        if (isEqual(toDate, dateNow)) {
            if (isEqual(fromDate, sub(toDate, { hours: 1 }))) {
                period = "hour";
            } else if (isEqual(fromDate, sub(toDate, { days: 1 }))) {
                period = "day";
            } else if (isEqual(fromDate, sub(toDate, { weeks: 1 }))) {
                period = "week";
            } else if (isEqual(fromDate, sub(toDate, { months: 1 }))) {
                period = "month";
            }
        }

        return period;
    }, [fromDate, toDate]);

    function handleRemoveUnitFromSelected(unitId) {
        setSelectedUnits((prev) => prev.filter((option) => option.value !== unitId));
    }

    function handleSetTimeRange(unit) {
        const dateNow = new Date();
        methods.setValue("to", dateNow);

        switch (unit) {
            case "hour":
                methods.setValue("from", sub(dateNow, { hours: 1 }));
                break;
            case "day":
                methods.setValue("from", sub(dateNow, { days: 1 }));
                break;
            case "week":
                methods.setValue("from", sub(dateNow, { weeks: 1 }));
                break;
            case "month":
                methods.setValue("from", sub(dateNow, { months: 1 }));
                break;
            default:
                return;
        }
    }

    return (
        <>
            <FormProvider {...methods}>
                <form>
                    <DateRowContainer>
                        <DateInputContainer>
                            <DateTimeInputForm name="from" label={t("from")} />
                        </DateInputContainer>

                        <DateInputContainer>
                            <DateTimeInputForm name="to" label={t("to")} />
                        </DateInputContainer>

                        <SetTimeButton
                            onClick={() => handleSetTimeRange("hour")}
                            $isSelected={selectedTimeRangePeriod === "hour"}
                        >
                            <T>{t("last_hour")}</T>
                        </SetTimeButton>
                        <SetTimeButton
                            onClick={() => handleSetTimeRange("day")}
                            $isSelected={selectedTimeRangePeriod === "day"}
                        >
                            <T>{t("last_day")}</T>
                        </SetTimeButton>
                        <SetTimeButton
                            onClick={() => handleSetTimeRange("week")}
                            $isSelected={selectedTimeRangePeriod === "week"}
                        >
                            <T>{t("last_week")}</T>
                        </SetTimeButton>
                        <SetTimeButton
                            onClick={() => handleSetTimeRange("month")}
                            $isSelected={selectedTimeRangePeriod === "month"}
                        >
                            <T>{t("last_month")}</T>
                        </SetTimeButton>
                    </DateRowContainer>
                </form>
            </FormProvider>

            <UnitStatusDataDisplay
                selectedUnits={selectedUnits}
                fromDate={methods.getValues("from")}
                toDate={methods.getValues("to")}
                handleRemoveUnitFromSelected={handleRemoveUnitFromSelected}
            />
        </>
    );
};

export default UnitStatusHistory;

const SetTimeButton = styled(CleanButton).attrs({ type: "button" })`
    background: ${(p) =>
        p.$isSelected ? p.theme.color.primary.light : p.theme.color.primary.xxlight};
    padding: 0.6rem 0.8rem;
    margin-right: 0.7rem;

    ${T} {
        color: ${(p) =>
            p.$isSelected ? p.theme.color.neutral.xxxlight : p.theme.color.neutral.base};
    }
`;

const DateRowContainer = styled.section`
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const DateInputContainer = styled.div`
    margin-right: 1rem;
`;

import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import sensorAPI from "../../../api/core/sensorAPI";

export const useDeleteSensorUnitConnection = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(sensorAPI.deleteSensorUnitConnection, {
        onSuccess: () => {
            queryClient.invalidateQueries("sensors");
        },
        onError: (error) =>
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            ),
    });
};

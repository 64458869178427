import toast from "react-hot-toast";
import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";

export const useTask = (id) =>
    useQuery(["task", id], () => taskAPI.getTask(id), {
        enabled: !!id,
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
            );
            console.log("Loading error: ", error.message);
        },
    });

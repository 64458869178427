import { useEffect, Fragment } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUserRoles } from "../hooks/useUserRoles";
import { useRoles } from "../../roles/hooks/useRoles";
import { useCustomers } from "../../customers/hooks/useCustomers";
import RoleTypeSelect from "./RoleTypeSelect";
import ServerSelect from "../../../../components/application/ServerSelect";
import QueryWrapper from "../../../../components/application/QueryWrapper";

import { device } from "../../../../theme";
import { TB } from "../../../../components/texts";
import { CleanButton } from "../../../../components/buttons";
import { Grid, GridBlock } from "../../../../components/layout/Grid";

const UserRoles = ({ user_id = null }) => {
    const { control, getValues } = useFormContext();
    const { t } = useTranslation();
    const roles = useUserRoles(user_id);
    const customers = useCustomers();
    const allRoles = useRoles();

    const {
        formState: { isDirty },
    } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "roles",
    });
    const {
        fields: customerFields,
        append: customerAppend,
        remove: customerRemove,
    } = useFieldArray({
        control,
        name: "customers",
    });

    useEffect(() => {
        if (roles.data && roles.data.length && customers.data && fields.length === 0 && !isDirty) {
            append(
                roles.data?.map((role) => {
                    return { value: role.role.id, label: role.role.description };
                })
            );
            customerAppend(
                roles.data?.map((role) => {
                    return {
                        value: role.customer_id,
                        label:
                            customers?.data?.find((item) => item.id === role.customer_id)?.name ||
                            "-",
                    };
                })
            );
        }
    }, [roles?.data, customers?.data, fields]);

    const addRole = () => {
        append({ value: "", label: "" });
        customerAppend({ value: "", label: "" });
    };

    const removeRole = (id) => {
        remove(id);
        customerRemove(id);
    };

    return (
        <QueryWrapper data={user_id ? roles : null}>
            <QueryWrapper data={[customers, allRoles]}>
                <Grid>
                    <GridBlock $columns={2}>
                        <Line />
                    </GridBlock>
                </Grid>

                {fields.map((field, index) => (
                    <Fragment key={field.id}>
                        <Grid>
                            <ServerSelect
                                name={`customers[${index}]`}
                                label={t("customer", "Customer")}
                                defaultValue={{ ...customerFields[index] }}
                                selectUse={useCustomers}
                                validate={{
                                    required: "En bruker må være tilknyttet en kunde",
                                }}
                                isClearable={false}
                            />
                            <RoleTypeSelect
                                name={`roles[${index}]`}
                                label={t("role", "Role")}
                                defaultValue={{ ...field }}
                                validate={{ required: "Du må sette en rolle" }}
                                customer_id={getValues(`customers[${index}]`)?.value}
                                isClearable={false}
                            />
                            <DeleteButton type="button" onClick={() => removeRole(index)}>
                                <TB $link $underline style={{ margin: "1rem 0" }}>
                                    {t("remove_role")}
                                </TB>
                            </DeleteButton>
                        </Grid>

                        <Grid>
                            <GridBlock $columns={2}>
                                <Line />
                            </GridBlock>
                        </Grid>
                    </Fragment>
                ))}

                <CleanButton type="button" onClick={() => addRole()}>
                    <TB $link $underline>{`+ ${t("add_role")}`}</TB>
                </CleanButton>
            </QueryWrapper>
        </QueryWrapper>
    );
};

export default UserRoles;

const Line = styled.hr`
    border: none;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};
    margin: 0;
    margin-bottom: 0.7rem;
    width: calc(100% + 6.15rem);

    @media only screen and ${device.mobile} {
        width: 100%;
        margin-bottom: 1.5rem;
    }
`;

const DeleteButton = styled(CleanButton)`
    justify-self: start;
    margin: 2rem 0;
`;

import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUnitComments } from "../unit/hooks/useUnitComments";
import { useUnitsComments } from "../unit/hooks/useUnitsComments";
import QueryWrapper from "../../components/application/QueryWrapper";
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import StandardTableContent from "../../components/application/StandardTableContent";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import { InputCheckboxClean, InputWithLabel } from "../../components/inputs";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import MainArea from "../../components/layout/MainArea";
import { H1, TB } from "../../components/texts";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";
import { Button } from "../../components/buttons";
import { usePermission } from "../user/hooks/usePermission";
import SelectStyled from "../../components/application/styles/SelectStyled";
import { useUnitCommentAcknowledge } from "../unit/mutations/useUnitCommentAcknowledge";
import { TaskStatus } from "../../api/core/taskAPI";

export const DeviationReports = ({ id }) => {
    const { t } = useTranslation();

    const permission = usePermission("unit_comment_admin");
    const unitOneComments = useUnitComments(id);
    const unitAllComments = useUnitsComments();
    const unitComments = id ? unitOneComments : unitAllComments;
    const methods = useForm();
    const search = methods.watch("search");
    const checkboxes = methods.watch("checkboxes");

    const selectedComments = checkboxes?.filter((item) => item.checked)?.map((item) => item.id);

    const columns = useMemo(
        () => [
            {
                header: "",
                id: "checkbox",
                accessorKey: "id",
                cell: ({ row, getValue }) => (
                    <CheckboxInputContainer>
                        <InputCheckboxClean
                            id={`check-${getValue()}`}
                            {...methods.register(`checkboxes.${row.id}.checked`)}
                        />
                    </CheckboxInputContainer>
                ),
                enableSorting: false,
            },
            {
                header: t("entry_date"),
                accessorKey: "createdAt",
                cell: ({ getValue }) => {
                    const value = getValue();
                    if (value == null) return null;
                    return new Date(value).toLocaleDateString("no", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                    });
                },
            },
            {
                header: t("deviation_or_defect"),
                accessorFn: (row) => (row.status === 2 ? t("deviation2") : t("defect")),
            },
            {
                header: t("unit"),
                accessorFn: (row) => `${row.unit.int_id} ${row.unit.manufacturer} ${row.unit.type}`,
            },
            {
                header: t("category"),
                accessorKey: "label",
            },
            {
                header: t("unit_defect_desc"),
                accessorKey: "comment",
            },
            {
                header: t("planned_date"),
                accessorFn: ({ workorder }) => {
                    if (workorder == null) return "-";
                    const { tasks } = workorder;
                    if (tasks == null) return "-";
                    if (tasks.length === 0) return "-";

                    return (
                        tasks.reduce((acc, { status, start }) => {
                            if (acc != null) return acc;
                            if (status === TaskStatus.DENIED) return acc;
                            if (status === TaskStatus.REQUEST) return acc;

                            return new Date(start)?.toLocaleDateString("no", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                            });
                        }, null) ?? t("awaiting")
                    );
                },
            },
            {
                header: t("registered_by"),
                accessorFn: (row) => `${row.user.first_name} ${row.user.last_name}`,
            },
        ],
        []
    );

    const data = useMemo(
        () => (unitComments?.data && unitComments.data.length > 0 ? unitComments.data : []),
        [unitComments?.data]
    );

    // Add ids to row checkbox values
    useEffect(() => {
        const allIds =
            data?.map((comment) => ({
                id: comment.id,
                checked: false,
            })) || [];
        methods.setValue("checkboxes", allIds);
    }, [data]);

    const initialState = useMemo(
        () => ({ pagination: { pageSize: 20 }, sorting: [{ id: "createdAt", desc: true }] }),
        []
    );
    const table = useReactTable({
        columns,
        data,
        initialState,
        autoResetPageIndex: false,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    const { mutate: acknowledgeComment } = useUnitCommentAcknowledge();
    function removeComments() {
        const ids = selectedComments.join(",");
        acknowledgeComment(ids);
    }

    return (
        <MainArea>
            <H1>{t("deviation_list")}</H1>

            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        name="search"
                        label={t("searchfordeviation")}
                        style={{ maxWidth: "27rem" }}
                    />
                </form>
            </FormProvider>

            {permission && (
                <OptionsRow>
                    <Button
                        disabled={!(selectedComments?.length > 0)}
                        onClick={() => removeComments()}
                    >
                        {t("remove_from_list")}
                    </Button>
                </OptionsRow>
            )}

            <QueryWrapper data={unitComments}>
                <TableStyled>
                    {rowModel.rows.length > 0 ? (
                        <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                    ) : (
                        <NoDeviations>
                            <TB>{t("no_deviations_to_show")}</TB>
                        </NoDeviations>
                    )}
                </TableStyled>
                <TablePaginationNav
                    pageCount={table.getPageCount()}
                    previousPage={table.previousPage}
                    canPreviousPage={table.getCanPreviousPage()}
                    nextPage={table.nextPage}
                    canNextPage={table.getCanNextPage()}
                    pageOptions={table.getPageOptions()}
                    gotoPage={table.setPageIndex}
                    pageIndex={table.getState().pagination.pageIndex}
                />
            </QueryWrapper>
        </MainArea>
    );
};

const TableStyled = styled(StandardTableStyle)`
    margin-top: 1rem;

    td:first-child,
    th:first-child {
        min-width: 1rem;
        max-width: 2rem;
    }
`;

const CheckboxInputContainer = styled.section`
    position: relative;
`;

const NoDeviations = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const OptionsRow = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    ${SelectStyled} {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
`;

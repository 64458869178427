import { useQuery, useQueryClient } from "react-query";
import recurringTasksAPI from "../../../api/core/recurringTasksAPI";

export const useRecurringTasks = () => {
    const queryClient = useQueryClient();

    return useQuery(["tasks", "recurring", "all"], recurringTasksAPI.getRecurringTasks, {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["tasks", "recurring", item.id], item));
        },
    });
};

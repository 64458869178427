import { fetchData, deleteEntry, updateData } from "../fetch";

const getCategory = async (id) => fetchData(`core/services/checkpoints/categories/${id}`);
const getCategories = async () => fetchData(`core/services/checkpoints/categories`);
const getCategoriesForCustomer = async (id) =>
    fetchData(`core/services/checkpoints/categories?customer_id=${id}`);
const getCategoriesTemplates = async () =>
    fetchData(`core/services/checkpoints/categories/templates`);

const updateCategory = async (data) => updateData(data, `core/services/checkpoints/categories`);
const deleteCategory = async (id) => deleteEntry(id, `core/services/checkpoints/categories`);

const getCheckpoint = async (id) => fetchData(`core/services/checkpoints/${id}`);
const getCheckpoints = async () => fetchData(`core/services/checkpoints`);
const getCheckpointsForCustomer = async (id) => fetchData(`core/services/checkpoints/${id}`);

const updateCheckpoint = async (data) => updateData(data, `core/services/checkpoints`);
const deleteCheckpoint = async (id) => deleteEntry(id, `core/services/checkpoints`);

const getConfig = async (id) => fetchData(`core/services/configurations/${id}`);
const getConfigs = async () => fetchData(`core/services/configurations`);
const getConfigTemplates = async (attributes) => fetchData(`core/services/configurations/templates` + (attributes ? `?attributes=${attributes.join("&attributes=")}` : ''));
const getConfigsForCustomer = async (id, attributes) =>
    fetchData(`core/services/configurations?customer_id=${id}` + (attributes ? `&attributes=${attributes.join("&attributes=")}` : ''));

const updateConfig = async (data) => updateData(data, `core/services/configurations`);
const deleteConfig = async (id) => deleteEntry(id, `core/services/configurations`);

export default {
    getCategory,
    getCategories,
    getCategoriesForCustomer,
    getCategoriesTemplates,
    updateCategory,
    deleteCategory,

    getCheckpoint,
    getCheckpoints,
    getCheckpointsForCustomer,
    updateCheckpoint,
    deleteCheckpoint,

    getConfig,
    getConfigs,
    getConfigTemplates,
    getConfigsForCustomer,
    updateConfig,
    deleteConfig,
};

import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import toast from "react-hot-toast";

import { useCustomer } from "./hooks/useCustomer";
import { useSaveCustomer } from "./hooks/useSaveCustomer";
import { useCompanyDataFromBrreg } from "./hooks/useCompanyDataFromBrreg";
import { useFeatureFlagsForCustomer } from "../../feature-flag/hooks/useFeatureFlagsForCustomer";
import { useAllAvailableFeatureFlags } from "../../feature-flag/hooks/useAllAvailableFeatureFlags";
import { useSaveFeatureFlagsForCustomer } from "../../feature-flag/hooks/useSaveFeatureFlagsForCustomer";
import { checkForPermission, usePermissions } from "../../user/hooks/usePermissions";
import { PERMISSIONS } from "../../auth/permissions";
import { emailRegex } from "../../../common/common";
import useServerLookupInlineBoolean from "../../application/hooks/useServerLookupInline";
import customerAPI from "../../../api/core/customerAPI";
import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import { H1, TB, T } from "../../../components/texts";
import {
    InputWithLabel,
    InputCheckbox,
    InputWithUnit,
    ExpandableSection,
} from "../../../components/inputs";
import { Button, CleanButton } from "../../../components/buttons";
import { FlexGap } from "../../../components/helpers/FlexGap";
import { Horizontal } from "../../../components/layout/FlexGrid";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import LoadingSpinner from "../../../components/layout/LoadingSpinner";
import CustomerConnections from "./components/CustomerConnections";
import Areas from "./components/Areas";
import EditAreaModal from "./components/EditAreaModal";

const CustomerEdit = () => {
    const [isAreaEditorOpen, setIsAreaEditorOpen] = useState(false);
    const [editAreaId, setEditAreaId] = useState(null);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const id = Number(params.id);

    const methods = useForm({ mode: "onChange" });
    const orgnr = methods.watch("organization_number");

    const customer = useCustomer(id);
    const permissions = usePermissions();
    const availableFeatureFlags = useAllAvailableFeatureFlags();
    const customerFeatureFlags = useFeatureFlagsForCustomer(id);

    const { mutate: saveCustomer, isLoading: isCustomerSaving } = useSaveCustomer();
    const { mutate: saveFeatures, isLoading: isFeaturesSaving } = useSaveFeatureFlagsForCustomer();
    const {
        data: brregData,
        refetch: refetchBrreg,
        isLoading: isBrregDataLoading,
    } = useCompanyDataFromBrreg(+orgnr?.split(" ").join(""));

    // Check database for existing customers with same name and throw a form error if found
    useServerLookupInlineBoolean({
        name: "name",
        apiCall: customerAPI.getCustomerNameExists,
        errorType: "taken",
        errorMessage: "Kundenavnet finnes allerede",
        methods,
    });

    useEffect(() => {
        if (id === 0) methods.reset({ name: "", active: true });
    }, [id]);

    useEffect(() => {
        if (customer?.data) methods.reset(customer.data);
    }, [customer?.data]);

    useEffect(() => {
        if (!brregData || !brregData.organisasjonsnummer) return;
        if (brregData.navn) methods.setValue("name", brregData.navn);
        const brregAddressData = brregData.postadresse || brregData.forretningsadresse;
        if (brregAddressData) {
            methods.setValue("address.streetAddress1", brregAddressData.adresse?.join(", "));
            methods.setValue("address.zipCode", brregAddressData.postnummer);
            methods.setValue("address.city", brregAddressData.poststed);
        }
    }, [brregData]);

    function fetchDataForOrg() {
        if (!Number(orgnr)) return toast.error(t("wrong_format"));
        refetchBrreg();
    }

    function canEditAreasFor(customerId) {
        if (!permissions?.data || permissions.data.length === 0) return false;

        return checkForPermission({
            dataToCheck: permissions.data,
            permission: PERMISSIONS.unit_admin.name,
            customer_id: customerId,
        });
    }

    function onSubmit(data) {
        const post = formatFormDataForSaving(data);
        const featureFlags = formatFeatureFlagsForSaving(data.features);

        saveCustomer(post, {
            onSuccess: (response) => {
                saveFeatures(
                    { data: featureFlags, id: response.data?.id },
                    { onSuccess: () => navigate("/administration/customers") }
                );
            },
        });
    }

    function registerCustomer() {
        const formValues = methods.getValues();
        const post = formatFormDataForSaving(formValues);
        const featureFlags = formatFeatureFlagsForSaving(formValues.features);

        if (!post.name)
            return methods.setError(
                "name",
                { type: "required", message: t("name_required") },
                { shouldFocus: true }
            );

        saveCustomer(post, {
            onSuccess: (response) => {
                saveFeatures(
                    { data: featureFlags, id: response.data?.id },
                    {
                        onSuccess: () =>
                            navigate(`/administration/customers/${response.data?.id}/edit`),
                    }
                );
            },
        });
    }

    function formatFormDataForSaving(data) {
        const post = {
            name: data.name,
            active: data.active,
            sms_on_task_completion: data.sms_on_task_completion,
            hosts: data.connections
                ?.filter((item) => item)
                ?.map((item) => {
                    const connection = { host_id: item.value };
                    if (item.id) connection.id = item.id;
                    return connection;
                }),
            organization_number: data.organization_number || "",
            address: {
                streetAddress1: data.address?.streetAddress1 || "",
                zipCode: data.address?.zipCode || "",
                city: data.address?.city || "",
            },
            invoice_address: {
                streetAddress1: data.invoice_address?.streetAddress1 || "",
                zipCode: data.invoice_address?.zipCode || "",
                city: data.invoice_address?.city || "",
            },
            contact_person: {
                name: data.contact_person.name || "",
                email: data.contact_person.email || null,
                mobile: data.contact_person.mobile || "",
            },
            information: data.information,
        };
        if (data.invoice_email) post.invoice_email = data.invoice_email;
        if (data.price_0) post.price_0 = Number(data.price_0);
        if (data.price_50) post.price_50 = Number(data.price_50);
        if (data.price_100) post.price_100 = Number(data.price_100);

        if (data.id) post.id = data.id;

        return post;
    }

    function formatFeatureFlagsForSaving(features) {
        let featureNames = [];
        for (const key of Object.keys(features)) {
            if (features[key]) featureNames.push(key);
        }
        return featureNames;
    }

    function createNewArea() {
        setEditAreaId(null);
        setIsAreaEditorOpen(true);
    }

    function openAreaEditorFor(areaId) {
        setEditAreaId(areaId);
        setIsAreaEditorOpen(true);
    }

    function closeAreaEditor() {
        setEditAreaId(null);
        setIsAreaEditorOpen(false);
    }

    return (
        <MainArea>
            <H1>{id === 0 ? t("register_customer") : t("change_customer")}</H1>

            <QueryWrapper data={id !== 0 ? customer : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <MainInfo>
                            <OrgNrContainer>
                                <InputWithLabelMid
                                    name="organization_number"
                                    label={t("orgnr")}
                                    validate={{
                                        validate: {
                                            mustBeNumber: (value) =>
                                                !isNaN(value) || t("has_to_be_number"),
                                        },
                                    }}
                                    autoComplete="off"
                                    type="number"
                                />

                                <FetchOrgButton onClick={fetchDataForOrg} type="button">
                                    <T $second>{t("get_data_from_brreg")}</T>
                                </FetchOrgButton>
                                {isBrregDataLoading ? <LoadingSpinner small light /> : null}
                            </OrgNrContainer>

                            <FlexGap $count={3} gap={"1.5rem"}>
                                <InputWithLabel
                                    name="name"
                                    label={t("name")}
                                    validate={{ required: t("name_required") }}
                                    autoComplete="off"
                                />
                            </FlexGap>

                            <InputCheckbox name="active" label={t("active")} />
                            <InputCheckbox
                                name="sms_on_task_completion"
                                label={t("sms_on_task_completion")}
                            />
                        </MainInfo>

                        {/* Kontaktinformasjon og fakturadetaljer */}
                        <ExpandableSection
                            label={t("contact_info_and_invoice_details")}
                            expandedByDefault
                        >
                            <FlexGap $count={2} gap={"1.5rem"}>
                                <section style={{ flex: 1, minWidth: "20rem" }}>
                                    <SectionHeader>
                                        <TB>{t("post_address")}</TB>
                                    </SectionHeader>

                                    <InputWithLabel
                                        name="address.streetAddress1"
                                        label={t("address")}
                                    />

                                    <Horizontal style={{ flexWrap: "wrap" }}>
                                        <InputWithLabelSmall
                                            name="address.zipCode"
                                            label={t("postal_code")}
                                        />
                                        <section style={{ flexGrow: 1 }}>
                                            <InputWithLabel
                                                name="address.city"
                                                label={t("location")}
                                            />
                                        </section>
                                    </Horizontal>
                                </section>

                                <section style={{ flex: 2, minWidth: "20rem" }}>
                                    <SectionHeader>
                                        <TB>{t("invoice_address")}</TB>
                                    </SectionHeader>

                                    <FlexGap $count={2} gap={"1.5rem"}>
                                        <section>
                                            <InputWithLabel
                                                name="invoice_address.streetAddress1"
                                                label={t("address")}
                                            />
                                            <Horizontal style={{ flexWrap: "wrap" }}>
                                                <InputWithLabelSmall
                                                    name="invoice_address.zipCode"
                                                    label={t("postal_code")}
                                                />
                                                <section style={{ flexGrow: 1 }}>
                                                    <InputWithLabel
                                                        name="invoice_address.city"
                                                        label={t("location")}
                                                    />
                                                </section>
                                            </Horizontal>
                                        </section>

                                        <InputWithLabel
                                            name="invoice_email"
                                            label={t("email_for_invoice")}
                                            validate={{
                                                pattern: {
                                                    value: emailRegex,
                                                    message: "Please enter a valid email",
                                                },
                                            }}
                                        />
                                    </FlexGap>
                                </section>
                            </FlexGap>

                            <SectionHeader style={{ marginTop: "1.5rem" }}>
                                <TB>{t("contact_person")}</TB>
                            </SectionHeader>
                            <FlexGap $count={3} gap="1.5rem">
                                <InputWithLabel name="contact_person.name" label={t("name")} />
                                <InputWithLabel
                                    name="contact_person.email"
                                    label={t("email_long")}
                                    validate={{
                                        pattern: {
                                            value: emailRegex,
                                            message: t("has_to_be_email"),
                                        },
                                    }}
                                />
                                <InputWithLabelMid
                                    name="contact_person.mobile"
                                    label={t("mobile_number")}
                                />
                                <InputWithLabel
                                    name="information"
                                    label={t("specific_terms_for_customer")}
                                    rows={6}
                                />
                            </FlexGap>
                        </ExpandableSection>

                        {/* Timepris ved verkstedsavtaler */}
                        <ExpandableSection label={t("hourly_workshop_rates")} expandedByDefault>
                            <HourlyRatesContainer>
                                <InputWithUnit
                                    name="price_0"
                                    label={t("standard_rate")}
                                    unit="kroner"
                                    hideErrorLabel
                                />
                                <InputWithUnit
                                    name="price_50"
                                    label={t("overtime-50_rate")}
                                    unit="kroner"
                                    hideErrorLabel
                                />
                                <InputWithUnit
                                    name="price_100"
                                    label={t("overtime-100_rate")}
                                    unit="kroner"
                                    hideErrorLabel
                                />
                            </HourlyRatesContainer>
                        </ExpandableSection>

                        {/* Bruksområder */}
                        <ExpandableSection label={t("useAreas")}>
                            {id ? (
                                <>
                                    <Areas
                                        customerId={id}
                                        openAreaEditorFor={openAreaEditorFor}
                                        closeAreaEditor={closeAreaEditor}
                                        canEditAreas={canEditAreasFor(id)}
                                    />

                                    {canEditAreasFor(id) ? (
                                        <CleanButton
                                            type="button"
                                            onClick={createNewArea}
                                            style={{ marginTop: "2rem" }}
                                        >
                                            <TB $link $underline>
                                                + {t("add_useArea")}
                                            </TB>
                                        </CleanButton>
                                    ) : null}
                                </>
                            ) : (
                                <NoCustomerId>
                                    {isCustomerSaving ? (
                                        <div style={{ marginTop: "2rem" }}>
                                            <LoadingSpinner small light />
                                        </div>
                                    ) : (
                                        <T>
                                            {t("to_create_useAreas_you_have_to")}{" "}
                                            <CleanButton
                                                type="button"
                                                onClick={registerCustomer}
                                                disabled={isCustomerSaving || isFeaturesSaving}
                                            >
                                                <TB $link $underline>
                                                    {t("register_the_customer")}
                                                </TB>{" "}
                                            </CleanButton>
                                        </T>
                                    )}
                                </NoCustomerId>
                            )}
                        </ExpandableSection>

                        {/* Verksted */}
                        <ExpandableSection label={t("workshop")}>
                            <CustomerConnections customer_id={id} />
                        </ExpandableSection>

                        {/* Feature Flags */}
                        <ExpandableSection label={t("features")}>
                            <QueryWrapper data={[availableFeatureFlags, customerFeatureFlags]}>
                                {availableFeatureFlags.data?.map((item, index) => (
                                    <InputCheckbox
                                        name={`features.${item.name}`}
                                        key={`${item.name}-${index}`}
                                        label={item.description}
                                        checked={customerFeatureFlags.data?.some(
                                            (feature) => feature.name === item.name
                                        )}
                                    />
                                ))}
                            </QueryWrapper>
                        </ExpandableSection>

                        <SaveOrAbort saving={isCustomerSaving || isFeaturesSaving} />
                    </form>
                </FormProvider>
            </QueryWrapper>

            {isAreaEditorOpen && (
                <EditAreaModal
                    isOpen={isAreaEditorOpen}
                    onClose={closeAreaEditor}
                    areaId={editAreaId}
                    customerId={id}
                />
            )}
        </MainArea>
    );
};

export default CustomerEdit;

const MainInfo = styled.section`
    margin-bottom: 2rem;
`;

const OrgNrContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const FetchOrgButton = styled(Button)`
    margin: 1rem;
`;

const InputWithLabelMid = styled(InputWithLabel)`
    max-width: 13rem;
`;

const InputWithLabelSmall = styled(InputWithLabel)`
    max-width: 7rem;
    margin-right: 0.5rem;
`;

const SectionHeader = styled.h2`
    margin-top: 0;
    margin-bottom: 1rem;
`;

const HourlyRatesContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max-content, 14rem));
    row-gap: 1.5rem;
`;

const NoCustomerId = styled.section`
    margin: 2rem 0;
    display: grid;
    place-items: center;
`;

import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { HamburgerIcon } from "../../../components/icons";
import { InputWithLabel } from "../../../components/inputs";
import { TB } from "../../../components/texts";

const PrecheckListCheckpoint = ({ checkpoint, category, index, deleteCheckpoint }) => {
    const { t } = useTranslation();

    return (
        <Draggable draggableId={checkpoint.name} index={index}>
            {(provided) => (
                <Container {...provided.draggableProps} ref={provided.innerRef}>
                    <CheckpointStyled>
                        <div {...provided.dragHandleProps}>
                            <HamburgerIcon />
                        </div>
                        <InputWithLabel
                            label={t("name_of_checkpoint")}
                            defaultValue={checkpoint.label}
                            name={checkpoint.name}
                            validate={{ required: t("checkpoint_must_have_name") }}
                        />
                        <TB $link $underline onClick={() => deleteCheckpoint(category, index)}>
                            {t("delete")}
                        </TB>
                    </CheckpointStyled>
                </Container>
            )}
        </Draggable>
    );
};

export default PrecheckListCheckpoint;

const Container = styled.div`
    background-color: ${(props) => props.theme.color.primary.xxlight};
`;

const CheckpointStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: ${(props) => props.theme.color.primary.xxlight};

    div:nth-child(2) {
        width: 100%;
        margin: 0 10px 0 10px;
    }
`;

import styled from "styled-components";
import { Checkbox } from "../../components/inputs";
import { T } from "../../components/texts";
import { CleanButton } from "../../components/buttons";
import { useState } from "react";
import { InfoIcon } from "../../components/icons";

const TaskPreCheck = ({ preCheckTemplate, setPreCheckTemplate, disabled = false }) => {
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

    return (
        <Container>
            {preCheckTemplate.schema.elements.map((element, i) => (
                <ItemContainer key={i}>
                    <CheckboxContainer>
                        <Checkbox
                            disabled={disabled}
                            size={21}
                            checked={element.value === true}
                            onClick={() => {
                                const elements = preCheckTemplate.schema.elements.map(
                                    (filteredElement) => {
                                        if (filteredElement.id === element.id) {
                                            return {
                                                ...filteredElement,
                                                value: !filteredElement.value,
                                            };
                                        } else {
                                            return { ...filteredElement };
                                        }
                                    }
                                );
                                setPreCheckTemplate({
                                    ...preCheckTemplate,
                                    schema: {
                                        ...preCheckTemplate.schema,
                                        elements,
                                    },
                                });
                            }}
                        ></Checkbox>
                        <ClickableText
                            disabled={disabled}
                            onClick={() => {
                                if (disabled) return;
                                const elements = preCheckTemplate.schema.elements.map(
                                    (filteredElement) => {
                                        if (filteredElement.id === element.id) {
                                            return {
                                                ...filteredElement,
                                                value: !filteredElement.value,
                                            };
                                        } else {
                                            return { ...filteredElement };
                                        }
                                    }
                                );
                                setPreCheckTemplate({
                                    ...preCheckTemplate,
                                    schema: {
                                        ...preCheckTemplate.schema,
                                        elements,
                                    },
                                });
                            }}
                        >
                            <T>{element.name}</T>
                        </ClickableText>
                        {element.description ? (
                            <InfoButton
                                type="button"
                                onClick={() => setIsDescriptionOpen((prev) => !prev)}
                            >
                                <InfoIcon />
                            </InfoButton>
                        ) : null}
                    </CheckboxContainer>
                    {element.description && isDescriptionOpen ? (
                        <Description>
                            <T>{element.description}</T>
                        </Description>
                    ) : null}
                </ItemContainer>
            ))}
        </Container>
    );
};

export default TaskPreCheck;

const ClickableText = styled.div`
    cursor: pointer;

    &:disabled {
        cursor: default;
    }
`;

const Container = styled.div`
    background-color: white;
    padding: 1rem 1rem 3rem;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px dashed ${(p) => p.theme.color.neutral.xlight};
`;

const CheckboxContainer = styled.div`
    display: flex;
    padding: 1rem 0;
    gap: 5px;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    width: 100%;
`;

const InfoButton = styled(CleanButton)`
    position: relative;
    top: 0.2rem;
`;

const Description = styled.span`
    display: block;
    padding-bottom: 1rem;
`;

import { fetchData, updateData, deleteEntry } from "../fetch";

const getKitboxes = async () => fetchData("core/kitting");
const getKitbox = async (id) => fetchData(`core/kitting/${id}`);
const postKitbox = async (data) => updateData(data, "core/kitting", "POST");
const patchKitbox = async (data) => updateData(data, "core/kitting", "PATCH");
const deleteKitbox = async (id) => deleteEntry(id, "core/kitting");

export default {
    getKitboxes,
    getKitbox,
    postKitbox,
    patchKitbox,
    deleteKitbox,
};

import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useServiceConfigsForCustomer } from "../admin/service/hooks/useServiceConfigsForCustomer";
import { useServiceConfigTemplates } from "../admin/service/hooks/useServiceConfigTemplates";

import { SelectForm } from "../../components/inputs";
import { T, TB } from "../../components/texts";
import { CleanButton } from "../../components/buttons";
import { MenuXIcon } from "../../components/icons";

const RecurringTaskServiceSelectSection = () => {
    const [isChangingService, setIsChangingService] = useState(false);
    const { t } = useTranslation();
    const methods = useFormContext();

    const customer_id = methods.watch("customer_id");
    const templateInSelectedTask = methods.watch("task_check_template");
    const hasServiceTemplate = Boolean(
        templateInSelectedTask && Object.keys(templateInSelectedTask)?.length > 0
    );

    const customerConfigs = useServiceConfigsForCustomer(customer_id?.value);
    const templateConfigs = useServiceConfigTemplates();

    const serviceIntervalOptions = useMemo(() => {
        if (!customer_id?.value || !templateConfigs?.data) return [];

        const allConfigs =
            customer_id.value === 1
                ? customerConfigs?.data || []
                : (customerConfigs?.data || []).concat(templateConfigs.data);

        const options = allConfigs.map(mapConfigToInterval).flat().map(mapIntervalToOption);

        return options;
    }, [customer_id, customerConfigs?.data, templateConfigs?.data]);

    function mapConfigToInterval(config) {
        return config.intervals.map((interval) => ({ ...interval, name: config.name }));
    }

    function mapIntervalToOption(interval) {
        return {
            value: interval.id,
            label: `${interval.name} ${
                interval.customer_id === 1 ? `(${t("system_template")})` : ""
            } - ${interval.interval} ${t("hours")}`,
            configId: interval.configuration_id,
            template: interval.data ? JSON.parse(interval.data) : null,
        };
    }

    return (
        <Container>
            {hasServiceTemplate && !isChangingService ? (
                <ServiceInfoContainer>
                    <T>{t("selected_service")}</T>

                    <SelectedServiceName>
                        {`${templateInSelectedTask?.serviceConfigurationName || t("unknown")} - ${
                            templateInSelectedTask?.interval || "–"
                        } ${t("hours")}`}
                    </SelectedServiceName>
                </ServiceInfoContainer>
            ) : (
                <SelectFormStyled
                    name="selected_service_interval"
                    label={t("choose_service")}
                    options={serviceIntervalOptions}
                    isClearable
                />
            )}

            {isChangingService ? (
                <CancelButton onClick={() => setIsChangingService(false)}>
                    <MenuXIcon />
                </CancelButton>
            ) : hasServiceTemplate ? (
                <EditButton onClick={() => setIsChangingService(true)}>
                    <TB $link $underline>
                        {t("change_service")}
                    </TB>
                </EditButton>
            ) : null}
        </Container>
    );
};

export default RecurringTaskServiceSelectSection;

const Container = styled.section`
    display: grid;
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    grid-gap: 1rem;
`;

const SelectFormStyled = styled(SelectForm)``;

const ServiceInfoContainer = styled.section``;

const SelectedServiceName = styled(T)`
    display: block;
    margin-top: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const EditButton = styled(CleanButton).attrs({ type: "button" })`
    text-align: left;
    margin-top: 2.2rem;
`;

const CancelButton = styled(CleanButton).attrs({ type: "button" })`
    text-align: left;
    margin-top: 2.5rem;
`;

import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { TaskStatus } from "../../../api/core/taskAPI";
import taskAPI from "../../../api/core/taskAPI";

export const useHistory = () => {
    const scheduledStatusList = [TaskStatus.COMPLETED, TaskStatus.INVOICED];
    const params = `?status=${scheduledStatusList}`;

    // Define the query key
    const queryKey = ["tasks", "websocket-hooked"];
    return useQuery(queryKey, () => taskAPI.getHistory(params), {
        refetchOnWindowFocus: false,
        meta: {
            filterFn: (task) => scheduledStatusList.includes(task.status),
        },
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Fetching task error: ", error.response?.data?.message || error.message);
        },
    });
};

import { forwardRef } from "react";
import { T } from "../texts";
import styled from "styled-components";

export const InputCheckboxClean = forwardRef(({ label, id, ...props }, ref) => {
    return (
        <Container>
            <input {...props} type="checkbox" id={id} ref={ref} />

            <CheckboxIcon
                width="15"
                height="15"
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1 6 L5.5 10.5 L10.5 2" />
            </CheckboxIcon>

            <label htmlFor={id}>
                <T>{label}</T>
            </label>
        </Container>
    );
});

export default InputCheckboxClean;

const CheckboxIcon = styled.svg`
    position: absolute;
    fill: none;
    stroke: ${(p) => p.theme.color.primary.base};
    stroke-width: 2px;
    transform: translate(0.15rem, 0.15rem);
    pointer-events: none;
    width: 1.2rem;
    height: 1.2rem;
    opacity: 0;
`;

const Container = styled.section`
    margin: 0.8rem 0;
    display: flex;

    input {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        border: none;
        overflow: hidden;

        &:checked + ${CheckboxIcon} {
            opacity: 1;
        }

        &:focus-visible ~ label {
            outline: 2px solid ${(p) => p.theme.color.primary.base};
            outline-offset: 0.2rem;
        }
    }

    label {
        cursor: pointer;
        user-select: none;
        word-break: break-word;

        &:before {
            content: "";
            box-sizing: border-box;
            display: inline-block;
            vertical-align: text-top;
            width: 1.25rem;
            height: 1.25rem;
            border: 2px solid ${(p) => p.theme.color.primary.light};
            background: #fff;
            margin-right: 0.3rem;
        }
    }
`;

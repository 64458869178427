import { useTranslation } from "react-i18next";
import styled from "styled-components";

import StandardTableStyle from "../../components/application/StandardTableStyle";
import { H1 } from "../../components/texts";
import { startOfYear } from "../../common/date";
import { useMemo } from "react";
import { DateTime, Interval } from "luxon";

const ActualCosts = ({ data, hoursThisMonth, count }) => {
    const { t } = useTranslation();

    const hoursThisYear = useMemo(() => {
        const now = DateTime.local();
        const startOfThisYear = startOfYear(now);
        return Math.floor(Interval.fromDateTimes(startOfThisYear, now).length("hours"));
    }, []);

    return (
        <TableStyled>
            <thead>
                <tr>
                    <th>
                        <H1>{t("summary")}</H1>
                    </th>
                    <th>{t("hours_capital")}</th>
                    <th>{t("materials")}</th>
                    <th>{t("cost")}</th>
                    <th>{t("uptime")}</th>
                </tr>
            </thead>
            {data != null ? (
                <>
                    <tbody>
                        <tr>
                            <td>{t("month")}</td>
                            <td>
                                {Number(data.estimates.month.hoursCost.toFixed(0)).toLocaleString(
                                    "no"
                                )}
                            </td>
                            <td>
                                {Number(
                                    data.estimates.month.materialsCost.toFixed(0)
                                ).toLocaleString("no")}
                            </td>
                            <td>
                                {Number(
                                    (
                                        data.estimates.month.hoursCost +
                                        data.estimates.month.materialsCost
                                    ).toFixed(0)
                                ).toLocaleString("no")}
                            </td>
                            <td>
                                {(count > 0 && data.downtimes.month > 0
                                    ? 100 * (1 - data.downtimes.month / (count * hoursThisMonth))
                                    : 100
                                ).toFixed(0)}
                                %
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>{t("so_far_this_year")}</td>
                            <td>
                                {Number(
                                    data.estimates.thisYear.hoursCost.toFixed(0)
                                ).toLocaleString("no")}
                            </td>
                            <td>
                                {Number(
                                    data.estimates.thisYear.materialsCost.toFixed(0)
                                ).toLocaleString("no")}
                            </td>
                            <td>
                                {Number(
                                    (
                                        data.estimates.thisYear.hoursCost +
                                        data.estimates.thisYear.materialsCost
                                    ).toFixed(0)
                                ).toLocaleString("no")}
                            </td>
                            <td>
                                {(count > 0 && data.downtimes.thisYear > 0
                                    ? 100 * (1 - data.downtimes.thisYear / (count * hoursThisYear))
                                    : 100
                                ).toFixed(0)}
                                %
                            </td>
                        </tr>
                    </tfoot>
                </>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan={5}>
                            <NoAreasSelected>{t("no_areas_selected")}</NoAreasSelected>
                        </td>
                    </tr>
                </tbody>
            )}
        </TableStyled>
    );
};

export default ActualCosts;

const NoAreasSelected = styled.div`
    text-align: center;
    padding: 2rem;
`;

const TableStyled = styled(StandardTableStyle)`
    tfoot tr td {
        font-weight: bold;
    }

    td,
    th {
        text-align: right;
        width: 16.25%;
    }

    td:first-child,
    th:first-child {
        text-align: left;
        width: 35%;
    }
`;

import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { usePart } from "./hooks/usePart";
import { useSavePart } from "./hooks/useSavePart";
import { PERMISSIONS } from "../auth/permissions";
import QueryWrapper from "../../components/application/QueryWrapper";

import { H1 } from "../../components/texts";
import { InputWithLabel, RadioSelect } from "../../components/inputs";
import { Grid } from "../../components/layout/Grid";
import MainArea from "../../components/layout/MainArea";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import CustomerSelect from "../application/components/role/CustomerSelect";

const PartEdit = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const id = Number(params.id);
    const methods = useForm({ mode: "onChange" });

    const part = usePart(id);
    const { mutate: savePart, isLoading: isSavingPart } = useSavePart();

    useEffect(() => {
        if (id === 0) {
            methods.reset({
                name: "",
                description: "",
                barcode: "",
                price: null,
                location: "",
                supplier_id: "",
                org_id: "",
                customer_id: null,
                stock: false,
                cost_price: null,
            });
        } else if (part?.data) {
            methods.reset(part.data);
        }
    }, [id, part?.data]);

    function onSubmit(data) {
        const post = {
            name: data.name,
            description: data.description,
            barcode: data.barcode,
            price: Number(data.price),
            location: data.location,
            supplier_id: data.supplier_id,
            org_id: data.org_id,
            customer_id: data.customer_id?.value,
            stock: Number(data.stock) ? true : false,
            cost_price: Number(data.cost_price),
        };
        if (id) post.id = id;

        savePart(post, { onSuccess: () => navigate(-1) });
    }

    return (
        <MainArea back>
            <H1>{id === 0 ? t("register_part") : t("edit_part")}</H1>
            <QueryWrapper data={id === 0 ? null : part}>
                <FormProvider {...methods}>
                    <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid $templateColumns={4}>
                            <InputWithLabel
                                label={t("name")}
                                name="name"
                                validate={{ required: t("name_required") }}
                            />
                            <InputWithLabel label={t("description_2")} name="description" />
                            <InputWithLabel label={t("barcode")} name="barcode" />

                            <div style={{ minWidth: "19rem" }}>
                                <RadioSelect
                                    name="stock"
                                    label={t("warehouse_status")}
                                    options={[
                                        { value: 1, label: t("stock_item") },
                                        { value: 0, label: t("order_item") },
                                    ]}
                                    defaultValue={part?.data?.stock ? 1 : 0}
                                />
                            </div>

                            <InputWithLabel label={t("supplier")} name="supplier_id" />
                            <InputWithLabel label={t("sales_price")} name="price" type="number" />
                            <InputWithLabel
                                label={t("cost_price")}
                                name="cost_price"
                                type="number"
                            />

                            <CustomerSelect
                                isDisabled={id === 0 ? false : true}
                                permission={PERMISSIONS.part_admin.name}
                            />
                        </Grid>
                        <SaveOrAbort saving={isSavingPart} />
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default PartEdit;

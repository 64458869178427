import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FlexGap } from "../../../../components/helpers/FlexGap";
import { TB } from "../../../../components/texts";
import OvertimeTable from "./OvertimeTable";
import TaskEdit from "../../../task/TaskEdit";
import DietTable from "./DietTable";
import ExtrasTable from "./ExtrasTable";
import VacationTable from "./VacationTable";
import TimeOffTable from "./TimeOffTable";
import SelfNotificationTable from "./SelfNotificationTable";
import SickLeaveTable from "./SickLeaveTable";
import PaidLeaveTable from "./PaidLeaveTable";
import UnpaidLeaveTable from "./UnpaidLeaveTable";
import SickChildTable from "./SickChildTable";

const EmployeeDetails = ({ employeeId, tasks, absences, extras }) => {
    const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);

    const { t } = useTranslation();

    const tasksForEmployee = useMemo(
        () => (tasks?.length ? tasks.filter((task) => task.mechanic_id === employeeId) : null),
        [employeeId, tasks]
    );

    const absencesForEmployee = useMemo(
        () =>
            absences?.length ? absences.filter((absence) => absence.user_id === employeeId) : null,
        [absences, employeeId]
    );

    const onCalls = useMemo(
        () => (extras?.length ? extras.filter((extra) => extra.user_id === employeeId) : null),
        [extras, employeeId]
    );

    function openEditorForTask(id) {
        setEditTaskId(id);
        setTaskEditorInitialData(null);
        setIsTaskEditorOpen(true);
    }

    function closeTaskEditor(data = null) {
        setEditTaskId(null);
        setTaskEditorInitialData(data);
        setIsTaskEditorOpen(false);
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setIsTaskEditorOpen(true), 50);
    }

    return (
        <div className="foo-bar">
            <Container $count={2} gap={"4rem"}>
                <div>
                    <OvertimeTable tasks={tasksForEmployee} openEditorForTask={openEditorForTask} />
                    <DietTable tasks={tasksForEmployee} openEditorForTask={openEditorForTask} />
                    <ExtrasTable
                        tasks={tasksForEmployee}
                        onCalls={onCalls}
                        openEditorForTask={openEditorForTask}
                    />
                </div>

                {absencesForEmployee?.length > 0 && (
                    <AbsencesContainer>
                        <TB>{t("absence")}</TB>

                        <VacationTable absences={absencesForEmployee} />
                        <TimeOffTable absences={absencesForEmployee} />
                        <SelfNotificationTable absences={absencesForEmployee} />
                        <SickChildTable absences={absencesForEmployee} />
                        <SickLeaveTable absences={absencesForEmployee} />
                        <PaidLeaveTable absences={absencesForEmployee} />
                        <UnpaidLeaveTable absences={absencesForEmployee} />
                    </AbsencesContainer>
                )}
            </Container>

            {isTaskEditorOpen && (
                <TaskEdit
                    editTaskId={editTaskId}
                    isOpen={isTaskEditorOpen}
                    onClose={closeTaskEditor}
                    initialData={taskEditorInitialData}
                    resetTaskEditor={resetTaskEditor}
                />
            )}
        </div>
    );
};

export default EmployeeDetails;

const Container = styled(FlexGap)`
    padding: 1rem 5rem;
`;

const AbsencesContainer = styled.div`
    margin-top: 3rem;
`;

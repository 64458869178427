import { useTranslation } from "react-i18next";
import { Button } from "../../components/buttons";
import { TB } from "../../components/texts";
import { useEffect, useState } from "react";
import styled from "styled-components";

const MyTasksButton = ({
    task,
    setTaskPreCheck,
    setTaskPostCheck,
    patchTask,
    openEditorForTask,
    disabled,
}) => {
    const { t } = useTranslation();

    const [parsedPreCheckTemplate, setParsedPreCheckTemplate] = useState(null);
    const [parsedPostCheckTemplate, setParsedPostCheckTemplate] = useState(null);

    useEffect(() => {
        if (task.pre_check_template) {
            if (!task.pre_check_template) {
                setParsedPreCheckTemplate(null);
                return;
            }

            const parsed = JSON.parse(task.pre_check_template);
            if (!parsed) {
                setParsedPreCheckTemplate(null);
                return;
            }

            parsed?.schema?.elements?.sort((a, b) => a.order - b.order);
            setParsedPreCheckTemplate(parsed);
        }
    }, [task.pre_check_template]);

    useEffect(() => {
        if (task.post_check_template) {
            if (!task.post_check_template) {
                setParsedPostCheckTemplate(null);
                return;
            }

            const parsed = JSON.parse(task.post_check_template);
            if (!parsed) {
                setParsedPostCheckTemplate(null);
                return;
            }

            parsed?.schema?.elements?.sort((a, b) => a.order - b.order);
            setParsedPostCheckTemplate(parsed);
        }
    }, [task.post_check_template]);

    const { status } = task;
    if (status === "scheduled") {
        if (
            parsedPreCheckTemplate?.type === "PreCheck" &&
            parsedPreCheckTemplate?.schema?.elements?.length > 0 &&
            parsedPreCheckTemplate.done === false
        ) {
            return (
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        setTaskPreCheck({
                            id: task.id,
                            template: parsedPreCheckTemplate,
                        });
                    }}
                    disabled={disabled}
                >
                    <TB $second>{t("start")}</TB>
                </Button>
            );
        }

        return (
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    patchTask({
                        id: task.id,
                        status: "started",
                    });
                }}
                disabled={disabled}
            >
                <TB $second>{t("start")}</TB>
            </Button>
        );
    }

    if (status === "started") {
        if (
            parsedPostCheckTemplate?.type === "PostCheck" &&
            parsedPostCheckTemplate?.schema?.elements?.length > 0 &&
            parsedPostCheckTemplate.done === false
        ) {
            return (
                <FinishTaskButton
                    onClick={(e) => {
                        e.stopPropagation();
                        if (!task.cause_id) {
                            openEditorForTask(task);
                            return;
                        }

                        setTaskPostCheck({
                            id: task.id,
                            template: parsedPostCheckTemplate,
                        });
                    }}
                    disabled={disabled}
                >
                    <TB $second>{t("finish")}</TB>
                </FinishTaskButton>
            );
        }

        return (
            <FinishTaskButton
                onClick={(e) => {
                    e.stopPropagation();
                    if (!task.cause_id) {
                        openEditorForTask(task);
                        return;
                    }

                    patchTask({
                        id: task.id,
                        status: "completed",
                    });
                }}
                disabled={disabled}
            >
                <TB $second>{t("finish")}</TB>
            </FinishTaskButton>
        );
    }

    return null;
};

export default MyTasksButton;

const FinishTaskButton = styled(Button)`
    background: ${(p) => p.theme.color.secondary.light};
`;

import styled from "styled-components";
import NavBar from "./NavBar";
import { useNavigate, useParams } from "react-router";
import { useUnitByUuid } from "../../unit/hooks/useUnitByUuid";
import { useEffect } from "react";
import UnitPublic from "../../unit/UnitPublic";
import { startOfWeek } from "../../../common/date";
import { TaskStatus } from "../../../api/core/taskAPI";

const DriverPublicApp = ({ userId }) => {
    const params = useParams();
    const navigate = useNavigate();

    const unitUuid = params.uuid;
    const unit = useUnitByUuid(
        unitUuid,
        startOfWeek(new Date()).toISODate(),
        startOfWeek(new Date()).plus({ months: 12 }).toISODate(),
        [TaskStatus.SCHEDULED, TaskStatus.SCHEDULED_DRAFT, TaskStatus.STARTED]
    );

    useEffect(() => {
        if (userId == null) return;
        if (unit?.data == null) return;

        navigate(`/display/drivers/${unit.data.id}`);
    }, [unit, userId]);

    return (
        <>
            <NavBar />
            <MainArea>
                <UnitPublic unitUuid={unitUuid} unit={unit} />
            </MainArea>
        </>
    );
};

export default DriverPublicApp;

const MainArea = styled.div`
    min-height: calc(100vh - calc(${(props) => props.theme.footer.height} + 3rem));
    width: 100%;
    background-clip: content-box;
    padding-bottom: 3.125rem;
`;

import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { useUnit } from "./hooks/useUnit";
import { usePreCheckAnswer } from "../pre-check/hooks/usePreCheckAnswer";
import QueryWrapper from "../../components/application/QueryWrapper";

import { standardDate } from "../../common/date";
import { T, H1, H2 } from "../../components/texts";
import { Col, Row } from "../../components/layout/FlexGrid";
import Spacer from "../../components/helpers/Spacer";
import Underline from "../../components/helpers/Underline";
import StatusLine from "../../components/layout/StatusLine";
import MainArea from "../../components/layout/MainArea";

const UnitPreChecklistAnswer = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const theme = useTheme();

    const preCheckAnswer = usePreCheckAnswer(id);
    const preCheckData = preCheckAnswer?.data;
    const unit = useUnit(preCheckData?.unit_id);

    return (
        <MainArea back>
            <QueryWrapper data={[preCheckAnswer, unit]}>
                <H1>
                    {unit.data?.int_id} {unit.data?.group?.name} {unit.data?.name} {unit.data?.type}
                </H1>

                <Row>
                    <StatusStyled $typeColor={theme.color.ok.base}>
                        <T>{t("approved")}</T>
                    </StatusStyled>
                    <Spacer />
                    <StatusStyled $typeColor={theme.color.warning.base}>
                        <T>{t("deviation")}</T>
                    </StatusStyled>
                    <Spacer />
                    <StatusStyled $typeColor={theme.color.error.base}>
                        <T>{t("defect")}</T>
                    </StatusStyled>
                </Row>

                <H2>{t("pre_check", "Pre Check")}</H2>

                <Row>
                    <StatusLine data={preCheckData} />
                    <T>
                        {standardDate(preCheckData?.createdAt)} {t("of")}{" "}
                        {preCheckData?.user?.first_name} {preCheckData?.user?.last_name}
                    </T>
                </Row>
                <Underline color={theme.color.neutral.xlight} />

                {preCheckData?.data?.template.schema.elements
                    .flat()
                    .map((element) => (element.elements ? element.elements : element))
                    .flat()
                    .filter((field) => field.type.includes("traffic"))
                    .map((field) => {
                        const value = preCheckData?.data?.data?.find((d) => d.name === field.name);
                        const status = parseInt(value.value);
                        const comment = preCheckData?.data?.data?.find(
                            (d) => d.name === `${field.name}:comment`
                        );
                        return (
                            <Fragment key={field.label}>
                                <PreChecksStyled>
                                    <Row>
                                        <StatusLine data={{ status: status }} />
                                        <Col>
                                            <T>{field.label}</T>
                                            <br />
                                            {comment && comment.value !== "" && (
                                                <T>{comment.value}</T>
                                            )}
                                        </Col>
                                    </Row>
                                </PreChecksStyled>
                                <Underline $dotted color={theme.color.neutral.xlight} />
                            </Fragment>
                        );
                    })}
            </QueryWrapper>
        </MainArea>
    );
};

export default UnitPreChecklistAnswer;

const StatusStyled = styled.span`
    border-left: 2px solid ${(props) => props.$typeColor};
    padding-left: 0.25rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
`;

const PreChecksStyled = styled.div`
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
`;

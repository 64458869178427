import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { TB } from "../../components/texts";
import Button from "../../components/buttons/Button";
import WorkshopSelect from "../../components/application/workshop/WorkshopSelect";
import { saveLastUsedWorkshopId } from "../../app/localStorage";
import CleanButton from "../../components/buttons/CleanButton";

const SchedulerNav = ({ createNewTask, addNewAbsence, mechanics, customers }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { workshopId } = useParams();

    useEffect(() => {
        if (workshopId) {
            saveLastUsedWorkshopId(workshopId);
        }
    }, [workshopId]);

    return (
        <MainWrapper>
            <WorkshopSelectWrapper>
                {customers?.length > 1 ? (
                    <WorkshopSelect
                        defaultWorkshopId={+workshopId}
                        onChange={(e) => {
                            navigate(`/scheduler/calendar/${e.value}`);
                        }}
                    />
                ) : null}
            </WorkshopSelectWrapper>

            {mechanics?.length > 0 ? (
                <>
                    <NewAbsenceButton onClick={addNewAbsence}>
                        <TB $underline $link>
                            {t("add_new_absence")}
                        </TB>
                    </NewAbsenceButton>

                    <NewTaskButton $bold onClick={createNewTask}>
                        {t("add_new_task")}
                    </NewTaskButton>
                </>
            ) : null}
        </MainWrapper>
    );
};

export default SchedulerNav;

const MainWrapper = styled.section`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const WorkshopSelectWrapper = styled.section`
    flex-grow: 1;
    z-index: 10;
    margin-right: 1.5rem;
    margin-bottom: 1rem;

    > * {
        min-width: 8rem;
        max-width: 20rem;
    }
`;

const NewAbsenceButton = styled(CleanButton)`
    margin-right: 1.5rem;
    margin-bottom: 1rem;
`;

const NewTaskButton = styled(Button)`
    margin: 0;
    margin-bottom: 1rem;
    opacity: 0;
    animation: fadeIn 200ms ease forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

import styled from "styled-components";

export const Text = styled.span`
    color: ${(props) =>
        props.color
            ? props.color
            : props.$second
            ? props.theme.text.color.second
            : props.$link
            ? props.theme.text.color.link
            : props.theme.text.color.main};
    border-bottom: ${(props) => (props.$underline ? "2px solid currentColor" : "none")};
`;

export default Text;

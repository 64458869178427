import Icon from "./Icon";

export const FlagIcon = () => {
    return (
        <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="#38558D">
            <path d="M3.75 0V20" strokeWidth="2" strokeMiterlimit="10" />
            <path
                d="M3.75 1.875H16.5625L14.6875 5.625L16.5625 9.375H3.75"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </Icon>
    );
};

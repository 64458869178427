import styled from "styled-components";

import { DefectIcon, InUseIcon } from "../../icons";

const UnitUseIcon = ({ unit }) => {
    return (
        <IconStyled>
            {unit?.defect && <DefectIcon />}
            {unit?.user && <InUseIcon />}
        </IconStyled>
    );
};

export default UnitUseIcon;

const IconStyled = styled.div`
    margin-top: 4px;
`;

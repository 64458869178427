import { usePreCheckLists } from "../../pre-check/hooks/usePreCheckLists";
import ServerSelect from "../../../components/application/ServerSelect";

const PreCheckListSelect = ({ customer_id = null, ...props }) => {
    return (
        <ServerSelect
            selectUse={usePreCheckLists}
            {...props}
            filter={customer_id ? { customer_id } : null}
        />
    );
};

export default PreCheckListSelect;

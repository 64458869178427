import MainArea from "../../../components/layout/MainArea";
import {H1, T} from "../../../components/texts";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "../../application/hooks/useSearchParams";
import {useEmployeesForCustomer} from "../hooks/useEmployeesForCustomer";
import {useTasksWithParams} from "../../task/hooks/useTasksWithParams";
import {endOfDay, formatISO, getYear, startOfDay} from "date-fns";
import {useAbsencesBetween} from "../../absence/hooks/useAbsencesBetween";
import {TaskStatus} from "../../../api/core/taskAPI";
import { PERMISSIONS } from "../../auth/permissions";
import CustomerSelectNoForm from "../../application/components/role/CustomerSelectNoForm";
import styled from "styled-components";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import {FormProvider, useForm} from "react-hook-form";
import {useConfigForCustomer} from "../../admin/setup/hooks/useConfigForCustomer";
import {useNationalHolidaysFromTo} from "../../national-holidays/hooks/useNationalHolidaysFromTo";
import {DateInput} from "../../../components/inputs";

const statusList = [TaskStatus.COMPLETED, TaskStatus.INVOICED];

const PayrollExport = () => {
  const { t } = useTranslation();
  const { getParam, setParam, deleteParam } = useSearchParams();

  const methods = useForm();

  const customer_id = getParam("customer_id");
  const fromDate = getParam("from");
  const toDate = getParam("to");

  const employees = useEmployeesForCustomer(customer_id);
  const tasks = useTasksWithParams(
          customer_id,
          statusList,
          startOfDay(new Date(fromDate)),
          endOfDay(new Date(toDate))
  );

  function updateFromToDate(newDate, key) {
    const formattedDate =
            newDate instanceof Date ? formatISO(newDate, { representation: "date" }) : null;

    if (formattedDate) {
      setParam(key, formattedDate);
    } else {
      deleteParam(key);
    }
  }


  return (
          <MainArea>
            <H1>Payroll Eksport</H1>
            <CustomerSelectContainer>
              <label htmlFor="customerSelect">
                <T>{t("customer")}</T>
              </label>

              <CustomerSelectNoForm
                      id="customerSelect"
                      onChange={(e) => setParam("customer_id", e.value)}
                      selected={customer_id}
                      key={`select-${customer_id}`}
                      permission={[
                        PERMISSIONS.scheduler_full.name,
                        PERMISSIONS.scheduler_read.name,
                      ]}
              />
            </CustomerSelectContainer>

            <FormProvider {...methods}>
              {!!customer_id && (
                      <form>
                        <DateInputs>
                          <DateInputContainer>
                            <DateInput
                                    name="from"
                                    label={t("from")}
                                    value={fromDate ? new Date(fromDate) : null}
                                    setValue={(v) => updateFromToDate(v, "from")}
                                    maxContainerWidth={"30rem"}
                                    minDate={new Date(2010, 0, 1)}
                                    maxDate={new Date(2049, 11, 31)}
                            />
                          </DateInputContainer>

                          <DateInputContainer>
                            <DateInput
                                    name="to"
                                    label={t("to")}
                                    value={toDate ? new Date(toDate) : null}
                                    setValue={(v) => updateFromToDate(v, "to")}
                                    minDate={new Date(2010, 0, 1)}
                                    maxDate={new Date(2049, 11, 31)}
                            />
                          </DateInputContainer>
                        </DateInputs>
                      </form>
              )}
            </FormProvider>

            <StandardTableStyle>
              <thead>
              <tr>
              <th>Ansatt Navn</th>
              <th>Ansatt Nr</th>
              <th>Lønnskode</th>
              <th>Antall</th>
              </tr>
              </thead>
              <tbody>
              {
                employees.data?.map((employee) => (
                        <tr>
                          <td>{employee.first_name + ' ' + employee.last_name}</td>
                          <td>{employee.payroll_number}</td>
                        </tr>
                ))
              }
              </tbody>
            </StandardTableStyle>
            {
              JSON.stringify(tasks)
            }

          </MainArea>
  );
}

export default PayrollExport;


const CustomerSelectContainer = styled.section`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    max-width: 21.5rem;
`;

const DateInputs = styled.section`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

const DateInputContainer = styled.div`
    margin-right: 1.5rem;
`;

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { CleanButton } from "../buttons";
import { T, TB } from "../texts";

const TablePaginationNav = ({
    pageCount,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageIndex,
}) => {
    const { t } = useTranslation();

    return (
        <Container>
            <TextNavButton onClick={() => previousPage()} disabled={!canPreviousPage} type="button">
                <TB $link>{t("previous")}</TB>
            </TextNavButton>

            {pageCount < 7 ? (
                pageOptions.map((option) => (
                    <PageButton
                        key={`option-${option}`}
                        onClick={() => gotoPage(option)}
                        disabled={option === pageIndex}
                        type="button"
                    >
                        <TB $link>{option + 1}</TB>
                    </PageButton>
                ))
            ) : (
                <>
                    {pageIndex > 1 && (
                        <>
                            <PageButton onClick={() => gotoPage(0)} type="button">
                                <TB $link>1</TB>
                            </PageButton>
                            <Dots>
                                <T>...</T>
                            </Dots>
                        </>
                    )}

                    {pageOptions
                        .slice(pageIndex === 0 ? 0 : pageIndex - 1, pageIndex + 2)
                        .map((option) => (
                            <PageButton
                                key={`option-${option}`}
                                onClick={() => gotoPage(option)}
                                disabled={option === pageIndex}
                                type="button"
                            >
                                <TB $link>{option + 1}</TB>
                            </PageButton>
                        ))}

                    {pageIndex < pageCount - 2 && (
                        <>
                            <Dots>
                                <T>...</T>
                            </Dots>
                            <PageButton onClick={() => gotoPage(pageCount - 1)} type="button">
                                <TB $link>{pageCount}</TB>
                            </PageButton>
                        </>
                    )}
                </>
            )}

            <TextNavButton onClick={() => nextPage()} disabled={!canNextPage} type="button">
                <TB $link>{t("next")}</TB>
            </TextNavButton>
        </Container>
    );
};

export default TablePaginationNav;

const Container = styled.article`
    margin-top: 1rem;
`;

const TextNavButton = styled(CleanButton)`
    user-select: none;
    height: 2.5rem;
    padding: 0 0.5rem;

    &:first-child {
        padding-left: 0;
    }

    &:disabled {
        > ${TB} {
            cursor: initial;
            color: ${(p) => p.theme.color.primary.light};
        }
    }
`;

const PageButton = styled(CleanButton)`
    user-select: none;
    height: 2.5rem;
    padding: 0 1rem;

    &:disabled {
        background: ${(p) => p.theme.color.primary.light};
        cursor: initial;

        > ${TB} {
            cursor: initial;
        }
    }
`;

const Dots = styled.span`
    user-select: none;
    padding: 0rem;
`;

import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 20px;
    height: 20px;
`;

export const ServiceIcon = () => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0)">
                <path d="M11.25 10.625L12.8125 12.1875" stroke="#38558D" strokeMiterlimit="10" />
                <path
                    d="M17.8128 19.0623L19.6877 17.1874L13.7503 11.25L11.8754 13.1249L17.8128 19.0623Z"
                    stroke="#38558D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M3.75 5L5.625 3.125L2.5 1.25L1.875 1.875L3.75 5Z"
                    stroke="#38558D"
                    strokeMiterlimit="10"
                />
                <path d="M8.75 8.125L4.6875 4.0625" stroke="#38558D" strokeMiterlimit="10" />
                <path
                    d="M18.125 3.90626L15.625 5.07813L14.375 3.78908L15.4688 1.26971C12.3438 1.2694 11.5625 2.81252 11.5625 4.37501V5.625L1.09375 16.2499L0.546875 18.1249L1.21094 18.7499L3.10531 18.2812L13.75 7.81249H15C16.5625 7.81249 18.125 7.03125 18.125 3.90626Z"
                    stroke="#38558D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </Svg>
    );
};

import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import {
    useReactTable,
    getSortedRowModel,
    getPaginationRowModel,
    getCoreRowModel,
    getFilteredRowModel,
} from "@tanstack/react-table";
import styled from "styled-components";

import { useSensors } from "./hooks/useSensors";
import { usePermissions } from "../user/hooks/usePermissions";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import QueryWrapper from "../../components/application/QueryWrapper";

import Spacer from "../../components/helpers/Spacer";
import MainArea from "../../components/layout/MainArea";
import { Horizontal } from "../../components/layout/FlexGrid";
import { Button } from "../../components/buttons";
import { H1, TB } from "../../components/texts";
import { InputWithLabel } from "../../components/inputs";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";

const SensorsList = () => {
    const { t } = useTranslation();
    const methods = useForm({ mode: "onChange" });
    const navigate = useNavigate();

    const permissions = usePermissions();
    const sensors = useSensors();
    const customers = useCustomers();

    const search = methods.watch("search");

    const columns = useMemo(
        () => [
            {
                header: t("sensor_id"),
                accessorKey: "ident",
                cell: ({ getValue, row }) => (
                    <EditLink to={`/administration/setup/sensors/${row.original.id}/edit`}>
                        <TB $link>{getValue()}</TB>
                    </EditLink>
                ),
            },
            {
                header: t("customer"),
                accessorFn: (row) =>
                    row.customer_id && customers?.data
                        ? customers.data.find((customer) => customer.id === row.customer_id)
                              ?.name || t("unknown")
                        : "",
            },
            {
                header: t("manufacturer"),
                accessorKey: "manufacturer",
            },
            {
                header: t("type"),
                accessorKey: "type",
            },
            {
                header: t("connected_to_vehicle"),
                accessorFn: (row) =>
                    row.sensor_units?.find((sensorUnit) => sensorUnit.active)?.unit.int_id,
            },
        ],
        [customers?.data]
    );

    const data = useMemo(() => sensors?.data || [], [sensors?.data]);
    const initialState = useMemo(() => ({ pagination: { pageSize: 20 } }), []);

    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    return (
        <MainArea back>
            <QueryWrapper data={permissions}>
                <Horizontal>
                    <H1>{t("sensors")}</H1>
                    <Spacer />
                    <Button onClick={() => navigate(`/administration/setup/sensors/0/edit`)}>
                        <TB $second>{t("register_new_sensor")}</TB>
                    </Button>
                </Horizontal>

                <FormProvider {...methods}>
                    <form>
                        <InputWithLabel
                            label={t("search_sensors")}
                            name="search"
                            style={{ maxWidth: "27rem" }}
                        />
                    </form>

                    <QueryWrapper data={sensors}>
                        {rowModel.rows.length > 0 ? (
                            <TableContainer>
                                <TableStyled>
                                    <StandardTableContent
                                        headerGroups={headerGroups}
                                        rowModel={rowModel}
                                    />
                                </TableStyled>
                            </TableContainer>
                        ) : (
                            <NoSensors>
                                <TB>{t("no_sensors_to_show")}</TB>
                            </NoSensors>
                        )}

                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={table.previousPage}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={table.nextPage}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={table.setPageIndex}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    </QueryWrapper>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default SensorsList;

const EditLink = styled(Link)`
    text-decoration: none;
`;

const TableStyled = styled(StandardTableStyle)`
    max-width: 70rem;
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const NoSensors = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
`;

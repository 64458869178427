import { useQuery, useQueryClient } from "react-query";
import unitAPI from "../../../api/core/unitAPI";

export const useUnitsOverview = ({ queryParamsObject, enabled }) => {
    const params = Object.keys(queryParamsObject).reduce((acc, curr) => {
        const value = queryParamsObject[curr];
        if (value !== undefined) {
            acc.push(`${curr}=${value}`);
        }

        return acc;
    }, []);
    const queryClient = useQueryClient();

    return useQuery(["units", params], () => unitAPI.getUnitsOverview(`?${params?.join("&")}`), {
        enabled,
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["units", item.id], item));
        },
    });
};

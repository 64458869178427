import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 32px;
    height: 31px;
    margin-top: -7px;
    margin-right: -7px;
`;

export const MenuXIcon = ({ stroke }) => {
    const theme = useContext(ThemeContext);
    stroke = stroke || theme.color.primary.base;

    return (
        <Svg viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.77832 7.59729L23.3347 22.7919" stroke={stroke} strokeWidth="2" />
            <path d="M23.335 7.59729L7.77861 22.7919" stroke={stroke} strokeWidth="2" />
        </Svg>
    );
};

import React from "react";
import styled from "styled-components";
import CalendarLeft from "./CalendarLeft";
import CalendarMain from "./CalendarMain";
import CalendarRight from "./CalendarRight";

const SchedulerMain = ({
    tasks,
    from_date,
    mechanics,
    createPrefilledTask,
    daySchedule,
    isOffHoursExpanded,
    setIsOffHoursExpanded,
    editAbsence,
    editExtra,
    handleTaskDoubleClick,
    isInAutoPlannerMode,
    autoPlannerSelectedTaskIds,
    onTaskCheckboxUpdate,
    isCheckboxDisabled,
    autoPlannerSelectedMechanicIds,
    onMechanicCheckboxUpdate,
}) => {
    return (
        <SchedulerMainStyled $mechanics={mechanics}>
            <CalendarLeft
                mechanics={mechanics}
                isCheckboxVisible={isInAutoPlannerMode}
                isCheckboxDisabled={isCheckboxDisabled}
                autoPlannerSelectedMechanicIds={autoPlannerSelectedMechanicIds}
                onCheckboxClick={onMechanicCheckboxUpdate}
            />
            <CalendarMain
                mechanics={mechanics}
                tasks={tasks}
                from_date={from_date}
                createPrefilledTask={createPrefilledTask}
                daySchedule={daySchedule}
                isOffHoursExpanded={isOffHoursExpanded}
                setIsOffHoursExpanded={setIsOffHoursExpanded}
                editAbsence={editAbsence}
                editExtra={editExtra}
                handleTaskDoubleClick={handleTaskDoubleClick}
                isInAutoPlannerMode={isInAutoPlannerMode}
                autoPlannerSelectedTaskIds={autoPlannerSelectedTaskIds}
                onTaskCheckboxUpdate={onTaskCheckboxUpdate}
                isCheckboxDisabled={isCheckboxDisabled}
            />
            <CalendarRight mechanics={mechanics} tasks={tasks} from_date={from_date} />
        </SchedulerMainStyled>
    );
};

export default SchedulerMain;

const SchedulerMainStyled = styled.div`
    display: flex;
    flex-direction: row;
    border-top: ${(p) =>
        `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};

    opacity: 0;
    overflow: auto;

    animation: fadeIn 100ms ease forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    .main {
        min-width: 30rem;
        width: calc(100% - ${(p) => p.theme.scheduler.rightWidth + p.theme.scheduler.leftWidth}px);
        overflow-y: hidden;
        overflow-x: scroll;
        scroll-behavior: smooth;

        .day {
            position: absolute;
            display: flex;
            align-items: center;
            width: ${(p) => p.dayWidth || p.theme.scheduler.dayWidth}px;
            border-right: ${(p) =>
                `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};
            border-bottom: ${(p) =>
                `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};
            height: ${(p) => p.cellHeight || p.theme.scheduler.cellHeight}px;
        }

        .header {
            display: flex;
            align-items: center;
            height: 2.5rem;

            position: relative;

            .day {
                > span {
                    padding-left: 0.4375rem;
                }
            }
        }

        .schedule {
            height: ${(p) =>
                p.$mechanics.reduce((accumulator, item) => accumulator + item.rows, 0) *
                p.theme.scheduler.cellHeight}px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: absolute;

            .task {
                position: absolute;
                height: ${(p) => p.theme.scheduler.cellHeight - p.theme.scheduler.borderWidth}px;
            }

            .mechanic {
                width: ${(p) => p.theme.scheduler.dayWidth * 7}px;
            }
        }
    }
`;

const color = {
    primary: {
        base: "#38558D", // Deep blue
        light: "#6D8AC5", // Light blue
        xlight: "#A7B9DC", // Light grey-ish blue
        xxlight: "#E2E8F3", // Light blue-ish white
    },
    secondary: {
        base: "#EB662E", // Deep orange
        light: "#F5713A", // Orange
        xxlight: "#FDE3D8", // Light orange-ish white
    },
    neutral: {
        base: "#333333", // Dark grey
        light: "#969696", // Grey
        xlight: "#CFCFCF", // Light grey
        xxxlight: "#FFF", // White
    },
    error: {
        base: "#FF0000", // Deep red
        xlight: "#FF9999", // Light red
        xxlight: "#FFD6D6", // Light red-ish white
    },
    warning: {
        base: "#FFCD11", // Orange-y yellow
        xlight: "#FFE999", // Light yellow
        xxlight: "#FFF6D6", // Light yellow-ish white
    },
    ok: {
        base: "#62C370", // Green
        xlight: "#B5E3BC", // Light green
        xxlight: "#C3EEC9", // Light green-ish white
        lightblue: "#D0E0F0", // Light blue-ish white
    },
    status: {
        base: "#E2E8F3", // Light grey-ish white
        scheduledDraft: "#FDE3D8", // Light orange-ish white
        started: "#A7B9DC", // Light grey-ish blue
        completed: "#E1F4E4", // Light green
        invoiced: "#62C370", // Green
    },
};

const font = {
    h1: {
        "font-family": "Staatliches, sans-serif",
        "font-style": "normal",
        "font-weight": "normal",
        "font-size": "24px",
        "line-height": "150%",
        "text-transform": "uppercase",
    },
    h2: {
        "font-family": "Open Sans, sans-serif",
        "font-style": "normal",
        "font-weight": "600",
        "font-size": "20px",
        "line-height": "150%",
    },
    t: {
        "font-family": "Open Sans, sans-serif",
        "font-style": "normal",
        "font-weight": "normal",
        "font-size": "16px",
        "line-height": "150%",
    },
    tb: {
        "font-family": "Open Sans, sans-serif",
        "font-style": "normal",
        "font-weight": "600",
        "font-size": "16px",
        "line-height": "150%",
    },
    s: {
        "font-family": "Open Sans, sans-serif",
        "font-style": "normal",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "150%",
    },
};

export const device = {
    mobile: `(min-width: 0px) and (max-width: 767px)`,
    tablet: `(min-width: 768px) and (max-width: 1223px)`,
    desktop: `(min-width: 1224px)`,
    m2t: `(min-width: 0px) and (max-width: 1223px)`,
    t2d: `(min-width: 768px)`,
};

export default {
    breakpoints: {
        m: 0,
        p: 768,
        d: 992,
    },
    spacing: {
        0: "0",
        1: "0.25rem",
        2: "0.5rem",
        3: "1rem",
        4: "1.5rem",
        5: "3rem",
    },
    font: font,
    color: color,
    button: {
        padding_y: "11px",
        padding_x: "20px",
    },
    input: {
        padding: "14px",
    },
    header: {
        height: "70px",
        background: {
            color: color.primary.base,
        },
    },
    text: {
        color: {
            main: color.neutral.base,
            second: color.neutral.xxxlight,
            link: color.primary.base,
        },
    },
    footer: {
        height: "60px",
        background: {
            color: color.secondary.base,
        },
        text: {
            color: color.neutral.xxxlight,
        },
    },
    scheduler: {
        dayWidth: 800, // Width for whole day, including off hours and breaks
        nonWorkingHoursWidth: 100, // Width of one segment displaying off hours
        workingHoursWidth: 800 - 100 * 2, // dayWidth - (nonWorkingHoursWidth * 2)
        cellHeight: 40,
        leftWidth: 200,
        rightWidth: 115,
        taskWidth: 124,
        borderWidth: 2,
    },
};

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { useAuth } from "../auth/hooks/useAuth";

import { Button, CleanButton } from "../../components/buttons";
import { H1, TB } from "../../components/texts";
import MainArea from "../../components/layout/MainArea";

const Logout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { logOut } = useAuth();

    function handleLogOut() {
        logOut().then(() => navigate("/"));
    }

    return (
        <MainArea>
            <H1>{t("logout")}</H1>

            <StyledLogout>
                <Button onClick={handleLogOut}>{t("logout")}</Button>

                <CleanButton onClick={() => navigate(-1)}>
                    <TB $underline $link>
                        {t("abort")}
                    </TB>
                </CleanButton>
            </StyledLogout>
        </MainArea>
    );
};

export default Logout;

const StyledLogout = styled.section`
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > button,
    button:active {
        margin-right: 20px;
    }
`;

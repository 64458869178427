import { useEffect, useState, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { useForm, FormProvider } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUnits } from "../../unit/hooks/useUnits";
import { useCustomers } from "../customers/hooks/useCustomers";
import { usePermissions, checkForPermission } from "../../user/hooks/usePermissions";
import { PERMISSIONS } from "../../auth/permissions";
import useMedia from "../../../components/helpers/hooks/useMedia";
import QueryWrapper from "../../../components/application/QueryWrapper";

import StandardTableStyle from "../../../components/application/StandardTableStyle";
import MainArea from "../../../components/layout/MainArea";
import Spacer from "../../../components/helpers/Spacer";
import { H1, TB, T } from "../../../components/texts";
import { Horizontal } from "../../../components/layout/FlexGrid";
import { Button } from "../../../components/buttons";
import { InputWithLabel, InputCheckbox } from "../../../components/inputs";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import StandardTableContent from "../../../components/application/StandardTableContent";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const Units = () => {
    const [data, setData] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobile } = useMedia();
    const methods = useForm({ mode: "onChange" });

    const permissions = usePermissions();
    const customers = useCustomers();
    const units = useUnits();

    const search = methods.watch("search");
    const showInactiveUnits = methods.watch("show-inactive-units");

    function canOpenUnitsForCustomer(customer_id) {
        if (!permissions?.data || permissions.data.length === 0) return false;

        const validPermissions = [PERMISSIONS.unit_admin.name];

        return validPermissions
            .map((permission) =>
                checkForPermission({
                    dataToCheck: permissions.data,
                    permission,
                    customer_id,
                })
            )
            .some((check) => check === true);
    }

    const columns = useMemo(
        () =>
            isMobile
                ? [
                      {
                          header: "ID",
                          accessorKey: "int_id",
                          cell: ({ getValue, row }) =>
                              canOpenUnitsForCustomer(row.original?.customer_id) ||
                              canOpenUnitsForCustomer(row.original?.host_id) ? (
                                  <EditLink to={`/units/${row.original.id}/edit`}>
                                      <TB $link>{getValue()}</TB>
                                  </EditLink>
                              ) : (
                                  <T>{getValue()}</T>
                              ),
                      },
                      {
                          header: t("customer"),
                          accessorKey: "customer",
                      },
                      {
                          id: "edit",
                          accessorKey: "int_id",
                          cell: ({ row }) => (
                              <EditLink to={`/units/${row.original.id}/edit`}>
                                  <TB $link>{t("edit")}</TB>
                              </EditLink>
                          ),
                          enableSorting: false,
                      },
                  ]
                : [
                      {
                          header: "ID",
                          accessorKey: "int_id",
                          cell: ({ getValue, row }) =>
                              canOpenUnitsForCustomer(row.original?.customer_id) ||
                              canOpenUnitsForCustomer(row.original?.host_id) ? (
                                  <EditLink to={`/units/${row.original.id}/edit`}>
                                      <TB $link>{getValue()}</TB>
                                  </EditLink>
                              ) : (
                                  <T>{getValue()}</T>
                              ),
                      },
                      {
                          header: t("customer"),
                          accessorKey: "customer",
                          cell: ({ getValue, row }) =>
                              canEditCustomer(row.original.customer_id) ? (
                                  <EditLink
                                      to={`/administration/customers/${row.original.customer_id}/edit`}
                                  >
                                      <T $link>{getValue()}</T>
                                  </EditLink>
                              ) : (
                                  <T>{getValue()}</T>
                              ),
                      },
                      {
                          header: t("useArea"),
                          accessorKey: "area.name",
                      },
                      {
                          header: t("group"),
                          accessorKey: "group.name",
                      },
                      {
                          header: `${t("brand")} - ${t("type")}`,
                          accessorFn: (row) => `${row.manufacturer} ${row.type}`,
                      },
                      {
                          header: t("production_year"),
                          accessorKey: "data.model_year",
                      },
                      {
                          header: t("serial_number_short"),
                          accessorFn: (row) => `${row.data?.serial_number ?? ""}`,
                      },
                      {
                          header: t("hours_capital"),
                          accessorKey: "hour_counter",
                          enableColumnFilter: false,
                      },
                      {
                          header: t("contact_person"),
                          accessorKey: "area.contact_person.name",
                          cell: ({ getValue, row }) => {
                              const mobile = row.original.area?.contact_person?.mobile;
                              const email = row.original.area?.contact_person?.email;
                              return (
                                  <T>
                                      {getValue()}
                                      {mobile ? `, ${mobile}` : null}
                                      {email ? (
                                          <a href={`mailto:${email}`}>
                                              , <T $link>{email}</T>
                                          </a>
                                      ) : null}
                                  </T>
                              );
                          },
                      },
                  ],
        [isMobile, permissions?.data]
    );

    const tableData = useMemo(() => data || [], [data]);
    const initialState = useMemo(() => ({ pagination: { pageSize: 20 } }), []);

    const table = useReactTable({
        data: tableData,
        columns,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    useEffect(() => {
        if (units.data && customers.data) {
            setData(
                units.data
                    .filter((unit) => (showInactiveUnits ? !unit.active : unit.active))
                    .map((unit) => {
                        return {
                            ...unit,
                            customer:
                                customers?.data.find((item) => item.id === unit.customer_id)
                                    ?.name || "-",
                        };
                    })
            );
        }
    }, [units?.data, customers?.data, showInactiveUnits]);

    const canAddNewUnits = useMemo(
        () =>
            permissions?.data
                ? checkForPermission({
                      dataToCheck: permissions.data,
                      permission: PERMISSIONS.unit_admin.name,
                  })
                : false,
        [permissions?.data]
    );

    function canEditCustomer(customerId) {
        if (!permissions?.data || permissions.data.length === 0) return false;

        return checkForPermission({
            dataToCheck: permissions.data,
            permission: PERMISSIONS.customer_admin.name,
            customer_id: customerId,
        });
    }

    return (
        <MainArea>
            <QueryWrapper data={permissions}>
                <Horizontal>
                    <H1>{t("vehicles_overview_short")}</H1>
                    <Spacer />
                    <Button disabled={!canAddNewUnits} onClick={() => navigate(`/units/0/edit`)}>
                        <TB $second>{t("create_new_vehicle")}</TB>
                    </Button>
                </Horizontal>

                <FormProvider {...methods}>
                    <form>
                        <InputWithLabel
                            label={t("search_vehicles")}
                            name={"search"}
                            style={{ maxWidth: "27rem" }}
                        />
                        <InputCheckbox
                            name="show-inactive-units"
                            label={t("show_only_inactive_vehicles")}
                        />

                        <QueryWrapper data={[units, customers]}>
                            {rowModel.rows.length > 0 ? (
                                <TableContainer>
                                    <TableStyled>
                                        <StandardTableContent
                                            headerGroups={headerGroups}
                                            rowModel={rowModel}
                                        />
                                    </TableStyled>
                                </TableContainer>
                            ) : (
                                <NoUnits>
                                    <TB>{t("no_vehicles_to_show")}</TB>
                                </NoUnits>
                            )}
                            <TablePaginationNav
                                pageCount={table.getPageCount()}
                                previousPage={table.previousPage}
                                canPreviousPage={table.getCanPreviousPage()}
                                nextPage={table.nextPage}
                                canNextPage={table.getCanNextPage()}
                                pageOptions={table.getPageOptions()}
                                gotoPage={table.setPageIndex}
                                pageIndex={table.getState().pagination.pageIndex}
                            />
                        </QueryWrapper>
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default Units;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
        }
    }

    tbody tr {
        transition: background-color 100ms ease;

        &:hover {
            background-color: ${(p) =>
                p.isGroupHeader ? `initial` : `${p.theme.color.neutral.xlight}44`};
            transition: background-color 50ms ease;
        }
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const NoUnits = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const EditLink = styled(Link)`
    text-decoration: none;
`;

import { useMemo } from "react";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUnitAreasForCustomer } from "../../../unit/hooks/useUnitAreasForCustomer";
import QueryWrapper from "../../../../components/application/QueryWrapper";

import StandardTableStyle from "../../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../../components/application/StandardTableContent";
import { TB } from "../../../../components/texts";
import { CleanButton } from "../../../../components/buttons";

const Areas = ({ customerId, openAreaEditorFor, canEditAreas }) => {
    const { t } = useTranslation();
    const areas = useUnitAreasForCustomer(customerId);

    const columns = useMemo(
        () =>
            canEditAreas
                ? [
                      {
                          header: t("name"),
                          accessorKey: "name",
                      },
                      {
                          header: t("description"),
                          accessorKey: "description",
                      },
                      {
                          header: t("contact_person"),
                          accessorKey: "contact_person.name",
                      },
                      {
                          id: "edit",
                          cell: ({ row }) => (
                              <CleanButton
                                  type="button"
                                  onClick={() => openAreaEditorFor(row.original.id)}
                              >
                                  <TB $link>{t("edit")}</TB>
                              </CleanButton>
                          ),
                      },
                  ]
                : [
                      {
                          header: t("name"),
                          accessorKey: "name",
                      },
                      {
                          header: t("description"),
                          accessorKey: "description",
                      },
                      {
                          header: t("contact_person"),
                          accessorKey: "contact_person.name",
                      },
                  ],
        [canEditAreas]
    );

    const data = useMemo(
        () => (areas?.data && areas.data.length > 0 ? areas.data : []),
        [areas?.data]
    );

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <QueryWrapper data={areas}>
            <TableContainer>
                <TableStyled>
                    <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                </TableStyled>
            </TableContainer>
        </QueryWrapper>
    );
};

export default Areas;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0.5rem;
        }
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

import { useTranslation } from "react-i18next";

import userAPI from "../../../../api/core/userAPI";
import useServerLookupBoolean from "../../hooks/useServerLookup";
import { InputWithLabel } from "../../../../components/inputs";

const Email = ({ ...props }) => {
    const { t } = useTranslation();
    useServerLookupBoolean({
        name: "email",
        apiCall: userAPI.getEmailExists,
        errorType: "taken",
        errorMessage: "E-post adressen er allerede tatt",
    });

    return (
        <InputWithLabel
            name={"email"}
            validate={{
                minLength: { value: 6, message: "E-post adressen må være minst 6 bokstaver" },
            }}
            label={t("email", "Email")}
            {...props}
        />
    );
};

export default Email;

import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ReactModal from "react-modal";

import QueryWrapper from "../../../components/application/QueryWrapper";
import { useSaveInspectionCategory } from "./hooks/useSaveInspectionCategory";
import { useInspectionCategory } from "./hooks/useInspectionCategory";

import SaveOrAbort from "../../../components/application/SaveOrAbort";
import { InputWithLabel } from "../../../components/inputs";
import { H1 } from "../../../components/texts";

const EditInspectionCategoryModal = ({ isOpen, onClose, categoryId }) => {
    const { t } = useTranslation();

    const methods = useForm({ mode: "onChange" });
    const category = useInspectionCategory(categoryId);
    const { mutate: saveCategory, isLoading } = useSaveInspectionCategory();

    useEffect(() => {
        if (!category?.data) return;

        methods.reset({ name: category.data.name });
    }, [category.data]);

    function onSubmit(data) {
        const post = {
            id: category?.data?.id,
            name: data.name,
            order: 0,
        };
        saveCategory(post, { onSuccess: onClose });
    }

    return (
        <ModalStyled
            isOpen={isOpen}
            onRequestClose={onClose}
            className={"Modal"}
            overlayClassName={"ModalOverlay"}
            shouldCloseOnOverlayClick={false}
        >
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <H1>{t("edit_category_name")}</H1>

                    <QueryWrapper data={category}>
                        <InputWithLabel
                            name="name"
                            label={t("name_of_category")}
                            validate={{ required: true }}
                        />

                        <SaveOrAbort onAbort={onClose} saving={isLoading} />
                    </QueryWrapper>
                </form>
            </FormProvider>
        </ModalStyled>
    );
};

export default EditInspectionCategoryModal;

const ModalStyled = styled(ReactModal)`
    margin-top: 12rem;
    padding: 2rem 1.5rem 5rem;
    max-width: 60rem;
    height: max-content;
    min-height: 21rem;
`;

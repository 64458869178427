import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import taskPostChecksAPI from "../../../../api/core/taskPostChecksAPI";

export const useSaveTaskPostCheckCheckpoint = ({ onSuccess }) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ customerId, data }) => taskPostChecksAPI.updateCheckpoint(customerId, data),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["task-post-checks"]);

                if (onSuccess instanceof Function) onSuccess(data, variables, context);
            },
            onError: (error) => {
                toast.error(
                    `Error: ${error.response?.data?.message || error.message || "unknown"}`
                );
                console.log("Saving error: ", error);
            },
        }
    );
};

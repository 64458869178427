import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import sensorAPI from "../../../api/core/sensorAPI";

export const useSaveSensor = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        ({ id, ...data }) =>
            id === 0 ? sensorAPI.postSensor(data) : sensorAPI.patchSensor({ id, ...data }),
        {
            onSuccess: () => queryClient.invalidateQueries("sensors"),
            onError: (error) =>
                toast.error(
                    `${t("error_when_saving")}: ${
                        error.response?.data?.message || error.message || t("unknown_error")
                    }`
                ),
        }
    );
};

import { useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useInspectionConfigsForCustomer } from "../inspection/hooks/useInspectionConfigsForCustomer";
import { useInspectionConfigTemplates } from "../inspection/hooks/useInspectionConfigTemplates";

import { SelectForm } from "../../../components/inputs";

const InspectionConfigSelect = ({ customer_id, name, label, unitId }) => {
    const { watch, setValue } = useFormContext();
    const { t } = useTranslation();

    const configsForCustomer = useInspectionConfigsForCustomer(customer_id);
    const configsTemplates = useInspectionConfigTemplates();

    const selectedConfigId = watch("periodic_technical_inspection_id");

    const options = useMemo(() => {
        if (!configsForCustomer?.data || !configsTemplates?.data) return [];

        const allConfigs =
            customer_id === 1
                ? configsForCustomer.data
                : [...configsForCustomer.data, ...configsTemplates.data];

        return allConfigs?.map((config) => ({
            value: config.id,
            label: `${config.name}${
                customer_id !== 1 && config.customer_id === 1 ? ` (${t("system_template")})` : ""
            }`,
        }));
    }, [configsForCustomer?.data, configsTemplates?.data]);

    useEffect(() => {
        if (options?.length && selectedConfigId && !selectedConfigId.value) {
            setValue(
                "periodic_technical_inspection_id",
                options.find((item) => item.value === selectedConfigId)
            );
        }
    }, [selectedConfigId, options]);

    useEffect(() => {
        if (unitId === 0) {
            setValue("periodic_technical_inspection", null);
        }
    }, [unitId, customer_id]);

    return (
        <SelectForm
            // Key rerenders (resets) select on customer change
            key={customer_id}
            name={name}
            label={label}
            options={options}
            isClearable
        />
    );
};

export default InspectionConfigSelect;

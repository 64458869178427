import { fetchData, updateData, deleteEntry } from "../fetch";

const getSensors = async () => fetchData(`core/sensor`);
const getSensor = async (id) => fetchData(`core/sensor/${id}`);
const postSensor = async (data) => updateData(data, `core/sensor`, "POST");
const patchSensor = async (data) => updateData(data, `core/sensor`, "PATCH");
const postSensorUnitConnection = async (data) => updateData(data, `core/sensor/unit`, "POST");
const deleteSensorUnitConnection = async (sensorId) => deleteEntry(sensorId, `core/sensor/unit`);
const getSensorsForUnit = async (unitId) => fetchData(`core/sensor/unit/${unitId}`);

const getSensorUnitHourCounter = async (unitId) =>
    fetchData(`core/sensor/unit/${unitId}/hour_counter`);
const postSensorUnitHourCounter = async ({ unitId, data }) =>
    updateData(data, `core/sensor/unit/${unitId}/hour_counter`, "PUT");

export default {
    getSensors,
    getSensor,
    postSensor,
    patchSensor,
    postSensorUnitConnection,
    deleteSensorUnitConnection,
    getSensorsForUnit,
    getSensorUnitHourCounter,
    postSensorUnitHourCounter,
};

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import StandardTableStyle from "../../components/application/StandardTableStyle";
import { H1, TB } from "../../components/texts";
import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

const UnitCostRanking = ({ unitCostsMap, downtimes, hoursThisMonth }) => {
    const { t } = useTranslation();
    const [showAllUnits, setShowAllUnits] = useState(false);
    const unitRanking = useMemo(() => {
        if (unitCostsMap == null) return null;
        if (downtimes == null) return null;

        const { unitDowntimeMap } = downtimes;
        const arr = Array.from(unitCostsMap.values()).map((unitCost) => {
            const downtime = unitDowntimeMap.get(unitCost.unit.id) ?? 0;
            return {
                ...unitCost,
                uptime: 100 * (1 - downtime / hoursThisMonth),
            };
        });
        return arr.sort((a, b) => {
            const aCost = a.hoursCost + a.materialsCost;
            const bCost = b.hoursCost + b.materialsCost;
            if (aCost < bCost) return 1;
            if (bCost < aCost) return -1;
            return 0;
        });
    }, [unitCostsMap, downtimes]);

    return (
        <TableStyled>
            <thead>
                <tr>
                    <th>
                        <LinkedTitleContainer>
                            <H1NoMargin>{t("top_5")}</H1NoMargin>
                            {showAllUnits ? (
                                <ShowMoreButton
                                    onClick={() => {
                                        setShowAllUnits(false);
                                    }}
                                >
                                    <TB $link>{t("show_less")}</TB>
                                </ShowMoreButton>
                            ) : (
                                <ShowMoreButton
                                    onClick={() => {
                                        setShowAllUnits(true);
                                    }}
                                >
                                    <TB $link>{t("show_all")}</TB>
                                </ShowMoreButton>
                            )}
                        </LinkedTitleContainer>
                    </th>
                    <th>{t("hours_capital")}</th>
                    <th>{t("material")}</th>
                    <th>{t("cost")}</th>
                    <th>{t("uptime")}</th>
                </tr>
            </thead>
            <tbody>
                {unitRanking != null ? (
                    unitRanking.map((unitCost, i) => {
                        if (!showAllUnits && i > 4) {
                            return null;
                        }

                        return (
                            <tr key={unitCost.unit.id}>
                                <td>
                                    <NavLink to={`/unit/${unitCost.unit.id}`}>
                                        <TB $link>
                                            {unitCost.unit.int_id} - {unitCost.unit.manufacturer}{" "}
                                            {unitCost.unit.type}
                                        </TB>
                                    </NavLink>
                                </td>
                                <td>
                                    {Number(unitCost.hoursCost.toFixed(0)).toLocaleString("no")}
                                </td>
                                <td>
                                    {Number(unitCost.materialsCost.toFixed(0)).toLocaleString("no")}
                                </td>
                                <td>
                                    {Number(
                                        (unitCost.hoursCost + unitCost.materialsCost).toFixed(0)
                                    ).toLocaleString("no")}
                                </td>
                                <td>{unitCost.uptime.toFixed(0)}%</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={6}>
                            <NoAreasSelected>{t("no_areas_selected")}</NoAreasSelected>
                        </td>
                    </tr>
                )}
            </tbody>
        </TableStyled>
    );
};

export default UnitCostRanking;

const H1NoMargin = styled(H1)`
    margin: 0;
`;

const LinkedTitleContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
`;

const NoAreasSelected = styled.div`
    text-align: center;
    padding: 2rem;
`;

const ShowMoreButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
`;

const TableStyled = styled(StandardTableStyle)`
    tfoot tr td {
        text-align: center;
        border: none;
    }

    td,
    th {
        text-align: right;
        width: 16.25%;
    }

    td:first-child,
    th:first-child {
        text-align: left;
        width: 35%;
    }
`;

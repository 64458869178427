import { useState, useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import PartSelect from "../../materials/PartSelect";
import { T, TB } from "../../../components/texts";
import { Input } from "../../../components/inputs";
import { CleanButton } from "../../../components/buttons";
import { DownArrowIcon } from "../../../components/icons";
import UnitServiceIntervalPart from "./UnitServiceIntervalPart";

const UnitServiceInterval = ({ interval, intervalIndex }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isAddingNewPart, setIsAddingNewPart] = useState(false);

    const { t } = useTranslation();
    const { register, watch, control, setValue } = useFormContext();

    const selectedPart = watch(`selected-part-${intervalIndex}`);

    const { fields, append, remove } = useFieldArray({
        control,
        name: `data.service_intervals.${intervalIndex}.parts`,
        keyName: "fieldId",
    });

    useEffect(() => {
        if (isAddingNewPart && selectedPart) {
            appendPart(selectedPart.value);
        }
    }, [selectedPart]);

    async function appendPart(partId) {
        if (!partId) return;

        setIsAddingNewPart(false);
        setValue(`selected-part-${intervalIndex}`, "");

        append({ part_id: partId, count: 1 });
    }

    function addPart() {
        setIsAddingNewPart(true);
    }

    function removePart(id) {
        remove(id);
    }

    return (
        <Container>
            <Header>
                <section>
                    <label htmlFor={`interval-${interval.id}`}>
                        <T $link>{`${t("interval")} ${intervalIndex + 1}`}</T>
                    </label>

                    <InputWithUnit>
                        <Input
                            {...register(`data.service_intervals.${intervalIndex}.interval`)}
                            type="number"
                        />

                        <T $link>{t("hours")}</T>
                    </InputWithUnit>
                </section>

                <ExpandButton type="button" onClick={() => setIsExpanded((prev) => !prev)}>
                    <DownArrowIcon className={isExpanded ? "expanded" : ""} />
                </ExpandButton>
            </Header>

            <Body className={isExpanded ? "expanded" : ""}>
                <TableHeader>
                    <TB>{t("part_name_or_number")}</TB>
                    <TB>{t("description_2")}</TB>
                    <TB>{t("location_2")}</TB>
                    <TB>{t("quantity")}</TB>
                    <div />
                </TableHeader>

                {fields?.length > 0
                    ? fields.map((field, index) => (
                          <UnitServiceIntervalPart
                              key={field.fieldId}
                              partId={field.part_id}
                              fieldIndex={index}
                              intervalIndex={intervalIndex}
                              handleRemovePart={removePart}
                          />
                      ))
                    : null}

                {isAddingNewPart ? (
                    <PartSelectWrapper>
                        <PartSelect
                            name={`selected-part-${intervalIndex}`}
                            isOptionDisabled={(option) =>
                                fields.some((item) => item.part_id === option.value)
                            }
                            hideErrorLabel
                        />
                    </PartSelectWrapper>
                ) : (
                    <AddPartButton type="button" onClick={() => addPart()}>
                        <TB $link $underline>
                            + {t("add_part")}
                        </TB>
                    </AddPartButton>
                )}
            </Body>
        </Container>
    );
};

export default UnitServiceInterval;

const Container = styled.article`
    border: 2px solid ${({ theme }) => theme.color.primary.xlight};
    position: relative;
    margin-top: 0.5rem;
    overflow: hidden;
`;

const Header = styled.section`
    background: ${({ theme }) => theme.color.primary.xlight};
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
`;

const InputWithUnit = styled.section`
    display: flex;
    align-items: center;

    input {
        max-width: 6rem;
        margin-right: 0.5rem;
        text-align: right;
        padding-right: 0.5rem;
    }
`;

const ExpandButton = styled(CleanButton)`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${DownArrowIcon} {
        transition: 200ms ease;

        &.expanded {
            transform: rotate(90deg);
        }
    }
`;

const Body = styled.section`
    height: 0;
    overflow-y: hidden;
    max-width: 70rem;

    &.expanded {
        margin: 2rem 1rem 1rem;
        height: auto;
    }
`;

const TableHeader = styled.section`
    display: flex;
    padding-bottom: 0.5rem;
    min-width: 35rem;

    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};

    > :nth-child(1) {
        flex-basis: 35%;
    }
    > :nth-child(2) {
        flex-basis: 20%;
    }
    > :nth-child(3) {
        flex-basis: 20%;
    }
    > :nth-child(4) {
        flex-basis: 20%;
    }
    > :nth-child(5) {
        flex-basis: 5%;
    }
`;

const AddPartButton = styled(CleanButton)`
    padding: 1rem 0;
`;

const PartSelectWrapper = styled.section`
    padding: 1rem 0;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};

    > div {
        max-width: 20rem;
    }
`;

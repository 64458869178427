import { updateData, fetchData } from "../fetch";

const getAll = async () => fetchData(`core/pre-check/all`);
const getOne = async (id) => fetchData(`core/pre-check/${id}`);
const getLast = async (id) => fetchData(`core/pre-check/last/${id}`);
const getDateFromTo = async (id, from, to) =>
    fetchData(`core/pre-check/date?unit_id=${id}&from=${from}&to=${to}`);
const getAnswerId = async (id) => fetchData(`core/pre-check/answer/${id}`);
const getReportFromTo = async (from, to) =>
    fetchData(`core/pre-check/report?from=${from}&to=${to}`);

const updateList = async (data) => updateData(data, "core/pre-check/list");
const saveNew = async (id, entry) => updateData({ unit_id: id, data: entry }, `core/pre-check`);

export default {
    getAll,
    getOne,
    getLast,
    getDateFromTo,
    getAnswerId,
    getReportFromTo,
    updateList,
    saveNew,
};

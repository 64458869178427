import styled from "styled-components";
import { device } from "../../theme";

export const FlexGap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${(props) => (props.gap ? props.gap : "0.5rem")};
    width: 100%;

    > * {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    @media only screen and ${device.mobile} {
        > * {
            width: 100%;
            flex: auto;
        }
    }
`;

import { useEffect } from "react";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";

import { T } from "../texts";
import { DefectIcon } from "../icons";

const FinishedStyled = styled(animated.div)`
    background-color: ${(props) => props.theme.color.error.xxlight};
    padding: ${(props) => (props.big ? "20px 5px 20px 5px" : "10px 5px 10px 5px")};
    border: ${(props) => props.theme.color.error.base};
    border-width: 2px;
    border-style: solid;
    width: calc(100% - 10px);
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        padding-right: 8px;
    }
`;

export const ErrorBox = ({ text, remove, ...orgprops }) => {
    const transition = useTransition(text, {
        from: { opacity: 0, transform: "translate3d(-50%, 0px, 0px)" },
        enter: { opacity: 1, transform: "translate3d(0%, 0px, 0px)" },
        leave: { opacity: 0, transform: "translate3d(50%, 0px, 0px" },
    });

    useEffect(() => {
        if (text && remove) {
            setTimeout(() => {
                remove(null);
            }, [5000]);
        }
    }, [text]);

    return transition(
        ({ item, key, props }) =>
            item && (
                <FinishedStyled key={key} style={props} {...orgprops}>
                    <DefectIcon />
                    <T>{item}</T>
                </FinishedStyled>
            )
    );
};

import { url2data, fetchData, updateData } from "../fetch";

const getCustomers = async () => fetchData(`core/customers`);
const updateCustomer = async (data) => updateData(data, `core/customers`);
const getCustomer = async (id) => fetchData(`core/customers/${id}`);
const getCustomerNameExists = async (name) => url2data(`core/customers/exists`, { name });
const getConnections = async (id) => fetchData(`core/customers/${id}/connections`);
const getUsersForCustomer = async (id) => fetchData(`core/customers/${id}/users`);
const getCustomersForWorkshop = async (workshopId) =>
    fetchData(`core/customers?is_customer_for=${workshopId}`);

export default {
    getCustomers,
    updateCustomer,
    getCustomer,
    getCustomerNameExists,
    getConnections,
    getUsersForCustomer,
    getCustomersForWorkshop,
};

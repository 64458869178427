import { useCustomerRoles } from "../../roles/hooks/useCustomerRoles";
import ServerSelect from "../../../../components/application/ServerSelect";

const RoleTypeSelect = ({ customer_id, ...props }) => {
    return (
        <ServerSelect
            selectUse={useCustomerRoles}
            selectKey={customer_id}
            listKey={"name"}
            {...props}
        />
    );
};

export default RoleTypeSelect;

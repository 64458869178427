import { useQuery } from "react-query";
import userAPI from "../../../api/core/userAPI";

export const usePermissions = () => useQuery("permissions", userAPI.getPermissions);

export const checkForPermission = ({ dataToCheck, permission, customer_id = null }) => {
    if (customer_id) {
        return (
            customer_id in dataToCheck &&
            (Array.isArray(permission)
                ? permission.some((permission) => permission in dataToCheck[customer_id])
                : permission in dataToCheck[customer_id])
        );
    } else {
        return Object.keys(dataToCheck).some((item) => permission in dataToCheck[item]);
    }
};

import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import styled from "styled-components";

import { useUnitArea } from "../../../unit/hooks/useUnitArea";
import { useSaveUnitArea } from "../hooks/useSaveUnitArea";
import { emailRegex } from "../../../../common/common";
import QueryWrapper from "../../../../components/application/QueryWrapper";

import SaveOrAbort from "../../../../components/application/SaveOrAbort";
import { InputWithLabel } from "../../../../components/inputs";
import { H1, TB } from "../../../../components/texts";
import { Grid } from "../../../../components/layout/Grid";

const EditAreaModal = ({ isOpen, onClose, areaId, customerId }) => {
    const { t } = useTranslation();
    const methods = useForm();

    const area = useUnitArea(areaId);
    const { mutate: saveArea, isLoading } = useSaveUnitArea();

    useEffect(() => {
        if (area?.data) methods.reset(area.data);
    }, [area?.data]);

    function onSubmit(data) {
        const post = {
            name: data.name,
            description: data.description,
            contact_person: {
                name: data.contact_person?.name || "",
                email: data.contact_person?.email || null,
                mobile: data.contact_person?.mobile || null,
            },
        };

        if (area?.data) {
            post.id = area.data.id;
        } else {
            post.customer_id = customerId;
        }

        saveArea(post, { onSuccess: onClose });
    }

    return (
        <ModalStyled
            isOpen={isOpen}
            onRequestClose={onClose}
            className={"Modal"}
            overlayClassName={"ModalOverlay"}
            shouldCloseOnOverlayClick={false}
        >
            <H1>{areaId ? t("edit_useArea") : t("add_useArea")}</H1>

            <QueryWrapper data={areaId ? area : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid $templateColumns={2} gap={1} style={{ marginBottom: "1rem" }}>
                            <InputWithLabel
                                name="name"
                                label={t("name_of_useArea")}
                                required
                                autoComplete="off"
                            />
                            <InputWithLabel
                                name="description"
                                label={t("description")}
                                autoComplete="off"
                            />
                        </Grid>

                        <TB>{t("contact_person")}</TB>

                        <Grid $templateColumns={2} gap={1} style={{ marginTop: "0.5rem" }}>
                            <InputWithLabel name="contact_person.name" label={t("name")} />
                            <InputWithLabel
                                name="contact_person.email"
                                label={t("email_long")}
                                type="email"
                                validate={{
                                    pattern: {
                                        value: emailRegex,
                                        message: t("has_to_be_email"),
                                    },
                                }}
                            />
                        </Grid>

                        <InputWithLabel
                            name="contact_person.mobile"
                            label={t("mobile_number")}
                            style={{ maxWidth: "13rem" }}
                            type="tel"
                        />

                        <SaveOrAbort onAbort={onClose} saving={isLoading} />
                    </form>
                </FormProvider>
            </QueryWrapper>
        </ModalStyled>
    );
};

export default EditAreaModal;

const ModalStyled = styled(ReactModal)`
    margin-top: 12rem;
    padding: 2rem 1.5rem 2rem;
    max-width: 60rem;
    height: max-content;
`;

import styled from "styled-components";

const SelectStyled = styled.div`
    display: flex;
    flex-direction: column;

    .rs__control,
    rs__control:hover {
        border: 2px solid #6d8ac5 !important;
        box-shadow: unset;
        border-radius: 0;
        height: 44px;
    }

    .rs__control--is-disabled {
        background-color: #eee;
    }

    .rs__single-value--is-disabled {
        color: ${(p) => p.theme.color.neutral.base};
    }

    p {
        margin-top: 5px;
        margin-bottom: 0px;
    }
`;

export default SelectStyled;

import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import { H1 } from "../../../components/texts";
import { useTaskPostChecks } from "./hooks/useTaskPostChecks";
import { useCustomersWithPermission } from "../customers/hooks/useCustomersWithPermission";
import { PERMISSIONS } from "../../auth/permissions";
import TaskPostChecksCustomerSelect from "./TaskPostChecksCustomerSelect";
import TaskPostChecksEditTable from "./TaskPostChecksEditTable";

const TaskPostChecks = () => {
    const { t } = useTranslation();
    const [customerId, setCustomerId] = useState(null);
    const taskPostChecks = useTaskPostChecks(customerId);

    const customers = useCustomersWithPermission({
        permission: PERMISSIONS.task_post_check_admin.name,
    });
    useEffect(() => {
        if (customers?.data?.length === 1) {
            setCustomerId(customers.data[0]?.id);
        }
    }, [customers?.data]);

    const data = useMemo(() => taskPostChecks?.data || [], [taskPostChecks?.data]);
    return (
        <MainArea back>
            <Header>
                <PageTitle>{t("task_post_checks")}</PageTitle>

                <QueryWrapper data={customers}>
                    {customers?.data?.length > 1 ? (
                        <SelectWrapper>
                            <TaskPostChecksCustomerSelect
                                onChange={(e) => setCustomerId(e.value)}
                            />
                        </SelectWrapper>
                    ) : null}
                </QueryWrapper>
            </Header>

            {customerId ? (
                <TaskPostChecksEditTable
                    checkpoints={data[0]?.checkpoints?.sort((a, b) => a.order - b.order) ?? []}
                    customerId={customerId}
                />
            ) : null}
        </MainArea>
    );
};

export default TaskPostChecks;

const Header = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const PageTitle = styled(H1)`
    margin: 1rem 0;
    margin-right: 1rem;
`;

const SelectWrapper = styled.section`
    width: min(25rem, 100%);
`;

import React from "react";
import styled, { useTheme } from "styled-components";
import { getDate } from "../../common/date";
import { T } from "../../components/texts";

const Extra = ({ extra, hoursInDay, isOffHoursExpanded, durationInHours, daySchedule }) => {
    const theme = useTheme();

    const startDate = getDate(extra.from);
    const startHour = startDate.hour;
    const startMinute = startDate.minute;

    const endDate = getDate(extra.to);
    const endHour = endDate.hour;
    const endMinute = endDate.minute;

    const workDayStartHour = Math.floor(daySchedule.work_start);
    const workDayEnd = daySchedule.work_end;

    const startsBeforeDay = startHour < workDayStartHour;
    const startsAfterDay = startHour >= workDayEnd;
    const endsAfterDay =
        endHour > workDayEnd || (endHour === Math.floor(workDayEnd) && endMinute > 0);

    const beforeDayOverflow = startsBeforeDay
        ? workDayStartHour - (startHour + startMinute / 60)
        : 0;

    const afterDayOverflow = endsAfterDay ? endHour + endMinute / 60 - workDayEnd : 0;

    const lastsWholeDay = durationInHours > 23.75;

    const wholeDayWidth = isOffHoursExpanded
        ? theme.scheduler.dayWidth
        : theme.scheduler.workingHoursWidth;

    const width = lastsWholeDay
        ? wholeDayWidth
        : !isOffHoursExpanded && startsAfterDay
          ? 0
          : ((isOffHoursExpanded ? theme.scheduler.dayWidth : theme.scheduler.workingHoursWidth) /
                hoursInDay) *
            (durationInHours - beforeDayOverflow - afterDayOverflow);

    return (
        <ExtraStyled width={width}>
            <Title>{extra.description}</Title>
        </ExtraStyled>
    );
};

export default Extra;

const ExtraStyled = styled.div`
    height: ${(p) => p.theme.scheduler.cellHeight - p.theme.scheduler.borderWidth}px;
    width: ${(p) => p.width}px;
    background: ${(p) => p.theme.color.ok.lightblue};
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const Title = styled(T)`
    padding-left: 0.7rem;
    user-select: none;
`;

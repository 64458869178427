import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { QueryClientProvider, QueryClient } from "react-query";

import WebSocketProvider from "./features/socket/SocketContext";
import { OnlineStatusProvider } from "./features/online-status/useOnlineStatus";
import { AuthProvider } from "./features/auth/hooks/useAuth";
import theme from "./theme";

const AppProviders = ({ children }) => {
    const queryClient = new QueryClient();

    return (
        <Suspense fallback={null}>
            <OnlineStatusProvider>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                        <AuthProvider>
                            <WebSocketProvider>
                                <BrowserRouter>{children}</BrowserRouter>
                            </WebSocketProvider>
                        </AuthProvider>
                    </ThemeProvider>
                </QueryClientProvider>
            </OnlineStatusProvider>
        </Suspense>
    );
};

export default AppProviders;

import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import QueryWrapper from "../../../components/application/QueryWrapper";
import { useUpdateCustomerConfigMutation } from "./hooks/useUpdateCustomerConfigMutation";
import { useConfigForCustomer } from "../setup/hooks/useConfigForCustomer";

import MainArea from "../../../components/layout/MainArea";
import { H1, H2, T } from "../../../components/texts";
import { DateTimeInputForm, Input } from "../../../components/inputs";
import { FlexGap } from "../../../components/helpers/FlexGap";
import { convertDateToMilitaryUTC, convertMilitaryToDate } from "./helpers";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import WorkshopConfigSelect from "../../../components/application/workshop/WorkshopConfigSelect";
import { useNavigate } from "react-router";

const SchedulerSetup = () => {
    const [workshopId, setWorkshopId] = useState("");
    const { t } = useTranslation();
    const methods = useForm({ mode: "onChange" });
    const navigate = useNavigate();

    const config = useConfigForCustomer(workshopId);
    const configMutation = useUpdateCustomerConfigMutation();

    useEffect(() => {
        if (!config.data) return;

        const workTime = config.data.scheduler?.workTime;
        const lunchBreak = config.data.scheduler?.lunchBreak;

        methods.reset({
            workFrom: convertMilitaryToDate(workTime?.from),
            workTo: convertMilitaryToDate(workTime?.to),
            lunchFrom: convertMilitaryToDate(lunchBreak?.from),
            lunchTo: convertMilitaryToDate(lunchBreak?.to),
            deviance_pti:
                config.data.automatic_periodic_technical_inspection_scheduler
                    .allowed_deviance_in_days,
            deviance_service:
                config.data.automatic_service_scheduler.allowed_deviance_in_unit_hours,
        });
    }, [config?.data]);

    function onSubmit(data) {
        const post = JSON.parse(JSON.stringify(config.data));

        post.scheduler.workTime.from = convertDateToMilitaryUTC(data.workFrom);
        post.scheduler.workTime.to = convertDateToMilitaryUTC(data.workTo);
        post.scheduler.lunchBreak.from = convertDateToMilitaryUTC(data.lunchFrom);
        post.scheduler.lunchBreak.to = convertDateToMilitaryUTC(data.lunchTo);
        post.automatic_periodic_technical_inspection_scheduler.allowed_deviance_in_days =
            data.deviance_pti;
        post.automatic_service_scheduler.allowed_deviance_in_unit_hours = data.deviance_service;

        configMutation.mutate(post, {
            onSuccess: () => navigate(-1),
        });
    }

    return (
        <MainArea back>
            <Wrapper>
                <HeaderStyled>
                    <NavStyled>
                        <H1>{t("scheduler_admin")}</H1>

                        <WorkshopSelectWrapper>
                            <WorkshopConfigSelect
                                defaultWorkshopId={workshopId}
                                onChange={(e) => setWorkshopId(e.value)}
                            />
                        </WorkshopSelectWrapper>
                    </NavStyled>

                    <T>{t("scheduler_admin_description")}</T>
                </HeaderStyled>

                {workshopId ? (
                    <QueryWrapper data={config}>
                        <FormProvider {...methods}>
                            <FormStyled onSubmit={methods.handleSubmit(onSubmit)}>
                                <SettingSection>
                                    <Description>
                                        <H2>{t("working_time")}</H2>
                                        <T>{t("working_time_setup_description")}</T>
                                    </Description>

                                    <FlexGap $count={3}>
                                        <DateTimeInputForm
                                            name={"workFrom"}
                                            label={t("working_time_start")}
                                            showTimeSelectOnly
                                        />
                                        <DateTimeInputForm
                                            name={"workTo"}
                                            label={t("working_time_end")}
                                            showTimeSelectOnly
                                        />
                                    </FlexGap>
                                </SettingSection>

                                <SettingSection>
                                    <Description>
                                        <H2>{t("lunch_break")}</H2>
                                        <T>{t("lunch_break_description")}</T>
                                    </Description>

                                    <FlexGap $count={3}>
                                        <DateTimeInputForm
                                            name={"lunchFrom"}
                                            label={t("lunch_break_start")}
                                            showTimeSelectOnly
                                        />
                                        <DateTimeInputForm
                                            name={"lunchTo"}
                                            label={t("lunch_break_end")}
                                            showTimeSelectOnly
                                        />
                                    </FlexGap>
                                </SettingSection>

                                <SettingSection>
                                    <Description>
                                        <H2>{t("periodic_technical_inspection")}</H2>
                                        <T>
                                            {t(
                                                "periodic_technical_inspection_scheduler_setup_description"
                                            )}
                                        </T>
                                    </Description>

                                    <FlexGap $count={2}>
                                        <DevianceInputLabel htmlFor="deviance_pti">
                                            <T>
                                                {t(
                                                    "allowed_deviance_for_periodic_technical_inspection"
                                                )}
                                            </T>
                                        </DevianceInputLabel>
                                    </FlexGap>

                                    <DevianceInput
                                        {...methods.register("deviance_pti", {
                                            validate: (value) =>
                                                !isNaN(value) || t("has_to_be_number"),
                                        })}
                                        type="number"
                                    />
                                </SettingSection>

                                <SettingSection>
                                    <Description>
                                        <H2>{t("service")}</H2>
                                        <T>{t("service_scheduler_setup_description")}</T>
                                    </Description>

                                    <FlexGap $count={2}>
                                        <DevianceInputLabel htmlFor="deviance_service">
                                            <T>{t("allowed_deviance_for_service")}</T>
                                        </DevianceInputLabel>
                                    </FlexGap>

                                    <DevianceInput
                                        {...methods.register("deviance_service", {
                                            validate: (value) =>
                                                !isNaN(value) || t("has_to_be_number"),
                                        })}
                                        type="number"
                                    />
                                </SettingSection>

                                <SaveOrAbort isSaving={configMutation.isLoading} />
                            </FormStyled>
                        </FormProvider>
                    </QueryWrapper>
                ) : null}
            </Wrapper>
        </MainArea>
    );
};

export default SchedulerSetup;

const DevianceInputLabel = styled.label`
    display: block;
    margin-bottom: 0.7rem;
`;

const DevianceInput = styled(Input)`
    max-width: 5rem;
    text-align: right;
    padding-right: 0.5rem;
    margin-bottom: 1.5rem;
`;

const Wrapper = styled.article`
    max-width: 40rem;
`;

const FormStyled = styled.form`
    opacity: 0;
    animation: fadeIn 300ms ease forwards;
`;

const HeaderStyled = styled.section`
    margin-bottom: 3rem;

    > h1 {
        margin-bottom: 1.2rem;
    }
`;

const NavStyled = styled.section`
    display: flex;
    align-items: baseline;
    margin-bottom: 1.5rem;
`;

const WorkshopSelectWrapper = styled.section`
    flex-grow: 1;

    > * {
        max-width: 20rem;
        margin-left: 2rem;
    }
`;

const SettingSection = styled.section`
    margin-bottom: 1rem;
`;

const Description = styled.section`
    margin-bottom: 1.5rem;
`;

import { useState, useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, CleanButton } from "../../components/buttons";
import { TrashIcon } from "../../components/icons";
import { InputWithLabel } from "../../components/inputs";
import { TB, T } from "../../components/texts";

const TaskAdditionalExpenses = ({ setAdditionalExpensesCost }) => {
    const [isAddingExpense, setIsAddingExpense] = useState(false);

    const { t } = useTranslation();

    const { control, setValue, getValues } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "additional_expenses",
        keyName: "fieldId",
    });

    useEffect(() => {
        updateExpensesCost();
    }, [fields]);

    function updateExpensesCost() {
        if (!setAdditionalExpensesCost) return;

        if (fields?.length > 0) {
            const sum = fields
                .map((item) => Number(item.cost) || 0)
                .reduce((acc, item) => acc + item, 0);

            setAdditionalExpensesCost(sum);
        } else {
            setAdditionalExpensesCost(0);
        }
    }

    function addExpenseLine() {
        setIsAddingExpense(true);
    }

    function saveExpense() {
        const newExpense = {
            description: getValues("expense_temp_description"),
            cost: getValues("expense_temp_cost"),
        };

        append(newExpense);

        setIsAddingExpense(false);
        setValue("expense_temp_description", "");
        setValue("expense_temp_cost", "");
    }

    function removeExpense(id) {
        remove(id);
    }

    function formatNumberToCurrency(number) {
        return new Intl.NumberFormat("no-NO", {
            style: "currency",
            currency: "NOK",
            maximumFractionDigits: 0,
        }).format(number);
    }

    return (
        <>
            {fields?.length > 0 && (
                <Expenses>
                    <Header>
                        <TB>{t("type_of_expense")}</TB>
                        <TB>{t("price")}</TB>
                        <div />
                    </Header>

                    {fields.map((field, index) => (
                        <Row key={field.fieldId}>
                            <T>{field.description}</T>
                            <T>{field.cost ? formatNumberToCurrency(field.cost) : "–"}</T>
                            <CleanButton type="button" onClick={() => removeExpense(index)}>
                                <TrashIcon />
                            </CleanButton>
                        </Row>
                    ))}
                </Expenses>
            )}

            {isAddingExpense ? (
                <ExpenseEditLine>
                    <DescriptionInputWrapper>
                        <InputWithLabel
                            name="expense_temp_description"
                            label={t("type_of_expense")}
                        />
                    </DescriptionInputWrapper>
                    <CostInputWrapper>
                        <InputWithLabel name="expense_temp_cost" label={t("price")} type="number" />
                    </CostInputWrapper>
                    <SaveExpenseButton type="button" onClick={saveExpense}>
                        {t("save_expense")}
                    </SaveExpenseButton>
                </ExpenseEditLine>
            ) : (
                <AddExpenseButton type="button" onClick={addExpenseLine}>
                    <TB $link $underline>
                        + {t("add_additional_expense")}
                    </TB>
                </AddExpenseButton>
            )}
        </>
    );
};

export default TaskAdditionalExpenses;

const AddExpenseButton = styled(CleanButton)`
    display: block;
`;

const ExpenseEditLine = styled.article`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const DescriptionInputWrapper = styled.section`
    min-width: 15rem;
    max-width: 40rem;
    flex-basis: 50%;
    margin-right: 1rem;
`;

const CostInputWrapper = styled.section`
    max-width: 5rem;
    margin-right: 1rem;
`;

const SaveExpenseButton = styled(Button)``;

const Expenses = styled.article`
    margin-bottom: 1rem;
`;

const Header = styled.section`
    display: flex;
    min-width: 20rem;
    max-width: 40rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};

    > :nth-child(1) {
        width: 80%;
        margin-right: 0.5rem;
    }
    > :nth-child(2) {
        width: 20%;
        margin-right: 0.5rem;
    }
    > :nth-child(3) {
        width: 2.5rem;
        display: block;
    }
`;

const Row = styled.section`
    display: flex;
    align-items: center;
    min-width: 20rem;
    max-width: 40rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};

    > :nth-child(1) {
        width: 80%;
        margin-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(2) {
        width: 20%;
        margin-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(3) {
        width: 2.5rem;
        display: block;
    }
`;

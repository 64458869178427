import styled from "styled-components";

import { H1 } from "../texts";
import { LogoIcon } from "../icons";

const Footer = () => (
    <StyledFooter>
        <div>
            <LogoIcon />
            <H1S $second>© Truck & Maskin</H1S>
        </div>
    </StyledFooter>
);

export default Footer;

const StyledFooter = styled.footer`
    width: 100%;
    height: ${(props) => props.theme.footer.height};
    background-color: ${(props) => props.theme.footer.background.color};
    color: ${(props) => props.theme.footer.text.color};
    padding: 5px 0 17px 12px;
    z-index: 20;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const H1S = styled(H1)`
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 0;
`;

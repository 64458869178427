import { deleteEntry, fetchData, updateData, updateDataPatch } from "../fetch";

const getTaskPostChecks = async (customer_id) =>
    fetchData(`core/task-post-checks?customer_id=${customer_id}`);
const getTaskPostCheck = async (id) => fetchData(`core/task-post-checks/${id}`);
const postTaskPostCheck = async (data) => updateData(data, "core/task-post-checks", "POST");
const patchTaskPostCheck = async (id, data) =>
    updateData(data, `core/task-post-checks/${id}`, "PATCH");
const deleteCheckpoint = async (customerId, id) =>
    deleteEntry(id, `core/task-post-checks/${customerId}/checkpoints`);
const updateCheckpoint = async (customerId, data) =>
    updateDataPatch(data, `core/task-post-checks/${customerId}/checkpoints`);

export default {
    getTaskPostChecks,
    getTaskPostCheck,
    postTaskPostCheck,
    patchTaskPostCheck,
    deleteCheckpoint,
    updateCheckpoint,
};

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { T, TB } from "../../../components/texts";
import { FlexGap } from "../../../components/helpers/FlexGap";

const TotalHoursTable = ({ fromDate, toDate, tasks, absences, extras }) => {
    const { t } = useTranslation();

    function formatDateForDisplay(date) {
        return date
            ? new Date(date).toLocaleDateString("no", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
              })
            : "";
    }

    const totalWorkHours = useMemo(() => sumUpTasksDuration(tasks), [tasks]);
    const workHoursWithOvertime50 = useMemo(
        () => sumUpTasksDuration(tasks?.filter((task) => task.overtime === 50)),
        [tasks]
    );
    const workHoursWithOvertime100 = useMemo(
        () => sumUpTasksDuration(tasks?.filter((task) => task.overtime === 100)),
        [tasks]
    );
    const numberOfTasksWithDiet95 =
        tasks?.filter((task) => task.living_expenses === "9.5t")?.length || 0;
    const numberOfTasksWithDiet12 =
        tasks?.filter((task) => task.living_expenses === "12t")?.length || 0;
    const numberOfTasksWithDietOvernight =
        tasks?.filter((task) => task.living_expenses === "overnatting")?.length || 0;
    const numberOfTasksWithExtraCallout = tasks?.filter((task) => task.extra_callout)?.length || 0;
    const tasksWithExtraShiftHours =
        tasks
            ?.filter((task) => task.extra_shift)
            ?.reduce((acc, curr) => {
                return acc + curr.duration;
            }, 0) || 0;
    const numberOfTasksWithExtraOnWatch = extras?.filter(
        (extra) => extra.type === "on_watch"
    ).length;
    const workHoursWithExtraHall = useMemo(
        () => sumUpTasksDuration(tasks?.filter((task) => task.extra_hall)),
        [tasks]
    );
    const workHoursWithExtraOut = useMemo(
        () => sumUpTasksDuration(tasks?.filter((task) => task.extra_out)),
        [tasks]
    );

    const totalAbsenceHours = useMemo(() => sumUpAbsencesDuration(absences) / 60, [absences]);

    const absenceHoursVacation = useMemo(
        () => sumUpAbsencesDuration(absences?.filter((absence) => absence.type === "ferie")) / 60,
        [absences]
    );
    const absenceHoursTimeOff = useMemo(
        () =>
            sumUpAbsencesDuration(absences?.filter((absence) => absence.type === "avspasering")) /
            60,
        [absences]
    );
    const absenceHoursSelfNotification = useMemo(
        () =>
            sumUpAbsencesDuration(absences?.filter((absence) => absence.type === "egenmelding")) /
            60,
        [absences]
    );
    const absenceHoursSickChild = useMemo(
        () =>
            sumUpAbsencesDuration(absences?.filter((absence) => absence.type === "sykt_barn")) / 60,
        [absences]
    );
    const absenceHoursSickLeave = useMemo(
        () =>
            sumUpAbsencesDuration(absences?.filter((absence) => absence.type === "sykemelding")) /
            60,
        [absences]
    );
    const absenceHoursPaidLeaveOfAbsence = useMemo(
        () =>
            sumUpAbsencesDuration(absences?.filter((absence) => absence.type === "perm_med_lonn")) /
            60,
        [absences]
    );
    const absenceHoursUnpaidLeaveOfAbsence = useMemo(
        () =>
            sumUpAbsencesDuration(
                absences?.filter((absence) => absence.type === "perm_uten_lonn")
            ) / 60,
        [absences]
    );

    const totalHours = totalWorkHours + totalAbsenceHours;

    function sumUpTasksDuration(tasks) {
        return tasks?.reduce((acc, task) => acc + task.duration, 0) || 0;
    }

    function sumUpAbsencesDuration(absences) {
        return absences?.reduce((acc, absence) => acc + absence.durationInMinutes, 0) || 0;
    }

    return (
        <Container>
            <Header>
                <DateText>
                    {formatDateForDisplay(fromDate)} - {formatDateForDisplay(toDate)}
                </DateText>

                <TB>
                    {t("total_amount_of_hours")}: {totalHours || 0}
                </TB>
            </Header>

            <Body>
                <WorkingHoursContainer>
                    <TotalHoursRow>
                        <TB>
                            {t("work_hours")}: {totalWorkHours}
                        </TB>
                    </TotalHoursRow>
                    <DetailHoursRow>
                        <FlexGap $count={4}>
                            <DetailHoursColumn>
                                <TB>
                                    {t("overtime")} ({t("hours_unit_symbol")})
                                </TB>
                                <T>
                                    {t("overtime")} 50%:{" "}
                                    <TB>
                                        {workHoursWithOvertime50}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                                <T>
                                    {t("overtime")} 100%:{" "}
                                    <TB>
                                        {workHoursWithOvertime100}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                            </DetailHoursColumn>

                            <DetailHoursColumn>
                                <TB>
                                    {t("diet")} ({t("quantity_unit_symbol")})
                                </TB>
                                <T>
                                    9,5 {t("hours_unit_symbol")}: <TB>{numberOfTasksWithDiet95}</TB>
                                </T>
                                <T>
                                    12 {t("hours_unit_symbol")}: <TB>{numberOfTasksWithDiet12}</TB>
                                </T>
                                <T>
                                    {t("overnight")}: <TB>{numberOfTasksWithDietOvernight}</TB>
                                </T>
                            </DetailHoursColumn>

                            <DetailHoursColumn>
                                <TB>{`${t("extras")} (${t("quantity_unit_symbol")})`}</TB>
                                <T>
                                    {t("callout")}: <TB>{numberOfTasksWithExtraCallout}</TB>
                                </T>
                                <T>
                                    {t("on_watch")}: <TB>{numberOfTasksWithExtraOnWatch}</TB>
                                </T>
                            </DetailHoursColumn>

                            <DetailHoursColumn>
                                <TB>{`${t("extras")} (${t("hours_unit_symbol")})`}</TB>
                                <T>
                                    {t("hall")}:{" "}
                                    <TB>
                                        {workHoursWithExtraHall}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                                <T>
                                    {t("out")}:{" "}
                                    <TB>
                                        {workHoursWithExtraOut}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                                <T>
                                    {t("shift")}:{" "}
                                    <TB>
                                        {tasksWithExtraShiftHours}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                            </DetailHoursColumn>
                        </FlexGap>
                    </DetailHoursRow>
                </WorkingHoursContainer>

                <AbsencesContainer>
                    <TotalHoursRow>
                        <TB>
                            {t("absence_hours")}: {totalAbsenceHours}
                        </TB>
                    </TotalHoursRow>
                    <DetailHoursRow>
                        <FlexGap $count={3}>
                            <DetailHoursColumn>
                                <TB>{`${t("absence")} (${t("hours_unit_symbol")})`} </TB>
                                <T>
                                    {t("vacation")}:{" "}
                                    <TB>
                                        {absenceHoursVacation}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                                <T>
                                    {t("time_off")}:{" "}
                                    <TB>
                                        {absenceHoursTimeOff}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                            </DetailHoursColumn>

                            <DetailHoursColumn>
                                <TB>&nbsp;</TB>
                                <T>
                                    {t("self_notification")}:{" "}
                                    <TB>
                                        {absenceHoursSelfNotification}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                                <T>
                                    {t("sick_child")}:{" "}
                                    <TB>
                                        {absenceHoursSickChild}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                                <T>
                                    {t("sick_leave")}:{" "}
                                    <TB>
                                        {absenceHoursSickLeave}&nbsp;{t("hours_unit_symbol")}
                                    </TB>
                                </T>
                            </DetailHoursColumn>

                            <DetailHoursColumn>
                                <TB>&nbsp;</TB>
                                <T>
                                    {t("paid_leave_of_absence")}:&nbsp;
                                    <TB>
                                        {absenceHoursPaidLeaveOfAbsence}&nbsp;
                                        {t("hours_unit_symbol")}
                                    </TB>
                                </T>

                                <T>
                                    {t("unpaid_leave_of_absence")}:&nbsp;
                                    <TB>
                                        {absenceHoursUnpaidLeaveOfAbsence}&nbsp;
                                        {t("hours_unit_symbol")}
                                    </TB>
                                </T>
                            </DetailHoursColumn>
                        </FlexGap>
                    </DetailHoursRow>
                </AbsencesContainer>
            </Body>
        </Container>
    );
};

export default TotalHoursTable;

const Container = styled.section`
    border: 2px solid ${(p) => p.theme.color.neutral.xlight};

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.section`
    background: ${(p) => p.theme.color.primary.xxlight};
    padding: 0.8rem;
`;

const Body = styled.section`
    display: flex;
`;

const DateText = styled(TB)`
    font-size: 1.25rem;
    margin-right: 2rem;
`;

const WorkingHoursContainer = styled.section`
    border-right: 2px solid ${(p) => p.theme.color.neutral.xlight};
    flex-grow: 1;
`;

const AbsencesContainer = styled.section`
    flex-grow: 1;
`;

const TotalHoursRow = styled.section`
    padding: 0.8rem;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};
`;

const DetailHoursRow = styled.section`
    padding: 0.8rem;
`;

const DetailHoursColumn = styled.section`
    padding-right: 1rem;
    ${TB}, ${T} {
        line-height: 1.8;
    }
`;

import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { T, TB } from "../../components/texts";
import { InputWithLabel } from "../../components/inputs";

const Password = () => {
    const { t } = useTranslation();
    const [change, setChange] = useState(false);
    const { watch, setError, clearErrors } = useFormContext();
    const new_password = watch("new_password");
    const new_password2 = watch("new_password2");

    useEffect(() => {
        if (new_password !== new_password2) {
            setError("new_password2", { type: "unequal", message: "Passordene er ikke like" });
        } else {
            clearErrors("new_password2");
        }
    }, [new_password, new_password2]);

    return (
        <PasswordStyled>
            <section className={"password"}>
                <T>{t("password", "Password")}</T>
                <T>********</T>
                {!change && (
                    <TB $link $underline onClick={() => setChange(true)}>
                        {t("change_password", "Change password")}
                    </TB>
                )}
                {change && (
                    <section className={"change"}>
                        <InputWithLabel
                            name={"password"}
                            type={"password"}
                            label={t("password", "Password")}
                        />
                        <InputWithLabel
                            name={"new_password"}
                            type={"password"}
                            validate={{ required: "Du må ha et passord", minLength: 4 }}
                            label={t("new_password", "New password")}
                        />
                        <InputWithLabel
                            name={"new_password2"}
                            type={"password"}
                            label={t("new_password_again", "New password again")}
                        />
                    </section>
                )}
            </section>
        </PasswordStyled>
    );
};

export default Password;

const PasswordStyled = styled.div`
    .change {
        width: 100%;
    }
    .password {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }
`;

import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";

export const useCompletedPreOrPostCheckTasks = (workshopId, statusList) => {
    let params = `?host_id=${workshopId}`;
    const host = Number(workshopId); // Ensure workshopId is a number

    // Define the query key
    const queryKey = ["tasks", "completed-pre-check", host];
    if (statusList?.length) {
        const joinedStatusList = statusList.join(",");
        params += `&status=${joinedStatusList}`;
        queryKey.push(joinedStatusList);
    }

    return useQuery(queryKey, () => taskAPI.getTasksWithCompletedPreOrPostCheck(params), {
        enabled: !!workshopId,
        refetchOnWindowFocus: false,
    });
};

import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";

import { device } from "../../../theme";

const InspectionSetupWithCustomer = () => {
    const { t } = useTranslation();

    return (
        <>
            <TabNav>
                <NavLink end to="">
                    {t("configurations")}
                </NavLink>
                <NavLink to={"categories"}>{t("categories_and_checkpoints")}</NavLink>
                <hr />
            </TabNav>
            <Outlet />
        </>
    );
};

export default InspectionSetupWithCustomer;

const TabNav = styled.nav`
    position: relative;
    margin: 2rem 0 4rem;

    a {
        position: relative;
        text-decoration: none;
        font-weight: bold;
        color: ${(p) => p.theme.color.primary.base};
        background: ${(p) => p.theme.color.primary.xlight};
        display: inline-block;
        padding: 0.8rem 1.3rem;
        margin-right: 0.4rem;
        border: 2px solid ${(p) => p.theme.color.primary.light};
        border-bottom: none;

        &.active {
            background: #fff;
            border-bottom: none;
            z-index: 1;
        }
    }

    hr {
        display: block;
        border: none;
        border-bottom: 2px solid ${(p) => p.theme.color.primary.light};
        margin: 0;
        position: absolute;
        bottom: 0;

        /* These values are based on the padding defined in the MainArea component */
        width: calc(100% + 5.625rem);
        transform: translate(-3.125rem);

        @media ${device.mobile} {
            width: calc(100% + 1.25rem);
            transform: translateX(-0.625rem);
        }
    }
`;

import { Fragment } from "react";
import { T } from "../texts";
import { flexRender } from "@tanstack/react-table";

const StandardTableRow = ({ row, renderRowSubComponent }) => {
    return (
        <Fragment>
            {row.depth > 0 ? (
                renderRowSubComponent ? (
                    renderRowSubComponent({ row })
                ) : null
            ) : (
                <tr>
                    {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                            <T>{flexRender(cell.column.columnDef.cell, cell.getContext())}</T>
                        </td>
                    ))}
                </tr>
            )}
        </Fragment>
    );
};

export default StandardTableRow;

import { useNavigate } from "react-router";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { device } from "../../theme";

import { LesserThanIcon } from "../icons";
import { T } from "../texts";
import CleanButton from "./CleanButton";

export const BackButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <BackButtonStyled onClick={() => navigate(-1)}>
            <Chevron />
            <T $link>{t("back", "Back")}</T>
        </BackButtonStyled>
    );
};

export default BackButton;

const Chevron = styled(LesserThanIcon)`
    margin-top: 4px;
    margin-right: 5px;
    margin-left: -2px;
    width: 16px;
    height: 16px;
`;

const BackButtonStyled = styled(CleanButton)`
    display: flex;
    flex-direction: row;
    margin-left: 3.125rem;
    margin-top: 1.25rem;

    @media ${device.mobile} {
        margin-left: 10px;
    }
`;

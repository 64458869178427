export const templates = {
    driver: ["unit_use"],
    mechanic: [
        "part_view_requests",
        "part_view_inventory",
        "part_view_storage_withdrawal",
        "unit_view_overview",
        "unit_view_status",
        "unit_view_groups",
        "unit_view_precheck",
        "scheduler_view_calendar",
        "scheduler_view_taskmanagement",
        "scheduler_view_recurring_tasks",
        "unit_admin",
        "scheduler_full",
        "mechanic",
        "part_admin",
        "part_checkout",
    ],
    scheduler: [
        "unit_view_overview",
        "unit_view_status",
        "unit_view_groups",
        "unit_view_precheck",
        "part_view_requests",
        "part_view_inventory",
        "part_view_storage_withdrawal",
        "scheduler_view_calendar",
        "scheduler_view_taskmanagement",
        "scheduler_view_recurring_tasks",
        "user_admin",
        "unit_admin",
        "unit_comment_admin",
        "unit_use",
        "scheduler_full",
        "part_checkout",
        "customer_admin",
    ],
    commentManager: [
        "unit_view_overview",
        "unit_view_status",
        "unit_view_groups",
        "unit_view_precheck",
        "unit_comment_email",
        "unit_comment_admin",
    ],
    vehicleCoordinator: [
        "unit_view_overview",
        "unit_view_status",
        "unit_view_groups",
        "unit_view_precheck",
        "unit_admin",
        "unit_comment_email",
        "unit_comment_admin",
        "unit_use",
        "scheduler_view_calendar",
        "scheduler_view_taskmanagement",
        "scheduler_view_recurring_tasks",
        "scheduler_read",
    ],
    departmentManager: [
        "user_admin",
        "scheduler_view_calendar",
        "scheduler_view_taskmanagement",
        "scheduler_view_recurring_tasks",
        "scheduler_read",
    ],
};

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Select from "react-select";

import { useUnitAreas } from "../unit/hooks/useUnitAreas";
import { useEffect, useMemo, useState } from "react";
import { T } from "../../components/texts";
import { useTasksWithAreaIds } from "./hooks/useTasksWithAreaIds";
import ActualCosts from "./ActualCosts";
import { TaskStatus } from "../../api/core/taskAPI";
import UnitCostRanking from "./UnitCostRanking";
import UnitStatusMap from "./UnitStatusMap";
import { CleanButton } from "../../components/buttons";
import { GreaterThanIcon, LesserThanIcon } from "../../components/icons";
import { add, endOfMonth, startOfMonth, startOfYear, sub } from "date-fns";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import { getDate, startOfYear as startOfYearLuxon } from "../../common/date";
import { DateTime } from "luxon";
import PreventativeTable from "./PreventativeTable";
import WorkordersTable from "./WorkordersTable";
import ProcurementsTable from "./ProcurementsTable";
import { useUnitsDashboard } from "./hooks/useUnitsDashboard";
import { UTCDate } from "@date-fns/utc";

const monthAndYear = (date) => {
    const dt = getDate(date);
    const dateString = dt.toLocaleString({ locale: "no", month: "short", year: "numeric" });

    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
};

const Dashboard = () => {
    const { t } = useTranslation();

    const [fromDate, setFromDate] = useState(startOfMonth(new UTCDate()));
    const selectedDate = useMemo(() => {
        return {
            from: fromDate,
            to: endOfMonth(fromDate),
        };
    }, [fromDate]);
    const { data: customers } = useCustomers();
    const dashboardUserConfig = useMemo(() => {
        return JSON.parse(window.localStorage.getItem("dashboard"));
    }, []);
    const [selectedCustomerId, setSelectedCustomerId] = useState(
        dashboardUserConfig != null ? dashboardUserConfig.selectedCustomerId : null
    );
    useEffect(() => {
        if (customers != null && customers.length === 1) {
            setSelectedCustomerId(customers[0].id);
        }
    }, [customers, setSelectedCustomerId]);

    const customerOptions = useMemo(() => {
        if (!customers) return [];

        return customers.map((customer) => ({
            label: customer.name,
            value: customer.id,
        }));
    }, [customers]);

    const [selectedAreas, setSelectedAreas] = useState(
        dashboardUserConfig != null ? dashboardUserConfig.selectedAreas : []
    );
    const { data: unitDefectHistory } = useUnitsDashboard({
        queryParamsObject: {
            area_ids: selectedAreas.map(({ value }) => value),
        },
        enabled: selectedAreas.length > 0,
    });

    useEffect(() => {
        window.localStorage.setItem(
            "dashboard",
            JSON.stringify({
                selectedCustomerId,
                selectedAreas,
            })
        );
    }, [selectedCustomerId, selectedAreas]);
    const { data: areas } = useUnitAreas(true);
    const selectedUnits = useMemo(() => {
        if (areas == null) return [];
        const selectedAreasMap = selectedAreas.reduce((acc, { value }) => {
            acc[value] = true;
            return acc;
        }, {});

        return areas.reduce((acc, curr) => {
            if (!selectedAreasMap[curr.id]) return acc;
            if (curr.units.length > 0) {
                acc.push(...curr.units);
            }
            return acc;
        }, []);
    }, [areas, selectedAreas]);
    const areaOptions = useMemo(() => {
        if (areas == null) return [];
        if (selectedCustomerId != null && selectedCustomerId.value != null) {
            const options = [
                ...areas
                    .filter((area) => area.customer_id === selectedCustomerId.value)
                    .map((area) => ({
                        label: area.name,
                        value: area.id,
                    })),
            ];
            options.unshift({
                label: t("select_all"),
                value: null,
            });
            return options;
        }

        const options = [
            ...areas.map((area) => ({
                label: area.name,
                value: area.id,
            })),
        ];
        options.unshift({
            label: t("select_all"),
            value: null,
        });
        return options;
    }, [areas, selectedCustomerId]);
    useEffect(() => {
        if (selectedCustomerId?.value == null) return;
        if (areas == null) return;
        const areaMap = new Map();
        for (const area of areas) {
            areaMap.set(area.id, area);
        }

        const filtered = [...selectedAreas].filter((areaOption) => {
            if (areaOption?.value == null) return false;
            const area = areaMap.get(areaOption.value);
            if (area == null) return false;
            return area.customer_id === selectedCustomerId.value;
        });
        setSelectedAreas(filtered);
    }, [selectedCustomerId, areas]);

    const areasQuery = useMemo(() => {
        if (selectedAreas == null) return [];
        return selectedAreas.map(({ value }) => Number(value));
    }, [selectedAreas]);
    const { data: areaTasks } = useTasksWithAreaIds(areasQuery);
    const data = useMemo(() => {
        if (areaTasks == null) return null;
        const now = new Date();
        const startOfThisYear = startOfYear(now);

        const unitCostsMap = new Map();
        if (selectedUnits != null) {
            for (const selectedUnit of selectedUnits) {
                unitCostsMap.set(selectedUnit.id, {
                    unit: selectedUnit,
                    hoursCost: 0,
                    materialsCost: 0,
                });
            }
        }

        return areaTasks.reduce(
            (acc, curr) => {
                switch (curr.status) {
                    case TaskStatus.REQUEST:
                        acc.workorders.requested++;
                        break;
                    case TaskStatus.SCHEDULED:
                        acc.workorders.scheduled++;
                        break;
                    case TaskStatus.STARTED:
                        acc.workorders.started++;
                        break;
                    case TaskStatus.COMPLETED:
                        acc.workorders.completed++;
                        break;
                    default:
                        break;
                }

                if (curr.parts && curr.parts.length > 0) {
                    for (const part of curr.parts) {
                        switch (curr.status) {
                            case TaskStatus.COMPLETED:
                            case TaskStatus.INVOICED:
                                acc.procurements.used++;
                                continue;
                            default:
                                break;
                        }

                        switch (part.status) {
                            case "ordered":
                                acc.procurements.ordered++;
                                break;
                            case "arrived":
                                acc.procurements.arrived++;
                                break;
                            case null:
                            case undefined:
                                acc.procurements.requested++;
                                break;
                            default:
                                break;
                        }
                    }
                }

                const addToUnitCost = (task) => {
                    const unitCost = (() => {
                        const existingCost = acc.unitCostsMap.get(task.unit.id);
                        if (existingCost) return existingCost;

                        const unitCost = {
                            unit: task.unit,
                            hoursCost: 0,
                            materialsCost: 0,
                        };
                        acc.unitCostsMap.set(task.unit.id, unitCost);
                        return unitCost;
                    })();
                    unitCost.materialsCost += task.parts_cost;
                    unitCost.hoursCost += task.invoice_total - task.parts_cost;
                };

                const extractServiceDetails = (task) => {
                    try {
                        const { task_check_template } = task;
                        if (task_check_template === "{}") return undefined;
                        const parsedTaskCheckTemplate =
                            typeof task_check_template === "string"
                                ? JSON.parse(task_check_template)
                                : task_check_template;
                        return {
                            findings: parsedTaskCheckTemplate.schema.elements.reduce(
                                (acc, groupElement) =>
                                    acc +
                                    groupElement.elements?.filter((element) => {
                                        const service = element.values?.service;
                                        return service && service !== "1";
                                    }).length,
                                0
                            ),
                            interval: parsedTaskCheckTemplate.interval,
                            defects: parsedTaskCheckTemplate.schema.elements.reduce(
                                (acc, groupElement) =>
                                    acc +
                                    groupElement.elements?.filter((element) => {
                                        const service = element.values?.service;
                                        return service && service === "3";
                                    }).length,
                                0
                            ),
                        };
                    } catch (err) {
                        return undefined;
                    }
                };

                const extractPeriodicTechnicalInspectionDetails = (task) => {
                    try {
                        const { task_check_template } = task;
                        if (task_check_template === "{}") return undefined;
                        const parsedTaskCheckTemplate =
                            typeof task_check_template === "string"
                                ? JSON.parse(task_check_template)
                                : task_check_template;
                        return {
                            findings: parsedTaskCheckTemplate.schema.elements.reduce(
                                (acc, groupElement) =>
                                    acc +
                                    groupElement.elements?.filter((element) => {
                                        const service = element.values?.service?.value;
                                        switch (service) {
                                            case "0": // - 0 - Checked and found in order
                                            case "OK":
                                            case "M": // M - Not found
                                            case "U": // U - Fixed
                                            case "NA": // NA - Not applicable
                                            case "-": // - - Not applicable
                                            case null: // Left empty
                                            case undefined: // Left empty
                                                return false;
                                            default:
                                                return true;
                                        }
                                    }).length,
                                0
                            ),
                            defects: parsedTaskCheckTemplate.schema.elements.reduce(
                                (acc, groupElement) =>
                                    acc +
                                    groupElement.elements?.filter(
                                        (element) =>
                                            element.values?.service?.value === "NC" ||
                                            element.values?.service?.value === "3"
                                    ).length,
                                0
                            ),
                        };
                    } catch (err) {
                        return undefined;
                    }
                };

                if (curr.end == null) return acc;
                const endDate = new Date(curr.end);
                if (endDate > now) return acc;
                if (endDate > selectedDate.from && endDate < selectedDate.to) {
                    acc.estimates.month.materialsCost += curr.parts_cost;
                    acc.estimates.month.hoursCost += curr.invoice_total - curr.parts_cost;

                    if (curr.type_id === 2) {
                        // Service task?
                        const extractedData = extractServiceDetails(curr);
                        if (extractedData != null) {
                            const { interval, findings, defects } = extractedData;
                            if (interval === 0) {
                                // Basic inspection?
                                acc.preventativeData.inspections.month.count++;
                                if (findings > 0) {
                                    acc.preventativeData.inspections.month.findings++;
                                    acc.preventativeData.inspections.month.defects += defects;
                                    acc.preventativeData.inspections.month.totalFindings +=
                                        findings;
                                }
                            } else {
                                acc.preventativeData.services.month.count++;
                                if (findings > 0) {
                                    acc.preventativeData.services.month.findings++;
                                    acc.preventativeData.services.month.defects += defects;
                                    acc.preventativeData.services.month.totalFindings += findings;
                                }
                            }
                        }
                    } else if (curr.type_id === 3) {
                        // Periodic technical inspection task?
                        acc.preventativeData.periodicTechnicalInspections.month.count++;
                        const extractedData = extractPeriodicTechnicalInspectionDetails(curr);
                        if (extractedData != null) {
                            const { findings, defects } = extractedData;
                            if (findings > 0) {
                                acc.preventativeData.periodicTechnicalInspections.month.findings++;
                                acc.preventativeData.periodicTechnicalInspections.month.defects +=
                                    defects;
                                acc.preventativeData.periodicTechnicalInspections.month.totalFindings +=
                                    findings;
                            }
                        }
                    }

                    addToUnitCost(curr);
                }

                if (endDate > startOfThisYear && endDate < now) {
                    acc.estimates.thisYear.materialsCost += curr.parts_cost;
                    acc.estimates.thisYear.hoursCost += curr.invoice_total - curr.parts_cost;
                }

                return acc;
            },
            {
                estimates: {
                    thisYear: {
                        hoursCost: 0,
                        materialsCost: 0,
                    },
                    month: {
                        hoursCost: 0,
                        materialsCost: 0,
                    },
                },
                preventativeData: {
                    periodicTechnicalInspections: {
                        month: {
                            count: 0,
                            findings: 0,
                            defects: 0,
                            totalFindings: 0,
                        },
                    },
                    services: {
                        month: {
                            count: 0,
                            findings: 0,
                            defects: 0,
                            totalFindings: 0,
                        },
                    },
                    inspections: {
                        month: {
                            count: 0,
                            findings: 0,
                            defects: 0,
                            totalFindings: 0,
                        },
                    },
                },
                workorders: {
                    requested: 0,
                    scheduled: 0,
                    started: 0,
                    completed: 0,
                },
                procurements: {
                    requested: 0,
                    ordered: 0,
                    arrived: 0,
                    used: 0,
                },
                unitCostsMap,
            }
        );
    }, [areaTasks, selectedDate, selectedUnits]);

    const downtimes = useMemo(() => {
        if (unitDefectHistory == null) return undefined;

        const now = DateTime.local();
        const startOfThisYear = startOfYearLuxon(now);
        return unitDefectHistory.reduce(
            (acc, curr) => {
                if (
                    curr.year >= selectedDate.from.getUTCFullYear() &&
                    curr.year <= selectedDate.to.getUTCFullYear() &&
                    curr.month >= selectedDate.from.getUTCMonth() + 1 &&
                    curr.month <= selectedDate.to.getUTCMonth() + 1
                ) {
                    acc.unitDowntimeMap.set(
                        curr.unit_id,
                        (acc.unitDowntimeMap.get(curr.unit_id) ?? 0) + curr.downtime
                    );
                    acc.month += curr.downtime;
                }

                if (
                    curr.year >= startOfThisYear.year &&
                    curr.year <= now.year &&
                    curr.month >= startOfThisYear.month &&
                    curr.month <= now.month
                ) {
                    acc.thisYear += curr.downtime;
                }

                return acc;
            },
            {
                month: 0,
                thisYear: 0,
                unitDowntimeMap: new Map(),
            }
        );
    }, [unitDefectHistory, selectedDate]);

    const hoursThisMonth = useMemo(() => {
        if (selectedDate == null) return 0;

        const now = new Date();
        return Math.abs((now > selectedDate.to ? selectedDate.to : now) - selectedDate.from) / 36e5;
    }, [selectedDate]);

    return (
        <div>
            <DashboardHeader>
                <div>
                    <label htmlFor="month">
                        <T>{t("month")}</T>
                    </label>
                    <MonthSelectContainer>
                        <TimeNav>
                            <CleanButton
                                onClick={() =>
                                    setFromDate((currentDate) => sub(currentDate, { months: 1 }))
                                }
                            >
                                <LesserThanIcon className="lt" />
                            </CleanButton>
                            <div>{monthAndYear(fromDate)}</div>
                            <CleanButton
                                onClick={() =>
                                    setFromDate((currentDate) => add(currentDate, { months: 1 }))
                                }
                            >
                                <GreaterThanIcon className="gt" />
                            </CleanButton>
                        </TimeNav>
                    </MonthSelectContainer>
                </div>
                {customers != null && customers.length > 1 ? (
                    <div>
                        <label htmlFor="customers">
                            <T>{t("customers")}</T>
                        </label>
                        <SelectContainer>
                            <Select
                                name="customers"
                                options={customerOptions}
                                placeholder={t("customer")}
                                classNamePrefix="rs"
                                noOptionsMessage={() => t("no_results")}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                onChange={(e) => setSelectedCustomerId(e)}
                                value={selectedCustomerId}
                            />
                        </SelectContainer>
                    </div>
                ) : null}
                <div>
                    <label htmlFor="areas">
                        <T>{t("areas")}</T>
                    </label>
                    <SelectContainer>
                        <Select
                            name="areas"
                            options={areaOptions}
                            placeholder={t("area")}
                            classNamePrefix="rs"
                            noOptionsMessage={() => t("no_results")}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            onChange={(e) => {
                                if (e.some(({ value }) => value === null)) {
                                    console.log(areaOptions);
                                    return setSelectedAreas(
                                        areaOptions.filter(({ value }) => value !== null)
                                    );
                                } else {
                                    return setSelectedAreas(e);
                                }
                            }}
                            value={selectedAreas}
                            closeMenuOnSelect={false}
                            isMulti
                        />
                    </SelectContainer>
                </div>
            </DashboardHeader>
            <MainContainer>
                <VerticalFlexbox>
                    <ActualCosts
                        data={
                            data != null && downtimes != null
                                ? {
                                      estimates: data.estimates,
                                      downtimes,
                                  }
                                : null
                        }
                        hoursThisMonth={hoursThisMonth}
                        count={selectedUnits.length}
                    />
                    <UnitCostRanking
                        unitCostsMap={data?.unitCostsMap}
                        downtimes={downtimes}
                        hoursThisMonth={hoursThisMonth}
                    />
                    <PreventativeTable preventativeData={data?.preventativeData} />
                    <WorkordersTable workorders={data?.workorders} />
                    <ProcurementsTable procurements={data?.procurements} />
                </VerticalFlexbox>
                <UnitStatusMap units={selectedUnits} />
            </MainContainer>
        </div>
    );
};

export default Dashboard;

const TimeNav = styled.section`
    display: grid;
    grid-template-columns: auto 5rem auto;
    gap: 0.75rem;
    text-align: center;
`;

const MonthSelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

const MainContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 1rem 1rem 0 1rem;

    & > div {
        flex: 1;
    }
`;

const VerticalFlexbox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
`;

const SelectContainer = styled.div`
    max-width: 50rem;
    min-width: 15rem;

    .rs__control,
    .rs__control:hover {
        border: 2px solid #6d8ac5 !important;
        box-shadow: unset;
        border-radius: 0;
    }

    .rs__control--is-disabled {
        background-color: #eee;
    }

    .rs__single-value--is-disabled {
        color: ${(p) => p.theme.color.neutral.base};
    }

    .rs__multi-value__label,
    .rs__multi-value__remove {
        font-size: 1rem;
        font-weight: bold;
        color: ${(p) => p.theme.color.primary.base};
        background: ${(p) => p.theme.color.primary.xlight};
    }

    .rs__multi-value__label {
        padding: 0.4rem;
        padding-left: 0.6rem;
        padding-right: 0.2rem;
    }

    .rs__multi-value__remove {
        &:hover {
            color: ${(p) => p.theme.color.primary.base};
            background: ${(p) => p.theme.color.primary.xxlight};
        }
        > svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
`;

const DashboardHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem;
    background-color: ${(props) => props.theme.color.primary.xlight};
`;

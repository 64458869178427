import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useMedia } from "../media/hooks/useMedia";

import { TB } from "../../components/texts";
import { CleanButton } from "../../components/buttons";
import LoadingSpinner from "../../components/layout/LoadingSpinner";

const SignatureImagePreview = ({ imageId }) => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();

    const { data: imageData, isLoading } = useMedia(imageId);

    function handleRemovePhoto() {
        const wantsToRemove = window.confirm(t("confirm_removing_photo"));

        if (wantsToRemove) {
            setValue("signature_media_id", null, { shouldDirty: true });
        }
    }

    return isLoading ? (
        <LoadingSpinner small light />
    ) : (
        <ImageContainer>
            <a href={imageData?.uri} target="_blank" rel="noreferrer noopener">
                <Image src={`${imageData?.uri}_thumbnail`} width={88} height={88} />
            </a>

            <RemoveImageButton type="button" onClick={handleRemovePhoto}>
                <TB $link>x</TB>
            </RemoveImageButton>
        </ImageContainer>
    );
};

export default SignatureImagePreview;

const ImageContainer = styled.section`
    position: relative;
    width: 5.5rem;
    height: 5.5rem;
    margin-top: 1rem;
    border: 2px solid ${(props) => props.theme.color.primary.light};
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;

const RemoveImageButton = styled(CleanButton)`
    position: absolute;
    background: #fff;
    right: 0rem;
    padding: 0 0.3rem;

    ${TB} {
        font-size: 0.8rem;
    }
`;

import { useEffect, useState, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useInspectionConfigsForCustomer } from "../admin/inspection/hooks/useInspectionConfigsForCustomer";
import { useInspectionConfigTemplates } from "../admin/inspection/hooks/useInspectionConfigTemplates";
import { SelectForm } from "../../components/inputs";

const InspectionSelector = ({ handleChange, isDisabled }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const { t } = useTranslation();
    const { watch } = useFormContext();

    const customerId = watch("customer_id");
    const task_check_template = watch("task_check_template");

    const templateConfigs = useInspectionConfigTemplates();
    const customerConfigs = useInspectionConfigsForCustomer(customerId?.value);

    const options = useMemo(() => {
        if (!customerId?.value || !customerConfigs?.data || !templateConfigs?.data) return [];

        const allConfigs =
            customerId.value === 1
                ? customerConfigs.data
                : customerConfigs.data.concat(templateConfigs.data);

        return allConfigs.map((config) => ({
            value: config.id,
            label: `${config.name}${config.customer_id === 1 ? ` (${t("system_template")})` : ""}`,
            inspection: config,
        }));
    }, [customerId, customerConfigs?.data, templateConfigs?.data]);

    useEffect(() => {
        if (!options?.length || !task_check_template) return;

        const preselectedConfig = options.find(
            (item) => item.value === task_check_template.configurationId
        );

        if (preselectedConfig) {
            setSelectedOption(preselectedConfig);
            handleChange(preselectedConfig);
        }
    }, [options, task_check_template]);

    function onChange(value) {
        handleChange(value);
        setSelectedOption(value);
    }

    return (
        <SelectForm
            name="inspection-config"
            label={t("choose_config")}
            options={options}
            onChange={onChange}
            isDisabled={isDisabled}
            value={selectedOption}
            isClearable
        />
    );
};

export default InspectionSelector;

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import NavBar from "../../components/navbar/NavBar";
import AbsenceFormContainer from "./AbsenceFormContainer";
import ExtrasFormContainer from "./ExtrasFormContainer";

const AbsenceEditContainer = ({
    onClose,
    workshopId,
    absenceId,
    extraId,
    currentlySelectedIndex,
    setCurrentlySelectedIndex,
}) => {
    const { t } = useTranslation();
    return (
        <NavBar
            currentlySelectedIndex={currentlySelectedIndex}
            setCurrentlySelectedIndex={setCurrentlySelectedIndex}
            options={[
                {
                    name: t("absence"),
                    element: (
                        <Container>
                            <AbsenceFormContainer
                                onClose={onClose}
                                workshopId={workshopId}
                                absenceId={absenceId}
                            />
                        </Container>
                    ),
                },
                {
                    name: t("extras"),
                    element: (
                        <Container>
                            <ExtrasFormContainer
                                onClose={onClose}
                                workshopId={workshopId}
                                extraId={extraId}
                            />
                        </Container>
                    ),
                },
            ]}
        />
    );
};

export default AbsenceEditContainer;

const Container = styled.article`
    padding-top: 1rem;
    background-color: ${(p) => p.theme.color.primary.xxlight};
`;

import { fetchData, url2data } from "../fetch";

const fetchAll = async () => fetchData(`core/features`);
const fetchForCustomer = async (id) => fetchData(`core/features/${id}`);
const updateForCustomer = async ({ data, id }) => url2data(`core/features/${id}`, data);
const fetchAllAvailable = async () => fetchData(`core/features/list`);

export default {
    fetchAll,
    fetchForCustomer,
    updateForCustomer,
    fetchAllAvailable,
};

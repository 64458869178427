import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import taskPreChecksAPI from '../../../../api/core/taskPreChecksAPI';

export const useDeleteTaskPreCheckCheckpoint = ({ onSuccess }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(({customerId, id}) => taskPreChecksAPI.deleteCheckpoint(customerId, id), {
        onSuccess: () => {
            if (onSuccess instanceof Function) onSuccess();

            toast.success(t("checkpoint_removed"));
            queryClient.invalidateQueries(["task-pre-checks"]);
        },
        onError: (error) => {
            toast.error(`Error: ${error.message || "unknown"}`);
            console.log("Delete error: ", error);
        },
    });
};

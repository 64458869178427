import { useEffect, useState } from "react";
import { BehaviorSubject, merge, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { useFormContext } from "react-hook-form";

const useServerLookupBoolean = ({ name, apiCall, errorType = "taken", errorMessage }) => {
    const {
        watch,
        setError,
        formState: { dirtyFields },
    } = useFormContext();
    const [subject, setSubject] = useState(null);
    const data = watch(name);

    useEffect(() => {
        if (subject === null) {
            const sub = new BehaviorSubject("");
            setSubject(sub);
        } else {
            const observable = subject
                .pipe(
                    map((s) => s.trim()),
                    distinctUntilChanged(),
                    debounceTime(200),
                    switchMap((term) => merge(of(term.length > 0 && apiCall(term)))),
                    catchError((e) => ({
                        loading: false,
                        errorMessage: "An application error occured",
                    }))
                )
                .subscribe(async (result) => {
                    const data = (await result).data;
                    if (data) {
                        setError(name, { type: errorType, message: errorMessage });
                    }
                });

            return () => {
                observable.unsubscribe();
                subject.unsubscribe();
            };
        }
    }, [subject]);

    useEffect(() => {
        if (name in dirtyFields && data) {
            subject.next(data);
        }
    }, [data]);

    return true;
};

export default useServerLookupBoolean;

import { useMutation } from "react-query";
import toast from "react-hot-toast";
import taskAPI from "../../../api/core/taskAPI";

export const useDistributeTasks = ({ onSuccess }) => {
    return useMutation((data) => taskAPI.postDistributeTasks(data), {
        onSuccess: ({ data }) => {
            if (onSuccess instanceof Function) onSuccess(data);
        },
        onError: (error) => {
            toast.error(
                `Det oppsto en feil ved fordeling av oppgaver: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Saving error: ", error.response?.data?.message || error.message);
        },
    });
};

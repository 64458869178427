import styled from "styled-components";

export const CleanButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-family: inherit;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

export default CleanButton;

import { useEffect, useState, useMemo } from "react";
import {
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useUsers } from "./hooks/useUsers";
import { useCustomers } from "../customers/hooks/useCustomers";
import useMedia from "../../../components/helpers/hooks/useMedia";
import QueryWrapper from "../../../components/application/QueryWrapper";

import { T, TB } from "../../../components/texts";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import StandardTableContent from "../../../components/application/StandardTableContent";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const CustomerUsers = ({ showInactive, search }) => {
    const [data, setData] = useState([]);
    const { t } = useTranslation();
    const { isMobile } = useMedia();

    const users = useUsers();
    const customers = useCustomers();

    const columns = useMemo(
        () =>
            isMobile
                ? [
                      {
                          header: t("firstname"),
                          accessorKey: "first_name",
                      },
                      {
                          header: t("lastname"),
                          accessorKey: "last_name",
                      },
                      {
                          id: "edit",
                          cell: ({ row }) => (
                              <EditLink to={`/administration/users/${row.original.id}/edit`}>
                                  <TB $link>{t("edit")}</TB>
                              </EditLink>
                          ),
                          enableSorting: false,
                      },
                  ]
                : [
                      {
                          header: t("firstname"),
                          accessorKey: "first_name",
                      },
                      {
                          header: t("lastname"),
                          accessorKey: "last_name",
                      },
                      {
                          header: t("email"),
                          accessorKey: "email",
                          cell: ({ getValue }) => {
                              const value = getValue();
                              return (
                                  <a href={`mailto:${value}`}>
                                      <T $link>{value}</T>
                                  </a>
                              );
                          },
                      },
                      {
                          header: t("customer"),
                          accessorKey: "customer",
                      },
                      {
                          id: "edit",
                          cell: ({ row }) => (
                              <EditLink to={`/administration/users/${row.original.id}/edit`}>
                                  <TB $link>{t("edit")}</TB>
                              </EditLink>
                          ),
                          enableSorting: false,
                      },
                  ],
        [isMobile]
    );

    const initialState = useMemo(() => ({ pagination: { pageSize: 15 } }), []);
    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    useEffect(() => {
        if (users.data && customers.data) {
            setData(
                users.data
                    .filter((user) => (showInactive ? !user.active : user.active))
                    .filter((user) => !user.employee)
                    .map((user) => {
                        return {
                            ...user,
                            customer:
                                customers.data.find((item) => item.id === user.customer_id)?.name ||
                                "Ukjent",
                        };
                    })
            );
        }
    }, [users?.data, customers?.data, showInactive]);

    return (
        <QueryWrapper data={[users, customers]}>
            {rowModel.rows.length > 0 ? (
                <TableContainer>
                    <TableStyled>
                        <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                    </TableStyled>
                </TableContainer>
            ) : (
                <NoUsers>
                    <TB>{t("no_users_to_show")}</TB>
                </NoUsers>
            )}

            <TablePaginationNav
                pageCount={table.getPageCount()}
                previousPage={table.previousPage}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                canNextPage={table.getCanNextPage()}
                pageOptions={table.getPageOptions()}
                gotoPage={table.setPageIndex}
                pageIndex={table.getState().pagination.pageIndex}
            />
        </QueryWrapper>
    );
};

export default CustomerUsers;

const EditLink = styled(Link)`
    text-decoration: none;
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 2rem;
`;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
            min-width: 3.5rem;
            max-width: 3.5rem;
        }
    }
`;

const NoUsers = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms 100ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

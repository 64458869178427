import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 22px;
    height: 30px;
`;

export const TrashIcon = ({ stroke }) => (
    <Svg width="22" height="32" viewBox="0 0 22 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.25781 4.3871V1H14.032V4.3871"
            stroke={stroke || "#38558D"}
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M1.93555 5.35449V30.9997H19.3549V5.35449"
            stroke={stroke || "#38558D"}
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M7.74219 10.1934V26.645"
            stroke={stroke || "#38558D"}
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M13.5488 10.1934V26.645"
            stroke={stroke || "#38558D"}
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M0 4.87109H21.2903"
            stroke={stroke || "#38558D"}
            strokeWidth="2"
            strokeMiterlimit="10"
        />
    </Svg>
);

import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import userAPI from "../../../../api/core/userAPI";

export const useSaveUser = ({ onSuccess }) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation((data) => userAPI.updateUser(data), {
        onSuccess: (response) => {
            queryClient.invalidateQueries("users");
            queryClient.invalidateQueries(["roles", "user", response.data?.id]);

            if (onSuccess instanceof Function) onSuccess();
        },
        onError: (error) => {
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            );

            console.log("Saving error: ", error.response?.data?.message || error.message);
        },
    });
};

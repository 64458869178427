import styled from "styled-components";

const columns = 12;

const getWidthString = (span) => {
    if (!span) return;

    const width = (span / columns) * 100;
    return `width: ${width}%;`;
};

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Col = styled.div`
    flex: ${(props) => (props.size ? props.size : 1)};
    ${({ xs }) => (xs ? getWidthString(xs) : "width: 100%")};

    @media only screen and (min-width: 768px) {
        ${({ sm }) => sm && getWidthString(sm)};
    }

    @media only screen and (min-width: 1024px) {
        ${({ md }) => md && getWidthString(md)};
    }

    @media only screen and (min-width: 1440px) {
        ${({ lg }) => lg && getWidthString(lg)};
    }
`;

export const Vertical = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
`;

export const Horizontal = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0;
`;

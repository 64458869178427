import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import axios from "axios";

export const useCompanyDataFromBrreg = (orgnr) => {
    const { t } = useTranslation();

    return useQuery(
        ["brreg", orgnr],
        () =>
            axios
                .get(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`, {
                    credentials: "omit",
                    withCredentials: false,
                })
                .then((response) => response.data),
        {
            enabled: false,
            cacheTime: 0,
            retry: 1,
            onError: (error) => {
                if (error?.response?.status === 404) {
                    toast.error(t("wrong_request"));
                } else if (error?.response?.data) {
                    toast.error(
                        `${t("error")}: ${
                            error.response.data.valideringsfeil[0]?.feilmelding ||
                            error.response.data.feilmelding ||
                            error.response.data.message ||
                            error.response.statusText ||
                            t("unknown_error")
                        }`
                    );
                }
            },
        }
    );
};

import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { T } from "../../components/texts";

export const InputCheckbox = ({
    label,
    name,
    size = 20,
    className,
    disabled = false,
    ...props
}) => {
    const { register, watch, setValue } = useFormContext();
    const [checked, setChecked] = useState(false);
    const value = watch(name);

    useEffect(() => {
        if (value !== checked) setChecked(value);
    }, [value]);

    return (
        <InputStyled>
            <CheckboxContainer
                className={className}
                onClick={() => !disabled && setValue(name, !checked, { shouldDirty: true })}
            >
                <HiddenCheckbox {...register(name)} {...props} />
                <StyledCheckbox checked={checked} size={size} disabled={disabled}>
                    <Icon
                        width={size - 6}
                        height={size - 6}
                        viewBox="0 0 12 13"
                        size={size - 6}
                        disabled={disabled}
                    >
                        <path d="M1 6 L5.5 10.5 L10.5 2" stroke="white" strokeWidth="2" />
                    </Icon>
                </StyledCheckbox>

                {label ? (
                    <label className="noselect" htmlFor={name}>
                        <T>{label}</T>
                    </label>
                ) : null}
            </CheckboxContainer>
        </InputStyled>
    );
};

export default InputCheckbox;

const InputStyled = styled.div`
    display: flex;
    flex-direction: row;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    label {
        margin-left: 7px;
        cursor: pointer;
    }
`;

const Icon = styled.svg`
    fill: none;
    stroke: ${(props) => props.theme.color.primary.light};
    stroke-width: 2px;
    stroke-opacity: ${(props) => (props.disabled ? "0.3" : "1")};
    vertical-align: top;
    width: ${(props) => (props.size ? props.size : "14")}px;
    height: ${(props) => (props.size ? props.size : "14")}px;
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div`
    display: inline-block;
    width: ${(props) => (props.size ? props.size : 14)}px;
    height: ${(props) => (props.size ? props.size : 14)}px;
    background: ${(props) => (props.checked ? props.theme.color.primary.base : "white")};
    border: 2px solid ${(props) => props.theme.color.primary.light};
    transition: all 150ms;
    background-clip: content-box;
    padding: 1px;
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px #55c;
    }

    ${Icon} {
        visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    }
`;

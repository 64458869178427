import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ReactModal from "react-modal";

import { Button, CleanButton } from "../buttons";
import { T, TB } from "../texts";
import LoadingSpinner from "../layout/LoadingSpinner";

const ConfirmModal = ({ isModalOpen, closeModal, onConfirm, text, isLoading }) => {
    const { t } = useTranslation();

    return (
        <ModalStyled
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className={"Modal"}
            overlayClassName={"ModalOverlay"}
        >
            <Text>{text}</Text>

            <BottomLine>
                <SpinnerContainer>{isLoading && <LoadingSpinner small />}</SpinnerContainer>

                <Buttons>
                    <SaveButton onClick={onConfirm}>{t("yes")}</SaveButton>

                    <CancelButton onClick={closeModal}>
                        <TB $underline $link>
                            {t("cancel")}
                        </TB>
                    </CancelButton>
                </Buttons>
            </BottomLine>
        </ModalStyled>
    );
};

export default ConfirmModal;

const ModalStyled = styled(ReactModal)`
    margin-top: 12rem;
    padding: 2rem 3rem 2rem;
    max-width: 35rem;
    height: max-content;
`;

const Text = styled(T)`
    display: block;
`;

const BottomLine = styled.article`
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const SpinnerContainer = styled.div`
    min-height: 3.3rem;
    padding: 1rem;
`;

const Buttons = styled.section``;

const SaveButton = styled(Button)`
    margin-right: 1.5rem;
    font-weight: bold;
`;

const CancelButton = styled(CleanButton)``;

import { useMemo } from "react";
import { useQuery } from "react-query";
import authorizationAPI from "../../../../api/core/authorizationAPI";

export const useCustomerRoles = (customerId) => {
    const query = useQuery(["roles", "customer", customerId], authorizationAPI.getRoles, {
        enabled: !!customerId,
    });

    return {
        ...query,
        data: useMemo(() =>
            query.data?.filter((item) => item.customer_id === customerId, [query.data, customerId])
        ),
    };
};

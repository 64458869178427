import { useEffect, useState } from "react";
import { BehaviorSubject, merge, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";

const useServerLookupInlineBoolean = ({
    name,
    apiCall,
    errorType = "taken",
    errorMessage,
    methods,
}) => {
    const {
        watch,
        setError,
        clearErrors,
        formState: { dirtyFields },
    } = methods;
    const [subject, setSubject] = useState(null);
    const input = watch(name);

    useEffect(() => {
        if (subject === null) {
            const sub = new BehaviorSubject("");
            setSubject(sub);
        } else {
            const observable = subject
                .pipe(
                    map((s) => s.trim()),
                    distinctUntilChanged(),
                    debounceTime(200),
                    switchMap((term) => merge(of(apiCall(term)))),
                    catchError((e) => ({
                        loading: false,
                        errorMessage: "An application error occured",
                    }))
                )
                .subscribe(async (result) => {
                    const response = (await result).data;
                    if (response) {
                        setError(name, { type: errorType, message: errorMessage });
                    } else {
                        clearErrors(name);
                    }
                });

            return () => {
                observable.unsubscribe();
            };
        }
    }, [subject]);

    useEffect(() => {
        if (name in dirtyFields) subject.next(input);
    }, [input]);

    return true;
};

export default useServerLookupInlineBoolean;

import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { standardDate, standardDateOnly } from "../../common/date";
import { ClockIcon, DescriptionIcon, FlagIcon, MoneyIcon, ErrorIcon } from "../../components/icons";

const TOOLTIP_WIDTH = 375;

const TaskTooltip = ({
    task,
    hovered,
    tooltipRenderTarget,
    tooltipParentTarget,
    explicitTooltipPlacement,
    shouldAlertAboutHighCost,
    shouldDisplayPartsStatus,
    taskPartsStatus,
    latestEtaForParts,
}) => {
    const { t } = useTranslation();

    const contact_person = task.unit?.area?.contact_person || task.customer?.contact_person;
    let description = t("no_description");
    if (task.description) {
        description = task.description;
    } else if (task.type_id === 2) {
        description = t("task_type.service");
    } else if (task.type_id === 3) {
        description = t("task_type.periodic_technical_inspection");
    }

    return (
        <TooltipPortal
            hovered={hovered}
            renderTarget={tooltipRenderTarget}
            placement={tooltipPosition(
                tooltipParentTarget.current,
                explicitTooltipPlacement && explicitTooltipPlacement()
            )}
        >
            <LineStyled>{`${task.unit?.int_id || ""} - ${task.unit?.group?.name || ""} - ${task.unit?.manufacturer || ""} - ${
                task.unit?.type || ""
            }`}</LineStyled>

            <LineStyled>
                {`${task.customer?.name || t("no_customer_name")} - ${
                    task.unit?.area?.name || t("no_useArea")
                } - ${contact_person?.name || t("no_contact_person")}${
                    contact_person?.mobile ? `, tlf. ${contact_person.mobile}` : ""
                }`}
                <TooltipIcons>
                    {task.priority === "high" && <FlagIcon />}
                    {shouldAlertAboutHighCost && <MoneyIcon />}
                </TooltipIcons>
            </LineStyled>

            <MainLineStyled>
                <span>
                    <span className="icon">
                        <DescriptionIcon />
                    </span>
                    <span>{description}</span>
                </span>
                <span>
                    <span className="icon">
                        <ClockIcon />
                    </span>
                    <span>{`${typeof task.duration === "number" ? task.duration : "-"}t`}</span>
                </span>

                {shouldDisplayPartsStatus && (
                    <span>
                        <span className="icon">
                            <ErrorIcon />
                        </span>
                        {taskPartsStatus === "ordered"
                            ? `${t("awaiting_parts")}. ${t("eta")}: ${
                                  latestEtaForParts ? standardDateOnly(latestEtaForParts) : "–"
                              }`
                            : t("parts_missing")}
                    </span>
                )}
            </MainLineStyled>

            <LineStyled>{`${t("created")} ${standardDate(task.createdAt)} ${t("by")} ${task.owner ? `${task.owner?.first_name || ""} ${task.owner?.last_name || ""}` : t("system")}`}</LineStyled>
        </TooltipPortal>
    );
};

export default TaskTooltip;

function tooltipPosition({ offsetHeight, offsetLeft, offsetTop }, explicitPlacement) {
    const { offsetWidth } = document.body;
    let left = explicitPlacement?.left || offsetLeft;

    left = left < 0 ? 0 : left > offsetWidth - TOOLTIP_WIDTH ? left - TOOLTIP_WIDTH : left;

    return {
        left:
            left < 0 ? 0 : left > offsetWidth - TOOLTIP_WIDTH ? offsetWidth - TOOLTIP_WIDTH : left,
        top: (explicitPlacement?.top || offsetTop) + offsetHeight,
    };
}

const TooltipPortal = ({ renderTarget, placement, children, hovered }) =>
    (renderTarget.current &&
        ReactDOM.createPortal(
            <TooltipStyled
                style={{
                    ...placement,
                    visibility: hovered ? "visible" : "hidden",
                    opacity: hovered ? "1" : "0",
                    transform: hovered ? `translate(4rem, -60%)` : `translate(4rem, -55%)`,
                }}
                children={children}
            />,
            renderTarget.current
        )) ||
    null;

const TooltipStyled = styled.div`
    min-height: 5rem;
    width: calc(${TOOLTIP_WIDTH}px - 4rem);
    background: #fff;
    position: absolute;
    border: 2px solid ${(p) => p.theme.color.primary.xlight};
    padding: 0.5rem;
    font-size: 0.875rem;
    z-index: 11;
    pointer-events: none;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    visibility: hidden;
    transform: translate(4rem, -55%);
    opacity: 0;
    transition:
        opacity 200ms ease,
        transform 200ms ease;
`;

const LineStyled = styled.p`
    margin: 0.2rem 0;
    line-height: 1.5;
`;

const MainLineStyled = styled.section`
    display: flex;
    flex-direction: column;
    max-width: 18rem;
    line-height: 1.5;
    font-size: 1rem;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    > span {
        display: flex;
    }

    .icon {
        position: relative;
        top: 0.15rem;
        margin-right: 0.6rem;

        svg {
            height: 1.25rem;
            width: 1.25rem;
        }
    }
`;

const TooltipIcons = styled.span`
    display: inline-flex;

    > svg {
        margin-left: 0.3rem;
        height: 1rem;
        width: 1rem;
    }
`;

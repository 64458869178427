import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 18px;
    height: 18px;
`;

export const DefectIcon = () => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M16.0342 3.57983C19.4737 7.01875 19.4737 12.5946 16.0342 16.0338C12.595 19.4736 7.01882 19.4736 3.57962 16.0338C0.140128 12.5949 0.140128 7.01903 3.57962 3.57983C7.01882 0.140057 12.5947 0.140057 16.0342 3.57983Z"
                stroke="#FF0000"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M9.80688 5.54547V11.7955"
                stroke="#FF0000"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M9.80688 12.9318V14.0682"
                stroke="#FF0000"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </Svg>
    );
};

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import styled from "styled-components";

import { useScheduledTasksBetween } from "../task/hooks/useScheduledTasksBetween";
import { usePatchTask } from "../task/mutations/usePatchTask";

import { startOfWeek } from "../../common/date";
import { monthAndYear, weekNumber } from "../../common/date";
import { T, TB } from "../../components/texts";
import { SecondaryButton, CleanButton, Button } from "../../components/buttons";
import { LesserThanIcon, GreaterThanIcon } from "../../components/icons";
import Spacer from "../../components/helpers/Spacer";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { TaskStatus } from "../../api/core/taskAPI";

const SchedulerHeader = ({
    from_date,
    setDate,
    isInAutoPlannerMode,
    isAutoPlannerReady,
    setIsInAutoPlannerMode,
    runAutomaticScheduler,
    autoPlannerSelectedTaskIds,
    completeAutomaticScheduler,
}) => {
    const { t } = useTranslation();
    const params = useParams();
    const workshopId = Number(params?.workshopId);
    const { mutate: patchTask, isLoading: isSavingTask } = usePatchTask();

    const { data: scheduledTasks } = useScheduledTasksBetween(
        startOfWeek(from_date).toISODate(),
        startOfWeek(from_date).plus({ weeks: 1 }).toISODate(),
        workshopId
    );

    function finalizeTasks() {
        if (!scheduledTasks) return;

        const tasksInDraftMode = scheduledTasks.filter((task) => task.status === "scheduled_draft");

        if (tasksInDraftMode?.length > 0) {
            tasksInDraftMode.forEach((task) => patchTask({ id: task.id, status: "scheduled" }));
        }
    }

    return (
        <SchedulerHeaderStyled>
            <CleanButton onClick={() => setDate(from_date.minus({ weeks: 1 }))}>
                <LesserThanIcon className={"lt"} />
            </CleanButton>

            <CleanButton onClick={() => setDate(from_date.plus({ weeks: 1 }))}>
                <GreaterThanIcon className={"gt"} />
            </CleanButton>

            <TB className={"month"}>{monthAndYear(from_date)}</TB>

            <T className={"week"}>
                {t("week")} {weekNumber(from_date)}
            </T>

            <Spacer />

            {isSavingTask ? <LoadingSpinner small={true} light={true} /> : null}

            {isInAutoPlannerMode ? (
                <>
                    <GreyButton onClick={() => setIsInAutoPlannerMode(false)}>
                        {t("abort")}
                    </GreyButton>
                    {isAutoPlannerReady ? (
                        <GreenButton onClick={() => completeAutomaticScheduler()}>
                            {t("automatic_scheduler_approve")}
                        </GreenButton>
                    ) : (
                        <OrangeButton
                            disabled={autoPlannerSelectedTaskIds.length < 1}
                            onClick={() => runAutomaticScheduler()}
                        >
                            {t("automatic_scheduler_start")}
                        </OrangeButton>
                    )}
                </>
            ) : (
                <AutoSchedulerButton onClick={() => setIsInAutoPlannerMode(true)}>
                    {t("automatic_scheduler")}
                </AutoSchedulerButton>
            )}

            {scheduledTasks?.filter((item) => item.status === TaskStatus.SCHEDULED_DRAFT)?.length >
            0 ? (
                <OrangeButton onClick={finalizeTasks}>{t("finish_scheduling")}</OrangeButton>
            ) : null}

            <BlueButton onClick={() => setDate(DateTime.local())}>{t("today")}</BlueButton>
        </SchedulerHeaderStyled>
    );
};

export default SchedulerHeader;

const SchedulerHeaderStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    .lt {
        margin-right: 1.25rem;
        margin-bottom: 0.5rem;
    }

    .gt {
        margin-left: 1.25rem;
        margin-bottom: 0.5rem;
    }

    .month {
        padding-left: 0.625rem;
        margin-bottom: 0.5rem;
    }

    .week {
        padding-left: 0.625rem;
        margin-bottom: 0.5rem;
    }
`;

const OrangeButton = styled(Button)`
    font-weight: 600;
    background: ${(p) => p.theme.color.secondary.base};
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;

    &:hover {
        background: ${(p) => p.theme.color.secondary.light};
    }

    &:disabled {
        background: ${(p) => p.theme.color.secondary.xxlight};
    }

    opacity: 0;
    animation: fadeIn 150ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const GreenButton = styled(Button)`
    font-weight: 600;
    background: ${(p) => p.theme.color.ok.base};
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;

    &:hover {
        background: ${(p) => p.theme.color.ok.light};
    }

    &:disabled {
        background: ${(p) => p.theme.color.ok.xxlight};
    }

    opacity: 0;
    animation: fadeIn 150ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const BlueButton = styled(SecondaryButton)`
    margin-bottom: 0.5rem;
`;

const AutoSchedulerButton = styled(Button)`
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
`;

const GreyButton = styled(Button)`
    background: ${(p) => p.theme.color.neutral.light};
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;

    &:hover {
        background: ${(p) => p.theme.color.neutral.xlight};
    }

    &:hover:not(:disabled) {
        background: ${(p) => p.theme.color.neutral.xlight};
    }
`;

import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import styled from "styled-components";

import QueryWrapper from "../../../components/application/QueryWrapper";
import { useServiceCategoriesForCustomer } from "./hooks/useServiceCategoriesForCustomer";
import { useDeleteServiceCategory } from "./mutations/useDeleteServiceCategory";

import { Input } from "../../../components/inputs";
import { Button } from "../../../components/buttons";
import { T, TB } from "../../../components/texts";
import EditCategoryModal from "./EditCategoryModal";
import NewCategoryModal from "./NewCategoryModal";
import ConfirmModal from "../../../components/application/ConfirmModal";
import CategoryList from "./CategoryList";

const Categories = ({ customerId }) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState("");
    const [isNewCategoryOpen, setIsNewCategoryOpen] = useState(false);
    const [isEditCategoryOpen, setIsEditCategoryOpen] = useState(false);
    const [isRemoveCategoryOpen, setIsRemoveCategoryOpen] = useState(false);

    const [removeCategoryId, setRemoveCategoryId] = useState(null);
    const [editCategoryId, setEditCategoryId] = useState(null);

    const categories = useServiceCategoriesForCustomer(customerId);
    const categoriesSorted = useMemo(
        () => (categories?.data ? [...categories.data].sort((a, b) => a.order - b.order) : []),
        [categories?.data]
    );

    const { mutate: removeCategory, isLoading: isRemovingCategory } = useDeleteServiceCategory({
        onSuccess: closeRemoveCategoryModal,
    });

    function handleAddNewCategory() {
        setIsNewCategoryOpen(true);
    }

    function askToEditCategory(categoryId) {
        setEditCategoryId(categoryId);
        setIsEditCategoryOpen(true);
    }

    function closeEditCategoryModal() {
        setIsEditCategoryOpen(false);
        setEditCategoryId(null);
    }

    function closeRemoveCategoryModal() {
        setIsRemoveCategoryOpen(false);
        setRemoveCategoryId(null);
    }

    function askToRemoveCategory(categoryId) {
        setRemoveCategoryId(categoryId);
        setIsRemoveCategoryOpen(true);
    }

    const debouncedSearchUpdate = useCallback(
        debounce((value) => {
            setSearchValue(value);
        }, 200),
        []
    );

    return (
        <>
            <Header>
                <SearchContainer>
                    <label htmlFor="configSearch">
                        <T>{t("search_for_service_category_or_checkpoint")}</T>
                    </label>
                    <Input
                        type="text"
                        id="configSearch"
                        name="configSearch"
                        onChange={(e) => debouncedSearchUpdate(e.target.value)}
                    />
                </SearchContainer>

                <NewConfigButton onClick={handleAddNewCategory}>
                    {t("add_new_category")}
                </NewConfigButton>
            </Header>

            {isNewCategoryOpen && (
                <NewCategoryModal
                    isOpen={isNewCategoryOpen}
                    onClose={() => setIsNewCategoryOpen(false)}
                    customerId={customerId}
                />
            )}

            {isEditCategoryOpen && (
                <EditCategoryModal
                    isOpen={isEditCategoryOpen}
                    onClose={closeEditCategoryModal}
                    categoryId={editCategoryId}
                />
            )}

            {isRemoveCategoryOpen && (
                <ConfirmModal
                    isModalOpen={isRemoveCategoryOpen}
                    closeModal={closeRemoveCategoryModal}
                    onConfirm={() => removeCategory(removeCategoryId)}
                    text={
                        <>
                            <TB>{`${t("sure_you_want_to_remove_service_category")} ${
                                categories?.data?.find((item) => item.id === removeCategoryId)?.name
                            }?`}</TB>
                            <br />
                            <br />
                            <T>{t("remove_service_category_warning")}</T>
                        </>
                    }
                    isLoading={isRemovingCategory}
                />
            )}

            <QueryWrapper data={categories}>
                <CategoryList
                    categories={categoriesSorted}
                    searchValue={searchValue}
                    askToEditCategory={askToEditCategory}
                    askToRemoveCategory={askToRemoveCategory}
                />
            </QueryWrapper>
        </>
    );
};

export default Categories;

const Header = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin-bottom: 2rem;
`;

const SearchContainer = styled.section`
    margin-right: 2rem;
    margin-bottom: 1rem;
`;

const NewConfigButton = styled(Button)`
    height: min-content;
    font-weight: 600;
    padding: 0.9rem 2rem;
    margin-bottom: 1rem;
`;

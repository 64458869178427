import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useSaveImage } from "../media/hooks/useSaveImage";
import { INSPECTION_RESULT_TYPES } from "../../constants";

import { T, TB } from "../../components/texts";
import { InfoIcon } from "../../components/icons";
import { CleanButton } from "../../components/buttons";
import { Traffic123, Input, InputWithLabel, SelectForm } from "../../components/inputs";
import { FlexGap } from "../../components/helpers/FlexGap";
import ServiceElementImage from "./ServiceElementImage";
import LoadingSpinner from "../../components/layout/LoadingSpinner";

const ServiceElement = ({
    element,
    isServiceDone,
    isInspectionDone,
    groupIndex,
    elementIndex,
    version,
}) => {
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

    const { t } = useTranslation();
    const { register, watch, getValues, setValue } = useFormContext();
    const { mutate: saveImage, isLoading: isSavingImage } = useSaveImage();

    const task_check_template = watch("task_check_template");

    const elementValuesPath =
        version === "1"
            ? `task_check_template.schema.elements.${element.id}.values`
            : `task_check_template.schema.elements.${groupIndex}.elements.${elementIndex}.values`;
    const elementValuesObject =
        version === "1"
            ? task_check_template?.schema?.elements?.[element.id]?.values
            : task_check_template?.schema?.elements?.[groupIndex]?.elements?.[elementIndex]?.values;

    function preventSubmit(e) {
        return e.key === "Enter" && e.preventDefault();
    }

    function handleAddPhotoToElement(imageFile) {
        const formData = new FormData();

        formData.append("module", "unit");
        formData.append("customer_id", watch("customer_id").value);
        formData.append("file", imageFile);

        saveImage(formData, {
            onSuccess: (response) => {
                const existingImages = getValues(`${elementValuesPath}.images`);

                const newImageArray = Array.isArray(existingImages)
                    ? [...existingImages, response.data?.id]
                    : [response.data?.id];

                setValue(`${elementValuesPath}.images`, newImageArray, { shouldDirty: true });
            },
        });
    }

    const inspectionResultOptions = INSPECTION_RESULT_TYPES?.map((value) => ({
        value,
        label: t(`inspection_result-${value}`),
    }));

    switch (element.type) {
        case "Comment":
            return (
                <DescriptionInputContainer>
                    <InputWithLabel
                        name={`${elementValuesPath}.comment`}
                        label={t("description_of_the_service")}
                        rows={6}
                        disabled={isServiceDone || isInspectionDone}
                    />
                </DescriptionInputContainer>
            );
        case "ServiceCheckpoint":
            return (
                <Container>
                    <FlexGap $count={3}>
                        <TextBlock>
                            <Name>
                                <T>{element.name}</T>

                                {element.description ? (
                                    <InfoButton
                                        type="button"
                                        onClick={() => setIsDescriptionOpen((prev) => !prev)}
                                    >
                                        <InfoIcon />
                                    </InfoButton>
                                ) : null}
                            </Name>

                            {element.description && isDescriptionOpen ? (
                                <Description>
                                    <T>{element.description}</T>
                                </Description>
                            ) : null}
                        </TextBlock>

                        <Result>
                            <Traffic123
                                name={`${elementValuesPath}.service`}
                                isDisabled={isServiceDone}
                                hideLabel
                            />
                        </Result>

                        <CommentSection>
                            <FlexGap $count={2}>
                                <Input
                                    type="text"
                                    onKeyDown={preventSubmit}
                                    {...register(`${elementValuesPath}.comment`)}
                                    disabled={isServiceDone}
                                />

                                {isServiceDone ? null : (
                                    <>
                                        <HiddenFileInput
                                            type="file"
                                            id={`file-${element.id}`}
                                            onChange={(e) =>
                                                handleAddPhotoToElement(
                                                    e.target.files[0],
                                                    element.id
                                                )
                                            }
                                            disabled={isServiceDone || isSavingImage}
                                        />

                                        <AddImageLabel htmlFor={`file-${element.id}`}>
                                            {isSavingImage ? (
                                                <LoadingSpinner small light />
                                            ) : (
                                                <TB $link>+ {t("add_photo")}</TB>
                                            )}
                                        </AddImageLabel>
                                    </>
                                )}
                            </FlexGap>

                            {elementValuesObject?.images?.length ? (
                                <ImagesContainer>
                                    {elementValuesObject.images.map((imageId, index) => (
                                        <ServiceElementImage
                                            key={`image-${index}`}
                                            imageId={imageId}
                                            elementId={element.id}
                                            elementIndex={elementIndex}
                                            groupIndex={groupIndex}
                                            version={version}
                                            isServiceDone={isServiceDone}
                                        />
                                    ))}
                                </ImagesContainer>
                            ) : null}
                        </CommentSection>
                    </FlexGap>
                </Container>
            );
        case "PeriodicTechnicalInspectionCheckpoint":
            return (
                <Container>
                    <FlexGap $count={3}>
                        <TextBlock>
                            <Name>
                                <T>{element.name}</T>

                                {element.description ? (
                                    <InfoButton
                                        type="button"
                                        onClick={() => setIsDescriptionOpen((prev) => !prev)}
                                    >
                                        <InfoIcon />
                                    </InfoButton>
                                ) : null}
                            </Name>

                            {element.description && isDescriptionOpen ? (
                                <Description>
                                    <T>{element.description}</T>
                                </Description>
                            ) : null}
                        </TextBlock>

                        <Result>
                            <SelectForm
                                name={`${elementValuesPath}.service`}
                                options={inspectionResultOptions}
                                isDisabled={isInspectionDone}
                            />
                        </Result>

                        <CommentSection>
                            <FlexGap $count={2}>
                                <Input
                                    type="text"
                                    onKeyDown={preventSubmit}
                                    {...register(`${elementValuesPath}.comment`)}
                                    disabled={isInspectionDone}
                                />

                                {isInspectionDone ? null : (
                                    <>
                                        <HiddenFileInput
                                            type="file"
                                            id={`file-${element.id}`}
                                            onChange={(e) =>
                                                handleAddPhotoToElement(
                                                    e.target.files[0],
                                                    element.id
                                                )
                                            }
                                            disabled={isInspectionDone || isSavingImage}
                                        />

                                        <AddImageLabel htmlFor={`file-${element.id}`}>
                                            {isSavingImage ? (
                                                <LoadingSpinner small light />
                                            ) : (
                                                <TB $link>+ {t("add_photo")}</TB>
                                            )}
                                        </AddImageLabel>
                                    </>
                                )}
                            </FlexGap>

                            {elementValuesObject?.images?.length ? (
                                <ImagesContainer>
                                    {elementValuesObject.images.map((imageId, index) => (
                                        <ServiceElementImage
                                            key={`image-${index}`}
                                            imageId={imageId}
                                            elementId={element.id}
                                            elementIndex={elementIndex}
                                            groupIndex={groupIndex}
                                            version={version}
                                            isServiceDone={isInspectionDone}
                                        />
                                    ))}
                                </ImagesContainer>
                            ) : null}
                        </CommentSection>
                    </FlexGap>
                </Container>
            );
        default:
            return null;
    }
};

export default ServiceElement;

const ImagesContainer = styled.section`
    margin-top: 0.2rem;
    display: flex;
`;

const Container = styled.article`
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px dashed ${(p) => p.theme.color.neutral.xlight};
`;

const TextBlock = styled.section`
    padding-right: 1.5rem;
`;

const Name = styled.span`
    display: flex;
    padding: 1rem 0;
    align-items: baseline;
    word-break: break-word;
`;

const Description = styled.span`
    display: block;
    padding-bottom: 1rem;
`;

const InfoButton = styled(CleanButton)`
    padding: 0 0.5rem;
    position: relative;
    top: 0.2rem;
`;

const Result = styled.section`
    padding-top: 0.4rem;
    max-width: 12rem;
`;

const CommentSection = styled.section`
    padding-top: 0.4rem;
    padding-bottom: 0.35rem;
    flex-grow: 1;
`;

const AddImageLabel = styled.label`
    padding: 0 1rem;
    minwidth: max-content;
    align-self: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;

const HiddenFileInput = styled.input.attrs({ type: "file" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:focus-visible + ${AddImageLabel} {
        outline: 2px solid ${(p) => p.theme.color.primary.light};
    }
`;

const DescriptionInputContainer = styled.section`
    width: 100%;
    max-width: 30rem;
`;

import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 20px;
    height: 20px;
`;

export const DescriptionIcon = () => {
    return (
        <Svg
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.1875 1.3125H17.1875V20.6875H2.8125V5.6875L7.1875 1.3125Z"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M2.8125 5.6875H7.1875V1.3125"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path d="M10 5.375H14.375" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M5.625 8.5H14.375" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M5.625 11.625H14.375" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M5.625 14.75H14.375" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M5.625 17.875H14.375" stroke="#38558D" strokeMiterlimit="10" />
        </Svg>
    );
};

import { useTranslation } from "react-i18next";
import { useUnits } from "./hooks/useUnits";
import ServerSelect from "../../components/application/ServerSelect";

const UnitSelect = ({ ...props }) => {
    const { t } = useTranslation();
    const { data: units } = useUnits();

    const customUnitStyles = {
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                alignItems: "center",
                display: "flex",
                ":before": data.meta
                    ? {
                          backgroundColor: "red",
                          borderRadius: 10,
                          content: "' '",
                          display: "block",
                          marginRight: 8,
                          height: 10,
                          width: 10,
                      }
                    : undefined,
            };
        },
        option: (styles, { data }) => {
            return {
                ...styles,
                alignItems: "center",
                display: "flex",
                ":before": data.meta
                    ? {
                          backgroundColor: "red",
                          borderRadius: 10,
                          content: "' '",
                          display: "block",
                          marginRight: 8,
                          height: 10,
                          width: 10,
                      }
                    : undefined,
            };
        },
    };

    return (
        <ServerSelect
            name="unit_id"
            label={props.label ? props.label : t("unit")}
            selectUse={useUnits}
            listKey="int_id"
            placeholder={t("select")}
            isOptionDisabled={(option) =>
                units?.find((item) => item.id === option.value)?.active === false
            }
            metaKey="defect"
            styles={customUnitStyles}
            {...props}
        />
    );
};

export default UnitSelect;

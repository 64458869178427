import { createContext, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { parseJwt } from "../helpers";
import { setBearer } from "../../../api/fetch";
import authAPI from "../../../api/authAPI";
import LoggingInSpinner from "../LoggingInSpinner";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [jwt, setJwt] = useState(null);
    const [authUser, setAuthUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [logInError, setLogInError] = useState(null);

    const queryClient = useQueryClient();

    async function logIn({ username, password }) {
        try {
            setLogInError(null);
            setIsLoading(true);

            const response = await authAPI.login(username, password);
            const jwt = response.data?.access_token;
            const userData = parseJwt(jwt);

            setBearer(jwt);
            setJwt(jwt);
            setAuthUser(userData);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setLogInError(error?.response?.data?.message);

            console.log("log in error: ", error?.response?.data?.message);
        }
    }

    async function logOut() {
        try {
            setLogInError(null);
            setIsLoading(true);

            await authAPI.logout();

            setJwt(null);
            setAuthUser(null);
            queryClient.clear();
            localStorage.clear();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setLogInError(error?.response?.data?.message);
            console.log("log out error: ", error);
        }
    }

    async function resetPassword({ token, password }) {
        try {
            setLogInError(null);
            authAPI.resetPassword(token, password);
        } catch (error) {
            setLogInError(error?.response?.data?.message);
            console.log("reset password error: ", error);
        }
    }

    async function refreshJwt() {
        try {
            const response = await authAPI.refresh();

            const jwt = response.data?.access_token;
            const userData = parseJwt(jwt);

            setBearer(jwt);
            setJwt(jwt);
            setAuthUser(userData);
        } catch (error) {
            console.log("Token refresh error", error?.response?.data);
        }
    }

    useEffect(() => {
        keepRefreshingJwt();
    }, []);

    let refreshInterval = null;

    async function keepRefreshingJwt() {
        setIsLoading(true);

        await refreshJwt();

        setIsLoading(false);

        if (refreshInterval) clearInterval(refreshInterval);

        refreshInterval = setInterval(async () => refreshJwt(), 2 * 60 * 1000);
    }

    if (isLoading) {
        return <LoggingInSpinner />;
    }

    return (
        <AuthContext.Provider
            value={{ logIn, logOut, jwt, setJwt, authUser, setAuthUser, logInError, resetPassword }}
        >
            {children}
        </AuthContext.Provider>
    );
};

import { useMemo, useState } from "react";
import {
    useReactTable,
    getGroupedRowModel,
    getCoreRowModel,
    flexRender,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { T, TB } from "../../../components/texts";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";
import { hasSameDay, getDate } from "../../../common/date";

const dateTimeDisplayOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
};

const timeDisplayOptions = {
    hour: "2-digit",
    minute: "2-digit",
};

const UnitsUpcomingTable = ({ tasks }) => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                header: t("planned_start"),
                accessorKey: "start",
                enableSorting: false,
                enableGrouping: true,
                cell: ({ row }) => {
                    const startDate = new Date(
                        row.leafRows
                            ? Math.min(
                                  ...row.leafRows.map(({ original }) => new Date(original.start))
                              )
                            : row.original.start
                    );
                    const endDate = new Date(
                        row.leafRows
                            ? Math.max(
                                  ...row.leafRows.map(({ original }) => new Date(original.end))
                              )
                            : row.original.end
                    );

                    const startTimeOnly = startDate.toLocaleTimeString("no", timeDisplayOptions);
                    const endDateFormatted = endDate.toLocaleString("no", dateTimeDisplayOptions);
                    const endTimeOnly = endDate.toLocaleString("no", timeDisplayOptions);

                    return `${startTimeOnly} – ${
                        hasSameDay(getDate(startDate), getDate(endDate))
                            ? endTimeOnly
                            : endDateFormatted
                    }`;
                },
                getGroupingValue: (row) => {
                    const dateString = new Date(row.start).toLocaleDateString("no", {
                        weekday: "long",
                        day: "2-digit",
                        month: "short",
                    });
                    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
                },
            },
            {
                id: "int_id",
                header: t("vehicle"),
                accessorKey: "unit.int_id",
                enableGrouping: true,
                enableSorting: false,
                getGroupingValue: (row) => {
                    return row.unit_id;
                },
            },
            {
                header: t("type"),
                accessorFn: (row) =>
                    `${row.unit?.group?.name || ""} - ${row.unit?.manufacturer || ""} ${row.unit?.type || ""}`,
                enableGrouping: false,
                enableSorting: false,
                aggregationFn: "unique",
            },
            {
                header: t("useArea"),
                accessorKey: "area_name",
                enableGrouping: false,
                enableSorting: false,
                aggregationFn: "unique",
            },
            {
                header: t("description_2"),
                accessorKey: "description",
                enableGrouping: false,
                enableSorting: false,
                aggregationFn: "concat",
                cell: ({ getValue }) => {
                    return <div style={{ whiteSpace: "preserve-breaks" }}>{getValue()}</div>;
                },
            },
        ],
        []
    );

    const renderRowSubComponent = ({ row }) => (
        <tr>
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                    <T>{flexRender(cell.column.columnDef.cell, cell.getContext())}</T>
                </td>
            ))}
        </tr>
    );

    const [grouping, setGrouping] = useState(["start", "int_id"]);
    // const [grouping, setGrouping] = useState(["start"]);
    const initialState = useMemo(
        () => ({
            grouping: ["start", "int_id"],
        }),
        []
    );

    const table = useReactTable({
        columns,
        data: tasks,
        initialState,
        enableGrouping: true,
        groupedColumnMode: "reorder",
        state: {
            grouping,
        },
        aggregationFns: {
            concat: (columnId, leafRows) =>
                leafRows.map(({ getValue }) => getValue(columnId)).join("\n"),
        },
        onGroupingChange: setGrouping,
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <Container>
            <Title>{t("vehicles_to_be_delivered_to_workshop")}</Title>
            {rowModel.rows.length > 0 ? (
                <article>
                    <TableStyled>
                        <StandardTableContent
                            headerGroups={headerGroups}
                            rowModel={rowModel}
                            renderRowSubComponent={renderRowSubComponent}
                        />
                    </TableStyled>
                </article>
            ) : (
                <NothingToShow>
                    <TB>{t("no_vehicles_to_show")}</TB>
                </NothingToShow>
            )}
        </Container>
    );
};

export default UnitsUpcomingTable;

const Container = styled.article`
    overflow: auto;
    flex-grow: 1;
    margin-bottom: 5rem;
`;

const Title = styled.h2`
    font-family: ${(props) => props.theme.font.h1["font-family"]};
    font-weight: ${(props) => props.theme.font.h1["font-weight"]};
    font-size: ${(props) => props.theme.font.h1["font-size"]};
    color: ${(props) => props.theme.text.color.main};
`;

const TableStyled = styled(StandardTableStyle)`
    min-width: 40rem;

    th:nth-child(1),
    td:nth-child(1) {
        width: 11rem;
    }

    tr {
        td.groupHeader {
            padding-top: 2rem;

            > ${TB} {
                max-width: 7rem;
                display: block;
                white-space: nowrap;
            }
        }
    }
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
`;

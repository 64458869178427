import { fetchData, patchData } from "../fetch";

const fetchAllConfig = async () => fetchData(`core/config`);
const fetchGlobalConfig = async () => fetchData(`core/config/global`);
const fetchMyConfig = async () => fetchData(`core/config/me`);
const fetchConfigForCustomer = async (id) => fetchData(`core/config/customer/${id}`);
const updateGlobalConfig = async (data) => patchData(`core/config/global`, data);
const updateMyConfig = async (data) => patchData(`core/config/me`, data);
const updateCustomerConfig = async (data) => patchData(`core/config/customer`, data);

export default {
    fetchAllConfig,
    fetchGlobalConfig,
    fetchMyConfig,
    fetchConfigForCustomer,
    updateGlobalConfig,
    updateMyConfig,
    updateCustomerConfig,
};

import Icon from "./Icon";

export const PDFIcon = () => (
    <Icon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4383_23857)">
            <path
                d="M10.7812 0.46875H25.7812V29.5312H4.21875V7.03125L10.7812 0.46875Z"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M4.21875 7.03125H10.7812V0.46875"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M7.54 20C7.5 20 7.48 19.98 7.48 19.94L7.504 11.696C7.504 11.664 7.52 11.648 7.552 11.648H9.856C10.592 11.648 11.172 11.872 11.596 12.32C12.028 12.768 12.244 13.376 12.244 14.144C12.244 14.712 12.132 15.204 11.908 15.62C11.684 16.036 11.392 16.356 11.032 16.58C10.672 16.804 10.28 16.916 9.856 16.916H8.944V19.94C8.944 19.98 8.924 20 8.884 20H7.54ZM8.944 15.464H9.856C10.112 15.464 10.332 15.344 10.516 15.104C10.708 14.856 10.804 14.536 10.804 14.144C10.804 13.832 10.72 13.576 10.552 13.376C10.384 13.176 10.152 13.076 9.856 13.076L8.944 13.088V15.464ZM13.1181 20C13.0781 20 13.0581 19.98 13.0581 19.94L13.0821 11.696C13.0821 11.664 13.0981 11.648 13.1301 11.648L15.3741 11.636C15.8141 11.628 16.2141 11.732 16.5741 11.948C16.9421 12.164 17.2341 12.456 17.4501 12.824C17.6661 13.184 17.7741 13.584 17.7741 14.024V17.468C17.7741 17.932 17.6621 18.356 17.4381 18.74C17.2141 19.116 16.9141 19.416 16.5381 19.64C16.1621 19.864 15.7381 19.98 15.2661 19.988L13.1181 20ZM14.5221 18.524H15.2661C15.5621 18.524 15.8101 18.42 16.0101 18.212C16.2181 18.004 16.3221 17.756 16.3221 17.468V14.012C16.3221 13.756 16.2261 13.536 16.0341 13.352C15.8501 13.16 15.6301 13.068 15.3741 13.076L14.5341 13.088L14.5221 18.524ZM18.7783 20C18.7383 20 18.7183 19.98 18.7183 19.94L18.7423 11.696C18.7423 11.664 18.7583 11.648 18.7903 11.648H22.6303C22.6703 11.648 22.6903 11.664 22.6903 11.696V13.052C22.6903 13.084 22.6743 13.1 22.6423 13.1H20.1823V14.96H22.6423C22.6743 14.96 22.6903 14.98 22.6903 15.02L22.7023 16.376C22.7023 16.408 22.6823 16.424 22.6423 16.424H20.1823V19.94C20.1823 19.98 20.1623 20 20.1223 20H18.7783Z"
                fill="#38558D"
            />
        </g>
        <defs>
            <clipPath id="clip0_4383_23857">
                <rect width="30" height="30" fill="white" />
            </clipPath>
        </defs>
    </Icon>
);

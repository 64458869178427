import { useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import taskPreChecksAPI from '../../../../api/core/taskPreChecksAPI';

export const useTaskPreChecks = (customerId) => {
    const queryClient = useQueryClient();

    return useQuery(["task-pre-checks", customerId], () => taskPreChecksAPI.getTaskPreChecks(customerId), {
        enabled: !!customerId,
        onSuccess: (data) => {
            data?.forEach((item) => queryClient.setQueryData(["task-pre-check", item.id], item));
        },
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
            );
            console.log("Loading error: ", error.message);
        },
    });
};

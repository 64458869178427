import { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { cloneDeep } from "lodash";
import styled, { useTheme } from "styled-components";

import authorizationAPI from "../../../api/core/authorizationAPI";
import { usePermission } from "../../user/hooks/usePermission";
import { useRole } from "./hooks/useRole";
import { useSaving } from "../../../components/application/hooks/useSaving";
import { useAllPermissions } from "./hooks/useAllPermissions";
import { device } from "../../../theme";
import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import CustomerSelect from "../../application/components/role/CustomerSelect";
import { InputWithLabel, InputCheckbox } from "../../../components/inputs";
import { TB, H1 } from "../../../components/texts";
import { FlexGap } from "../../../components/helpers/FlexGap";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import { Button } from "../../../components/buttons";
import { templates } from "./Templates";
import { PERMISSIONS } from "../../auth/permissions";

const RoleCategory = ({ name }) => {
    const theme = useTheme();

    const permissions = useAllPermissions();
    const system_admin = usePermission("system_admin");
    const [navbarPermissions, standardPermissions] = useMemo(() => {
        if (permissions?.data == null) return [[], []];
        const navbar = [];
        const standard = [];
        for (const permission of permissions.data) {
            if (permission.category !== name) continue;
            if (permission.name === PERMISSIONS.system_admin.name) continue;

            if (permission.is_navbar) {
                navbar.push(permission);
            } else {
                standard.push(permission);
            }
        }

        return [navbar, standard];
    }, [permissions, name]);

    return (
        <RoleCategoryStyled>
            <QueryWrapper data={permissions}>
                <Container>
                    <div>
                        <TB color={theme.color.primary.base}>{name}</TB>
                    </div>
                    {navbarPermissions.length > 0 ? (
                        <PermissionsContainer>
                            <div key={`${name}-navbar`}>Navigasjon:</div>
                            {navbarPermissions.map((p) => (
                                <div key={p.id}>
                                    <InputCheckbox
                                        name={`permissions[${permissions.data
                                            ?.map((v) => v.id)
                                            .indexOf(p.id)}]`}
                                        label={p.description}
                                        disabled={p.system && !system_admin}
                                    />
                                </div>
                            ))}
                        </PermissionsContainer>
                    ) : null}
                    {standardPermissions.length > 0 ? (
                        <PermissionsContainer>
                            {standardPermissions.map((p) => (
                                <div key={p.id}>
                                    <InputCheckbox
                                        name={`permissions[${permissions.data
                                            ?.map((v) => v.id)
                                            .indexOf(p.id)}]`}
                                        label={p.description}
                                        disabled={p.system && !system_admin}
                                    />
                                </div>
                            ))}
                        </PermissionsContainer>
                    ) : null}
                </Container>
            </QueryWrapper>
        </RoleCategoryStyled>
    );
};

const RoleEdit = () => {
    const { t } = useTranslation();

    const params = useParams();
    const id = Number(params.id);

    const methods = useForm({ mode: "onChange" });
    const { saving, save } = useSaving();
    const permissions = useAllPermissions();
    const role = useRole(id);

    const categories = useMemo(
        () => [
            ...new Set(
                permissions.data
                    ?.filter((p) => p.name !== PERMISSIONS.system_admin.name)
                    ?.map((p) => p.category)
            ),
        ],
        [permissions]
    );

    useEffect(() => {
        if (id === 0 && permissions.data && permissions.data.length > 0)
            methods.reset({
                customer_id: null,
                name: "",
                description: "",
                permissions: permissions.data.map((_) => false),
            });
    }, [id, permissions?.data]);

    useEffect(() => {
        if (role.data && permissions.data && permissions.data.length > 0) {
            const roleData = cloneDeep(role.data);

            roleData.permissions = permissions.data.map((permission) =>
                roleData.permissions.some(
                    (rolePermission) => rolePermission.permission?.id === permission.id
                )
            );

            methods.reset(roleData);
        }
    }, [role?.data, permissions?.data]);

    const onSubmit = (data) => {
        let post = { ...data };

        post.customer_id = post.customer_id?.value;
        post.permissions = post.permissions
            .map((p, idx) => (p ? permissions.data[idx].id : null))
            .filter((p) => p);

        save({ api: authorizationAPI.updatePermission, post, ok: "/administration/roles" });
    };

    const setValuesToTemplate = (templateRole) => {
        if (permissions?.data == null) return;

        const templatePermissions = templates[templateRole];
        if (templatePermissions == null) return;

        const value = permissions.data.map(({ name }) => templatePermissions.includes(name));
        methods.setValue("permissions", value, {
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    return (
        <MainArea back>
            <H1>
                {Number(id) === 0
                    ? t("register_role", "Register new role")
                    : t("change_role", "Change role")}
            </H1>

            <QueryWrapper data={id !== 0 ? [role, permissions] : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <section className={"header"}>
                            <FlexGap $count={3}>
                                <InputWithLabel
                                    label={t("name", "Name")}
                                    name={"name"}
                                    validate={{ required: "Rollen må ha et navn" }}
                                />

                                <InputWithLabel
                                    label={t("description", "Description")}
                                    name={"description"}
                                />

                                <CustomerSelect
                                    isDisabled={id !== 0}
                                    validate={{ required: "Rollen må være knyttet til en kunde" }}
                                />
                            </FlexGap>

                            <div>{t("templates")}:</div>
                            <TemplatesContainer>
                                <Button type="button" onClick={() => setValuesToTemplate("driver")}>
                                    Sjåfør
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => setValuesToTemplate("mechanic")}
                                >
                                    Mekaniker
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => setValuesToTemplate("scheduler")}
                                >
                                    Planlegger
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => setValuesToTemplate("commentManager")}
                                >
                                    Kommentaransvarlig
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => setValuesToTemplate("vehicleCoordinator")}
                                >
                                    Kjøretøyskoordinator
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => setValuesToTemplate("departmentManager")}
                                >
                                    Avdelingsleder
                                </Button>
                            </TemplatesContainer>
                        </section>

                        {categories.map((category) => (
                            <RoleCategory key={category} name={category} />
                        ))}

                        <SaveOrAbort saving={saving} />
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default RoleEdit;

const RoleCategoryStyled = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.color.primary.xxlight};
    padding: 3px 10px 20px 10px;
    margin-bottom: 4px;

    .roles {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .role {
            display: flex;
            flex-direction: column;
            max-width: 25%;
            flex: 1;

            label {
                margin-top: -2px;
            }

            > div > div {
                align-items: start;
                padding-top: 2px;
            }

            @media only screen and ${device.mobile} {
                width: 100%;
                max-width: 100%;
                flex: auto;
            }
        }
    }
`;

const PermissionsContainer = styled.div`
    display: flex;
    column-gap: 1.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const TemplatesContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    padding-top: 5px;
`;

import styled from "styled-components";
import ReactModal from "react-modal";
import { H1 } from "../../components/texts";
import { useTranslation } from "react-i18next";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import { FormProvider, useForm } from "react-hook-form";
import { FlexGap } from "../../components/helpers/FlexGap";
import { DateTimeInputForm } from "../../components/inputs";
import { useDistributeTasks } from "../task/mutations/useDistributeTasks";

const AutomaticPlannerModal = ({
    tasks,
    mechanics,
    onClose,
    onDistributionResponse,
    isOpen,
    host_id,
}) => {
    const { t } = useTranslation();

    const { mutate: distributeTasks } = useDistributeTasks({ onSuccess: onDistributionResponse });
    const methods = useForm({ mode: "onChange" });

    function onSubmit(data) {
        const post = {
            fromDate: data.fromDate,
            toDate: data.toDate,
            tasks,
            mechanics,
            host_id,
        };

        distributeTasks(post);
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={"Modal"}
            overlayClassName={"ModalOverlay"}
            shouldCloseOnOverlayClick={false}
        >
            <Container>
                <TitleStyled>{t("automatic_scheduler")}</TitleStyled>
                <FormProvider {...methods}>
                    <FormStyled onSubmit={methods.handleSubmit(onSubmit)}>
                        <FlexGap $count={2}>
                            <section>
                                <FlexGapDateStyled $count={2}>
                                    <DateTimeInputForm name="fromDate" label={t("from")} required />
                                    <DateTimeInputForm name="toDate" label={t("to")} required />
                                </FlexGapDateStyled>

                                <ButtonsFlex>
                                    <SaveOrAbortWrapper>
                                        <SaveOrAbort onAbort={onClose} save="schedule" />
                                    </SaveOrAbortWrapper>
                                </ButtonsFlex>
                            </section>
                        </FlexGap>
                    </FormStyled>
                </FormProvider>
            </Container>
        </ReactModal>
    );
};

export default AutomaticPlannerModal;

const TitleStyled = styled(H1)`
    padding-left: 1rem;
`;

const FlexGapDateStyled = styled(FlexGap)`
    padding-top: 1.5rem;
    margin-bottom: -1rem;
`;

const Container = styled.article`
    padding-top: 1rem;
    background-color: ${(p) => p.theme.color.primary.xxlight};
`;

const FormStyled = styled.form`
    padding: 1rem;
`;

const ButtonsFlex = styled.section`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const SaveOrAbortWrapper = styled.section`
    margin-top: -0.5rem;
`;

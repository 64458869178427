import { useMemo } from "react";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TB } from "../../../components/texts";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";

const UnitsDoneTable = ({ tasks }) => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
                enableSorting: false,
            },
            {
                header: t("type"),
                accessorFn: (row) =>
                    `${row.unit?.group?.name || ""} - ${row.unit?.manufacturer || ""} ${row.unit?.type || ""}`,
                enableSorting: false,
            },
            {
                header: t("useArea"),
                accessorKey: "area_name",
                size: 150,
                maxSize: 150,
            },
            {
                header: t("finished"),
                accessorKey: "end",
                enableSorting: false,
                cell: ({ getValue }) =>
                    new Date(getValue()).toLocaleString("no", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                    }),
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data: tasks,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <Container>
            <Title>{t("vehicles_done")}</Title>

            {rowModel.rows.length > 0 ? (
                <TableContainer>
                    <TableStyled>
                        <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                    </TableStyled>
                </TableContainer>
            ) : (
                <NothingToShow>
                    <TB>{t("no_vehicles_to_show")}</TB>
                </NothingToShow>
            )}
        </Container>
    );
};

export default UnitsDoneTable;

const Container = styled.article`
    margin-bottom: 5rem;
`;

const Title = styled.h2`
    font-family: ${(props) => props.theme.font.h1["font-family"]};
    font-weight: ${(props) => props.theme.font.h1["font-weight"]};
    font-size: ${(props) => props.theme.font.h1["font-size"]};
    color: ${(props) => props.theme.text.color.main};
`;

const TableContainer = styled.article`
    overflow: auto;
`;

const TableStyled = styled(StandardTableStyle)`
    th:nth-child(1),
    td:nth-child(1) {
        width: 11rem;
    }
`;

const NothingToShow = styled.section`
    min-height: 7rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
`;

import { useEffect, useMemo } from "react";
import {
    useReactTable,
    getSortedRowModel,
    getPaginationRowModel,
    getCoreRowModel,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePermissions, checkForPermission } from "../../user/hooks/usePermissions";
import { useCustomers } from "./hooks/useCustomers";
import QueryWrapper from "../../../components/application/QueryWrapper";
import { PERMISSIONS } from "../../auth/permissions";

import StandardTableContent from "../../../components/application/StandardTableContent";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import MainArea from "../../../components/layout/MainArea";
import Spacer from "../../../components/helpers/Spacer";
import { H1, TB, T } from "../../../components/texts";
import { Horizontal } from "../../../components/layout/FlexGrid";
import { Button } from "../../../components/buttons";
import { InputWithLabel, InputCheckbox } from "../../../components/inputs";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const Customers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const methods = useForm();

    const customers = useCustomers();
    const permissions = usePermissions();

    const search = methods.watch("search");
    const showInactiveCustomers = methods.watch("show-inactive-customers");

    const columns = useMemo(
        () => [
            {
                header: t("customer_number_short"),
                accessorKey: "id",
            },
            {
                header: t("customer"),
                accessorKey: "name",
                cell: ({ getValue, row }) =>
                    canEditCustomer(row.original?.id) ? (
                        <EditLink to={`/administration/customers/${row.original?.id}/edit`}>
                            <TB $link>{getValue()}</TB>
                        </EditLink>
                    ) : (
                        <T>{getValue()}</T>
                    ),
            },
            {
                header: t("contact_person"),
                accessorKey: "contact_person.name",
            },
            {
                header: t("mobile_number_short"),
                accessorKey: "contact_person.mobile",
            },
            {
                header: t("invoice_email"),
                accessorKey: "invoice_email",
            },
        ],
        [permissions?.data]
    );

    const data = useMemo(
        () =>
            customers?.data && customers.data.length > 0
                ? customers.data.filter((customer) =>
                      showInactiveCustomers ? !customer.active : customer.active
                  )
                : [],
        [customers?.data, showInactiveCustomers]
    );

    function canEditCustomer(customerId) {
        if (!permissions?.data || permissions.data.length === 0) return false;

        return checkForPermission({
            dataToCheck: permissions.data,
            permission: PERMISSIONS.customer_admin.name,
            customer_id: customerId,
        });
    }

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 20,
            },
        }),
        []
    );
    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    return (
        <MainArea>
            <Horizontal>
                <H1>{t("customers")}</H1>
                <Spacer />
                <Button onClick={() => navigate(`/administration/customers/0/edit`)}>
                    <TB $second>{t("create_new_customer")}</TB>
                </Button>
            </Horizontal>

            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        label={t("search_customers")}
                        name={"search"}
                        style={{ maxWidth: "27rem" }}
                    />
                    <InputCheckbox
                        name="show-inactive-customers"
                        label={t("show_only_inactive_customers")}
                    />

                    <QueryWrapper data={customers}>
                        {rowModel.rows.length > 0 ? (
                            <TableContainer>
                                <TableStyled>
                                    <StandardTableContent
                                        headerGroups={headerGroups}
                                        rowModel={rowModel}
                                    />
                                </TableStyled>
                            </TableContainer>
                        ) : (
                            <NoCustomers>
                                <TB>{t("no_customers_to_show")}</TB>
                            </NoCustomers>
                        )}

                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={table.previousPage}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={table.nextPage}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={table.setPageIndex}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    </QueryWrapper>
                </form>
            </FormProvider>
        </MainArea>
    );
};

export default Customers;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:first-child,
        td:first-child {
            width: 6rem;
        }

        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
        }
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const EditLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const NoCustomers = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import {
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
} from "@tanstack/react-table";
import styled from "styled-components";

import { useKitboxes } from "./hooks/useKitboxes";
import QueryWrapper from "../../../components/application/QueryWrapper";

import Spacer from "../../../components/helpers/Spacer";
import MainArea from "../../../components/layout/MainArea";
import { Horizontal } from "../../../components/layout/FlexGrid";
import { Button } from "../../../components/buttons";
import { H1, TB } from "../../../components/texts";
import { InputWithLabel } from "../../../components/inputs";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const KitboxesList = () => {
    const { t } = useTranslation();
    const methods = useForm();
    const navigate = useNavigate();

    const kitboxes = useKitboxes();

    const search = methods.watch("search");

    const columns = useMemo(
        () => [
            {
                header: t("name"),
                accessorKey: "name",
                cell: ({ getValue, row }) => (
                    <EditLink to={`/administration/setup/kitbox/${row.original.id}/edit`}>
                        <TB $link>{getValue()}</TB>
                    </EditLink>
                ),
            },
            {
                header: t("customer"),
                accessorKey: "customer.name",
            },
        ],
        []
    );
    const data = useMemo(() => kitboxes?.data || [], [kitboxes?.data]);

    const initialState = useMemo(
        () => ({ sortBy: [{ id: "name" }], pagination: { pageSize: 40 } }),
        []
    );
    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function goToAddNewKitbox() {
        navigate("/administration/setup/kitbox/0/edit");
    }

    return (
        <MainArea back>
            <Horizontal>
                <H1>{t("kitboxes")}</H1>
                <Spacer />
                <Button onClick={goToAddNewKitbox}>
                    <TB $second>{t("add_new_kitbox")}</TB>
                </Button>
            </Horizontal>

            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        label={t("search_kitboxes")}
                        name="search"
                        style={{ maxWidth: "27rem" }}
                    />
                </form>
            </FormProvider>

            <QueryWrapper data={kitboxes}>
                {rowModel.rows.length > 0 ? (
                    <TableContainer>
                        <TableStyled>
                            <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                        </TableStyled>
                    </TableContainer>
                ) : (
                    <NoContent>
                        <TB>{t("no_kitboxes_to_show")}</TB>
                    </NoContent>
                )}

                <TablePaginationNav
                    pageCount={table.getPageCount()}
                    previousPage={table.previousPage}
                    canPreviousPage={table.getCanPreviousPage()}
                    nextPage={table.nextPage}
                    canNextPage={table.getCanNextPage()}
                    pageOptions={table.getPageOptions()}
                    gotoPage={table.setPageIndex}
                    pageIndex={table.getState().pagination.pageIndex}
                />
            </QueryWrapper>
        </MainArea>
    );
};

export default KitboxesList;

const EditLink = styled(Link)`
    text-decoration: none;
`;

const TableStyled = styled(StandardTableStyle)`
    max-width: 40rem;

    tr {
        th:last-child,
        td:last-child {
            width: 50%;
        }
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const NoContent = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;
`;

import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ExpandableSection } from "../../../components/inputs";
import { FlexGap } from "../../../components/helpers/FlexGap";
import { T } from "../../../components/texts";

const ConfigIntervalCategory = ({ customerId, categoryData, intervalIndex }) => {
    const { t } = useTranslation();
    const { register } = useFormContext();

    const { checkpoints, name: categoryName, id: categoryId } = categoryData;

    const checkpointsInColumns = useMemo(() => {
        if (!checkpoints?.length) return [];

        const numberOfColumns = 4;
        const arrayFromNumberOfColumns = Array.from(Array(numberOfColumns).keys());

        const columnsWithCheckpoints = arrayFromNumberOfColumns.map((column) => {
            const checkpointsForColumn = checkpoints.slice(
                Math.ceil(checkpoints.length / 4) * column,
                Math.ceil(checkpoints.length / 4) * (column + 1)
            );

            return {
                categoryName: categoryName,
                columnIndex: column,
                checkpoints: checkpointsForColumn,
            };
        });

        return columnsWithCheckpoints;
    }, [checkpoints, categoryName]);

    return (
        <ExpandableSection
            label={
                <CategoryLabel>
                    {categoryName}

                    {categoryData?.customer_id === 1 && customerId !== 1 ? (
                        <T> ({t("system_category").toLowerCase()})</T>
                    ) : null}

                    {checkpointsInColumns.length > 0 && (
                        <CheckpointsSelectedText
                            categoryData={categoryData}
                            customerId={customerId}
                            checkpoints={checkpoints}
                            intervalIndex={intervalIndex}
                        />
                    )}
                </CategoryLabel>
            }
        >
            {checkpointsInColumns.length > 0 ? (
                <FlexGap $count={4}>
                    {checkpointsInColumns.map((column) => (
                        <section key={`${column.categoryName}-column-${column.columnIndex}`}>
                            {column.checkpoints?.map((value) => (
                                <CheckpointContainer key={`${categoryId}.${value.id}`}>
                                    <input
                                        {...register(`intervals.${intervalIndex}.checkpoints`)}
                                        type="checkbox"
                                        id={`${intervalIndex}.${categoryId}.${value.id}`}
                                        value={value.id}
                                    />

                                    <CheckboxIcon
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M1 6 L5.5 10.5 L10.5 2" />
                                    </CheckboxIcon>

                                    <label htmlFor={`${intervalIndex}.${categoryId}.${value.id}`}>
                                        <T>{value.name}</T>
                                    </label>
                                </CheckpointContainer>
                            ))}
                        </section>
                    ))}
                </FlexGap>
            ) : (
                <T>{t("no_checkpoints_found")}</T>
            )}
        </ExpandableSection>
    );
};

export default ConfigIntervalCategory;

const CheckpointsSelectedText = ({ categoryData, checkpoints, intervalIndex }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const selectedCheckpoints = useWatch({
        control,
        name: `intervals.${intervalIndex}.checkpoints`,
    });

    const selectedCheckpointsInCategory = useMemo(() => {
        if (typeof selectedCheckpoints === "boolean") {
            // When initialising a new checkpoints list, the given value for selectedCheckpoints
            // is set to a boolean for a split second.
            // TODO: investigate a permanent solution to the boolean issue.
            return undefined;
        }
        return selectedCheckpoints?.filter((item) =>
            categoryData?.checkpoints?.some((checkpoint) => checkpoint.id === Number(item))
        );
    }, [selectedCheckpoints, categoryData]);

    return (
        <T>
            {` — ${selectedCheckpointsInCategory?.length || 0} ${t("out_of")} ${
                checkpoints.length
            } ${t("checkpoints_selected")}`}
        </T>
    );
};

const CategoryLabel = styled.span`
    ${T} {
        color: inherit;
    }
`;

const CheckboxIcon = styled.svg`
    position: absolute;
    fill: none;
    stroke: ${(p) => p.theme.color.primary.base};
    stroke-width: 2px;
    transform: translate(0.15rem, 0.15rem);
    pointer-events: none;
    width: 1.2rem;
    height: 1.2rem;
    opacity: 0;
`;

const CheckpointContainer = styled.section`
    margin: 0.8rem 0;
    margin-right: 1rem;

    display: flex;

    input {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        border: none;
        overflow: hidden;

        &:checked + ${CheckboxIcon} {
            opacity: 1;
        }

        &:focus-visible ~ label {
            outline: 2px solid ${(p) => p.theme.color.primary.base};
            outline-offset: 0.2rem;
        }
    }

    label {
        cursor: pointer;
        user-select: none;
        word-break: break-word;

        &:before {
            content: "";
            box-sizing: border-box;
            display: inline-block;
            vertical-align: text-top;
            width: 1.25rem;
            height: 1.25rem;
            border: 2px solid ${(p) => p.theme.color.primary.light};
            background: #fff;
            margin-right: 0.3rem;
        }
    }
`;

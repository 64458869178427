import { useQuery, useQueryClient } from "react-query";
import partAPI from "../../../api/core/partAPI";

export const useParts = () => {
    const queryClient = useQueryClient();

    return useQuery("parts", partAPI.getAll, {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["parts", item.id], item));
        },
    });
};

import { useQuery, useQueryClient } from "react-query";
import unitAPI from "../../../api/core/unitAPI";

export const useUnitAreas = (includeUnits) => {
    const queryClient = useQueryClient();

    return useQuery("unitAreas", () => unitAPI.getAreas(includeUnits), {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["unitAreas", item.id], item));
        },
    });
};

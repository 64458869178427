import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { format as formatDate } from "date-fns";
import styled from "styled-components";
import toast from "react-hot-toast";

import { useSensor } from "./hooks/useSensor";
import { useUnits } from "../unit/hooks/useUnits";
import { useSaveSensor } from "./hooks/useSaveSensor";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import { useSaveSensorUnitConnection } from "./hooks/useSaveSensorUnitConnection";
import { useDeleteSensorUnitConnection } from "./hooks/useDeleteSensorUnitConnection";
import QueryWrapper from "../../components/application/QueryWrapper";

import { H1, TB, T } from "../../components/texts";
import { CleanButton } from "../../components/buttons";
import MainArea from "../../components/layout/MainArea";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import ServerSelect from "../../components/application/ServerSelect";
import { InputCheckbox, InputWithLabel } from "../../components/inputs";
import { useSensorUnitHourCounter } from "./hooks/useSensorUnitHourCounter";
import { useSaveSensorUnitHourCounter } from "./hooks/useSaveSensorUnitHourCounter";
import { DownArrowIcon } from "../../components/icons";

const SensorEdit = () => {
    const [isHourCounterEditOpen, setIsHourCounterEditOpen] = useState(false);
    const { t } = useTranslation();

    const methods = useForm();
    const navigate = useNavigate();
    const params = useParams();
    const sensorId = Number(params.id);

    const sensor = useSensor(sensorId);
    const selectedUnit = methods.watch("unit");
    const selectedCustomer = methods.watch("customer_id");
    const activeSensorUnitId = sensor?.data?.sensor_units?.find(
        (sensorUnit) => sensorUnit.active
    )?.unit_id;
    const hourCounter = useSensorUnitHourCounter(selectedUnit?.value);

    const isSelectedUnitDifferentThanSaved =
        !!selectedUnit?.value && activeSensorUnitId !== selectedUnit?.value;

    const { mutate: saveSensor, isLoading: isSavingSensor } = useSaveSensor();
    const { mutate: saveSensorUnitConnection, isLoading: isSavingSensorUnitConnection } =
        useSaveSensorUnitConnection();
    const { mutate: deleteSensorUnitConnection, isLoading: isDeletingSensorUnitConnection } =
        useDeleteSensorUnitConnection();
    const { mutate: saveSensorUnitHourCounter, isLoading: isSavingSensorUnitHourCounter } =
        useSaveSensorUnitHourCounter();

    useEffect(() => {
        if (sensorId === 0) {
            methods.reset({ active: true });
        } else if (sensor?.data) {
            methods.reset({
                ident: sensor.data.ident,
                manufacturer: sensor.data.manufacturer || "",
                type: sensor.data.type || "",
                customer_id: sensor.data.customer_id,
                active: sensor.data.active,
                unit: sensor.data.sensor_units?.find((sensorUnit) => sensorUnit.active)?.unit_id,
            });
        }
    }, [sensorId, sensor?.data]);

    // Set the hour counter text field to the last found hour counter value on selected unit or to null
    useEffect(() => {
        if (!isSelectedUnitDifferentThanSaved) return;

        if (hourCounter?.data) {
            methods.setValue("hour_counter", hourCounter?.data.value);
        } else {
            methods.setValue("hour_counter", null);
        }
    }, [hourCounter?.data]);

    function onSubmit(data) {
        const post = {
            ident: data.ident,
            manufacturer: data.manufacturer,
            type: data.type,
            customer_id: data.customer_id.value,
            active: data.active || false,
        };

        post.id = sensorId || 0;

        saveSensor(post, {
            onSuccess: (response) => {
                const sensorIdFromResponse = response.data?.id;
                const isUnitSelected = !!selectedUnit?.value;
                const previousConnectedUnitId = response.data?.sensor_units?.find(
                    (item) => item.active
                )?.unit_id;
                const shouldUpdateUnitConnection = previousConnectedUnitId !== selectedUnit?.value;

                if (isUnitSelected && shouldUpdateUnitConnection) {
                    const post = {
                        id: selectedUnit?.value,
                        sensor_id: response.data.id,
                    };
                    if (previousConnectedUnitId) {
                        deleteSensorUnitConnection(sensorIdFromResponse, {
                            onSuccess: () => {
                                saveSensorUnitConnection(post, {
                                    onSuccess: () => {
                                        if (data.hour_counter) {
                                            saveSensorUnitHourCounter(
                                                {
                                                    unitId: selectedUnit.value,
                                                    data: {
                                                        hour_counter: Number(data.hour_counter),
                                                    },
                                                },
                                                { onSuccess: navigateToSensorsList }
                                            );
                                        } else {
                                            navigateToSensorsList();
                                        }
                                    },
                                });
                            },
                        });
                    } else {
                        saveSensorUnitConnection(post, {
                            onSuccess: () => {
                                if (data.hour_counter) {
                                    saveSensorUnitHourCounter(
                                        {
                                            unitId: selectedUnit.value,
                                            data: {
                                                hour_counter: Number(data.hour_counter),
                                            },
                                        },
                                        { onSuccess: navigateToSensorsList }
                                    );
                                } else {
                                    navigateToSensorsList();
                                }
                            },
                        });
                    }
                } else {
                    navigateToSensorsList();
                }
            },
        });
    }

    function navigateToSensorsList() {
        navigate("/administration/setup/sensors");
    }

    async function handleDeleteConnectionRequest() {
        const wantsToDelete = window.confirm(t("confirm_deleting_sensor_unit_connection"));

        if (wantsToDelete) {
            deleteSensorUnitConnection(sensorId, {
                onSuccess: () => toast.success(t("sensor_unit_connection_removed")),
            });
        }
    }

    return (
        <MainArea>
            <Heading>{sensorId === 0 ? t("register_new_sensor") : t("edit_sensor")}</Heading>

            <QueryWrapper data={sensorId !== 0 ? sensor : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <CustomerSelect
                            name={"customer_id"}
                            label={t("choose_customer")}
                            selectUse={useCustomers}
                            validate={{ required: t("customer_required") }}
                        />
                        <InputWithLabelStyled
                            name="ident"
                            label={t("sensor_id")}
                            validate={{ required: t("sensor_id_required") }}
                        />
                        <InputWithLabelStyled name="manufacturer" label={t("manufacturer")} />
                        <InputWithLabelStyled name="type" label={t("type")} />
                        <ConnectionContainer>
                            <UnitConnectionSelect
                                name="unit"
                                label={t("connect_to_vehicle")}
                                selectUse={useUnits}
                                listKey="int_id"
                                filter={{ customer_id: selectedCustomer?.value }}
                                isClearable={false}
                            />
                            <CleanButton
                                type="button"
                                disabled={
                                    !(selectedUnit?.value === activeSensorUnitId) ||
                                    !selectedUnit?.value
                                }
                                onClick={handleDeleteConnectionRequest}
                            >
                                <TB $link $underline>
                                    {t("remove_sensor_connection")}
                                </TB>
                            </CleanButton>
                        </ConnectionContainer>

                        {isSelectedUnitDifferentThanSaved ? (
                            <HourCounterExpandButton
                                type="button"
                                onClick={() => setIsHourCounterEditOpen((prev) => !prev)}
                            >
                                <TB $link $underline>
                                    {t("update_hour_counter")}
                                    <DownArrowIcon
                                        className={isHourCounterEditOpen ? "expanded" : ""}
                                    />
                                </TB>
                            </HourCounterExpandButton>
                        ) : null}

                        {isSelectedUnitDifferentThanSaved && isHourCounterEditOpen ? (
                            <>
                                <HourCounterInfoText>
                                    <T>{`${t("most_recent_hour_counter_value")}${
                                        hourCounter.data?.value
                                            ? ` (${formatDate(
                                                  new Date(hourCounter.data.timestamp),
                                                  "dd.MM.yy"
                                              )}): `
                                            : ":"
                                    } `}</T>
                                    <br />

                                    <TB>
                                        {hourCounter.data?.value
                                            ? `${hourCounter.data.value} t`
                                            : "–"}
                                    </TB>
                                </HourCounterInfoText>

                                <HourCounterInput
                                    name="hour_counter"
                                    label={t("updated_hour_counter")}
                                    type="number"
                                />
                            </>
                        ) : null}

                        <InputCheckboxStyled name="active" label={t("active_sensor")} />
                        <SaveOrAbortWrapper>
                            <SaveOrAbort
                                saving={
                                    isSavingSensor ||
                                    isSavingSensorUnitConnection ||
                                    isDeletingSensorUnitConnection ||
                                    isSavingSensorUnitHourCounter
                                }
                            />
                        </SaveOrAbortWrapper>
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default SensorEdit;

const InputWithLabelStyled = styled(InputWithLabel)`
    max-width: 30rem;
`;

const HourCounterInput = styled(InputWithLabel)`
    max-width: 10rem;
    margin-top: 1rem;
`;

const CustomerSelect = styled(ServerSelect)`
    max-width: 30rem;
`;

const UnitConnectionSelect = styled(ServerSelect)`
    min-width: 15rem;
    max-width: 25rem;
    margin-right: 1rem;
`;

const ConnectionContainer = styled.section`
    display: flex;
`;

const InputCheckboxStyled = styled(InputCheckbox)`
    margin-top: 1.5rem;
`;

const Heading = styled(H1)`
    margin-bottom: 2rem;
`;

const SaveOrAbortWrapper = styled.section`
    margin-top: -0.5rem;
`;

const HourCounterExpandButton = styled(CleanButton)`
    display: block;

    ${DownArrowIcon} {
        margin-left: 0.5rem;
        transform: rotate(-90deg);
        transition: 300ms ease;

        &.expanded {
            transform: rotate(90deg);
        }
    }
`;

const HourCounterInfoText = styled.section`
    margin-top: 2rem;
`;

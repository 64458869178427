import { useNavigate } from "react-router";
import { useState } from "react";

export const useSaving = () => {
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState(null);

    const navigate = useNavigate();

    const save = ({ api, post, ok, onOk }) => {
        setSaving(true);
        setErrors(null);

        api(post)
            .then(() => {
                setSaving(false);
                if (ok) navigate(ok);
                if (onOk) onOk();
            })
            .catch((err) => {
                setErrors(err.response?.data?.message);
                setSaving(false);
            });
    };

    return { saving, save, postErrors: errors };
};

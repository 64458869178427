import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 20px;
    height: 20px;
`;

export const ClockNextIcon = () => {
    return (
        <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M11 4.75V11L13.8125 13.8125" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M2.25 11H3.5" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M18.5 11H19.75" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M11 19.75V18.5" stroke="#38558D" strokeMiterlimit="10" />
            <path d="M11 3.5V2.25" stroke="#38558D" strokeMiterlimit="10" />
            <path
                d="M11 20.6875C16.3503 20.6875 20.6875 16.3503 20.6875 11C20.6875 5.64969 16.3503 1.3125 11 1.3125"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M11 20.6875C5.64969 20.6875 1.3125 16.3503 1.3125 11C1.3125 8.90216 1.97937 6.9606 3.11219 5.37497L4.5 4"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M5.0625 6.9375V3.5H1.625"
                stroke="#38558D"
                strokeMiterlimit="10"
                strokeLinejoin="bevel"
            />
        </Svg>
    );
};

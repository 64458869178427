import { createContext, useContext, useState } from "react";

const MapHighlightContext = createContext();

export const useMapHighlight = () => useContext(MapHighlightContext);

export const MapHighlightProvider = ({ children }) => {
    const [highlightedVehicleId, setHighlightedVehicleId] = useState(null);
    const [highlightedTime, setHighlightedTime] = useState(null);
    const [closestDataPointIndexForUnit, setClosestDataPointIndexForUnit] = useState(null);

    return (
        <MapHighlightContext.Provider
            value={{
                highlightedVehicleId,
                highlightedTime,
                closestDataPointIndexForUnit,
                setHighlightedVehicleId,
                setHighlightedTime,
                setClosestDataPointIndexForUnit,
            }}
        >
            {children}
        </MapHighlightContext.Provider>
    );
};

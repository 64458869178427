import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { useCustomersWithPermission } from "../../../features/admin/customers/hooks/useCustomersWithPermission";
import { PERMISSIONS } from "../../../features/auth/permissions";
import QueryWrapper from "../QueryWrapper";

import SelectStyled from "../styles/SelectStyled";

const WorkshopConfigSelect = ({ defaultWorkshopId, ...props }) => {
    const [options, setOptions] = useState(null);
    const { t } = useTranslation();

    const customers = useCustomersWithPermission({
        permission: PERMISSIONS.config_admin.name,
    });

    useEffect(() => {
        if (customers.data) {
            setOptions(getOptionsFromData(customers.data));
        }
    }, [customers?.data]);

    const getOptionsFromData = (data) => {
        return data.map((item) => ({ label: item.name, value: item.id }));
    };

    const getDefaultWorkshopData = (id) => {
        const workshopData = options?.find((item) => item.value === id);
        return workshopData || "";
    };

    return (
        <QueryWrapper data={customers}>
            <SelectStyled>
                <Select
                    value={getDefaultWorkshopData(defaultWorkshopId)}
                    options={options}
                    placeholder={t("choose_workshop")}
                    noOptionsMessage={() => t("no_results")}
                    id="workshop_id"
                    name="workshop_id"
                    classNamePrefix={"rs"}
                    {...props}
                />
            </SelectStyled>
        </QueryWrapper>
    );
};

export default WorkshopConfigSelect;

import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { TB } from "../../components/texts";
import { weekNumber, getDate, hasSameWeek } from "../../common/date";
import { shouldTaskAppearInCalendar } from "./helpers";

const CalendarRight = ({ from_date, mechanics, tasks }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const MechanicsHours = useMemo(() => {
        return mechanics.map((mechanic) => (
            <div
                key={mechanic.id}
                className={"mechanic-hours"}
                style={{ height: `${mechanic.rows * theme.scheduler.cellHeight}px` }}
            >
                <TB>
                    {tasks
                        .filter(
                            (task) =>
                                task.mechanic_id === mechanic.id && shouldTaskAppearInCalendar(task)
                        )
                        .filter((task) => hasSameWeek(getDate(task.start), from_date))
                        .reduce((accumulator, task) => accumulator + task.duration, 0)}
                </TB>
            </div>
        ));
    }, [from_date, tasks, mechanics]);

    const sumTotalHours = useMemo(() => {
        return tasks
            .filter((task) => shouldTaskAppearInCalendar(task))
            .filter((task) => hasSameWeek(getDate(task.start || ""), from_date))
            .reduce((accumulator, task) => accumulator + task.duration, 0);
    }, [from_date, tasks]);

    return (
        <CalendarRightStyled $mechanics={mechanics}>
            <div className={"header"}>
                <div className={"cell week"}>
                    <TB>
                        {t("week", "Week")} {weekNumber(from_date)}
                    </TB>
                </div>
            </div>

            <div className={"header"}>
                <div className={"cell sum"}>
                    <TB>{t("sum_hours", "Sum hours")}</TB>
                </div>
            </div>

            <div className={"schedule"}>{MechanicsHours}</div>

            <div className={"header"}>
                <div className={"cell sum"}>
                    <TB>{sumTotalHours}</TB>
                </div>
            </div>
        </CalendarRightStyled>
    );
};

export default CalendarRight;

const CalendarRightStyled = styled.div`
    width: ${(p) => p.rightWidth || p.theme.scheduler.rightWidth}px;
    border-left: ${(p) =>
        `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};

    // Add shadow to left edge only
    box-shadow: -3px 0 4px rgba(0, 0, 0, 0.15);
    clip-path: inset(0px 0px 0px -10px);

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: ${(p) => p.cellHeight || p.theme.scheduler.cellHeight}px;

        .cell {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 0.625rem;
            width: ${(p) => p.dayWidth || p.theme.scheduler.dayWidth}px;
            border-bottom: ${(p) =>
                `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};
            height: ${(p) => p.cellHeight || p.theme.scheduler.cellHeight}px;
        }
    }

    .schedule {
        border-bottom: 2px solid ${(p) => p.theme.color.neutral.base};
        height: ${(p) =>
            `${
                p.$mechanics.reduce((accumulator, item) => accumulator + item.rows, 0) *
                p.theme.scheduler.cellHeight
            }px`};
        padding-right: 0.625rem;

        > div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom: ${(p) =>
                `${p.theme.scheduler.borderWidth}px solid ${p.theme.color.neutral.xlight}`};
        }

        > div:last-child {
            border-bottom: unset;
        }
    }
`;

import { useEffect, useState } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import QueryWrapper from "../../../components/application/QueryWrapper";
import { useServiceConfigsForCustomer } from "./hooks/useServiceConfigsForCustomer";
import { useServiceConfigTemplates } from "./hooks/useServiceConfigTemplates";
import { useServiceConfig } from "./hooks/useServiceConfig";
import { useSaveServiceConfig } from "./mutations/useSaveServiceConfig";
import { useDeleteServiceConfig } from "./mutations/useDeleteServiceConfig";

import { InputWithLabel, SelectForm } from "../../../components/inputs";
import { Button, CleanButton } from "../../../components/buttons";
import { FlexGap } from "../../../components/helpers/FlexGap";
import { TB } from "../../../components/texts";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import ConfigInterval from "./ConfigInterval";
import ConfirmModal from "../../../components/application/ConfirmModal";

const ConfigSetup = ({ customerId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [intervals, setIntervals] = useState([]);
    const [intervalOptions, setIntervalOptions] = useState([]);

    const { serviceId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const methods = useForm();

    const configs = useServiceConfigsForCustomer(customerId);
    const configTemplates = useServiceConfigTemplates();
    const selectedConfig = useServiceConfig(+serviceId);
    const { mutate: saveConfig, isLoading: isConfigSaving } = useSaveServiceConfig({
        onSuccess: () => navigate("/administration/setup/service"),
    });
    const { mutate: deleteConfig, isLoading: isDeletingConfig } = useDeleteServiceConfig({
        onSuccess: () => {
            setIsModalOpen(false);
            navigate("/administration/setup/service");
        },
    });

    const { fields, append, prepend, remove, replace } = useFieldArray({
        control: methods.control,
        name: "intervals",
        keyName: "key",
    });

    // Set up all config & interval options for copying checkpoints from other configs/intervals
    useEffect(() => {
        if (configs?.data && configTemplates?.data) {
            const allConfigs =
                customerId === 1 ? configs.data : [...configTemplates.data, ...configs.data];

            const configOptions = allConfigs
                .filter((config) => config.id !== +serviceId)
                ?.map((config) => ({
                    label:
                        customerId === 1 || config.customer_id !== 1
                            ? config.name
                            : `${config.name} (systemmal)`,

                    value: config.id,
                }));
            setTemplateOptions(configOptions);

            let allIntervals = [];

            allConfigs?.forEach((config) =>
                config.intervals?.forEach((element) =>
                    allIntervals.push({
                        ...element,
                        name: `${config.name} - ${element.interval} ${
                            element.customer_id === 1 && customerId !== 1
                                ? `(${t("system_template")})`
                                : ""
                        }`,
                    })
                )
            );
            setIntervals(allIntervals);
        }
    }, [configs?.data, configTemplates?.data]);

    useEffect(() => {
        if (intervals.length > 0) {
            const options = intervals.map((interval) => ({
                label: interval.name,
                value: interval.id,
                checkpoints: interval.checkpoints,
            }));
            setIntervalOptions(options);
        }
    }, [intervals]);

    // Add intervals based on data from selected config or add an empty interval when creating a new config
    useEffect(() => {
        if (selectedConfig?.data) {
            const data = selectedConfig.data;

            methods.reset({
                id: data.id,
                name: data.name,
            });

            if (data.intervals && data.intervals.length > 0) {
                append(
                    data.intervals
                        .sort((a, b) => a.interval - b.interval)
                        .map((interval) => ({
                            id: interval.id,
                            hours: interval.interval,
                            workhours: interval.workhours,
                            checkpoints: interval.checkpoints?.map((item) => String(item)),
                        }))
                );
            }
        } else if (!+serviceId) {
            append({});
        }
    }, [selectedConfig?.data]);

    function handleConfigTemplateChange(configId) {
        const selectedTemplate =
            configs?.data?.find((item) => item.id === configId) ||
            configTemplates?.data?.find((item) => item.id === configId);

        replace(
            selectedTemplate.intervals.map((interval) => ({
                hours: interval.interval,
                workhours: interval.workhours,
                checkpoints: interval.checkpoints?.map((item) => String(item)),
            }))
        );
    }

    function onSubmit(data) {
        const post = {
            name: data.name,
        };

        if (selectedConfig?.data?.id) {
            post.id = selectedConfig.data.id;
        } else {
            post.customer_id = customerId;
        }

        if (data.intervals && data.intervals.length > 0) {
            post.intervals = data.intervals
                .map((interval) => {
                    const preparedInterval = {
                        interval: +interval.hours,
                        workhours: +interval.workhours || null,
                        checkpoints: interval.checkpoints
                            ? interval.checkpoints.map((item) => +item)
                            : [],
                    };

                    if (interval.id) preparedInterval.id = interval.id;

                    return preparedInterval;
                })
                .sort((a, b) => a.hours - b.hours);
        }

        saveConfig(post);
    }

    function askToRemoveConfig() {
        setIsModalOpen(true);
    }

    function addInterval() {
        append({});
    }

    function addBaseInterval() {
        prepend({ hours: 0 });
    }

    function removeInterval(id) {
        remove(id);
    }

    return (
        <>
            <QueryWrapper data={+serviceId ? selectedConfig : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <FlexGap $count={3} gap={"1.5rem"}>
                            <InputWithLabel
                                label={t("title_of_service_config")}
                                name="name"
                                validate={{ required: t("name_required") }}
                            />

                            <SelectContainer>
                                <SelectForm
                                    name="service-template"
                                    label={`${t("get_from")}:`}
                                    options={templateOptions}
                                    onChange={(option) => handleConfigTemplateChange(option.value)}
                                />
                            </SelectContainer>

                            {selectedConfig?.data?.id ? (
                                <DeleteButtonContainer>
                                    <CleanButton type="button" onClick={askToRemoveConfig}>
                                        <TB $link $underline>
                                            {t("remove_config")}
                                        </TB>
                                    </CleanButton>
                                </DeleteButtonContainer>
                            ) : null}
                        </FlexGap>

                        {fields?.map((field, index) => (
                            <ConfigInterval
                                key={field.key}
                                fieldKey={field.key}
                                customerId={customerId}
                                removeInterval={() => removeInterval(index)}
                                intervalIndex={index}
                                intervalOptions={intervalOptions}
                            />
                        ))}

                        <AddIntervalButton onClick={addInterval}>
                            {t("add_new_interval")}
                        </AddIntervalButton>

                        {fields?.[0]?.hours === 0 ? null : (
                            <CleanButton type="button" onClick={addBaseInterval}>
                                <TB $link $underline>
                                    {t("add_base_setup")}
                                </TB>
                            </CleanButton>
                        )}

                        <SaveOrAbort saving={isConfigSaving} />
                    </form>
                </FormProvider>
            </QueryWrapper>

            {isModalOpen && (
                <ConfirmModal
                    isModalOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    onConfirm={() => deleteConfig(selectedConfig?.data?.id)}
                    text={t("sure_you_want_to_remove_config")}
                    isLoading={isDeletingConfig}
                />
            )}
        </>
    );
};

export default ConfigSetup;

const SelectContainer = styled.section`
    min-width: 22rem;
`;

const DeleteButtonContainer = styled.section`
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const AddIntervalButton = styled(Button).attrs({ type: "button" })`
    margin-bottom: 2rem;
    margin-right: 1.5rem;
`;

import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useCustomer } from "../admin/customers/hooks/useCustomer";
import { TaskStatus } from "../../api/core/taskAPI";

import { TB, T } from "../../components/texts";

const TaskEditResultExtraInfo = ({
    partsCost,
    additionalExpensesCost,
    invoiceTotal,
    unitHourCounter,
    status,
}) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();

    const duration = watch("duration");
    const overtime = watch("overtime");
    const customer_id = watch("customer_id")?.value;

    const { data: customerData } = useCustomer(customer_id);

    const hoursCost = useMemo(() => {
        if (!customerData || !duration) return 0;

        let hourlyRate = customerData?.price_0;

        if (+overtime === 100) {
            hourlyRate = customerData?.price_100;
        } else if (+overtime === 50) {
            hourlyRate = customerData?.price_50;
        }

        return +duration * (hourlyRate || 0);
    }, [customerData, overtime, duration]);

    const totalSum = (hoursCost || 0) + (partsCost || 0) + (additionalExpensesCost || 0);

    function formatCostForDisplay(cost) {
        return `kr ${cost.toLocaleString("no")}${Number.isInteger(cost) ? ",-" : ""}`;
    }

    return (
        <Container>
            <EconomyList>
                <TB>{t("economy")}:</TB>

                {status === TaskStatus.INVOICED ? (
                    <>
                        <li>
                            <LabelText>{t("sum_invoiced")}: </LabelText>
                            <TB>{invoiceTotal ? formatCostForDisplay(invoiceTotal) : "–"}</TB>
                        </li>
                        <br />
                        <li>
                            <LabelText>{t("hour_counter_when_invoiced")}:</LabelText>
                            <TB>
                                {unitHourCounter
                                    ? `${unitHourCounter} ${t("hours_unit_symbol")}`
                                    : "–"}
                            </TB>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            <LabelText>{t("personnel")}: </LabelText>
                            <TB>{formatCostForDisplay(hoursCost)}</TB>
                        </li>

                        <li>
                            <LabelText>{t("material")}: </LabelText>
                            <TB>{formatCostForDisplay(partsCost)}</TB>
                        </li>

                        <li>
                            <LabelText>{t("additional_expenses")}: </LabelText>
                            <TB>{formatCostForDisplay(additionalExpensesCost)}</TB>
                        </li>

                        <li>
                            <LabelText>{t("sum")}: </LabelText>
                            <TB>{formatCostForDisplay(totalSum)}</TB>
                        </li>
                    </>
                )}
            </EconomyList>
        </Container>
    );
};

export default TaskEditResultExtraInfo;

const Container = styled.section`
    padding: 1rem 2rem;
`;

const EconomyList = styled.ul`
    margin-top: 2rem;
    list-style-type: none;
    padding-left: 0;

    > ${TB} {
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    li {
        padding-left: 2rem;
        line-height: 1.5;
    }
`;

const LabelText = styled(T)`
    display: inline-block;
    width: 14rem;
`;

import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 18px;
    height: 18px;
`;

export const InfoIcon = () => {
    return (
        <Svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.57912 16.0335C0.139628 12.5945 0.139628 7.01868 3.57912 3.57947C7.01832 0.139701 12.5945 0.139702 16.0337 3.57947C19.4732 7.0184 19.4732 12.5942 16.0337 16.0335C12.5945 19.4732 7.01861 19.4732 3.57912 16.0335Z"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M9.80664 14.0684L9.80664 7.81836"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M9.80664 6.68164L9.80664 5.54528"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </Svg>
    );
};

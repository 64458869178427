import { useEffect, useState } from "react";
import { debounceTime, map, distinctUntilChanged, switchMap, catchError } from "rxjs/operators";
import { BehaviorSubject, of, merge } from "rxjs";
import { useTranslation } from "react-i18next";

import unitAPI from "../../../api/core/unitAPI";
import { InputWithLabel } from "../../inputs";

const UnitSearchInput = ({ onChange }) => {
    const { t } = useTranslation();
    const [units, setUnits] = useState(null);
    const [subject, setSubject] = useState(null);

    useEffect(() => {
        if (subject === null) {
            const sub = new BehaviorSubject("");
            setSubject(sub);
        } else {
            const observable = subject
                .pipe(
                    map((s) => s.trim()),
                    distinctUntilChanged(),
                    debounceTime(200),
                    switchMap((term) => merge(of(unitAPI.getUnitSearch(term)))),
                    catchError(() => ({
                        loading: false,
                        errorMessage: "An application error occured",
                    }))
                )
                .subscribe(async (result) => {
                    const data = (await result).data;
                    const dataWithoutDefect = data.filter((item) => !item.defect);
                    const dataSortedByGroupName = dataWithoutDefect.sort((a, b) =>
                        a.group?.name?.localeCompare(b.group?.name)
                    );

                    setUnits(dataSortedByGroupName);
                });

            return () => {
                observable.unsubscribe();
                subject.unsubscribe();
            };
        }
    }, [subject]);

    useEffect(() => {
        if (onChange) onChange(units);
    }, [units]);

    const onChangeInput = (e) => {
        if (subject) {
            return subject.next(e.target.value);
        }
    };

    return <InputWithLabel name={"search"} label={t("searchforunit")} onChange={onChangeInput} />;
};

export default UnitSearchInput;

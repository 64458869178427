import { useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SelectForm } from "../../../components/inputs";
import { useUnitGroups } from "../../unit/hooks/useUnitGroups";

const UnitGroupsSelect = ({ customer_id, name, label, validate }) => {
    const { watch, setValue } = useFormContext();
    const { t } = useTranslation();
    const selectedGroupId = watch(name);

    const unitGroups = useUnitGroups();

    const options = useMemo(() => {
        if (!unitGroups?.data || !unitGroups?.data.length) return [];

        const groupsForCustomerAndTemplates = unitGroups.data.filter(
            (group) => group.customer_id === customer_id || group.customer_id === 1
        );

        const groupOptions = groupsForCustomerAndTemplates
            .sort((a, b) => a.name?.localeCompare(b.name))
            .map((group) => ({
                value: group.id,
                label:
                    group.customer_id === 1 && customer_id !== 1
                        ? `${group.name} (${t("system_template")})`
                        : group.name,
            }));

        return groupOptions;
    }, [unitGroups?.data, customer_id]);

    useEffect(() => {
        if (options?.length && selectedGroupId && !selectedGroupId.value) {
            setValue(
                name,
                options.find((item) => item.value === selectedGroupId)
            );
        }
    }, [selectedGroupId, options]);

    return (
        <SelectForm
            key={customer_id}
            name={name}
            label={label}
            options={options}
            validate={validate}
        />
    );
};

export default UnitGroupsSelect;

import { updateData, fetchData, deleteEntry } from "../fetch";

const fetchExtra = async (id) => fetchData(`core/extras/${id}`);
const fetchExtras = async (params) => fetchData(`core/extras${params || ""}`);
const updateExtra = async (data) => updateData(data, `core/extras`);
const deleteExtra = async (id) => deleteEntry(id, `core/extras`);

export default {
    fetchExtra,
    fetchExtras,
    updateExtra,
    deleteExtra,
};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import authAPI from "../../../api/authAPI";

import { H1, Text, TB } from "../../../components/texts";
import { InputWithLabel } from "../../../components/inputs";
import { CleanButton, DarkButton } from "../../../components/buttons";
import { useForm, FormProvider } from "react-hook-form";

const ResetPasswordRequest = ({ remove, cancel }) => {
    const [email, setEmail] = useState("");
    const { t } = useTranslation();

    const methods = useForm();

    const sendEmail = (e) => {
        e.preventDefault();

        authAPI.resetPasswordRequest(email).then(() => {
            if (remove) remove();
        });
    };

    return (
        <ResetPasswordRequestStyled>
            <H1 $second>{t("forgot_password")}</H1>
            <Text $second>{t("forgot_password_description")}</Text>
            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        second
                        name={"email"}
                        label={t("email_long")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className={"submit"}>
                        <DarkButton type="submit" onClick={(e) => sendEmail(e)}>
                            {t("send_email")}
                        </DarkButton>
                        <CleanButton onClick={() => cancel()}>
                            <TB $second $underline $link>
                                {t("cancel")}
                            </TB>
                        </CleanButton>
                    </div>
                </form>
            </FormProvider>
        </ResetPasswordRequestStyled>
    );
};

export default ResetPasswordRequest;

const ResetPasswordRequestStyled = styled.div`
    form {
        margin-top: 10px;
    }
    .submit {
        button {
            margin: 20px 20px 20px 0;
        }
    }
`;

import styled from "styled-components";

import { CleanButton } from "../../../components/buttons";
import { Input } from "../../../components/inputs";
import { T } from "../../../components/texts";
import { useEffect, useState } from "react";

const InspectionEditableCell = ({
    getValue,
    row: { original },
    column: { id, columnDef },
    table,
}) => {
    const initialValue = getValue();

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [isInEditMode, setIsInEditMode] = useState(false);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        table.options.meta?.updateData(original.id, id, value);
        setIsInEditMode(false);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return isInEditMode ? (
        <>
            <LabelStyled>{columnDef.header}</LabelStyled>
            <InputStyled
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onBlur={onBlur}
                autoFocus
            />
        </>
    ) : (
        <EditButton onClick={() => setIsInEditMode(true)}>
            <T>{getValue()}</T>
        </EditButton>
    );
};

export default InspectionEditableCell;

const InputStyled = styled(Input)`
    width: calc(100% - 1rem);
`;

const LabelStyled = styled.label`
    display: block;
    padding-bottom: 0.3rem;
`;

const EditButton = styled(CleanButton)`
    min-width: 100%;
    min-height: 100%;
    text-align: left;
`;

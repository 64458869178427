import { useMemo, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import styled from "styled-components";

import { useUnits } from "../../unit/hooks/useUnits";

import MainArea from "../../../components/layout/MainArea";
import { H1, T, TB } from "../../../components/texts";
import UnitStatusHistory from "./UnitStatusHistory";
import UnitStatusLive from "./UnitStatusLive";

const UnitStatus = () => {
    const [selectedUnits, setSelectedUnits] = useState([]);

    const { t } = useTranslation();

    const units = useUnits();

    const unitOptions = useMemo(() => {
        if (!units?.data || !units.data.length) return [];

        const unitsInGroups = sortUnitsIntoGroupOptions(units.data);

        const unitsFiltered = unitsInGroups.filter((group) => {
            const allOptionsForGroup = group.options.filter((option) => option.type !== "group");

            const selectedGroupUnits = selectedUnits?.filter((unit) =>
                allOptionsForGroup.some((option) => option.value === unit.value)
            );
            const isAllGroupUnitsSelected = selectedGroupUnits.length === allOptionsForGroup.length;
            return !isAllGroupUnitsSelected;
        });

        return unitsFiltered;
    }, [units?.data, selectedUnits]);

    function sortUnitsIntoGroupOptions(units) {
        if (!units || !units.length) return [];

        let groups = [];

        units.forEach((unit) => {
            const groupName = unit.group?.name;

            if (groups.some((item) => item.label === groupName)) {
                groups
                    .find((item) => item.label === groupName)
                    ?.options.push({ label: unit.int_id, value: unit.id });
            } else {
                groups.push({
                    label: groupName,
                    options: [{ label: unit.int_id, value: unit.id }],
                });
            }
        });

        groups.forEach((group) =>
            group.options.unshift({
                label: `${t("choose_all")} - ${group.label}`,
                type: "group",
                value: group.label,
            })
        );

        return groups;
    }

    function handleSelectChange(selected) {
        const isGroupSelected = selected.some((option) => option.type === "group");

        if (isGroupSelected) {
            const selectedGroup = selected.find((option) => option.type === "group");
            const allUnitsInGroup = unitOptions.find(
                (item) => item.label === selectedGroup.value
            ).options;

            const selectionWithAllUnitsFromGroup = [
                ...selected.filter(
                    (item) => !allUnitsInGroup.some((unit) => unit.value === item.value)
                ),
                ...allUnitsInGroup,
            ];

            const selectionWithoutGroup = selectionWithAllUnitsFromGroup.filter(
                (item) => item.type !== "group"
            );
            setSelectedUnits(selectionWithoutGroup);
        } else {
            setSelectedUnits(selected);
        }
    }

    return (
        <MainArea>
            <H1>{t("unit_status")}</H1>

            <Navigation>
                <NavLink end to="/units/status">
                    <TB $link> {t("history")}</TB>
                </NavLink>

                <NavLink to="/units/status/live">
                    <TB $link>{t("live_view")}</TB>
                </NavLink>
            </Navigation>

            <label htmlFor="units">
                <T>{t("vehicle")}</T>
            </label>
            <UnitSelectContainer>
                <Select
                    name="units"
                    options={unitOptions}
                    placeholder={t("choose_vehicles")}
                    classNamePrefix="rs"
                    noOptionsMessage={() => t("no_results")}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    onChange={handleSelectChange}
                    value={selectedUnits}
                    closeMenuOnSelect={false}
                    isMulti
                />
            </UnitSelectContainer>

            <Routes>
                <Route path="/live" element={<UnitStatusLive selectedUnits={selectedUnits} />} />
                <Route
                    index={true}
                    element={
                        <UnitStatusHistory
                            selectedUnits={selectedUnits}
                            setSelectedUnits={setSelectedUnits}
                        />
                    }
                />
            </Routes>
        </MainArea>
    );
};

export default UnitStatus;

const Navigation = styled.section`
    border: 2px solid ${(p) => p.theme.color.primary.base};
    display: flex;
    flex-wrap: wrap;
    width: max-content;
    margin-bottom: 1rem;

    a {
        padding: 0.5rem 1rem;
        text-decoration: none;

        &.active {
            background: ${(p) => p.theme.color.primary.xlight};
        }
    }
`;

const UnitSelectContainer = styled.div`
    max-width: 25.65rem;

    .rs__control,
    rs__control:hover {
        border: 2px solid #6d8ac5 !important;
        box-shadow: unset;
        border-radius: 0;
    }

    .rs__control--is-disabled {
        background-color: #eee;
    }

    .rs__single-value--is-disabled {
        color: ${(p) => p.theme.color.neutral.base};
    }

    .rs__multi-value__label,
    .rs__multi-value__remove {
        font-size: 1rem;
        font-weight: bold;
        color: ${(p) => p.theme.color.primary.base};
        background: ${(p) => p.theme.color.primary.xlight};
    }

    .rs__multi-value__label {
        padding: 0.4rem;
        padding-left: 0.6rem;
        padding-right: 0.2rem;
    }

    .rs__multi-value__remove {
        &:hover {
            color: ${(p) => p.theme.color.primary.base};
            background: ${(p) => p.theme.color.primary.xxlight};
        }
        > svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
`;

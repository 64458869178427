import { useFormContext } from "react-hook-form";
import styled, { useTheme } from "styled-components";

import { T } from "../texts";
import Spacer from "../helpers/Spacer";
import { DotIcon } from "../icons/DotIcon";

export const Traffic123 = ({ label, name, hideLabel, isDisabled, addLabelDecorator = false }) => {
    const theme = useTheme();
    const { register, setValue, watch } = useFormContext();
    const checked = watch(name);

    function handleClick(value) {
        if (isDisabled) return;
        setValue(name, "" + value, { shouldDirty: true });
    }

    const labelDecoration = (() => {
        if (!addLabelDecorator) return undefined;
        if (checked === 1) return <DotIcon color={theme.color.ok.base} height={16} width={16} />;
        if (checked === 2)
            return <DotIcon color={theme.color.warning.base} height={16} width={16} />;
        if (checked === 3) return <DotIcon color={theme.color.error.base} height={16} width={16} />;
        return undefined;
    })();
    return (
        <Traffic123Styled>
            {hideLabel ? null : (
                <>
                    <CenterVertical>
                        <CenterHorizontal>
                            {labelDecoration}
                            <label>
                                <T>{label}</T>
                            </label>
                        </CenterHorizontal>
                    </CenterVertical>
                    <Spacer />
                </>
            )}

            <Container $typeColor={theme.color.ok.base}>
                <Input {...register(name)} type="radio" value={"1"} disabled={isDisabled} />
                <Checkmark checked={Number(checked) === 1} onClick={() => handleClick(1)} />
            </Container>
            <Container $typeColor={theme.color.warning.base}>
                <Input {...register(name)} type="radio" value={"2"} disabled={isDisabled} />
                <Checkmark checked={Number(checked) === 2} onClick={() => handleClick(2)} />
            </Container>
            <Container $typeColor={theme.color.error.base}>
                <Input {...register(name)} type="radio" value={"3"} disabled={isDisabled} />
                <Checkmark checked={Number(checked) === 3} onClick={() => handleClick(3)} />
            </Container>
        </Traffic123Styled>
    );
};

const CenterHorizontal = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

const CenterVertical = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Traffic123Styled = styled.div`
    display: flex;
    flex-direction: row;
`;

const Container = styled.span`
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 44px;
    height: 44px;
    background-color: #e2e8f3;
    border-bottom: 2px solid ${(props) => props.$typeColor};
    border-left: 2px solid white;
`;

const Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const Checkmark = styled.span`
    position: absolute;
    top: 9px;
    left: 9px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-image: ${(props) =>
        props.checked ? "url(/svg/radio-checked.svg)" : "url(/svg/radio.svg)"};
`;

import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUserConnections } from "../../users/hooks/useUserConnections";
import { useCustomers } from "../hooks/useCustomers";
import QueryWrapper from "../../../../components/application/QueryWrapper";

import { device } from "../../../../theme";
import { T, TB } from "../../../../components/texts";
import { CleanButton } from "../../../../components/buttons";
import Underline from "../../../../components/helpers/Underline";
import ServerSelect from "../../../../components/application/ServerSelect";

const CustomerConnections = ({ customer_id }) => {
    const { control } = useFormContext();
    const { t } = useTranslation();

    const connections = useUserConnections(customer_id);
    const customers = useCustomers();

    const {
        fields,
        append,
        remove: removeConnection,
    } = useFieldArray({
        control,
        name: "connections",
        keyName: "fieldId",
    });

    useEffect(() => {
        if (connections.data && connections.data.length && customers.data && fields.length === 0) {
            append(
                connections.data?.map((item) => ({
                    value: item.host_id,
                    label:
                        customers.data.find((element) => element.id === item.host_id)?.name ||
                        "Ukjent",
                    status: item.status || "-",
                    id: item.id,
                }))
            );
        }
    }, [connections?.data, customers?.data, fields]);

    function addConnection() {
        append({ value: 0, label: "" });
    }

    return (
        <WrapperStyled>
            <Header>
                <TB>{t("workshop")}</TB>
                <TB>{t("status")}</TB>
            </Header>
            <Underline $second />
            <QueryWrapper data={[customers, connections]}>
                <List>
                    {fields.map((field, index) => (
                        <div key={field.fieldId}>
                            <ListItem>
                                <SelectWrapper>
                                    <ServerSelect
                                        name={`connections[${index}]`}
                                        defaultValue={{ ...fields[index] }}
                                        selectUse={useCustomers}
                                        placeholder={t("select")}
                                        isClearable={false}
                                        hideErrorLabel
                                    />
                                </SelectWrapper>

                                <T>{field.status || "-"}</T>

                                <DeleteButton type="button">
                                    <TB $link $underline onClick={() => removeConnection(index)}>
                                        {t("delete")}
                                    </TB>
                                </DeleteButton>
                            </ListItem>
                            <Underline $second />
                        </div>
                    ))}
                </List>

                <CleanButton type="button" onClick={addConnection}>
                    <TB $underline $link>
                        + {t("add_workshops")}
                    </TB>
                </CleanButton>
            </QueryWrapper>
        </WrapperStyled>
    );
};

export default CustomerConnections;

const WrapperStyled = styled.article`
    width: 50%;
    margin-bottom: 0.5rem;

    @media ${device.mobile} {
        width: 100%;
    }
`;

const Header = styled.section`
    margin: 0.5rem 0;
    display: flex;

    > span:first-child {
        flex-basis: 65%;
    }
`;

const List = styled.section`
    margin-bottom: 1rem;
`;

const ListItem = styled.div`
    display: flex;
    margin: 0.5rem 0;
    align-items: center;

    > ${T} {
        flex-basis: 26%;
    }
`;

const SelectWrapper = styled.div`
    flex-basis: 65%;
    padding-right: 2rem;
`;

const DeleteButton = styled.button`
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
`;

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
    useReactTable,
    getSortedRowModel,
    getPaginationRowModel,
    getCoreRowModel,
    getFilteredRowModel,
    getGroupedRowModel,
    flexRender,
} from "@tanstack/react-table";

import { useScheduledTasksForMechanicBetween } from "../task/hooks/useScheduledTasksForMechanicBetween";
import { usePatchTask } from "../task/mutations/usePatchTask";
import { useMyUser } from "../admin/users/hooks/useMyUser";
import { hasSameDay, getDate, startOfWeek, monthAndYear, weekNumber } from "../../common/date";
import QueryWrapper from "../../components/application/QueryWrapper";

import { H1, TB, T } from "../../components/texts";
import { CleanButton, Button } from "../../components/buttons";
import MainArea from "../../components/layout/MainArea";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import TaskEdit from "../task/TaskEdit";
import { LesserThanIcon, GreaterThanIcon } from "../../components/icons";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { Horizontal } from "../../components/layout/FlexGrid";
import Spacer from "../../components/helpers/Spacer";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";
import StandardTableContent from "../../components/application/StandardTableContent";
import TaskPreCheckModal from "../task/TaskPreCheckModal";
import TaskPostCheckModal from "../task/TaskPostCheckModal";
import MyTasksButton from "./MyTasksButton";

const dateTimeDisplayOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
};

const timeDisplayOptions = {
    hour: "2-digit",
    minute: "2-digit",
};

const MyTasks = () => {
    const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);
    const [fromDate, setFromDate] = useState(startOfWeek(new Date()));
    const [taskPreCheck, setTaskPreCheck] = useState(null);
    const [taskPostCheck, setTaskPostCheck] = useState(null);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);

    const { t } = useTranslation();

    const { mutate: patchTask, isLoading: isUpdatingTask } = usePatchTask();

    const user = useMyUser();
    const myTasks = useScheduledTasksForMechanicBetween({
        mechanicId: user?.data?.id,
        fromDate: fromDate?.toISODate(),
        toDate: fromDate?.plus({ weeks: 1 }).toISODate(),
    });

    const data = useMemo(() => myTasks?.data || [], [myTasks?.data]);
    const columns = useMemo(
        () => [
            {
                header: t("time"),
                accessorKey: "start",
                cell: ({ getValue, row }) => {
                    const startDate = new Date(getValue());
                    const endDate = new Date(row.original?.end);

                    const startTimeOnly = startDate.toLocaleTimeString("no", timeDisplayOptions);
                    const endDateFormatted = endDate.toLocaleString("no", dateTimeDisplayOptions);
                    const endTimeOnly = endDate.toLocaleString("no", timeDisplayOptions);

                    return `${startTimeOnly} – ${
                        hasSameDay(getDate(startDate), getDate(endDate))
                            ? endTimeOnly
                            : endDateFormatted
                    }`;
                },
                getGroupingValue: (row) => {
                    const dateString = new Date(row.start).toLocaleDateString("no", {
                        weekday: "long",
                        day: "2-digit",
                        month: "short",
                    });
                    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
                },
                sortingFn: (rowA, rowB) => {
                    const aStart = new Date(rowA.original.start);
                    const bStart = new Date(rowB.original.start);
                    if (aStart > bStart) return 1;
                    if (aStart < bStart) return -1;
                    return 0;
                },
            },
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
                enableSorting: false,
                enableGrouping: false,
            },
            {
                header: t("description_2"),
                accessorKey: "description",
                enableSorting: false,
                enableGrouping: false,
            },
            {
                header: t("client"),
                accessorKey: "customer.name",
                enableSorting: false,
                enableGrouping: false,
            },
            {
                header: t("kitbox"),
                accessorKey: "kitting_box.name",
                enableSorting: false,
                enableGrouping: false,
            },
            {
                id: "action",
                accessorKey: "status",
                cell: ({ row }) => (
                    <MyTasksButton
                        task={row.original}
                        disabled={isUpdatingTask}
                        setTaskPreCheck={setTaskPreCheck}
                        setTaskPostCheck={setTaskPostCheck}
                        patchTask={patchTask}
                        openEditorForTask={openEditorForTask}
                    />
                ),
                enableSorting: false,
                enableGrouping: false,
            },
        ],
        []
    );

    const renderRowSubComponent = ({ row }) => {
        return (
            <ClickableRow
                $taskStatus={row.original?.status}
                onClick={() => {
                    openEditorForTask(row.original);
                }}
            >
                {row.getVisibleCells().map((cell) => (
                    <td>
                        <T>{flexRender(cell.column.columnDef.cell, cell.getContext())}</T>
                    </td>
                ))}
            </ClickableRow>
        );
    };

    const [grouping, setGrouping] = useState(["start"]);
    const initialState = useMemo(
        () => ({
            sorting: [{ id: "start", desc: false }],
            pagination: {
                pageSize: 40,
            },
            grouping: ["start"],
        }),
        []
    );
    const table = useReactTable({
        columns,
        data,
        initialState,
        enableGrouping: true,
        groupedColumnMode: "reorder",
        state: {
            grouping,
        },
        onGroupingChange: setGrouping,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    function openEditorForTask(task) {
        setEditTaskId(task.id);
        setTaskEditorInitialData(null);
        setIsTaskEditorOpen(true);
    }

    function closeTaskEditor(data = null) {
        setEditTaskId(null);
        setTaskEditorInitialData(data);
        setIsTaskEditorOpen(false);
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setIsTaskEditorOpen(true), 50);
    }

    function openEditorForNewTask() {
        setEditTaskId(null);
        setIsTaskEditorOpen(true);
    }

    return (
        <>
            {taskPreCheck !== null ? (
                <TaskPreCheckModal
                    isOpen={taskPreCheck !== null}
                    preCheckTemplate={taskPreCheck.template}
                    setPreCheckTemplate={(val) => {
                        const post = {
                            id: taskPreCheck.id,
                            pre_check_template: JSON.stringify(val),
                            status: "started",
                        };
                        patchTask(post);
                        setTaskPreCheck(null);
                    }}
                    onClose={() => setTaskPreCheck(null)}
                />
            ) : null}
            {taskPostCheck !== null ? (
                <TaskPostCheckModal
                    isOpen={taskPostCheck !== null}
                    postCheckTemplate={taskPostCheck.template}
                    setPostCheckTemplate={(val) => {
                        const post = {
                            id: taskPostCheck.id,
                            post_check_template: JSON.stringify(val),
                            status: "completed",
                        };
                        patchTask(post);
                        setTaskPostCheck(null);
                    }}
                    onClose={() => setTaskPostCheck(null)}
                />
            ) : null}
            <MainArea>
                <Horizontal>
                    <H1>{t("my_tasks")}</H1>
                    <Spacer />
                    <Button onClick={openEditorForNewTask}>
                        <TB $second>{t("new_request")}</TB>
                    </Button>
                </Horizontal>

                <TimeNav>
                    <CleanButton
                        onClick={() =>
                            setFromDate((currentDate) => currentDate?.minus({ weeks: 1 }))
                        }
                    >
                        <LesserThanIcon className="lt" />
                    </CleanButton>
                    <CleanButton
                        onClick={() =>
                            setFromDate((currentDate) => currentDate?.plus({ weeks: 1 }))
                        }
                    >
                        <GreaterThanIcon className="gt" />
                    </CleanButton>

                    <TB className="month">{monthAndYear(fromDate)}</TB>
                    <T className="week">{`${t("week")} ${weekNumber(fromDate)}`}</T>

                    {isUpdatingTask && <LoadingSpinner small light />}
                </TimeNav>

                <QueryWrapper data={[user, myTasks]}>
                    {rowModel.rows.length > 0 ? (
                        <div>
                            <TableContainer>
                                <TableStyled>
                                    <StandardTableContent
                                        headerGroups={headerGroups}
                                        rowModel={rowModel}
                                        renderRowSubComponent={renderRowSubComponent}
                                    />
                                </TableStyled>
                            </TableContainer>
                            <TablePaginationNav
                                pageCount={table.getPageCount()}
                                previousPage={table.previousPage}
                                canPreviousPage={table.getCanPreviousPage()}
                                nextPage={table.nextPage}
                                canNextPage={table.getCanNextPage()}
                                pageOptions={table.getPageOptions()}
                                gotoPage={table.setPageIndex}
                                pageIndex={table.getState().pagination.pageIndex}
                            />
                        </div>
                    ) : (
                        <NoTasksFound>
                            <TB>{t("no_tasks_found")}</TB>
                        </NoTasksFound>
                    )}
                </QueryWrapper>
            </MainArea>

            {isTaskEditorOpen && (
                <TaskEdit
                    editTaskId={editTaskId}
                    isOpen={isTaskEditorOpen}
                    onClose={closeTaskEditor}
                    initialData={taskEditorInitialData}
                    resetTaskEditor={resetTaskEditor}
                    isInMechanicView
                />
            )}
        </>
    );
};

export default MyTasks;

const TableContainer = styled.article`
    overflow: auto;
`;

const TableStyled = styled(StandardTableStyle)`
    min-width: 55rem;

    tr {
        td {
            height: 3.5rem;
        }

        th:last-child,
        td:last-child {
            min-width: 4.5rem;
            max-width: 4.5rem;
            text-align: center;
            padding-right: 0;
        }
    }
`;

const TimeNav = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .lt {
        margin-right: 1.25rem;
    }

    .gt {
        margin-left: 1.25rem;
    }

    .month {
        padding-left: 0.625rem;
    }

    .week {
        padding-left: 0.625rem;
        margin-right: 0.5rem;
    }
`;

const NoTasksFound = styled.article`
    min-height: 30vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    animation: fadeIn 1000ms ease forwards;
`;

const ClickableRow = styled.tr`
    cursor: pointer;
    transition: background-color 100ms ease;
    background: ${(p) =>
        p.$taskStatus === "completed"
            ? p.theme.color.status.invoiced
            : p.$taskStatus === "started"
              ? p.theme.color.status.completed
              : "none"};
    &:hover {
        background-color: ${(p) =>
            p.$taskStatus === "completed"
                ? `${p.theme.color.status.invoiced}bb`
                : p.$taskStatus === "started"
                  ? `${p.theme.color.status.completed}77`
                  : `${p.theme.color.neutral.xlight}44`};
        transition: background-color 50ms ease;
    }
`;

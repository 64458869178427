import styled from "styled-components";

export const Button = styled.button`
    border: none;
    padding: ${(props) => props.theme.button.padding_y} ${(props) => props.theme.button.padding_x};
    margin: 1px 1px 1px 1px;
    text-decoration: none;
    background-color: ${(props) => props.theme.color.primary.base};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    font-family: ${(props) => props.theme.font.t["font-family"]};
    font-style: ${(props) => props.theme.font.t["font-style"]};
    font-weight: ${(props) =>
        props.$bold ? props.theme.font.tb["font-weight"] : props.theme.font.t["font-weight"]};
    font-size: ${(props) => props.theme.font.tb["font-size"]};
    color: ${(props) => props.theme.color.neutral.xxxlight};
    transition: transform 50ms ease;

    &:hover:not(:disabled) {
        background-color: ${(props) => !props.disabled && props.theme.color.primary.light};
    }
    &:active:not(:disabled) {
        transform: scale(0.97);
    }

    &:disabled {
        opacity: 0.3;
    }
`;

export default Button;

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TaskStatus } from "../../api/core/taskAPI";

import { H2, T, TB } from "../../components/texts";
import { FlexGap } from "../../components/helpers/FlexGap";
import ServiceIntervalSelector from "./ServiceIntervalSelector";
import ServiceElement from "./ServiceElement";
import ReportPdfDownload from "./ReportPdfDownload";
import { useEffect } from "react";

const ServiceExecutionForm = ({ selectedTask, isServiceDone }) => {
    const { t } = useTranslation();

    const templateInSelectedTask = JSON.parse(selectedTask?.task_check_template || null);

    const { watch, setValue } = useFormContext();
    const customerId = watch("customer_id");
    const taskCheckTemplate = watch("task_check_template");

    useEffect(() => {
        if (!customerId) setValue("task_check_template", "{}");
    }, [customerId]);

    function selectInterval(option) {
        if (option?.interval?.data) {
            const parsedData = JSON.parse(option.interval.data);
            if (
                taskCheckTemplate?.serviceConfigurationId === parsedData?.serviceConfigurationId &&
                taskCheckTemplate?.serviceConfigurationIntervalId ===
                    parsedData?.serviceConfigurationIntervalId
            )
                return;
            setValue("task_check_template", parsedData, { shouldDirty: true });
        } else {
            setValue("task_check_template", "{}", { shouldDirty: true });
        }
    }

    const shouldSelectorBeDisabled =
        selectedTask?.status === TaskStatus.STARTED ||
        selectedTask?.status === TaskStatus.COMPLETED ||
        selectedTask?.status === TaskStatus.INVOICED;

    return (
        <Container>
            <Header>
                <SelectorContainer hidden={isServiceDone}>
                    <ServiceIntervalSelector
                        handleChange={selectInterval}
                        // Disable selector if task is started or completed
                        isDisabled={shouldSelectorBeDisabled}
                    />
                </SelectorContainer>

                {isServiceDone ? (
                    <ServiceTitle>
                        <TB>{`${templateInSelectedTask?.serviceConfigurationName} ${
                            templateInSelectedTask?.customer_id === 1
                                ? `(${t("system_template")})`
                                : ""
                        } - ${templateInSelectedTask?.interval} ${t("hours")}`}</TB>
                    </ServiceTitle>
                ) : null}

                <WorkHours>
                    <T>
                        {taskCheckTemplate?.workhours
                            ? `${t("estimated_duration")}: ${taskCheckTemplate?.workhours} ${t("hours")}`
                            : null}
                    </T>
                </WorkHours>
            </Header>

            {taskCheckTemplate?.schema?.elements?.length > 1 ? (
                <>
                    <Legend>
                        <T className="green">{t("approved")}</T>
                        <T className="yellow">{t("deviation")}</T>
                        <T className="red">{t("defect")}</T>
                    </Legend>

                    <TableHeader>
                        <FlexGap $count={3}>
                            <H2>{t("checkpoints")}</H2>
                            <H2>{t("result")}</H2>
                            <H2>{t("comment")}</H2>
                        </FlexGap>
                    </TableHeader>

                    {taskCheckTemplate?.schema?.elements?.map((group, groupIndex) => (
                        <Category key={`${group.groupName}-${groupIndex}`}>
                            {group.groupName ? (
                                <CategoryHeader>
                                    <TB>{group.groupName}</TB>
                                </CategoryHeader>
                            ) : null}

                            {group.elements?.map((element, elementIndex) => (
                                <ServiceElement
                                    key={`${element.id}${elementIndex}`}
                                    element={element}
                                    isServiceDone={isServiceDone}
                                    groupIndex={groupIndex}
                                    elementIndex={elementIndex}
                                    version={taskCheckTemplate?.version}
                                />
                            ))}
                        </Category>
                    ))}

                    {!!selectedTask?.media?.length && (
                        <ReportPdfDownload media={selectedTask?.media[0]} />
                    )}
                </>
            ) : null}
        </Container>
    );
};

export default ServiceExecutionForm;

const Container = styled.article`
    background: #fff;
    padding: 1rem;
`;

const Header = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const SelectorContainer = styled.section`
    width: 100%;
    max-width: 28rem;
    display: ${(props) => (props.hidden ? "none" : "block")};
`;

const ServiceTitle = styled.section`
    margin-top: 2rem;
    margin-bottom: 0.5rem;
`;

const WorkHours = styled.section`
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
`;

const Legend = styled.section`
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;

    ${T} {
        margin-right: 2rem;
        padding-left: 0.3rem;
        border-left: 2px solid;

        &.green {
            border-color: ${(p) => p.theme.color.ok.base};
        }
        &.yellow {
            border-color: ${(p) => p.theme.color.warning.base};
        }
        &.red {
            border-color: ${(p) => p.theme.color.error.base};
        }
    }
`;

const TableHeader = styled.section`
    border-bottom: 1px solid ${(p) => p.theme.color.neutral.xlight};

    ${H2} {
        margin-bottom: 0.5rem;

        &:nth-child(2) {
            max-width: 12rem;
        }
    }
`;

const Category = styled.article`
    padding: 1.5rem 0;
`;

const CategoryHeader = styled.span`
    display: block;
    border-bottom: 1px solid ${(p) => p.theme.color.neutral.xlight};
`;

import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import { InputWithLabel } from "./InputWithLabel";
import { CalendarIcon } from "../icons";

const DateInputInternal = forwardRef(({ label, inputName, onClick, name, ...props }, ref) => {
    const { t } = useTranslation();

    return (
        <InputContainer onClick={onClick}>
            <InputWithLabel
                placeholder={t("date")}
                label={label}
                name={inputName}
                hideErrorLabel
                {...props}
            />
            <CalendarIconStyled>
                <CalendarIcon />
            </CalendarIconStyled>
        </InputContainer>
    );
});

export const DateTimeInputForm = ({
    label,
    name,
    second,
    required = false,
    hideErrorLabel,
    showTimeSelectOnly = false,
    showDateOnly,
    ...props
}) => {
    const {
        watch,
        formState: { errors },
    } = useFormContext();

    const value = watch(name);

    // get setValue function from form context if possible, otherwise has to be sent in through props
    const setValue = useFormContext().setValue || props.setValue;

    return (
        <DatePickerContainer>
            <DatePicker
                required={required}
                dateFormat={
                    showTimeSelectOnly ? "HH:mm" : showDateOnly ? "dd.MM.yyyy" : "dd.MM.yyyy HH:mm"
                }
                selected={value ? new Date(value) : null}
                onChange={(date) => setValue(name, date, { shouldDirty: true })}
                customInput={<DateInputInternal label={label} inputName={name} />}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeSelect={showDateOnly ? false : true}
                showTimeSelectOnly={showTimeSelectOnly}
                timeFormat="HH:mm"
                timeIntervals={15}
                autoComplete="off"
                withPortal
                {...props}
            />
            {hideErrorLabel ? null : name in errors ? (
                <p role={"alert"}>{errors[name]?.message}</p>
            ) : (
                <p>&nbsp;</p>
            )}
        </DatePickerContainer>
    );
};

const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin-top: 5px;
        margin-bottom: 0px;
    }
`;

const InputContainer = styled.div`
    position: relative;
`;

const CalendarIconStyled = styled.div`
    position: absolute;
    right: 0.2rem;
    transform: translateY(-115%);
    cursor: pointer;
    background: white;
    padding: 0 0.2rem;
`;

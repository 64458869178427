import { useTranslation } from "react-i18next";
import LoadingSpinner from "../layout/LoadingSpinner";
import { ErrorBox } from "../layout/ErrorBox";
import { T } from "../texts";

const QueryWrapper = ({ data, customSpinner, children }) => {
    const { t } = useTranslation();

    const Spinner = customSpinner || LoadingSpinner;

    if (!data) {
        return children;
    } else if (Array.isArray(data)) {
        return (
            <>
                {data.some((d) => d?.isLoading) ? (
                    <Spinner />
                ) : data.some((d) => d?.isError) ? (
                    data
                        .filter((d) => d.isError)
                        .map((d, index) => (
                            <ErrorBox key={`${d.error?.message}${index}`}>
                                {`${d.error?.message}`}
                            </ErrorBox>
                        ))
                ) : !data.some((d) => "data" in d) ? (
                    <T>{t("not_found")}</T>
                ) : (
                    <>{children}</>
                )}
            </>
        );
    } else {
        return (
            <>
                {data.isLoading ? (
                    <Spinner />
                ) : data.isError ? (
                    <ErrorBox>{`${data.error?.message}`}</ErrorBox>
                ) : !("data" in data) ? (
                    <T>{t("not_found")}</T>
                ) : data.data ? (
                    <>{children}</>
                ) : null}
            </>
        );
    }
};

export default QueryWrapper;

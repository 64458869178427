import { fetchData } from "../fetch";

const getAll = () => fetchData(`core/national-holidays`);
const getForYear = (year) => fetchData(`core/national-holidays/${year}`);
const getFromTo = (from, to) => fetchData(`core/national-holidays?from=${from}&to=${to}`);

export default {
    getAll,
    getForYear,
    getFromTo,
};

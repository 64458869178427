import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    formatISO,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    isWeekend,
    startOfDay,
    endOfDay,
    differenceInMinutes,
    isSameDay,
    getYear,
} from "date-fns";
import styled from "styled-components";

import { useTasksWithParams } from "../../task/hooks/useTasksWithParams";
import { useSearchParams } from "../../application/hooks/useSearchParams";
import { useEmployeesForCustomer } from "../hooks/useEmployeesForCustomer";
import { useAbsencesBetween } from "../../absence/hooks/useAbsencesBetween";
import { useConfigForCustomer } from "../../admin/setup/hooks/useConfigForCustomer";
import { useNationalHolidaysFromTo } from "../../national-holidays/hooks/useNationalHolidaysFromTo";
import { PERMISSIONS } from "../../auth/permissions";

import MainArea from "../../../components/layout/MainArea";
import CustomerSelectNoForm from "../../application/components/role/CustomerSelectNoForm";
import { H1, T } from "../../../components/texts";
import { DateInput } from "../../../components/inputs";
import TotalHoursTable from "./TotalHoursTable";
import EmployeesHoursTable from "./EmployeesHoursTable";
import { convertMilitaryToDate } from "../../admin/setup/helpers";
import { TaskStatus } from "../../../api/core/taskAPI";
import { useExtrasBetween } from "../../absence/hooks/useExtrasBetween";

const statusList = [TaskStatus.COMPLETED, TaskStatus.INVOICED];

const HourReports = () => {
    const { t } = useTranslation();
    const { getParam, setParam, deleteParam } = useSearchParams();

    const methods = useForm();

    const customer_id = getParam("customer_id");
    const fromDate = getParam("from");
    const toDate = getParam("to");

    const employees = useEmployeesForCustomer(customer_id);
    const tasks = useTasksWithParams(
        customer_id,
        statusList,
        startOfDay(new Date(fromDate)),
        endOfDay(new Date(toDate))
    );
    const absences = useAbsencesBetween({
        fromDate: startOfDay(new Date(fromDate)),
        toDate: endOfDay(new Date(toDate)),
        customerId: customer_id,
    });
    const extras = useExtrasBetween({
        fromDate: startOfDay(new Date(fromDate)),
        toDate: endOfDay(new Date(toDate)),
        customerId: customer_id,
    });
    const customerConfig = useConfigForCustomer(customer_id);
    const nationalHolidays = useNationalHolidaysFromTo(
        fromDate ? getYear(new Date(fromDate)) : null,
        toDate ? getYear(new Date(toDate)) : null
    );

    const lunchBreakInterval = useMemo(() => {
        const lunchBreakTimes = customerConfig?.data?.scheduler?.lunchBreak;
        if (!lunchBreakTimes) return {};

        return {
            start: convertMilitaryToDate(lunchBreakTimes.from),
            end: convertMilitaryToDate(lunchBreakTimes.to),
        };
    }, [customerConfig?.data]);

    useEffect(() => {
        if (!fromDate && !toDate) {
            updateFromToDate(startOfMonth(new Date()), "from");
            updateFromToDate(endOfMonth(new Date()), "to");
        }
    }, [fromDate, toDate]);

    function updateFromToDate(newDate, key) {
        const formattedDate =
            newDate instanceof Date ? formatISO(newDate, { representation: "date" }) : null;

        if (formattedDate) {
            setParam(key, formattedDate);
        } else {
            deleteParam(key);
        }
    }

    const updatedTasksData = useMemo(() => {
        if (!tasks?.data || !employees?.data || !customerConfig?.data) return [];

        const tasksForEmployees = tasks.data.filter((task) =>
            employees.data.some((employee) => employee.id === task.mechanic_id)
        );

        return tasksForEmployees;
    }, [tasks?.data, employees?.data, customerConfig?.data]);

    const updatedAbsencesData = useMemo(() => {
        if (!absences?.data || !employees?.data || !nationalHolidays?.data) return [];

        const absencesForEmployees = absences.data.filter((absence) =>
            employees.data.some((employee) => employee.id === absence.user_id)
        );

        const endOfToDate = endOfDay(new Date(toDate));
        const absencesWithDuration = absencesForEmployees
            // Filter out absences with invalid dates to prevent crashing
            .filter((absence) => new Date(absence.from) < new Date(absence.to))
            .flatMap((absence) => splitAbsenceIntoWorkDays(absence))
            ?.filter((absence) => absence.durationInMinutes > 0 && absence.from < endOfToDate);

        return absencesWithDuration;
    }, [absences?.data, employees?.data, nationalHolidays?.data]);

    const updatedExtrasData = useMemo(() => {
        if (!extras?.data || !employees?.data || !nationalHolidays?.data) return [];

        const extrasForEmployees = extras.data.filter((absence) =>
            employees.data.some((employee) => employee.id === absence.user_id)
        );

        const endOfToDate = endOfDay(new Date(toDate));
        const extrasWithDuration = extrasForEmployees
            // Filter out extras with invalid dates to prevent crashing
            .filter((extra) => new Date(extra.from) < new Date(extra.to))
            .flatMap((extra) => splitExtraIntoFullDays(extra))
            ?.filter((extra) => extra.from < endOfToDate);

        return extrasWithDuration;
    }, [extras?.data, employees?.data, nationalHolidays?.data]);

    function splitAbsenceIntoWorkDays(absence) {
        const absenceInterval = {
            start: new Date(absence.from),
            end: new Date(absence.to),
        };

        const daysOfAbsence = eachDayOfInterval(absenceInterval);
        const daysOfAbsenceWithoutWeekends = daysOfAbsence.filter((day) => !isWeekend(day));
        const daysOfAbsenceWithoutNationalHolidays = daysOfAbsenceWithoutWeekends.filter(
            (day) =>
                !nationalHolidays?.data?.some((holiday) => isSameDay(new Date(holiday.date), day))
        );

        const absencesForEachWorkDay = daysOfAbsenceWithoutNationalHolidays.map((generatedDay) => ({
            ...absence,
            from: isSameDay(new Date(absence.from), generatedDay)
                ? new Date(absence.from)
                : startOfDay(generatedDay),
            to: isSameDay(new Date(absence.to), generatedDay)
                ? new Date(absence.to)
                : endOfDay(generatedDay),
        }));
        const absencesWithDurationAdded = absencesForEachWorkDay.map((absence) => ({
            ...absence,
            durationInMinutes: getAbsenceDurationInMinutes(absence),
        }));

        return absencesWithDurationAdded;
    }

    function splitExtraIntoFullDays(extra) {
        const extraInterval = {
            start: new Date(extra.from),
            end: new Date(extra.to),
        };

        return eachDayOfInterval(extraInterval).map((generatedDay) => ({
            ...extra,
            from: isSameDay(new Date(extra.from), generatedDay)
                ? new Date(extra.from)
                : startOfDay(generatedDay),
            to: isSameDay(new Date(extra.to), generatedDay)
                ? new Date(extra.to)
                : endOfDay(generatedDay),
        }));
    }

    function getAbsenceDurationInMinutes(absence) {
        const durationInMinutes = differenceInMinutes(new Date(absence.to), new Date(absence.from));

        if (durationInMinutes < 0) {
            return 0;
        } else if (durationInMinutes >= 7.5 * 60) {
            return 7.5 * 60;
        } else {
            return durationInMinutes;
        }
    }

    return (
        <>
            <MainArea>
                <H1>{t("hour_report")}</H1>

                <CustomerSelectContainer>
                    <label htmlFor="customerSelect">
                        <T>{t("customer")}</T>
                    </label>

                    <CustomerSelectNoForm
                        id="customerSelect"
                        onChange={(e) => setParam("customer_id", e.value)}
                        selected={customer_id}
                        key={`select-${customer_id}`}
                        permission={[
                            PERMISSIONS.scheduler_full.name,
                            PERMISSIONS.scheduler_read.name,
                        ]}
                    />
                </CustomerSelectContainer>

                <FormProvider {...methods}>
                    {!!customer_id && (
                        <form>
                            <DateInputs>
                                <DateInputContainer>
                                    <DateInput
                                        name="from"
                                        label={t("from")}
                                        value={fromDate ? new Date(fromDate) : null}
                                        setValue={(v) => updateFromToDate(v, "from")}
                                        maxContainerWidth={"30rem"}
                                        minDate={new Date(2010, 0, 1)}
                                        maxDate={new Date(2049, 11, 31)}
                                    />
                                </DateInputContainer>

                                <DateInputContainer>
                                    <DateInput
                                        name="to"
                                        label={t("to")}
                                        value={toDate ? new Date(toDate) : null}
                                        setValue={(v) => updateFromToDate(v, "to")}
                                        minDate={new Date(2010, 0, 1)}
                                        maxDate={new Date(2049, 11, 31)}
                                    />
                                </DateInputContainer>
                            </DateInputs>
                        </form>
                    )}
                </FormProvider>

                {!!customer_id && (
                    <>
                        <TotalHoursTable
                            fromDate={fromDate}
                            toDate={toDate}
                            customer_id={customer_id}
                            absences={updatedAbsencesData}
                            extras={updatedExtrasData}
                            tasks={updatedTasksData}
                            lunchBreakInterval={lunchBreakInterval}
                        />
                        <EmployeesHoursTable
                            fromDate={fromDate}
                            toDate={toDate}
                            customer_id={customer_id}
                            absences={updatedAbsencesData}
                            extras={updatedExtrasData}
                            tasks={updatedTasksData}
                            lunchBreakInterval={lunchBreakInterval}
                        />
                    </>
                )}
            </MainArea>
        </>
    );
};

export default HourReports;

const CustomerSelectContainer = styled.section`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    max-width: 21.5rem;
`;

const DateInputs = styled.section`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

const DateInputContainer = styled.div`
    margin-right: 1.5rem;
`;

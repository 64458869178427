import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useFeatureFlagsForCurrentUser } from "../feature-flag/hooks/useFeatureFlagsForCurrentUser";
import { usePermissions, checkForPermission } from "../user/hooks/usePermissions";
import { useUnitsLastUsed } from "../unit/hooks/useUnitsLastUsed";
import { useUnitsComments } from "../unit/hooks/useUnitsComments";
import { useUnitsInUse } from "../unit/hooks/useUnitsInUse";
import { useMyUser } from "../admin/users/hooks/useMyUser";
import { PERMISSIONS } from "../auth/permissions";
import { device } from "../../theme";
import { standardDate } from "../../common/date";
import QueryWrapper from "../../components/application/QueryWrapper";

import { H1, T, TB, S } from "../../components/texts";
import { Horizontal, Vertical } from "../../components/layout/FlexGrid";
import Spacer from "../../components/helpers/Spacer";
import MainArea from "../../components/layout/MainArea";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { ChooseUnitButton } from "../../components/buttons/ChooseUnitButton";
import { Comments } from "../unit/Comments";

const Frontpage = () => {
    const { t } = useTranslation();

    const featureFlags = useFeatureFlagsForCurrentUser();
    const unitsLastUsed = useUnitsLastUsed();
    const unitComments = useUnitsComments();
    const permissions = usePermissions();
    const unitsInUse = useUnitsInUse();
    const user = useMyUser();

    return (
        <MainArea>
            <QueryWrapper
                data={[user, featureFlags, unitComments, unitsInUse, unitsLastUsed, permissions]}
                customSpinner={FrontpageSpinner}
            >
                <FrontpageContainer>
                    <Horizontal style={{ marginBottom: "1rem" }}>
                        <H1>{`${t("hi")} ${user.data?.first_name || ""}!`}</H1>
                    </Horizontal>

                    <Comments />
                    <InUses />
                    <LastUsed />

                    <NavLink to="/unit/search">
                        <TB $underline $link>
                            {t("allunits")}
                        </TB>
                    </NavLink>
                    <UseButton />
                </FrontpageContainer>
            </QueryWrapper>
        </MainArea>
    );
};

const FrontpageSpinner = () => (
    <section
        style={{
            height: "80vh",
        }}
    >
        <LoadingSpinner light />
    </section>
);

export default Frontpage;

const InUses = () => {
    const { t } = useTranslation();

    const unitsInUse = useUnitsInUse();

    return unitsInUse.data?.length ? (
        <UnitsListStyled>
            <TB>{t("inuse")}</TB>

            {unitsInUse.data?.map((unit) => (
                <InUse key={unit.unit.id} unit={unit} />
            ))}
        </UnitsListStyled>
    ) : null;
};

const InUse = ({ unit }) => {
    return (
        <InUseStyled>
            <Horizontal>
                <Vertical>
                    <T>
                        {unit?.unit.int_id} {unit?.unit.group.name} {unit?.unit.custom_name}
                    </T>
                    <T>{unit?.unit.type}</T>
                    <S>{standardDate(unit?.stopped || unit?.started)}</S>
                </Vertical>
                <Spacer />
                <CenterVertical>
                    <ChooseUnitButton id={unit?.unit?.id} />
                </CenterVertical>
            </Horizontal>
        </InUseStyled>
    );
};

const UseButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const permissions = usePermissions();

    const disabled = useMemo(
        () =>
            permissions?.data
                ? !checkForPermission({
                      dataToCheck: permissions.data,
                      permission: PERMISSIONS.unit_use.name,
                  })
                : true,
        [permissions?.data]
    );

    return (
        <UseButtonStyled disabled={disabled} onClick={() => !disabled && navigate("/unit/unituse")}>
            <TB $second>{t("useunitlong")}</TB>
        </UseButtonStyled>
    );
};

const LastUsed = () => {
    const unitsLastUsed = useUnitsLastUsed();
    const unitsInUse = useUnitsInUse();
    const { t } = useTranslation();

    const filteredUnits = useMemo(() => {
        return unitsLastUsed?.data?.filter(
            (u) => !unitsInUse?.data?.some((item) => item.unit.id === u.unit.id)
        );
    }, [unitsLastUsed?.data, unitsInUse?.data]);

    return (
        <UnitsListStyled>
            <TB>{t("lastused")}</TB>

            {filteredUnits?.length > 0 &&
                filteredUnits.map((unit, index) => (
                    <InUse key={`${unit.unit.id}${index}`} unit={unit} />
                ))}
        </UnitsListStyled>
    );
};

const InUseStyled = styled.div`
    background-color: ${(props) => props.theme.color.primary.xxlight};
    padding: 5px 10px 5px 10px;
    width: 100%;
    margin-top: 5px;

    @media ${device.mobile} {
        width: 100%;
    }
`;

const UnitsListStyled = styled.section`
    margin-top: 1rem;
    margin-bottom: 0.625rem;
    max-width: 62rem;
`;

const UseButtonStyled = styled.div`
    background-color: ${(props) => props.theme.color.secondary.base};
    height: 92px;
    width: 92px;
    border-radius: 100%;
    position: fixed;
    bottom: calc(${(props) => props.theme.footer.height} + 21px);
    right: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};

    @media ${device.mobile} {
        right: 15px;
    }
`;

const FrontpageContainer = styled.section`
    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const CenterVertical = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

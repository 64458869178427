import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { useMedia } from "../media/hooks/useMedia";

import { TB } from "../../components/texts";
import { CleanButton } from "../../components/buttons";
import LoadingSpinner from "../../components/layout/LoadingSpinner";

const ServiceElementImage = ({
    imageId,
    elementId,
    elementIndex,
    isServiceDone,
    groupIndex,
    version,
}) => {
    const { data: imageData, isLoading } = useMedia(imageId);
    const { t } = useTranslation();
    const { getValues, setValue } = useFormContext();

    function handleRemovePhoto() {
        const wantsToRemove = window.confirm(t("confirm_removing_photo"));

        if (wantsToRemove) {
            const imagesPath =
                version === "1"
                    ? `task_check_template.schema.elements.${elementId}.values.images`
                    : `task_check_template.schema.elements.${groupIndex}.elements.${elementIndex}.values.images`;
            const existingImages = getValues(imagesPath);

            const newImageArray = existingImages.filter((image) => image !== imageId);

            setValue(imagesPath, newImageArray, {
                shouldDirty: true,
            });
        }
    }

    return isLoading ? (
        <LoadingSpinner small light />
    ) : (
        <ImageContainer>
            <a href={imageData?.uri} target="_blank" rel="noreferrer noopener">
                <Image src={`${imageData?.uri}_thumbnail`} width={88} height={88} />
            </a>

            {!isServiceDone && (
                <RemoveImageButton type="button" onClick={handleRemovePhoto}>
                    <TB $link>x</TB>
                </RemoveImageButton>
            )}
        </ImageContainer>
    );
};

export default ServiceElementImage;

const ImageContainer = styled.section`
    position: relative;
    width: 5.5rem;
    height: 5.5rem;
    margin-right: 0.3rem;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const RemoveImageButton = styled(CleanButton)`
    position: absolute;
    background: #fff;
    right: 0rem;
    padding: 0 0.3rem;

    ${TB} {
        font-size: 0.8rem;
    }
`;

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { T } from "../texts";
import { Input } from "./Input";

export const InputWithUnit = ({ label, name, unit, validate = {}, hideErrorLabel, ...props }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <Container>
            <label htmlFor={name}>
                <T>{label}</T>
            </label>

            <InputContainer>
                <Input
                    {...register(name, validate)}
                    id={name}
                    aria-invalid={errors[name] ? "true" : "false"}
                    {...props}
                />
                <span>
                    <T>{unit}</T>
                </span>
            </InputContainer>

            {!hideErrorLabel &&
                (name in errors ? (
                    <p role="alert">{errors[name]?.message || t("error")}</p>
                ) : (
                    <p>&nbsp;</p>
                ))}
        </Container>
    );
};

const Container = styled.section``;

const InputContainer = styled.section`
    display: flex;
    align-items: center;

    input {
        min-width: 5rem;
        max-width: 7rem;
        margin-right: 0.5rem;
        text-align: right;
        padding-right: 0.5rem;
    }

    p {
        margin-top: 0.25rem;
    }
`;

import styled from "styled-components";
import ReactModal from "react-modal";

import TaskEditContainer from "./TaskEditContainer";

const TaskEdit = ({
    onClose,
    isOpen,
    editTaskId,
    workshopId,
    initialData,
    isInMechanicView,
    resetTaskEditor,
}) => {
    return (
        <ModalStyled
            isOpen={isOpen}
            onRequestClose={onClose}
            className={"Modal"}
            overlayClassName={"ModalOverlay"}
            shouldCloseOnOverlayClick={false}
        >
            <TaskEditContainer
                editTaskId={editTaskId}
                initialData={initialData}
                isInMechanicView={isInMechanicView}
                onClose={onClose}
                resetTaskEditor={resetTaskEditor}
                workshopId={workshopId}
            />
        </ModalStyled>
    );
};

export default TaskEdit;

const ModalStyled = styled(ReactModal)`
    height: max-content;
    max-height: 90vh;
`;

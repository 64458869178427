import { useEffect, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { usePreCheckLists } from "../../pre-check/hooks/usePreCheckLists";
import { usePermissions, checkForPermission } from "../../user/hooks/usePermissions";
import { useCustomers } from "../customers/hooks/useCustomers";
import { PERMISSIONS } from "../../auth/permissions";
import QueryWrapper from "../../../components/application/QueryWrapper";

import StandardTableContent from "../../../components/application/StandardTableContent";
import StandardTableStyle from "../../../components/application/StandardTableStyle";
import TablePaginationNav from "../../../components/application/TablePaginationNav";
import MainArea from "../../../components/layout/MainArea";
import Spacer from "../../../components/helpers/Spacer";
import { H1, TB, T } from "../../../components/texts";
import { Horizontal } from "../../../components/layout/FlexGrid";
import { Button } from "../../../components/buttons";
import { InputWithLabel } from "../../../components/inputs";
import { fuzzyFilter } from "../../../components/application/FuzzyFilter";

const PrecheckLists = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const methods = useForm({ mode: "onChange" });

    const customers = useCustomers();
    const permissions = usePermissions();
    const preChecks = usePreCheckLists();

    const search = methods.watch("search");

    const columns = useMemo(
        () => [
            {
                header: t("name"),
                accessorKey: "name",
            },
            {
                header: t("customer"),
                accessorKey: "customer",
            },
            {
                header: "",
                id: "edit",
                accessorKey: "id",
                cell: ({ getValue }) => (
                    <EditLink to={`/units/prechecks/${getValue()}/edit`}>
                        <TB $link>{t("edit")}</TB>
                    </EditLink>
                ),
                enableSorting: false,
            },
        ],
        []
    );

    const data = useMemo(() => {
        if (!preChecks?.data || !customers?.data || !permissions?.data) return [];

        const preChecksWithPermission = preChecks.data.filter((item) =>
            checkForPermission({
                dataToCheck: permissions.data,
                permission: PERMISSIONS.precheck_admin.name,
                customer_id: item.customer_id,
            })
        );

        const preChecksWithCustomerName = preChecksWithPermission.map((item) => ({
            ...item,
            customer:
                customers?.data.find((customer) => customer.id === item.customer_id)?.name || "–",
        }));

        return preChecksWithCustomerName;
    }, [preChecks?.data, customers?.data, permissions?.data]);

    const initialState = useMemo(() => ({ pagination: { pageSize: 20 } }), []);

    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    const shouldButtonBeDisabled = useMemo(
        () =>
            permissions?.data
                ? !checkForPermission({
                      dataToCheck: permissions.data,
                      permission: PERMISSIONS.precheck_admin.name,
                  })
                : true,
        [permissions?.data]
    );

    return (
        <MainArea>
            <QueryWrapper data={permissions}>
                <Horizontal>
                    <H1>{t("precheck")}</H1>
                    <Spacer />
                    <Button
                        disabled={shouldButtonBeDisabled}
                        onClick={() => navigate(`/units/prechecks/0/edit`)}
                    >
                        <TB $second>{t("create_new_precheck")}</TB>
                    </Button>
                </Horizontal>

                <FormProvider {...methods}>
                    <form>
                        <InputWithLabel
                            label={t("search_prechecks")}
                            name={"search"}
                            style={{ maxWidth: "27rem" }}
                        />
                        <QueryWrapper data={[preChecks, customers]}>
                            {rowModel.rows.length > 0 ? (
                                <TableContainer>
                                    <TableStyled>
                                        <StandardTableContent
                                            headerGroups={headerGroups}
                                            rowModel={rowModel}
                                        />
                                    </TableStyled>
                                </TableContainer>
                            ) : (
                                <NoPreChecks>
                                    <T>{t("no_prechecks_to_show")}</T>
                                </NoPreChecks>
                            )}
                            <TablePaginationNav
                                pageCount={table.getPageCount()}
                                previousPage={table.previousPage}
                                canPreviousPage={table.getCanPreviousPage()}
                                nextPage={table.nextPage}
                                canNextPage={table.getCanNextPage()}
                                pageOptions={table.getPageOptions()}
                                gotoPage={table.setPageIndex}
                                pageIndex={table.getState().pagination.pageIndex}
                            />
                        </QueryWrapper>
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default PrecheckLists;

const EditLink = styled(Link)`
    text-decoration: none;
`;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
        }
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    margin-top: 1rem;
`;

const NoPreChecks = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

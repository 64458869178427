import styled from "styled-components";

const StandardTableStyle = styled.table`
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        text-align: left;
        border-bottom: 2px solid ${(props) => props.theme.color.neutral.xlight};
        height: 2.5rem;
        padding-right: 1rem;

        .SortIcon {
            margin-left: 0.35rem;
        }
    }

    th {
        user-select: none;
        white-space: nowrap;
    }

    td {
        min-width: 5rem;
        max-width: 20rem;
        overflow-wrap: break-word;
    }

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

export default StandardTableStyle;

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import MainArea from "../../components/layout/MainArea";
import { H1, T, TB } from "../../components/texts";
import { TruckIcon } from "../../components/icons/TruckIcon";

const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <MainArea>
                <TextContainer>
                    <H1>{t("we_cant_find_this_page")} (404)</H1>

                    <span>
                        <T>{t("you_can_try_going_to")}</T>{" "}
                        <NavLink to="/">
                            <TB $link $underline>
                                {t("the_frontpage")}
                            </TB>
                        </NavLink>{" "}
                        <T>{t("and_see_if_you_find_what_you_need_there")}.</T>
                    </span>
                </TextContainer>
            </MainArea>

            <TruckContainer>
                <TruckIcon />
            </TruckContainer>
            <BackgroundFill />
        </Wrapper>
    );
};

export default PageNotFound;

const Wrapper = styled.div`
    min-height: 95vh;
    margin-bottom: -3.25rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const TextContainer = styled.section`
    padding: 3rem 20% 10rem;
`;

const TruckContainer = styled.div`
    animation: driving 13s linear infinite;
    margin-bottom: -0.2rem;

    @keyframes driving {
        0% {
            transform: translateX(-8%);
        }

        100% {
            transform: translateX(108%);
        }
    }
`;

const BackgroundFill = styled.div`
    flex-grow: 1;
    background: ${(p) => p.theme.color.primary.light};
    position: relative;
`;

import toast from "react-hot-toast";
import { useQuery } from "react-query";
import sensorAPI from "../../../api/core/sensorAPI";

export const useSensorUnitHourCounter = (unitId) =>
    useQuery(
        ["sensors", "unit", unitId, "hourCounter"],
        () => sensorAPI.getSensorUnitHourCounter(unitId),
        {
            enabled: !!unitId,
            onError: (error) => {
                toast.error(
                    `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
                );
                console.log("Loading error: ", error.message);
            },
        }
    );

import { useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import kitboxAPI from "../../../../api/core/kitboxAPI";

export const useKitboxes = () => {
    const queryClient = useQueryClient();

    return useQuery("kitbox", kitboxAPI.getKitboxes, {
        onSuccess: (data) => {
            data?.forEach((item) => queryClient.setQueryData(["kitbox", item.id], item));
        },
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
            );
            console.log("Loading error: ", error.message);
        },
    });
};

import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";
import { DateTime } from "luxon";

export const useTasksWithParams = (workshopId, statusList, from, to) => {
    let params = `?host_id=${workshopId}`;
    const host = Number(workshopId); // Ensure workshopId is a number

    // Define the query key
    const queryKey = ["tasks", "websocket-hooked", "all", host];
    if (from) {
        params += `&from=${from}`;
        queryKey.push(from);
    }
    if (to) {
        params += `&to=${to}`;
        queryKey.push(to);
    }
    if (statusList?.length) {
        const joinedStatusList = statusList.join(",");
        params += `&status=${joinedStatusList}`;
        queryKey.push(joinedStatusList);
    }

    return useQuery(queryKey, () => taskAPI.getTasksWithConnectedWorkshops(params), {
        enabled: !!workshopId,
        refetchOnWindowFocus: false,
        meta: {
            filterFn: (task) => {
                if (task.host_id !== host) return false;
                if (from && DateTime.fromISO(from) >= DateTime.fromISO(task.start)) return false;
                if (to && DateTime.fromISO(to) <= DateTime.fromISO(task.end)) return false;
                if (statusList && !statusList.includes(task.status)) return false;
                return true;
            },
        },
    });
};

import { useQuery, useQueryClient } from "react-query";
import sensorAPI from "../../../api/core/sensorAPI";

export const useSensors = () => {
    const queryClient = useQueryClient();

    return useQuery("sensors", sensorAPI.getSensors, {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["sensors", item.id], item));
        },
    });
};

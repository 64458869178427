import { useQuery } from "react-query";
import extraAPI from "../../../api/core/extraAPI";

export const useExtrasBetween = ({ fromDate, toDate, customerId }) => {
    const params = `?from=${fromDate}&to=${toDate}&customer_id=${customerId}`;

    return useQuery(["extras", customerId, fromDate, toDate], () => extraAPI.fetchExtras(params), {
        enabled: !!fromDate && !!toDate && !!customerId,
    });
};

import { T } from "../texts";
import React, { useEffect } from "react";
import styled from "styled-components";
import { animated, useTransition } from "react-spring";

const FinishedStyled = styled(animated.div)`
    background-color: ${(props) => props.theme.color.warning.xxlight};
    padding: ${(props) => (props.big ? "20px 5px 20px 5px" : "10px 5px 10px 5px")};
    width: calc(100% - 10px);
    margin-top: 5px;
    margin-bottom: 5px;
`;

export const InfoBox = ({ text, remove, ...orgprops }) => {
    const transition = useTransition(text, {
        from: { opacity: 0, transform: "translate3d(-50%, 0px, 0px)" },
        enter: { opacity: 1, transform: "translate3d(0%, 0px, 0px)" },
        leave: { opacity: 0, transform: "translate3d(50%, 0px, 0px" },
    });

    useEffect(() => {
        if (text && remove) {
            setTimeout(() => {
                remove(null);
            }, [5000]);
        }
    }, [text]);

    return transition(
        ({ item, key, props }) =>
            item && (
                <FinishedStyled key={key} style={props} {...orgprops}>
                    <T>{item}</T>
                </FinishedStyled>
            )
    );
};

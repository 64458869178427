import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";

import StandardTableStyle from "../../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../../components/application/StandardTableContent";
import { TB } from "../../../../components/texts";
import { CleanButton } from "../../../../components/buttons";

const ExtrasTable = ({ tasks, onCalls, openEditorForTask }) => {
    const { t } = useTranslation();

    const data = useMemo(() => {
        const extras = [];
        if (tasks?.length) {
            tasks.forEach((task) => {
                const {
                    id,
                    start,
                    workorder,
                    duration,
                    extra_callout,
                    extra_hall,
                    extra_out,
                    extra_shift,
                } = task;
                if (extra_callout) {
                    extras.push({
                        id,
                        start,
                        workorder,
                        duration: 1,
                        type: "callout",
                    });
                }

                if (extra_hall) {
                    extras.push({
                        id,
                        start,
                        workorder,
                        duration,
                        type: "hall",
                    });
                }

                if (extra_out) {
                    extras.push({
                        id,
                        start,
                        workorder,
                        duration,
                        type: "out",
                    });
                }

                if (extra_shift) {
                    extras.push({
                        id,
                        start,
                        workorder,
                        duration,
                        type: "shift",
                    });
                }
            }, []);
        }

        if (onCalls?.length) {
            onCalls.forEach((onCallAbsence) => {
                const { from } = onCallAbsence;
                extras.push({
                    id: null,
                    start: from,
                    workorder: null,
                    duration: 1,
                    type: "bakvakt",
                });
            });
        }

        return extras;
    }, [tasks, onCalls]);

    const columns = useMemo(
        () => [
            {
                header: t("date"),
                accessorKey: "start",
                cell: ({ getValue }) => formatDateForDisplay(getValue()) || "–",
            },
            {
                header: t("type"),
                accessorFn: (row) => t(row.type),
            },
            {
                header: t("quantity"),
                accessorKey: "duration",
                cell: ({ getValue, row }) => {
                    const { original } = row;
                    const { type } = original;
                    switch (type) {
                        case "callout":
                        case "bakvakt":
                            return getValue();
                        default:
                            return `${getValue()} ${t("hours_unit_symbol")}`;
                    }
                },
            },
            {
                header: t("order"),
                accessorKey: "workorder.id",
                cell: ({ getValue, row }) => {
                    const value = getValue();
                    const taskId = row.original.id;
                    if (value && taskId) {
                        return (
                            <CleanButton type="button" onClick={() => openEditorForTask(taskId)}>
                                <TB $link>{value}</TB>
                            </CleanButton>
                        );
                    } else {
                        return <span style={{ marginRight: "1rem" }}>-</span>;
                    }
                },
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    function formatDateForDisplay(date) {
        return date
            ? new Date(date).toLocaleDateString("no", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
              })
            : "";
    }

    return rowModel.rows.length ? (
        <Container>
            <TB>{t("extras")}</TB>

            <TableStyled>
                <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
            </TableStyled>
        </Container>
    ) : null;
};

export default ExtrasTable;

const Container = styled.section`
    max-width: 35rem;
    margin: 3rem 0;
`;

const TableStyled = styled(StandardTableStyle)`
    th {
        border-bottom: 2px solid ${(p) => p.theme.color.neutral.base};

        > span {
            font-weight: normal;
        }
    }

    th,
    td {
        text-align: right;
    }

    th:first-child,
    td:first-child {
        text-align: left;
        width: 7rem;
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
        text-align: left;
    }

    td:last-child {
        text-align: right;
        padding-right: 0;
    }
`;

import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import toast from "react-hot-toast";

import timer from "./bft";
import { TB } from "../../components/texts";

let timerId = null;

const Reload = () => {
    const [show, setShow] = useState(false);
    const previousHtml = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        if (show)
            toast.custom(
                <Container onClick={() => window.location.reload()}>
                    <TB>{t("new_version_available_message")}</TB>
                </Container>,
                { duration: Infinity }
            );
    }, [show]);

    function checkForUpdates() {
        fetch(window.location.protocol + "//" + window.location.host + "/version.txt")
            .then((response) => {
                if (response.status !== 200) throw new Error("offline");
                return response.text();
            })
            .then((html) => {
                if (!previousHtml.current) {
                    previousHtml.current = html;
                    return;
                }
                if (previousHtml.current !== html) {
                    previousHtml.current = html;
                    setShow(true);
                }
            })
            .catch(() => {
                console.log("Reload error");
            });
    }

    useEffect(() => {
        if (timerId) timer.clearTimeout(timerId);

        timerId = timer.setInterval(
            checkForUpdates,
            60 * 1000, // tryDelay: 1 minutes
            24 * 60 * 60 * 1000 // forceDelay: 1 day
        );
    }, []);

    return null;
};

export default Reload;

const Container = styled.div`
    padding: 1em;
    background-color: ${(p) => p.theme.color.warning.xlight};
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        color: #666666;
    }
`;

import { DateTime } from "luxon";

export const getDate = (date) => {
    if (typeof date?.startOf === "function") return date;
    if (typeof date?.getMonth === "function") return DateTime.fromJSDate(date);
    return DateTime.fromISO(date);
};

export const standardDate = (date) => {
    const dt = getDate(date);
    return dt.toLocaleString(
        {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        },
        {
            locale: "no",
        }
    );
};

export const standardDateOnly = (date) => {
    return new Date(date)?.toLocaleString("no", { dateStyle: "short" });
};

export const monthAndYear = (date) => {
    const dt = getDate(date);
    const dateString = dt.toLocaleString({ locale: "no", month: "long", year: "numeric" });

    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
};

export const weekNumber = (date) => {
    const dt = getDate(date);
    return dt.toFormat("WW");
};

export const yearNumber = (date) => {
    return getDate(date).toFormat("y");
};

export const weekDay = (date) => {
    return getDate(date).weekday;
};

export const weekDays = (date) => {
    const wn = parseInt(weekNumber(date));
    const year = parseInt(yearNumber(date));
    return [1, 2, 3, 4, 5, 6, 7].map((day) =>
        DateTime.fromObject({ weekYear: year, weekNumber: wn, weekday: day })
    );
};

export const weekDaysText = (date) => {
    const days = weekDays(date);
    return days.map((day) => ({ text: weekDayText(day), date: day }));
};

export const weekDayText = (date) => {
    const text = getDate(date).setLocale("no").toFormat("EEEE dd. MMMM");
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const startOfDay = (date) => {
    return getDate(date).startOf("day");
};

export const startOfWeek = (date) => {
    return getDate(date).startOf("week");
};

export const startOfYear = (date) => {
    return getDate(date).startOf("year");
};

export const endOfWeek = (date) => {
    return getDate(date).endOf("week");
};

export const startOfMonth = (date) => {
    return getDate(date).startOf("month");
};

export const endOfMonth = (date) => {
    return getDate(date).endOf("month");
};

export const endOfDay = (date) => {
    return getDate(date).endOf("day");
};

export const fromMillis = (millis) => {
    if (typeof millis === "string") return DateTime.fromMillis(parseInt(millis));
    return DateTime.fromMillis(millis);
};

export const isInCurrentWeek = (date) => {
    return date.hasSame(DateTime.now(), "week");
};

export const hasSameWeek = (date1, date2) => {
    return date1.hasSame(date2, "week");
};

export const hasSameDay = (date1, date2) => {
    return date1.hasSame(date2, "day");
};

export const hoursBetweenDates = (date1, date2) => {
    return date1.diff(date2, ["hours"]).values.hours;
};

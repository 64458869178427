import React from "react";
import styled from "styled-components";
import BackButton from "../buttons/BackButton";
import { device } from "../../theme";

const MainAreaStyled = styled.div`
    padding: 1.25rem 2.5rem 3.125rem 3.125rem;

    @media ${device.mobile} {
        padding: 1.25rem 0.625rem 3.125rem 0.625rem;
    }
`;

const MainArea = ({ back, children }) => {
    return (
        <>
            {back && <BackButton />}
            <MainAreaStyled>{children}</MainAreaStyled>
        </>
    );
};

export default MainArea;

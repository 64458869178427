import { useTranslation } from "react-i18next";
import styled from "styled-components";

import StandardTableStyle from "../../components/application/StandardTableStyle";
import { H1, TB } from "../../components/texts";
import { NavLink } from "react-router-dom";

const ProcurementsTable = ({ procurements }) => {
    const { t } = useTranslation();

    return (
        <TableStyled>
            <thead>
                <tr>
                    <th>
                        <LinkedTitleContainer>
                            <H1NoMargin>{t("procurement")}</H1NoMargin>
                            <NavLink to="/materials/requests">
                                <TB $link>{t("materials")} ►</TB>
                            </NavLink>
                        </LinkedTitleContainer>
                    </th>
                    <th>{t("count")}</th>
                    <th>{t("requests")}</th>
                    <th>{t("status-ordered")}</th>
                    <th>{t("status-arrived")}</th>
                </tr>
            </thead>
            {procurements != null ? (
                <>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                                {procurements.requested +
                                    procurements.ordered +
                                    procurements.arrived +
                                    procurements.used}
                            </td>
                            <td>{procurements.requested}</td>
                            <td>{procurements.ordered}</td>
                            <td>{procurements.arrived}</td>
                        </tr>
                    </tbody>
                </>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan={6}>
                            <NoAreasSelected>{t("no_areas_selected")}</NoAreasSelected>
                        </td>
                    </tr>
                </tbody>
            )}
        </TableStyled>
    );
};

export default ProcurementsTable;

const H1NoMargin = styled(H1)`
    margin: 0;
`;

const LinkedTitleContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
`;

const NoAreasSelected = styled.div`
    text-align: center;
    padding: 2rem;
`;

const TableStyled = styled(StandardTableStyle)`
    tfoot tr td {
        font-weight: bold;
    }

    td,
    th {
        text-align: right;
        width: 16.25%;
    }

    td:first-child,
    th:first-child {
        text-align: left;
        width: 35%;
    }
`;

import { useRef, useEffect, useMemo } from "react";
import { differenceInMinutes, endOfDay, format, startOfDay } from "date-fns";
import { nb } from "date-fns/locale";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";

import { useUnits } from "../../unit/hooks/useUnits";
import { useLiveSensorValuesForUnits } from "./hooks/useLiveSensorValuesForUnits";

import LoadingSpinner from "../../../components/layout/LoadingSpinner";
import { useTasksBetweenDates } from "../../task/hooks/useTasksBetweenDates";
import { TaskStatus } from "../../../api/core/taskAPI";

mapboxgl.accessToken =
    "pk.eyJ1IjoibHN0cm9jaWFrIiwiYSI6ImNsNnAzN2Q3YjA2d2ozYmxwZWJ2a3lmbWUifQ.I0YRhD7jWyP5uTbNlXmSJQ";

const UnitStatusLive = ({ selectedUnits }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const previouslySelectedUnits = useRef(null);

    const allUnits = useUnits();
    const { data: unitTasks } = useTasksBetweenDates(
        [TaskStatus.SCHEDULED, TaskStatus.STARTED, TaskStatus.COMPLETED, TaskStatus.INVOICED],
        startOfDay(new Date()),
        endOfDay(new Date())
    );
    const mappedUnitTasks = useMemo(() => {
        const unitTasksMap = new Map();
        if (unitTasks == null) return unitTasksMap;
        return unitTasks.reduce((acc, curr) => {
            const existingTasks = acc.get(curr.unit_id);
            if (existingTasks != null) {
                existingTasks.push(curr);
            } else {
                acc.set(curr.unit_id, [curr]);
            }
            return acc;
        }, unitTasksMap);
    }, [unitTasks]);

    const selectedUnitsWithCustomerIds = useMemo(
        () =>
            selectedUnits?.map((selected) => ({
                unit_id: selected.value,
                customer_id: allUnits?.data?.find((item) => item.id === selected.value)
                    ?.customer_id,
            })),
        [selectedUnits, allUnits?.data]
    );

    const liveData = useLiveSensorValuesForUnits(selectedUnitsWithCustomerIds);
    const isLiveDataLoading = liveData?.some((item) => item.status === "loading");
    const isAllLiveDataLoaded = liveData?.every((item) => item.status === "success");

    // Set up map
    useEffect(() => {
        if (mapRef.current) return;

        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/lstrociak/cl6p3ee5l002314n04f4xw4t7",
            center: [13, 65],
            zoom: 4.2,
        });

        mapRef.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

        const pulsingDotSize = 200;
        const pulsingDot = {
            width: pulsingDotSize,
            height: pulsingDotSize,
            data: new Uint8Array(pulsingDotSize * pulsingDotSize * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
                const canvas = document.createElement("canvas");
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext("2d");
            },

            // Call once before every frame where the icon will be used.
            render: function () {
                const duration = 1900;
                const t = (performance.now() % duration) / duration;

                const radius = (pulsingDotSize / 2) * 0.3;
                const outerRadius = (pulsingDotSize / 2) * 0.7 * t + radius;
                const context = this.context;

                // Draw the outer circle.
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
                context.fillStyle = `rgba(98, 195, 112, ${0.65 - t})`;
                context.fill();

                // Update this image's data with data from the canvas.
                this.data = context.getImageData(0, 0, this.width, this.height).data;

                // Continuously repaint the map, resulting
                // in the smooth animation of the dot.
                mapRef.current.triggerRepaint();

                // Return `true` to let the map know that the image was updated.
                return true;
            },
        };

        mapRef.current.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

        // return () => mapRef.current.remove();
    }, []);

    // Set up sensor data display
    useEffect(() => {
        if (!mapRef.current || !isAllLiveDataLoaded) return;

        setTimeout(() => setupDotsDisplay(liveData), 100);
    }, [isAllLiveDataLoaded, mapRef.current, selectedUnits, liveData]);

    function removePositionDot(unitId) {
        const existingSource = mapRef.current?.getSource("positionDotSource" + unitId);
        const existingPositionDotLayer = mapRef.current?.getLayer("positionDotLayer" + unitId);

        if (existingPositionDotLayer && existingSource) {
            mapRef.current.removeLayer("positionDotLayer" + unitId);
            mapRef.current.removeLayer("pulsingDotLayer" + unitId);
            mapRef.current.removeSource("positionDotSource" + unitId);
        }
    }

    function setupDotsDisplay(sensorData) {
        const sensorDataWithValues = sensorData.filter(
            (item) => item.data?.["position.latitude"] && item.data?.["position.longitude"]
        );
        const positionDotSources = getPositionDotSources(sensorDataWithValues);
        const positionDotLayers = getPositionDotLayers(positionDotSources);
        const pulsingDotLayers = getPulsingDotLayers(positionDotSources);

        // Remove unselected units from map
        const removedUnits = previouslySelectedUnits.current?.filter(
            (previousUnit) =>
                !selectedUnits.some((selectedUnit) => selectedUnit.value === previousUnit.value)
        );
        if (removedUnits?.length) {
            removedUnits?.forEach((item) => removePositionDot(item.value));
        }

        previouslySelectedUnits.current = selectedUnits;
        mapRef.current.fire("removeAllPopups");

        const existingSources = positionDotSources
            .map((source) =>
                mapRef.current?.getSource("positionDotSource" + source.data.properties.unitId)
            )
            .filter(Boolean);

        // Add or update sources for dots
        positionDotSources.forEach((source) => {
            const sourceId = "positionDotSource" + source.data.properties.unitId;
            const existingSource = mapRef.current.getSource(sourceId);

            if (existingSource) {
                existingSource.setData(source.data);
            } else {
                mapRef.current.addSource(sourceId, source);
            }
        });

        // Add popups with unit info
        positionDotSources.forEach((source) => {
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                className: "map-popup",
                offset: 8,
            }).setHTML(
                `<p>
                ${source.data.properties.unitData.int_id}
                </p>
                ${
                    source.data.properties.isStale
                        ? `<p>
                            ${format(
                                new Date(source.data.properties.timestamp * 1000),
                                "dd.MM.yy HH:mm",
                                {
                                    locale: nb,
                                }
                            )}
                            </p>`
                        : ""
                }
                `
            );

            popup.setLngLat(source.data.geometry?.coordinates).addTo(mapRef.current);

            mapRef.current.on("removeAllPopups", () => {
                popup.remove();
            });
        });

        // Add layers for pulsing dots
        pulsingDotLayers.forEach((layer) => {
            const existingLayer = mapRef.current.getLayer(layer.id);
            if (!existingLayer) mapRef.current.addLayer(layer);
        });

        // Add layers for position dots
        positionDotLayers.forEach((layer) => {
            const existingLayer = mapRef.current.getLayer(layer.id);
            if (!existingLayer) mapRef.current.addLayer(layer);
        });

        // Focus map on displayed points if there are new sources added
        const allDotCoordinates = positionDotSources.map(
            (source) => source.data.geometry.coordinates
        );
        if (allDotCoordinates?.length && positionDotSources?.length > existingSources?.length) {
            fitMapViewToCoordinates(allDotCoordinates);
        }
    }

    function fitMapViewToCoordinates(coordinates) {
        const mapDisplayBounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
        coordinates.forEach((coordinate) => mapDisplayBounds.extend(coordinate));

        mapRef.current.fitBounds(mapDisplayBounds, {
            padding: 90,
            maxZoom: 16,
        });
    }

    function getPositionDotSources(data) {
        return data.map((item) => ({
            type: "geojson",
            data: {
                type: "Feature",
                properties: {
                    unitId: item.data?.["unit_id"],
                    unitData: getDataForUnitId(item.data?.["unit_id"]),
                    unitTasks: getTasksForUnitId(item.data?.["unit_id"]),
                    timestamp: item.data?.timestamp,
                    engineIgnitionStatus: item.data?.["engine.ignition.status"],
                    isStale: !(
                        differenceInMinutes(new Date(), new Date(item.data?.timestamp * 1000)) < 5
                    ),
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        item.data?.["position.longitude"],
                        item.data?.["position.latitude"],
                    ],
                },
            },
        }));
    }

    function getPositionDotLayers(sources) {
        return sources.map((source) => ({
            id: "positionDotLayer" + source.data.properties.unitId,
            type: "circle",
            source: "positionDotSource" + source.data.properties.unitId,
            paint: {
                "circle-radius": 5,
                "circle-color": [
                    "case",
                    ["boolean", ["get", "isStale"], false],
                    source.data.properties.unitData?.defect
                        ? "red"
                        : source.data.properties.unitTasks?.length > 0
                          ? "yellow"
                          : "#333333",
                    "#62c370",
                ],
                "circle-opacity": ["case", ["boolean", ["get", "isStale"], false], 0.65, 1],
                "circle-stroke-color": [
                    "case",
                    ["boolean", ["get", "isStale"], false],
                    "#333333",
                    "#38558d",
                ],
                "circle-stroke-width": 2,
                "circle-stroke-opacity": ["case", ["boolean", ["get", "isStale"], false], 0.65, 1],
            },
        }));
    }

    function getPulsingDotLayers(sources) {
        return sources.map((source) => ({
            id: "pulsingDotLayer" + source.data.properties.unitId,
            type: "symbol",
            source: "positionDotSource" + source.data.properties.unitId,
            layout: {
                "icon-image": "pulsing-dot",
                "icon-allow-overlap": true,
                visibility: source.data.properties.isStale ? "none" : "visible",
            },
        }));
    }

    function getDataForUnitId(id) {
        return allUnits?.data.find((unit) => unit.id === id);
    }

    function getTasksForUnitId(id) {
        return mappedUnitTasks?.get(id);
    }

    return (
        <>
            <MapContainerStyled ref={mapContainerRef} className={"map-container"}>
                {isLiveDataLoading ? (
                    <SpinnerContainer>
                        <LoadingSpinner />
                    </SpinnerContainer>
                ) : null}
            </MapContainerStyled>
        </>
    );
};

export default UnitStatusLive;

const MapContainerStyled = styled.article`
    margin-top: 2rem;
    height: 50rem;
    opacity: 0;
    animation: fadeIn 3000ms 200ms ease forwards;

    .map-popup {
        .mapboxgl-popup-content {
            padding: 0.2rem 0;
            border-radius: 0;
            border: 1px solid #333;

            p {
                font-family: ${(props) => props.theme.font.t["font-family"]};
                font-size: ${(props) => props.theme.font.t["font-size"]};
                line-height: ${(props) => props.theme.font.t["line-height"]};
                margin: 0;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
        .mapboxgl-popup-tip {
            display: none;
        }
    }
`;

const SpinnerContainer = styled.article`
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.5;
`;

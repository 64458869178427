import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFeatureFlagsForCurrentUser } from "../../feature-flag/hooks/useFeatureFlagsForCurrentUser";
import { usePermissions, checkForPermission } from "../../user/hooks/usePermissions";
import { PERMISSIONS } from "../../auth/permissions";
import QueryWrapper from "../../../components/application/QueryWrapper";

import { H1 } from "../../../components/texts";
import MainArea from "../../../components/layout/MainArea";
import LegacyTable from "../../../components/application/LegacyTable";

const tableLayout = [
    {
        label: "setup",
        field: "setup",
        header: true,
    },
    {
        label: "description",
        field: "description",
    },
];

const Setup = () => {
    const { t } = useTranslation();
    const permissions = usePermissions();
    const featureFlags = useFeatureFlagsForCurrentUser();

    const tableData = useMemo(
        () => [
            {
                id: 1,
                _links: [
                    {
                        field: "setup",
                        link: "/administration/setup/service",
                    },
                ],
                _featureFlags: ["extended"],
                setup: t("service_config_setup_link"),
                description: t("service_config_setup_description"),
            },
            {
                id: 2,
                _links: [
                    {
                        field: "setup",
                        link: "/administration/setup/inspection",
                    },
                ],
                _featureFlags: ["extended"],
                setup: t("periodic_technical_inspection"),
                description: t("periodic_technical_inspection_setup_desc"),
            },
            {
                id: 3,
                _links: [
                    {
                        field: "setup",
                        link: "/administration/setup/scheduler",
                    },
                ],
                _featureFlags: ["extended"],
                setup: t("scheduler_admin"),
                description: t("scheduler_admin_description"),
                _permission: PERMISSIONS.config_admin.name,
            },
            {
                id: 4,
                _links: [
                    {
                        field: "setup",
                        link: "/administration/setup/units",
                    },
                ],
                setup: t("vehicle_use"),
                description: t("set_hours_before_automatic_vehicle_release"),
                _permission: PERMISSIONS.unit_admin.name,
            },
            {
                id: 5,
                _links: [{ field: "setup", link: "/administration/setup/sensors" }],
                setup: t("sensors"),
                description: t("sensors_setup_description"),
                _permission: PERMISSIONS.sensor_admin.name,
            },
            {
                id: 6,
                _links: [{ field: "setup", link: "/administration/setup/kitbox" }],
                setup: t("kitboxes"),
                description: t("kitboxes_setup_description"),
            },
            {
                id: 7,
                _links: [{ field: "setup", link: "/administration/setup/task-pre-checks" }],
                setup: t("task_pre_checks"),
                description: t("task_pre_checks_description"),
            },
            {
                id: 8,
                _links: [{ field: "setup", link: "/administration/setup/task-post-checks" }],
                setup: t("task_post_checks"),
                description: t("task_post_checks_description"),
            },
        ],
        [t]
    );

    const tableDataFiltered = useMemo(
        () =>
            permissions?.data && featureFlags?.data
                ? tableData.filter((item) => {
                      const hasPermission =
                          "_permission" in item
                              ? checkForPermission({
                                    dataToCheck: permissions.data,
                                    permission: item._permission,
                                })
                              : true;

                      const hasFlags = checkForFeatureFlag({
                          item,
                          featureFlags: featureFlags?.data,
                      });

                      return hasPermission && hasFlags;
                  })
                : [],
        [permissions?.data, featureFlags?.data, tableData]
    );

    function checkForFeatureFlag({ item, featureFlags }) {
        if ("_featureFlags" in item) {
            const hasAllFlags = item._featureFlags?.every((item) =>
                featureFlags.some((flag) => flag.name === item)
            );

            return Boolean(hasAllFlags);
        } else {
            return true;
        }
    }

    return (
        <MainArea>
            <H1>{t("setup_of_system")}</H1>
            <QueryWrapper data={[permissions, featureFlags]}>
                <LegacyTable def={tableLayout} data={tableDataFiltered} max={12} />
            </QueryWrapper>
        </MainArea>
    );
};

export default Setup;

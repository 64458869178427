import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUnit } from "./hooks/useUnit";
import { useUnitFinishMutaton } from "./mutations/useUnitFinishMutation";
import QueryWrapper from "../../components/application/QueryWrapper";

import { InputWithLabel, RadioSelect } from "../../components/inputs";
import { Button, CleanButton } from "../../components/buttons";
import { H1, TB } from "../../components/texts";
import MainArea from "../../components/layout/MainArea";

const UnitFinished = ({ onFinishNavigateTo = "/" }) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const unit = useUnit(id);

    const methods = useForm({ defaultValues: { status: 1, comment: "" } });
    const status = methods.watch("status");
    const comment = methods.watch("comment");

    const { mutate: finishUnit } = useUnitFinishMutaton();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function finish(data) {
        finishUnit(
            {
                id: id,
                comment: data.comment,
                statusId: data.status,
            },
            {
                onSuccess: () => navigate(onFinishNavigateTo),
            }
        );
    }

    return (
        <MainArea>
            <QueryWrapper data={unit}>
                <H1>
                    {unit.data?.int_id} {unit.data?.group?.name} {unit.data?.name} {unit.data?.type}
                </H1>

                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(finish)}>
                        <RadioSelectContainer>
                            <RadioSelect
                                name="status"
                                defaultValue={1}
                                options={[
                                    { value: 1, label: t("approved") },
                                    { value: 2, label: t("deviation") },
                                    { value: 3, label: t("defect") },
                                ]}
                            />
                        </RadioSelectContainer>

                        <InputWithLabel label={t("comment")} name={"comment"} rows={6} />
                        <Button disabled={status?.value > 1 && comment === ""}>
                            {t("confirmfinished")}
                        </Button>
                        <BackButton onClick={() => navigate(-1)} type="button">
                            <TB $underline $link>
                                {t("cancel")}
                            </TB>
                        </BackButton>
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default UnitFinished;

const RadioSelectContainer = styled.section`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const BackButton = styled(CleanButton)`
    margin-left: 20px;
`;

import { useQuery } from "react-query";
import serviceAPI from "../../../../api/core/serviceAPI";

export const useServiceConfigsForCustomer = (customerId, attributes) =>
    useQuery(
        ["service", "config", "customer", customerId, attributes],
        () => serviceAPI.getConfigsForCustomer(customerId, attributes),
        {
            enabled: !!customerId,
        }
    );

import { useState, useRef, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { getDate } from "../../common/date";
import { TaskStatus } from "../../api/core/taskAPI";
import { shouldTaskAppearInCalendar } from "./helpers";

import { T, TB } from "../../components/texts";
import { StarIcon, MoneyIcon, FlagIcon } from "../../components/icons";
import TaskTooltip from "./TaskTooltip";
import { DotIcon } from "../../components/icons/DotIcon";
import { Checkbox } from "../../components/inputs";

const hourlyRate = 800;

const Task = ({
    task,
    hoursInDay,
    isOffHoursExpanded,
    tooltipRenderTarget,
    explicitTooltipPlacement,
    daySchedule,
    handleTaskDoubleClick,
    isCheckboxDisabled = false,
    isCheckboxVisible = false,
    isCheckboxChecked = false,
    onCheckboxClick = null,
    isDraggable = true,
    isResizeable = true,
}) => {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [hovered, setHovered] = useState(false);

    const theme = useTheme();
    const tooltipParentTarget = useRef(null);

    const taskPartsStatus = useMemo(
        () => (task?.parts?.length ? getStatusForParts(task?.parts) : null),
        [task?.parts]
    );

    const shouldDisplayPartsStatus =
        !!taskPartsStatus && taskPartsStatus !== "stock_item" && taskPartsStatus !== "arrived";

    const latestEtaForParts = useMemo(
        () => (shouldDisplayPartsStatus ? getLatestEtaForParts(task.parts) : null),
        [shouldDisplayPartsStatus]
    );

    const shouldAlertAboutMissingParts =
        (shouldDisplayPartsStatus && taskPartsStatus === "not_ordered") ||
        (taskPartsStatus === "ordered" &&
            latestEtaForParts &&
            new Date(latestEtaForParts) > new Date(task.start));

    const shouldAlertAboutHighCost = task.parts_cost + task.duration * hourlyRate >= 10000;

    const startsBeforeDay = useMemo(() => {
        if (!shouldTaskAppearInCalendar(task)) return false;

        const taskStartHour = getDate(task.start).hour;
        const dayStartHour = Math.floor(daySchedule.work_start);
        const taskStartsBeforeDay = taskStartHour < dayStartHour;

        return taskStartsBeforeDay;
    }, [task, isOffHoursExpanded, daySchedule]);

    const endsAfterDay = useMemo(() => {
        if (!shouldTaskAppearInCalendar(task)) return false;

        const taskEndDate = getDate(task.end);
        const taskEndHour = taskEndDate.hour;
        const taskEndMinute = taskEndDate.minute;

        const taskEndsAfterDay =
            taskEndHour > daySchedule.work_end ||
            (taskEndHour === Math.floor(daySchedule.work_end) && taskEndMinute > 0);

        return taskEndsAfterDay;
    }, [task, isOffHoursExpanded, daySchedule]);

    const taskWidth = useMemo(() => {
        if (!shouldTaskAppearInCalendar(task)) return theme.scheduler.taskWidth;

        const taskStartDate = getDate(task.start);
        const taskStartHour = taskStartDate.hour;
        const taskStartMinute = taskStartDate.minute;

        const taskEndDate = getDate(task.end);
        const taskEndHour = taskEndDate.hour;
        const taskEndMinute = taskEndDate.minute;

        const beforeDayOverflow = startsBeforeDay
            ? Math.floor(daySchedule.work_start) - (taskStartHour + taskStartMinute / 60)
            : 0;

        const afterDayOverflow = endsAfterDay
            ? taskEndHour + taskEndMinute / 60 - daySchedule.work_end
            : 0;

        const width =
            ((isOffHoursExpanded ? theme.scheduler.dayWidth : theme.scheduler.workingHoursWidth) /
                hoursInDay) *
            (task.duration - beforeDayOverflow - afterDayOverflow);

        return width;
    }, [theme, task, isOffHoursExpanded, hoursInDay, daySchedule, startsBeforeDay, endsAfterDay]);

    function getStatusForParts(taskParts) {
        const hasNonStockParts = taskParts.some((item) => !item.part.stock);
        const hasStatusRequest = taskParts.some(
            (item) => item.status === "request" || (!item.part.stock && !item.status)
        );
        const hasStatusOrdered = taskParts.some((item) => item.status === "ordered");
        const hasStatusArrived = taskParts.some(
            (item) => item.status === "arrived" || item.status === "paid"
        );

        let statusForParts = "";

        if (hasStatusRequest) {
            statusForParts = hasNonStockParts ? "not_ordered" : "stock_item";
        } else if (hasStatusOrdered) {
            statusForParts = "ordered";
        } else if (hasStatusArrived) {
            statusForParts = "arrived";
        }

        return statusForParts;
    }

    function getLatestEtaForParts(taskParts) {
        const existingEtas = taskParts
            .filter((item) => item.status === "ordered" && !!item.eta)
            ?.map((item) => item.eta);

        if (existingEtas?.length) {
            const latestEta = existingEtas.sort((a, b) => new Date(b) - new Date(a))?.[0];
            return latestEta || null;
        } else {
            return null;
        }
    }

    const classNames = ["task", "noselect"];
    if (isDraggable) {
        classNames.push("draggable");
    }

    if (isResizeable) {
        classNames.push("resizeable");
    }

    return (
        // This span is needed for duration drag to work, do not remove.
        <span>
            <TaskStyled
                ref={tooltipParentTarget}
                $task={task}
                className={classNames.join(" ")}
                data-id={task.id}
                $hoursInDay={hoursInDay}
                $isOffHoursExpanded={isOffHoursExpanded}
                $taskWidth={taskWidth}
                $startsBeforeDay={startsBeforeDay}
                $endsAfterDay={endsAfterDay}
                onDoubleClick={(e) => {
                    if (e.target.classList != null && e.target.classList.contains("checkbox")) {
                        // Ignore double clicks on checkboxes!
                        return;
                    }
                    handleTaskDoubleClick(e);
                }}
                $shouldAlertAboutMissingParts={shouldAlertAboutMissingParts}
                $shouldAppearInCalendar={shouldTaskAppearInCalendar(task)}
            >
                <TaskContentStyled
                    onMouseMove={(e) => {
                        if (isMouseDown) return;
                        if (e.target.classList != null && e.target.classList.contains("checkbox")) {
                            setHovered(false);
                        } else {
                            setHovered(true);
                        }
                    }}
                    onMouseLeave={() => setHovered(false)}
                    onMouseDown={() => {
                        setIsMouseDown(true);
                        setHovered(false);
                    }}
                    onMouseUp={() => setIsMouseDown(false)}
                >
                    {isCheckboxVisible ? (
                        <Checkbox
                            disabled={isCheckboxDisabled}
                            className="checkbox"
                            checked={isCheckboxChecked}
                            size={16}
                            onClick={() => {
                                if (onCheckboxClick != null) {
                                    onCheckboxClick();
                                }
                            }}
                        />
                    ) : null}
                    <TaskTextStyled $task={task}>
                        <T>{task.unit?.int_id || task.unit_id}</T>
                    </TaskTextStyled>

                    <IconStyled
                        $status={task.status}
                        $shouldAlertAboutMissingParts={shouldAlertAboutMissingParts}
                    >
                        {task.priority === "high" ? <FlagIcon /> : null}{" "}
                        {task.from_customer ? <StarIcon /> : null}{" "}
                        {shouldAlertAboutHighCost && <MoneyIcon />}
                        {!!task.unit?.defect && <DotIcon color="red" />}
                    </IconStyled>
                </TaskContentStyled>

                {tooltipParentTarget.current && (
                    <TaskTooltip
                        task={task}
                        hovered={hovered}
                        tooltipRenderTarget={tooltipRenderTarget}
                        tooltipParentTarget={tooltipParentTarget}
                        explicitTooltipPlacement={explicitTooltipPlacement}
                        shouldAlertAboutHighCost={shouldAlertAboutHighCost}
                        shouldDisplayPartsStatus={shouldDisplayPartsStatus}
                        taskPartsStatus={taskPartsStatus}
                        latestEtaForParts={latestEtaForParts}
                    />
                )}
            </TaskStyled>
        </span>
    );
};

export default Task;

const TaskStyled = styled.div`
    height: ${(p) => p.theme.scheduler.cellHeight - p.theme.scheduler.borderWidth}px;
    width: ${(p) => p.$taskWidth || p.theme.scheduler.taskWidth}px;

    background-color: ${(p) => {
        const opacity = p.$task.isAutoPlanned ? "95" : "FF";

        if (p.$task.status === TaskStatus.INVOICED) {
            return p.theme.color.status.invoiced + opacity;
        }

        if (p.$shouldAlertAboutMissingParts) {
            return p.theme.color.error.xlight + opacity;
        }

        switch (p.$task.status) {
            case TaskStatus.SCHEDULED_DRAFT:
                return p.theme.color.status.scheduledDraft + opacity;
            case TaskStatus.STARTED:
                return p.theme.color.status.started + opacity;
            case TaskStatus.COMPLETED:
                return p.theme.color.status.completed + opacity;
            case TaskStatus.INVOICED:
                return p.theme.color.status.invoiced + opacity;
            default:
                return p.theme.color.status.base + opacity;
        }
    }};

    border-left: ${(p) =>
        p.$startsBeforeDay
            ? `2px dotted ${p.theme.color.neutral.base}`
            : p.$shouldAppearInCalendar
              ? `2px solid ${p.theme.color.primary.base}`
              : "none"};
    border-right: ${(p) => (p.$endsAfterDay ? `2px dotted ${p.theme.color.neutral.base}` : "none")};

    position: relative;
    margin-right: 2px;
    margin-bottom: 2px;

    animation: fadeIn 500ms ease forwards;
`;

const TaskContentStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 0.7rem;
`;

const TaskTextStyled = styled.span`
    text-wrap: nowrap;
    width: max-content;

    opacity: ${(p) => {
        if (p.$task.isAutoPlanned) {
            return 0.33;
        }

        return 1;
    }};

    > ${T} {
        margin-right: 0.4rem;
    }
`;

const IconStyled = styled(TB)`
    position: absolute;
    min-width: 1rem;
    right: 0rem;
    display: flex;

    background: ${(p) => {
        if (p.$status === TaskStatus.INVOICED) {
            return p.theme.color.status.invoiced;
        }

        if (p.$shouldAlertAboutMissingParts) {
            return p.theme.color.error.xlight;
        }

        switch (p.$status) {
            case TaskStatus.SCHEDULED_DRAFT:
                return p.theme.color.status.scheduledDraft;
            case TaskStatus.STARTED:
                return p.theme.color.status.started;
            case TaskStatus.COMPLETED:
                return p.theme.color.status.completed;
            case TaskStatus.INVOICED:
                return p.theme.color.status.invoiced;
            default:
                return p.theme.color.status.base;
        }
    }};

    > svg {
        margin-left: 0.1rem;
    }
`;

import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import inspectionAPI from "../../../../api/core/inspectionAPI";

export const useSaveInspectionCheckpoint = () => {
    const queryClient = useQueryClient();

    return useMutation((data) => inspectionAPI.updateCheckpoint(data), {
        onSuccess: () => {
            queryClient.invalidateQueries(["inspection", "category"]);
        },
        onError: (error) => {
            toast.error(`Error: ${error.response?.data?.message || error.message || "unknown"}`);
            console.log("Saving error: ", error);
        },
    });
};

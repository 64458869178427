import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import unitAPI from "../../../api/core/unitAPI";

export const useUnitCommentAcknowledge = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation((id) => unitAPI.getAcknowledgeComment(id), {
        onSuccess: () => {
            queryClient.invalidateQueries("units");
        },
        onError: (error) =>
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            ),
    });
};

import ReactModal from "react-modal";
import styled from "styled-components";
import TaskPostCheck from "./TaskPostCheck";
import { H1, TB } from "../../components/texts";
import { useTranslation } from "react-i18next";
import { Button, CleanButton } from "../../components/buttons";
import { useState } from "react";

const TaskPostCheckModal = ({ isOpen, onClose, postCheckTemplate, setPostCheckTemplate }) => {
    const { t } = useTranslation();
    const [postCheckTemplateState, setPostCheckTemplateState] = useState(postCheckTemplate);

    return (
        <ModalStyled isOpen={isOpen} className={"Modal"} overlayClassName={"ModalOverlay"}>
            <TitleStyled>{t("sja")}</TitleStyled>
            <TaskPostCheck
                postCheckTemplate={postCheckTemplateState}
                setPostCheckTemplate={setPostCheckTemplateState}
            />
            <SummaryButtons>
                <InnerButtonContainer>
                    <CleanButtonContainer>
                        <CleanButton type="button" onClick={onClose}>
                            <TB $underline $link>
                                {t("abort")}
                            </TB>
                        </CleanButton>
                    </CleanButtonContainer>
                    <ActionButton
                        onClick={() => {
                            setPostCheckTemplate({
                                ...postCheckTemplateState,
                                done: true,
                            });
                        }}
                    >
                        {t("finish_activity")}
                    </ActionButton>
                </InnerButtonContainer>
            </SummaryButtons>
        </ModalStyled>
    );
};

export default TaskPostCheckModal;

const CleanButtonContainer = styled.div`
    margin-right: 1.5rem;
`;

const SummaryButtons = styled.section`
    padding: 0rem 1rem 0.5rem;
`;

const InnerButtonContainer = styled.div`
    padding-top: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
`;

const ActionButton = styled(Button)`
    min-width: 15rem;
    background: ${(p) => p.theme.color.primary.light};
    justify-content: center;
`;

const TitleStyled = styled(H1)`
    padding-left: 1rem;
`;

const ModalStyled = styled(ReactModal)`
    top: 3rem;
    height: max-content;
    max-height: 85vh;
    width: 75%;
`;

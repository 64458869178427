import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 15px;
    height: 15px;
`;

export const MoneyIcon = () => {
    return (
        <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
                d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M4.04568 13C4.01523 13 4 12.9808 4 12.9425L4.02284 5.05747C4.02284 5.01916 4.03806 5 4.06851 5H5.34742C5.37787 5 5.39309 5.01916 5.39309 5.05747V7.78161L7.18584 5.04598C7.20868 5.01533 7.23532 5 7.26577 5H8.57893C8.60938 5 8.61699 5.01533 8.60177 5.04598L6.62632 8.21839L8.84156 12.954C8.85679 12.9847 8.83776 13 8.78447 13H7.39138C7.35332 13 7.33048 12.9847 7.32287 12.954L5.78133 9.57471L5.38167 10.2069V12.9425C5.38167 12.9808 5.36645 13 5.336 13H4.04568Z"
                fill="#38558D"
            />
            <path
                d="M9.39823 13C9.36778 13 9.35255 12.9808 9.35255 12.9425L9.37539 5.04598C9.37539 5.01533 9.39062 5 9.42107 5H11.7277C12.1387 5 12.5156 5.10345 12.8581 5.31035C13.2083 5.50958 13.4862 5.78161 13.6917 6.12644C13.8972 6.4636 14 6.84674 14 7.27586C14 7.55939 13.9581 7.81609 13.8744 8.04598C13.7907 8.2682 13.6917 8.45977 13.5775 8.62069C13.4633 8.77395 13.3605 8.88889 13.2692 8.96552C13.6803 9.42529 13.8858 9.96552 13.8858 10.5862L13.8972 12.9425C13.8972 12.9808 13.8782 13 13.8401 13H12.5498C12.5194 13 12.5041 12.9885 12.5041 12.9655V10.5862C12.5041 10.3103 12.4052 10.0728 12.2072 9.87356C12.0169 9.66667 11.7809 9.56322 11.4993 9.56322H10.7456L10.7342 12.9425C10.7342 12.9808 10.719 13 10.6886 13H9.39823ZM10.7456 8.18391H11.7277C11.9636 8.18391 12.173 8.09579 12.3557 7.91954C12.5384 7.7433 12.6297 7.52874 12.6297 7.27586C12.6297 7.03065 12.5384 6.81992 12.3557 6.64368C12.1806 6.46743 11.9713 6.37931 11.7277 6.37931H10.7456V8.18391Z"
                fill="#38558D"
            />
        </Svg>
    );
};

import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 20px;
    height: 20px;
`;

export const RadioButtonIcon = () => (
    <Svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" fill="white" stroke="#6D8AC5" strokeWidth="2" />
        <circle cx="10" cy="10" r="7" fill="#38558D" />
        <circle cx="10" cy="10" r="2" fill="white" />
    </Svg>
);

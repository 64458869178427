import styled from "styled-components";
import Button from "./Button";

export const SecondaryButton = styled(Button)`
    background-color: ${(props) => props.theme.color.primary.xlight};
    color: ${(props) => props.theme.color.primary.base};
    font-weight: 600;

    &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.color.primary.light};
        color: ${(props) => props.theme.color.neutral.xxxlight};
    }

    &:disabled {
        opacity: 0.6;
    }
`;

export default SecondaryButton;

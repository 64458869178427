import { useQuery, useQueryClient } from "react-query";
import userAPI from "../../../../api/core/userAPI";

export const useUsers = (ids, enabled = true) => {
    const queryClient = useQueryClient();
    const query = ids ? `ids=${ids.join(",")}` : undefined;

    return useQuery(["users", query], () => userAPI.getAll(query), {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["users", item.id], item));
        },
        enabled,
    });
};

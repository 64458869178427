import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import kitboxAPI from "../../../../api/core/kitboxAPI";

export const useSaveKitbox = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        ({ id, ...data }) =>
            id === 0 ? kitboxAPI.postKitbox(data) : kitboxAPI.patchKitbox({ id, ...data }),
        {
            onSuccess: () => queryClient.invalidateQueries("kitbox"),
            onError: (error) =>
                toast.error(
                    `${t("error_when_saving")}: ${
                        error.response?.data?.message || error.message || t("unknown_error")
                    }`
                ),
        }
    );
};

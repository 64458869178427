import { useEffect, useState } from "react";
import { usePermissions } from "./usePermissions";
import { useAuth } from "../../auth/hooks/useAuth";

export const usePermission = (name, customer_id = null) => {
    const [state, setState] = useState(false);
    const [requested, setRequested] = useState(false);

    const permissions = usePermissions();
    const { authUser } = useAuth();
    const userId = authUser?.sub;

    useEffect(() => {
        if (!userId) return;

        if (!permissions?.data && !requested) {
            setRequested(true);
        }
        if (permissions?.data) {
            if (customer_id) {
                setState(customer_id in permissions.data && name in permissions.data[customer_id]);
            } else setState(Object.keys(permissions.data).some((k) => name in permissions.data[k]));
        }
    }, [permissions?.data, name, requested, userId, customer_id]);

    return state;
};

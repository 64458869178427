import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { startOfDay, endOfDay, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import { useUnit } from "./hooks/useUnit";
import { standardDate } from "../../common/date";
import { usePreChecksFromTo } from "../pre-check/hooks/usePreChecksFromTo";
import QueryWrapper from "../../components/application/QueryWrapper";

import { T, H1, TB } from "../../components/texts";
import { DateInput } from "../../components/inputs";
import { Row } from "../../components/layout/FlexGrid";
import Underline from "../../components/helpers/Underline";
import StatusLine from "../../components/layout/StatusLine";
import Spacer from "../../components/helpers/Spacer";
import { GreaterThanIcon } from "../../components/icons";
import MainArea from "../../components/layout/MainArea";

const UnitControls = () => {
    const [fromDate, setFromDate] = useState(sub(new Date(), { days: 14 }));
    const [toDate, setToDate] = useState(new Date());
    const params = useParams();
    const unitId = Number(params.id);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const methods = useForm();
    const theme = useTheme();

    const prechecks = usePreChecksFromTo(unitId, startOfDay(fromDate), endOfDay(toDate));
    const unit = useUnit(unitId);

    return (
        <MainArea back>
            <UnitControlsStyled>
                <QueryWrapper data={unit}>
                    <H1>
                        {unit.data?.int_id} {unit.data?.group?.name} {unit.data?.name}{" "}
                        {unit.data?.type}
                    </H1>
                    <FormProvider {...methods}>
                        <Row className={"dates"}>
                            <DateInput
                                name={"from"}
                                label={"Fra"}
                                value={fromDate}
                                setValue={setFromDate}
                                maxDate={new Date()}
                            />
                            <DateInput
                                name={"to"}
                                label={"Til"}
                                value={toDate}
                                setValue={setToDate}
                                minDate={fromDate}
                                maxDate={new Date()}
                            />
                        </Row>
                    </FormProvider>
                    <TB>{t("last_prechecks", "Last pre checks")}</TB>
                    <Underline color={theme.color.neutral.xlight} />
                    <QueryWrapper data={prechecks}>
                        {prechecks.data?.map((pre, idx) => {
                            return (
                                <Fragment key={idx}>
                                    <PreChecksStyled onClick={() => navigate(`${pre.id}/answer`)}>
                                        <Row>
                                            <StatusLine data={pre} />
                                            <T>
                                                {standardDate(pre.createdAt)} {t("of", "of")}{" "}
                                                {pre?.user?.first_name} {pre?.user?.last_name}
                                            </T>
                                            <Spacer />
                                            <Chevron />
                                        </Row>
                                    </PreChecksStyled>
                                    <Underline $dotted color={theme.color.neutral.xlight} />
                                </Fragment>
                            );
                        })}
                    </QueryWrapper>
                </QueryWrapper>
            </UnitControlsStyled>
        </MainArea>
    );
};

export default UnitControls;

const UnitControlsStyled = styled.div`
    .dates {
        > div:nth-child(2) {
            margin-left: 20px;
        }
    }
`;

const PreChecksStyled = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
`;

const Chevron = styled(GreaterThanIcon)`
    margin-top: 2px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
`;

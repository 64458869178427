import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 18px;
    height: 18px;
`;

export const NotInUseIcon = () => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M3.75 0V20" stroke="#38558D" strokeWidth="2" strokeMiterlimit="10" />
            <path
                d="M3.75 1.875H16.5625L14.6875 5.625L16.5625 9.375H3.75"
                stroke="#38558D"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </Svg>
    );
};

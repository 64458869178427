import toast from "react-hot-toast";
import { useQuery } from "react-query";
import recurringTasksAPI from "../../../api/core/recurringTasksAPI";

export const useRecurringTask = (id) =>
    useQuery(
        ["tasks", "recurring", id],
        () => recurringTasksAPI.getRecurringTask(id),

        {
            enabled: !!id,
            onError: (error) => {
                toast.error(
                    `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
                );
                console.log("Loading error: ", error.message);
            },
        }
    );

import { useQuery } from "react-query";
import toast from "react-hot-toast";
import extraAPI from "../../../api/core/extraAPI";

export const useExtra = (id) =>
    useQuery(["extras", id], () => extraAPI.fetchExtra(id), {
        enabled: typeof id === "number" && !isNaN(id),
        onError: (error) => {
            toast.error(
                `Klarte ikke hente fravær: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Fetching extras error: ", error.response?.data?.message || error.message);
        },
    });

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import { InputWithLabel } from "./InputWithLabel";
import { CalendarIcon } from "../icons";

const DateInputInternal = forwardRef(
    ({ label, inputName, onClick, name, hideErrorLabel, ...props }, ref) => {
        const { t } = useTranslation();

        return (
            <InputContainer onClick={onClick}>
                <CalendarIconStyled>
                    <CalendarIcon />
                </CalendarIconStyled>

                <InputWithLabel
                    placeholder={t("date")}
                    label={label}
                    name={inputName}
                    hideErrorLabel={hideErrorLabel}
                    {...props}
                />
            </InputContainer>
        );
    }
);

export const DateInput = ({
    value,
    setValue,
    label,
    name,
    hideErrorLabel,
    className,
    ...props
}) => {
    return (
        <DatePicker
            dateFormat={"dd.MM.yyyy"}
            selected={value}
            onChange={(date) => setValue(date)}
            customInput={
                <DateInputInternal
                    label={label}
                    inputName={name}
                    hideErrorLabel={hideErrorLabel}
                    className={className}
                />
            }
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            {...props}
        />
    );
};

const InputContainer = styled.div`
    position: relative;
    max-width: 10rem;
`;

const CalendarIconStyled = styled.div`
    position: absolute;
    left: calc(100%);
    transform: translate(-125%, 90%);
    cursor: pointer;
`;

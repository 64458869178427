import { useLocation, useNavigate } from "react-router-dom";

export const useSearchParams = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);

    function getParam(name) {
        return queryParams.get(name);
    }

    function setParam(name, value) {
        queryParams.set(name, value);
        navigate({ search: queryParams.toString() });
    }

    function deleteParam(name) {
        queryParams.delete(name);
        navigate({ search: queryParams.toString() });
    }

    function hasParam(name) {
        return queryParams.has(name);
    }

    return { getParam, setParam, deleteParam, hasParam, queryParams };
};

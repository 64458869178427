import { useTranslation } from "react-i18next";
import { useCustomersWithPermission } from "../../../admin/customers/hooks/useCustomersWithPermission";
import { useCustomers } from "../../../admin/customers/hooks/useCustomers";
import ServerSelect from "../../../../components/application/ServerSelect";

const CustomerSelect = ({ permission, ...props }) => {
    const { t } = useTranslation();

    return (
        <ServerSelect
            name={`customer_id`}
            label={t("customer")}
            selectUse={permission ? useCustomersWithPermission : useCustomers}
            selectKey={permission ? { permission } : null}
            validate={{ required: t("customer_required") }}
            {...props}
        />
    );
};

export default CustomerSelect;

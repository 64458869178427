import styled from "styled-components";

const StatusLine = styled.div`
    height: 20px;
    border-left: 2px solid
        ${(props) =>
            props.data.status === 1
                ? props.theme.color.ok.base
                : props.data.status === 2
                ? props.theme.color.warning.base
                : props.theme.color.error.base};
    padding-right: 5px;
    padding-top: 3px;
`;

export default StatusLine;

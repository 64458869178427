import { useQuery, useQueryClient } from "react-query";
import unitAPI from "../../../api/core/unitAPI";

export const useUnits = () => {
    const queryClient = useQueryClient();

    return useQuery("units", unitAPI.getAll, {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["units", item.id], item));
        },
    });
};

import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUnit } from "../unit/hooks/useUnit";
import { useServiceConfigsForCustomer } from "../admin/service/hooks/useServiceConfigsForCustomer";
import { useServiceConfigTemplates } from "../admin/service/hooks/useServiceConfigTemplates";

import { SelectForm } from "../../components/inputs";

const ServiceIntervalSelector = ({ handleChange, isDisabled }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const { t } = useTranslation();
    const { watch } = useFormContext();

    const customerId = watch("customer_id");
    const task_check_template = watch("task_check_template");
    const unitId = watch("unit_id");

    const customerConfigs = useServiceConfigsForCustomer(customerId?.value);
    const templateConfigs = useServiceConfigTemplates();
    const unit = useUnit(unitId?.value);

    useEffect(() => {
        if (!options?.length || !task_check_template) return;

        const preselectedIntervalId = task_check_template.serviceConfigurationIntervalId;
        const preselectedConfig = options.find((item) => item.value === preselectedIntervalId);

        if (preselectedConfig) {
            setSelectedOption(preselectedConfig);
            handleChange(preselectedConfig);
        } else if (preselectedIntervalId) {
            const option = {
                value: preselectedIntervalId,
                configId: task_check_template.serviceConfigurationId,
                label: `${task_check_template.serviceConfigurationName} - ${
                    task_check_template.interval
                } ${t("hours")}`,
            };
            setSelectedOption(option);
            handleChange(option);
        }
    }, [options, task_check_template]);

    useEffect(() => {
        if (!customerId?.value || !customerConfigs?.data || !templateConfigs?.data || !unit?.data)
            return setOptions([]);

        const unitServiceIntervals = unit.data.data?.service_intervals;

        const allConfigs =
            customerId.value === 1
                ? customerConfigs.data
                : customerConfigs.data.concat(templateConfigs.data);

        const configOptions = allConfigs
            .map(mapConfigToInterval)
            .flat()
            .filter(filterForUnitIntervals)
            .map(mapIntervalToOption);

        setOptions(configOptions);

        function mapConfigToInterval(config) {
            return config.intervals
                .map((interval) => ({ ...interval, name: config.name }))
                .sort((a, b) => a.interval - b.interval);
        }

        function filterForUnitIntervals(interval) {
            if (unitServiceIntervals?.length) {
                return unitServiceIntervals.some((item) => item.interval_id === interval.id);
            } else {
                return true;
            }
        }

        function mapIntervalToOption(interval) {
            const sameIntervalInUnit = unitServiceIntervals?.find(
                (item) => item.interval_id === interval.id
            );

            const intervalHours = sameIntervalInUnit
                ? sameIntervalInUnit.interval
                : interval.interval;

            return {
                value: interval.id,
                label: `${interval.name}${interval.customer_id === 1 ? ` (systemmal)` : ``} - ${
                    intervalHours === 0 ? t("base_setup") : `${intervalHours} ${t("hours")}`
                }`,
                configId: interval.configuration_id,
                interval,
            };
        }
    }, [customerId, customerConfigs?.data, templateConfigs?.data, unit?.data]);

    function onChange(value) {
        handleChange(value);
        setSelectedOption(value);
    }

    return (
        <SelectForm
            name="service-interval"
            label={t("choose_service")}
            options={options}
            onChange={onChange}
            isDisabled={isDisabled}
            value={selectedOption}
            isClearable
        />
    );
};

export default ServiceIntervalSelector;

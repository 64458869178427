import { useState, useRef, useEffect } from "react";
import styled, { useTheme } from "styled-components";

import { useNationalHolidaysForYear } from "../national-holidays/hooks/useNationalHolidaysForYear";

import { calculateCurrentTimePosition } from "./helpers";
import { weekDaysText, isInCurrentWeek, yearNumber, hasSameDay, getDate } from "../../common/date";
import { TB } from "../../components/texts";
import CalendarCells from "./CalendarCells";
import { CleanButton } from "../../components/buttons";

const CalendarMain = ({
    from_date,
    tasks,
    mechanics,
    createPrefilledTask,
    daySchedule,
    isOffHoursExpanded,
    setIsOffHoursExpanded,
    editAbsence,
    editExtra,
    handleTaskDoubleClick,
    isInAutoPlannerMode,
    autoPlannerSelectedTaskIds,
    onTaskCheckboxUpdate,
    isCheckboxDisabled,
}) => {
    const [scrollToday, setScrollToday] = useState(true);
    const [width, setWidth] = useState(0);

    const theme = useTheme();
    const mainRef = useRef(null);
    const scrollRef = useRef(null);

    const { data: nationalHolidays } = useNationalHolidaysForYear(yearNumber(from_date));

    useEffect(() => {
        // Update calendar sizing on window resize
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    // Set calendar width on launch
    useEffect(() => {
        updateDimensions();
    }, [mainRef]);

    useEffect(() => {
        setScrollToday(true);
    }, [from_date]);

    // Center calendar on today's date if displaying current week
    useEffect(() => {
        if (mainRef.current && width && isInCurrentWeek(from_date))
            mainRef.current.scroll({
                left:
                    calculateCurrentTimePosition({
                        date: from_date,
                        dayWidth: theme.scheduler.dayWidth,
                        nonWorkingHoursWidth: theme.scheduler.nonWorkingHoursWidth,
                        daySchedule: daySchedule,
                    }) -
                    width / 2,
            });
    }, [mainRef, width, scrollToday, from_date]);

    function updateDimensions() {
        setWidth(mainRef?.current?.clientWidth);
    }

    function toggleOffHoursExpanded() {
        setIsOffHoursExpanded((prev) => !prev);
    }

    function getIsOffDay(day) {
        if (!day || !nationalHolidays?.length) return false;

        return nationalHolidays?.some((holiday) =>
            hasSameDay(getDate(holiday?.date), getDate(day))
        );
    }

    function getOffDayDescription(day) {
        return (
            nationalHolidays?.find((holiday) => hasSameDay(getDate(holiday?.date), getDate(day)))
                ?.description || ""
        );
    }

    return (
        <div className={"main"} ref={mainRef}>
            <div style={{ position: "relative" }}>
                <div className={"header main-scroll"} ref={scrollRef}>
                    {/* Headers for weekdays */}
                    {weekDaysText(from_date).map((day, index) => {
                        const isOffDay = getIsOffDay(day.date);
                        return (
                            <WeekDayHeader
                                key={index}
                                className={"day"}
                                $index={index}
                                $isOffDay={isOffDay}
                            >
                                <ToggleOffHoursButton
                                    onClick={toggleOffHoursExpanded}
                                    style={{ fontSize: "0.9rem" }}
                                >
                                    {isOffHoursExpanded ? "←" : "→"}
                                </ToggleOffHoursButton>

                                <WeekDayTitle $isOffDay={isOffDay}>{`${day.text} ${
                                    isOffDay ? `- ${getOffDayDescription(day.date)}` : ""
                                }`}</WeekDayTitle>

                                <ToggleOffHoursButton onClick={toggleOffHoursExpanded}>
                                    {isOffHoursExpanded ? "→" : "←"}
                                </ToggleOffHoursButton>
                            </WeekDayHeader>
                        );
                    })}
                </div>

                <CalendarCells
                    tasks={tasks}
                    from_date={from_date}
                    mechanics={mechanics}
                    createPrefilledTask={createPrefilledTask}
                    daySchedule={daySchedule}
                    isOffHoursExpanded={isOffHoursExpanded}
                    setIsOffHoursExpanded={setIsOffHoursExpanded}
                    editAbsence={editAbsence}
                    editExtra={editExtra}
                    handleTaskDoubleClick={handleTaskDoubleClick}
                    nationalHolidays={nationalHolidays}
                    isInAutoPlannerMode={isInAutoPlannerMode}
                    autoPlannerSelectedTaskIds={autoPlannerSelectedTaskIds}
                    onTaskCheckboxUpdate={onTaskCheckboxUpdate}
                    isCheckboxDisabled={isCheckboxDisabled}
                />
            </div>
        </div>
    );
};

export default CalendarMain;

const WeekDayHeader = styled.div`
    left: ${(p) => p.$index * p.theme.scheduler.dayWidth}px;
    padding: 0 0.5rem;
    background: ${(p) => (p.$isOffDay ? "#f5f5f588" : "none")};
`;

const WeekDayTitle = styled(TB)`
    width: 100%;
    text-align: center;
    color: ${(p) => (p.$isOffDay ? p.theme.color.error.base : "initial")};
`;

const ToggleOffHoursButton = styled(CleanButton)`
    font-size: 0.9rem;
`;

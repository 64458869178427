import { startOfWeek, getDate, startOfDay } from "../../common/date";
import { TaskStatus } from "../../api/core/taskAPI";
import { convertMilitaryToDate } from "../admin/setup/helpers";

export function calculateCurrentTimePosition({
    date,
    dayWidth,
    nonWorkingHoursWidth,
    daySchedule,
    isOffHoursExpanded,
}) {
    const dateNow = getDate(new Date().toISOString());
    const startOfThisWeek = startOfWeek(date);
    const startOfDayToday = startOfDay(dateNow);

    const workStart = Math.floor(+daySchedule.work_start);
    const workEnd = Math.ceil(+daySchedule.work_end);
    const hoursInDay = workEnd - workStart;

    const borderWidth = 2;

    const currentTimeMs = new Date().getTime();
    const workDayStartMs = new Date().setHours(workStart, 0, 0, 0);
    const workDayEndMs = new Date().setHours(workEnd, 0, 0, 0);

    const startOfWorkDay = getDate(new Date(workDayStartMs).toISOString());

    const distanceToStartOfDayToday =
        startOfDayToday.diff(startOfThisWeek).as("minutes") * (dayWidth / (24 * 60));

    const distanceFromStartOfWorkToNow =
        dateNow.diff(startOfWorkDay).as("minutes") *
        ((dayWidth - (isOffHoursExpanded ? 0 : nonWorkingHoursWidth * 2)) / (hoursInDay * 60));

    const isCurrentTimeWithinWorkingDay =
        currentTimeMs > workDayStartMs && currentTimeMs < workDayEndMs;

    const middleOfDayToday = distanceToStartOfDayToday + dayWidth / 2;

    const xPosition =
        distanceToStartOfDayToday +
        (isOffHoursExpanded ? 0 : nonWorkingHoursWidth) +
        distanceFromStartOfWorkToNow -
        borderWidth;

    return !isOffHoursExpanded && !isCurrentTimeWithinWorkingDay ? middleOfDayToday : xPosition;
}

export function shouldTaskAppearInCalendar(task) {
    const statusText = task?.status || task;

    switch (statusText) {
        case TaskStatus.SCHEDULED:
            return true;
        case TaskStatus.SCHEDULED_DRAFT:
            return true;
        case TaskStatus.STARTED:
            return true;
        case TaskStatus.COMPLETED:
            return true;
        case TaskStatus.INVOICED:
            return true;

        case TaskStatus.REQUEST:
            return false;
        case TaskStatus.DENIED:
            return false;

        default:
            return false;
    }
}

export function convertMilitaryToNum(string) {
    if (!string) return null;

    const date = convertMilitaryToDate(string);
    if (date === "") return null;

    const hours = date.getHours();
    const minutes = date.getMinutes();
    return Number(`${hours}.${(minutes / 60) * 100}`);
}

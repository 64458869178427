import { useState } from "react";
import styled from "styled-components";

import { deleteSavedUser, getSavedUsers } from "../../../app/localStorage";

import { Horizontal } from "../../../components/layout/FlexGrid";
import { T } from "../../../components/texts";
import { CloseIcon } from "../../../components/icons";
import { CleanButton } from "../../../components/buttons";

const EarlierLoggedIn = ({ onClick }) => {
    const [earlier, setEarlier] = useState(getSavedUsers());

    return (
        <Container>
            {Object.keys(earlier).map((user) => {
                return (
                    <User key={user}>
                        <Horizontal>
                            <CleanButton
                                onClick={() => onClick(earlier[user])}
                                style={{ flexGrow: 1, textAlign: "left" }}
                            >
                                <T $second>{user}</T>
                            </CleanButton>

                            <CleanButton
                                onClick={() => {
                                    deleteSavedUser(user);
                                    setEarlier(getSavedUsers());
                                }}
                            >
                                <CloseIcon stroke={"#A7B9DC"} />
                            </CleanButton>
                        </Horizontal>
                    </User>
                );
            })}
        </Container>
    );
};

export default EarlierLoggedIn;

const Container = styled.article`
    border-top: 0.125rem solid #a7b9dc;
    margin-right: 1.25rem;
`;

const User = styled.section`
    border-bottom: 0.125rem solid #a7b9dc;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
`;

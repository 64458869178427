import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCustomers } from "../customers/hooks/useCustomers";
import { useUnitGroups } from "../../unit/hooks/useUnitGroups";
import QueryWrapper from "../../../components/application/QueryWrapper";
import useMedia from "../../../components/helpers/hooks/useMedia";

import MainArea from "../../../components/layout/MainArea";
import { H1, TB } from "../../../components/texts";
import { Horizontal } from "../../../components/layout/FlexGrid";
import { Button } from "../../../components/buttons";
import { InputWithLabel } from "../../../components/inputs";
import Spacer from "../../../components/helpers/Spacer";
import LegacyTable from "../../../components/application/LegacyTable";

const tableLayouts = {
    desktop: [
        {
            label: ["group"],
            field: "name",
        },
        {
            label: ["description"],
            field: "description",
        },
        {
            label: ["customer"],
            field: "customer",
        },
    ],
    mobile: [
        {
            label: ["group"],
            field: "name",
        },
        {
            label: ["customer"],
            field: "customer",
        },
    ],
};

const UnitGroups = () => {
    const groups = useUnitGroups();
    const customers = useCustomers();
    const navigate = useNavigate();
    const methods = useForm();
    const { t } = useTranslation();
    const search = methods.watch("search");
    const [data, setData] = useState([]);
    const { isMobile } = useMedia();

    useEffect(() => {
        if (groups.data && customers.data) {
            setData(
                groups.data.map((group) => {
                    return {
                        ...group,
                        customer:
                            customers.data.find((element) => element.id === group.customer_id)
                                ?.name || "Ukjent",
                    };
                })
            );
        }
    }, [groups?.data, customers?.data]);

    return (
        <MainArea>
            <Horizontal>
                <H1>{t("vehicle_groups")}</H1>
                <Spacer />
                <Button onClick={() => navigate("/units/groups/0/edit")}>
                    <TB $second>{t("new_vehicle_group")}</TB>
                </Button>
            </Horizontal>

            <QueryWrapper data={[groups, customers]}>
                <section className={"area-table"}>
                    <FormProvider {...methods}>
                        <form>
                            <InputWithLabel
                                label={t("search_vehicle_groups")}
                                name={"search"}
                                style={{ maxWidth: "27rem" }}
                            />
                        </form>
                    </FormProvider>

                    <LegacyTable
                        def={isMobile ? tableLayouts.mobile : tableLayouts.desktop}
                        data={data}
                        search={search}
                        actions={[
                            {
                                label: "edit",
                                onClick: (id) => navigate(`/units/groups/${id}/edit`),
                            },
                        ]}
                    />
                </section>
            </QueryWrapper>
        </MainArea>
    );
};

export default UnitGroups;

import { url2data } from "./fetch";
import { API_AUTH_BASE_URL } from "../constants";

const login = async (username, password) => {
    const url = API_AUTH_BASE_URL + `auth/login`;

    return url2data(url, { username, password });
};

const logout = async () => {
    const url = API_AUTH_BASE_URL + `auth/logout`;

    return url2data(url, {});
};

const refresh = async () => {
    const url = API_AUTH_BASE_URL + `auth/refresh`;

    return url2data(url, {});
};

const resetPasswordRequest = async (email) => {
    const url = API_AUTH_BASE_URL + `auth/resetPasswordRequest`;

    return url2data(url, { email });
};

const resetPassword = async (token, password) => {
    const url = API_AUTH_BASE_URL + `auth/resetPassword`;

    return url2data(url, { token, password });
};

export default {
    login,
    logout,
    refresh,
    resetPasswordRequest,
    resetPassword,
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { useCustomers } from "../../../features/admin/customers/hooks/useCustomers";
import QueryWrapper from "../QueryWrapper";
import SelectStyled from "../styles/SelectStyled";

const WorkshopSelect = ({ defaultWorkshopId, ...props }) => {
    const [options, setOptions] = useState(null);
    const customers = useCustomers();

    const { t } = useTranslation();

    useEffect(() => {
        if (customers?.data) {
            setOptions(getOptionsFromData(customers.data));
        }
    }, [customers?.data]);

    function getOptionsFromData(data) {
        return data.map((item) => ({ label: item.name, value: item.id }));
    }

    function getDefaultWorkshopData(id) {
        const workshopData = options?.find((item) => +item.value === +id);
        return workshopData || "";
    }

    return (
        <QueryWrapper data={customers}>
            <SelectStyled>
                <Select
                    value={getDefaultWorkshopData(defaultWorkshopId)}
                    options={options}
                    placeholder={t("choose_workshop")}
                    noOptionsMessage={() => t("no_results")}
                    id="workshop_id"
                    name="workshop_id"
                    classNamePrefix={"rs"}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    {...props}
                />
            </SelectStyled>
        </QueryWrapper>
    );
};

export default WorkshopSelect;

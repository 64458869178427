import { useMemo } from "react";
import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";
import { TaskStatus } from "../../../api/core/taskAPI";

/**
 * Custom hook to fetch and manage tasks that are not scheduled.
 *
 * @param {number} workshopId - The ID of the workshop.
 * @returns {Object} An object containing query results and the filtered data.
 */
export const useNotScheduledTasks = (workshopId) => {
    const scheduledStatusList = [TaskStatus.REQUEST];
    const host = Number(workshopId); // Ensure workshopId is a number
    const params = `?status=${scheduledStatusList}&host_id=${host}&attributes=id&attributes=status&attributes=host_id&attributes=duration&attributes=description&attributes=priority&attributes=from_customer&attributes=createdAt&attributes=unit.id&attributes=unit.int_id&attributes=unit.manufacturer&attributes=unit.type&attributes=unit.defect&attributes=customer.name&attributes=customer.contact_person&attributes=owner.first_name&attributes=owner.last_name&attributes=parts.part_id&attributes=parts.status&attributes=parts.eta&attributes=part.stock&attributes=type_id`;

    // Define the query key
    const queryKey = ["tasks", "websocket-hooked", "notScheduled", host];
    const query = useQuery(queryKey, () => taskAPI.getTasksWithConnectedWorkshops(params), {
        enabled: !!host, // Enable query only if host is truthy
        refetchOnWindowFocus: false,
        meta: {
            filterFn: (task) => {
                if (task.host_id !== host) return false;
                return scheduledStatusList.includes(task.status);
            },
        },
    });

    // Memoize the filtered data
    const data = useMemo(
        () => query.data?.filter((item) => item.host_id === host),
        [query.data, host]
    );

    return { ...query, data };
};

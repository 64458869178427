import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 24px;
    height: 24px;
`;

export const CloseIcon = ({ stroke }) => {
    return (
        <Svg
            width="43"
            height="42"
            viewBox="0 0 43 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10.7305 10.4813L32.1918 31.4435" stroke={stroke} strokeWidth="2" />
            <path d="M32.1921 10.4812L10.7308 31.4434" stroke={stroke} strokeWidth="2" />
        </Svg>
    );
};

import styled from "styled-components";

const NavBar = ({ options, currentlySelectedIndex, setCurrentlySelectedIndex }) => {
    return (
        <>
            <BarContainer>
                {options?.map((option, i) => (
                    <OptionContainer
                        key={i}
                        className={i === currentlySelectedIndex ? activeClassName : undefined}
                        onClick={() => {
                            setCurrentlySelectedIndex(i);
                        }}
                    >
                        {option.name}
                    </OptionContainer>
                ))}
            </BarContainer>
            {options?.[currentlySelectedIndex]?.element}
        </>
    );
};

export default NavBar;

const activeClassName = "active";

const BarContainer = styled.div`
    display: flex;
    position: sticky;
    background-color: ${(p) => p.theme.header.background.color};
    color: ${(p) => p.theme.text.color.second};
    font-weight: ${(p) => p.theme.font.tb["font-weight"]};
    font-family: ${(p) => p.theme.font.tb["font-family"]};
`;

const OptionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 80px;
    cursor: pointer;

    &.${activeClassName} {
        background-color: ${(p) => p.theme.color.primary.light};
    }
`;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import styled from "styled-components";

import { useIsLoggedIn } from "../../../features/auth/hooks/useIsLoggedIn";
import { PERMISSIONS } from "../../../features/auth/permissions";
import unitAPI from "../../../api/core/unitAPI";
import useMedia from "../../helpers/hooks/useMedia";

import { T } from "../../texts";
import { Row } from "../../layout/FlexGrid";
import UnitUseIcon from "./UnitUseIcon";

const searchOptions = async (inputValue) => {
    return await getOptions(inputValue, "");
};

const searchOptionsMobile = async (inputValue) => {
    return await getOptions(inputValue, "mobile");
};

const getOptions = async (inputValue, type) => {
    const result = await unitAPI.getUnitSearch(inputValue, PERMISSIONS.unit_use.name, true);
    const options = result?.data.map((unit) => {
        let name = "";
        switch (type) {
            case "mobile":
                name = unit.custom_name ? unit.custom_name : `${unit.group?.name} ${unit.type}`;
                break;
            default:
                name =
                    (unit.custom_name ? " " + unit.custom_name : "") +
                    ` ${unit.group?.name} ${unit.type}`;
                break;
        }
        return { label: `${unit.int_id} ${name}`, value: unit.id, data: unit };
    });
    return options;
};

const CustomOptionLabel = (props) => {
    return (
        <OptionStyled>
            <Row>
                <IconCol>
                    <UnitUseIcon unit={props.data} />
                </IconCol>
                <InfoCol disabled={props.data?.defect}>
                    <T>{props.label}</T>
                </InfoCol>
            </Row>
        </OptionStyled>
    );
};

const UnitSearch = ({ onChange }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const { isMobile } = useMedia();
    const isLoggedIn = useIsLoggedIn();

    useEffect(() => {
        if (onChange) onChange(value.data);
    }, [value]);

    return (
        <InputStyled>
            {isLoggedIn && (
                <AsyncSelect
                    classNamePrefix={"rs"}
                    cacheOptions
                    loadOptions={isMobile ? searchOptionsMobile : searchOptions}
                    defaultOptions
                    onChange={(v) => setValue(v)}
                    hideSelectedOptions={true}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary: theme.colors.primary.base,
                        },
                    })}
                    isOptionDisabled={(option) => option.data?.defect}
                    formatOptionLabel={CustomOptionLabel}
                    placeholder={t("select")}
                />
            )}
        </InputStyled>
    );
};

export default UnitSearch;

const OptionStyled = styled.div`
    min-height: 20px;
    padding: 2px 5px 5px 0;
`;

const IconCol = styled.div`
    width: 24px;
`;

const InfoCol = styled.div`
    width: 100%;
    opacity: ${(props) => (props.disabled ? "50%" : "100%")};
`;

const InputStyled = styled.div`
    .rs__control,
    rs__control:hover {
        border: 2px solid #6d8ac5 !important;
        box-shadow: unset;
        border-radius: 0;
        height: 44px;
    }

    .rs__single-value {
        height: 30px;
    }

    .rs__control--is-disabled {
        background-color: #eee;
    }

    .rs__single-value--is-disabled {
        color: ${(p) => p.theme.color.neutral.base};
    }
`;

import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import inspectionAPI from "../../../../api/core/inspectionAPI";

export const useDeleteInspectionCheckpoint = ({ onSuccess }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation((id) => inspectionAPI.deleteCheckpoint(id), {
        onSuccess: () => {
            if (onSuccess instanceof Function) onSuccess();

            toast.success(t("checkpoint_removed"));
            queryClient.invalidateQueries(["inspection", "category"]);
        },
        onError: (error) => {
            toast.error(`Error: ${error.message || "unknown"}`);
            console.log("Delete error: ", error);
        },
    });
};

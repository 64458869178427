import { useMemo } from "react";
import { useQuery } from "react-query";
import customerAPI from "../../../api/core/customerAPI";

export const useEmployeesForCustomer = (customerId) => {
    const query = useQuery(
        ["users", "customer", customerId],
        () => customerAPI.getUsersForCustomer(customerId),
        {
            enabled: !!customerId,
        }
    );

    return {
        ...query,
        data: useMemo(() => query.data?.filter((user) => !!user.employee), [query.data]),
    };
};

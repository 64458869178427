import { useState, useEffect } from "react";
import { useLocation, NavLink, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { HamburgerIcon, LogoIcon, MenuXIcon } from "../../../components/icons";
import { H1 } from "../../../components/texts";

const NavBar = () => {
    const params = useParams();
    const unitId = Number(params.id);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation();

    const location = useLocation();

    // Close mobile menu when location changes
    useEffect(() => {
        setIsMobileMenuOpen(false);
    }, [location]);

    // Blocks body scroll when mobile menu is open
    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.removeProperty("overflow");
        }
        return () => {
            document.body.style.removeProperty("overflow");
        };
    }, [isMobileMenuOpen]);

    return isMobileMenuOpen ? (
        <MobileMenuContainer>
            <MobileHeader>
                <NavLink to={`/display/drivers/${unitId}`}>
                    <LogoIcon />
                </NavLink>

                <MenuButton onClick={() => setIsMobileMenuOpen(false)}>
                    <MenuXIcon stroke="white" />
                    <H1 $second>{t("menu")}</H1>
                </MenuButton>
            </MobileHeader>
            <MobileMenuContent>
                <li>
                    <NavLinkMain to={`/display/drivers/${unitId}`}>{t("main")}</NavLinkMain>
                </li>
            </MobileMenuContent>
        </MobileMenuContainer>
    ) : (
        <HeaderContainer>
            <MainHeader>
                <PathDisplay>
                    <LogoLink to={`/display/drivers/${unitId}`}>
                        <LogoIcon />
                    </LogoLink>
                </PathDisplay>
            </MainHeader>

            <MobileHeader className="header-closed">
                <NavLink to={`/display/drivers/${unitId}`}>
                    <LogoIcon />
                </NavLink>

                <MenuButton onClick={() => setIsMobileMenuOpen(true)}>
                    <HamburgerContainer>
                        <HamburgerIcon stroke={theme.text.color.second} />
                    </HamburgerContainer>
                    <H1 $second>{t("menu")}</H1>
                </MenuButton>
            </MobileHeader>
        </HeaderContainer>
    );
};

export default NavBar;

const HeaderContainer = styled.div`
    top: 0;
    position: sticky;
    z-index: 40;
`;

const MainHeader = styled.nav`
    background-color: ${(props) => props.theme.header.background.color};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.125rem;

    @media (max-width: 912px) {
        display: none;
    }
`;

const MobileMenuContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: auto;
    background-color: ${(props) => props.theme.header.background.color};
`;

const MobileHeader = styled.nav`
    height: 4rem;
    background-color: ${(props) => props.theme.header.background.color};
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0.625rem 0;
    display: flex;
    z-index: 99;

    &.header-closed {
        @media (min-width: 913px) {
            display: none;
        }
    }
`;

const MobileMenuContent = styled.ul`
    display: flex;
    flex-direction: column;
    z-index: 10;
    color: ${(props) => props.theme.text.color.second};
    list-style: none;
    padding: 0;
    margin: 0;

    opacity: 0;
    animation: fadeIn 300ms ease forwards;

    &:before {
        content: "";
        width: calc(100% - (0.625rem * 2));
        margin-left: 0.625rem;
        height: 2px;
        background: white;
    }

    > li {
        padding: 0.5rem 0.625rem;

        &:after {
            position: absolute;
            display: block;
            content: "";
            width: calc(100% - (0.625rem * 2));
            height: 2px;
            background: #fff;
            margin-top: 0.5rem;
        }

        > a {
            display: block;
        }
    }
`;

const LogoLink = styled(NavLink)`
    margin-right: 1.5rem;
    padding: 0.6rem 1rem 0.05rem;
`;

const PathDisplay = styled.section`
    display: flex;
    align-items: center;
`;

const NavLinkMain = styled(NavLink)`
    color: ${(props) => props.theme.text.color.second};
    font-weight: ${(props) => props.theme.font.tb["font-weight"]};
    font-family: ${(props) => props.theme.font.tb["font-family"]};
    text-decoration: none;
`;

const MenuButton = styled.button`
    background: none;
    padding: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    > * {
        margin-left: 0.3rem;
    }
`;

const HamburgerContainer = styled.div`
    position: relative;
    top: -0.1rem;
    left: -0.1rem;
`;

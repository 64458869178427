import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { useServiceConfigsForCustomer } from "../service/hooks/useServiceConfigsForCustomer";
import { useServiceConfigTemplates } from "../service/hooks/useServiceConfigTemplates";

import { SelectForm } from "../../../components/inputs";

const ServiceConfigSelect = ({ customer_id, name, label }) => {
    const [options, setOptions] = useState([]);
    const { watch, setValue } = useFormContext();

    const configsForCustomer = useServiceConfigsForCustomer(customer_id);
    const configsTemplates = useServiceConfigTemplates();

    const selectedConfigId = watch("service_configuration_id");

    useEffect(() => {
        if (!configsForCustomer?.data || !configsTemplates?.data) return;

        const allConfigs =
            customer_id === 1
                ? configsForCustomer.data
                : [...configsForCustomer.data, ...configsTemplates.data];

        if (allConfigs.length > 0) {
            const options = allConfigs.map((config) => ({
                value: config.id,
                label: `${config.name} ${
                    config.intervals?.length > 0
                        ? `(${config.intervals.map((item) => item.interval).join(", ")})`
                        : ``
                }`,
            }));

            setOptions(options);
        } else {
            setOptions([]);
        }
    }, [configsForCustomer?.data, configsTemplates?.data]);

    useEffect(() => {
        if (options.length && selectedConfigId && !selectedConfigId.value) {
            setValue(
                "service_configuration_id",
                options.find((item) => item.value === selectedConfigId)
            );
        }
    }, [selectedConfigId, options]);

    return (
        <SelectForm
            // Key rerenders (resets) select on customer change
            key={customer_id}
            name={name}
            label={label}
            options={options}
            isClearable
        />
    );
};

export default ServiceConfigSelect;

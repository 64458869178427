import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { T } from "../texts";

export const CheckBoxOneChoice = ({ options, name, label, className, disabled = false }) => {
    const { watch, setValue } = useFormContext();
    const values = watch();

    function updateValue(option) {
        if (option.independent) {
            const valueName = option.value;
            const newValue = option.value === values[valueName] ? "" : option.value;
            setValue(valueName, newValue, { shouldDirty: true });
        } else {
            const newValue = option.value === values[name] ? "" : option.value;
            setValue(name, newValue, { shouldDirty: true });
        }
    }

    return (
        <>
            <Container>
                <Header>{label}</Header>
                <OptionsContainer>
                    {options && options.length > 0
                        ? options.map((option, index) => {
                              const valueName = option.independent ? option.value : name;

                              return (
                                  <InputStyled key={`${option.value}-${index}`}>
                                      <CheckboxContainer
                                          className={className}
                                          onClick={() => updateValue(option)}
                                      >
                                          <HiddenCheckbox
                                              defaultChecked={values[name] === option.value}
                                              name={`${valueName}-${option.label}`}
                                          />
                                          <StyledCheckbox
                                              checked={values[valueName] === option.value}
                                              disabled={disabled}
                                          >
                                              <Icon viewBox="0 0 12 13" disabled={disabled}>
                                                  <path
                                                      d="M1 6 L5.5 10.5 L10.5 2"
                                                      stroke="white"
                                                      strokeWidth="2"
                                                  />
                                              </Icon>
                                          </StyledCheckbox>

                                          <label
                                              className="noselect"
                                              name={`${valueName}-${option.label}`}
                                          >
                                              <T>{option.label}</T>
                                          </label>
                                      </CheckboxContainer>
                                  </InputStyled>
                              );
                          })
                        : null}
                </OptionsContainer>
            </Container>
        </>
    );
};

export default CheckBoxOneChoice;

const Container = styled.section`
    min-width: max-content;
    margin-bottom: 1rem;
`;

const Header = styled(T)`
    display: block;
    margin-bottom: 0.5rem;
`;

const OptionsContainer = styled.section`
    display: flex;
    flex-direction: row;
    gap: 2rem;
`;

const InputStyled = styled.div`
    display: flex;
    flex-direction: row;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    label {
        margin-left: 7px;
        cursor: pointer;
    }
`;

const Icon = styled.svg`
    fill: none;
    stroke: ${(props) => props.theme.color.primary.light};
    stroke-width: 2px;
    stroke-opacity: ${(props) => (props.disabled ? "0.3" : "1")};
    vertical-align: top;
    width: 1rem;
    height: 1rem;
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div`
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: ${(props) => (props.checked ? props.theme.color.primary.base : "white")};
    border: 2px solid ${(props) => props.theme.color.primary.light};
    transition: all 200ms;
    background-clip: content-box;
    padding: 1px;
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px lightgrey;
    }

    ${Icon} {
        visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    }
`;

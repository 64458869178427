import styled from "styled-components";
import { device } from "../../theme";

export const Grid = styled.section`
    display: grid;
    grid-template-columns: repeat(${(p) => p.$templateColumns || 3}, 1fr);
    column-gap: ${(p) => p.gap || 1.5}rem;

    @media only screen and ${device.mobile} {
        grid-template-columns: 1fr;
    }
`;

export const GridBlock = styled.section`
    grid-column: span ${(p) => p.$columns || 1};
    grid-row: span ${(p) => p.$rows || 1};
`;

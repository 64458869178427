import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import taskAPI from "../../../api/core/taskAPI";

export const usePatchTask = () => {
    const queryClient = useQueryClient();

    return useMutation((data) => taskAPI.patchTask(data), {
        onSuccess: () => queryClient.invalidateQueries("tasks"),
        onError: (error) => {
            toast.error(
                `Det oppsto en feil ved lagring: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Saving error: ", error.response?.data?.message || error.message);
        },
    });
};

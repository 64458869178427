import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAuth } from "./hooks/useAuth";
import { device } from "../../theme";

import { H1, TB, Text } from "../../components/texts";
import { InputWithLabel } from "../../components/inputs";
import { DarkButton, CleanButton } from "../../components/buttons";
import { InfoBox } from "../../components/layout/InfoBox";
import { ErrorBox } from "../../components/layout/ErrorBox";
import { LogoIcon } from "../../components/icons";
import EarlierLoggedIn from "./components/EarlierLoggedIn";
import ResetPasswordRequest from "./components/ResetPasswordRequest";

const Login = () => {
    const [resetPassword, setResetPassword] = useState(false);
    const [info, setInfo] = useState(null);

    const { t } = useTranslation();
    const methods = useForm();

    const { logIn, logInError } = useAuth();

    const username = methods.watch("username");
    const password = methods.watch("password");

    const login = (e) => {
        e.preventDefault();

        logIn({ username, password });
    };

    useEffect(() => {
        if (resetPassword) {
            methods.setValue("password", "");
        }
    }, [resetPassword]);

    return (
        <StyledContainer>
            {resetPassword && (
                <ResetPasswordRequest
                    remove={() => {
                        setResetPassword(false);
                        setInfo(t("check_link_password"));
                    }}
                    cancel={() => setResetPassword(false)}
                />
            )}
            {!resetPassword && (
                <div>
                    <div>
                        <LogoIcon />
                    </div>
                    <Text $second>{t("login_info")}</Text>
                    <HR />
                    <section className={"page"}>
                        <section className={"previous"}>
                            <H1 $second>{t("previous_loggin")}</H1>
                            <EarlierLoggedIn
                                onClick={(username) => methods.setValue("username", username)}
                            />
                        </section>
                        <section className={"login"}>
                            <H1 $second>{t("login")}</H1>
                            <ErrorBox text={logInError} />
                            <InfoBox text={info} remove={setInfo} />

                            <FormProvider {...methods}>
                                <form>
                                    <InputWithLabel
                                        second
                                        name={"username"}
                                        label={t("username")}
                                    />
                                    <InputWithLabel
                                        second
                                        type="password"
                                        name={"password"}
                                        label={t("password")}
                                    />

                                    <div className={"submit"}>
                                        <DarkButton type="submit" onClick={login}>
                                            {t("login")}
                                        </DarkButton>

                                        <CleanButton
                                            type="button"
                                            onClick={() => setResetPassword(true)}
                                        >
                                            <TB $second $underline $link>
                                                {t("forgot_password")}
                                            </TB>
                                        </CleanButton>
                                    </div>
                                </form>
                            </FormProvider>
                        </section>
                    </section>
                </div>
            )}
        </StyledContainer>
    );
};

export default Login;

const StyledContainer = styled.div`
    background-color: ${(props) => props.theme.color.primary.base};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
    min-height: calc(100vh - calc(${(props) => props.theme.footer.height}));

    .page {
        flex-direction: row;
        display: flex;

        .login {
            min-width: 450px;

            @media only screen and ${device.mobile} {
                min-width: 100%;
            }
        }

        .previous {
            min-width: 300px;
            border-right: 2px solid white;
            padding-left: 12px;
            margin-right: 10px;
            padding-right: 10px;
            @media ${device.mobile} {
                display: none;
            }
        }

        .submit {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            button {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
`;

const HR = styled.hr`
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${(props) => props.theme.color.secondary.xxlight};
`;

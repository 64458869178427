import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import UnitSearch from "../../components/application/unit/UnitSearch";
import UnitUsing from "../../components/application/unit/UnitUsing";
import { UseUnitButton } from "../../components/buttons/UseUnitButton";

import { H1, T } from "../../components/texts";
import MainArea from "../../components/layout/MainArea";

const UnitUse = () => {
    const { t } = useTranslation();
    const [unit, setUnit] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <MainArea back>
            <H1>{t("useunitlong", "Use unit")}</H1>
            <T>{t("searchandchoose", "Search and choose unit")}</T>

            <UnitSearch onChange={(unit) => setUnit(unit)} />

            {unit && <UnitUsing unit={unit} />}

            <ButtonContainer>
                <UseUnitButton id={unit?.id} />
            </ButtonContainer>
        </MainArea>
    );
};

export default UnitUse;

const ButtonContainer = styled.section`
    margin-top: 1.25rem;
`;

import { useMemo } from "react";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { startOfDay, sub, format, endOfDay } from "date-fns";
import styled from "styled-components";

import { useTasksForUnit } from "../task/hooks/useTasksForUnit";
import { TaskStatus } from "../../api/core/taskAPI";

import { H2, T } from "../../components/texts";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";

const UnitMaintenanceHistoryTable = ({ unitId }) => {
    const { t } = useTranslation();

    const pastTasks = useTasksForUnit(
        sub(startOfDay(new Date()), { months: 12 }),
        endOfDay(new Date()),
        unitId,
        [TaskStatus.COMPLETED, TaskStatus.INVOICED]
    );

    const tableData = useMemo(() => {
        if (!pastTasks?.data?.length) return [];

        return pastTasks.data.sort((a, b) => new Date(b.end) - new Date(a.end)).slice(0, 5);
    }, [pastTasks?.data]);

    const columns = useMemo(
        () => [
            {
                header: t("date"),
                accessorKey: "end",
                cell: ({ getValue }) => format(new Date(getValue()), "dd.MM.yy"),
            },
            {
                header: t("description_2"),
                accessorKey: "description",
                enableSorting: false,
            },
            {
                header: t("feedback"),
                accessorKey: "report",
                enableSorting: false,
            },
        ],
        []
    );

    const initialState = useMemo(
        () => ({
            sortBy: [
                {
                    id: "end",
                    desc: true,
                },
            ],
        }),
        []
    );

    const table = useReactTable({
        columns,
        data: tableData,
        initialState,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <Container>
            <H2>{t("last_services")}</H2>

            {rowModel.rows.length > 0 ? (
                <TableContainer>
                    <StandardTableStyle>
                        <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                    </StandardTableStyle>
                </TableContainer>
            ) : (
                <T>{t("no_services_found")}</T>
            )}
        </Container>
    );
};

export default UnitMaintenanceHistoryTable;

const Container = styled.article`
    margin-top: 3.5rem;
`;

const TableContainer = styled.div`
    overflow: auto;
    max-width: 50rem;
`;

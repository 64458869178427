import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { useCustomersWithPermission } from "../../../admin/customers/hooks/useCustomersWithPermission";
import { useCustomers } from "../../../admin/customers/hooks/useCustomers";
import QueryWrapper from "../../../../components/application/QueryWrapper";
import SelectStyled from "../../../../components/application/styles/SelectStyled";

const CustomerSelectNoForm = ({ permission, selected, onChange, ...props }) => {
    const useData = permission ? useCustomersWithPermission : useCustomers;
    const customers = useData(permission ? { permission } : null);

    const { t } = useTranslation();

    const options = useMemo(
        () =>
            customers?.data && customers.data.length
                ? customers.data.map((customer) => ({ label: customer.name, value: customer.id }))
                : [],
        [customers?.data]
    );

    return (
        <QueryWrapper data={customers}>
            <SelectStyled>
                <Select
                    options={options}
                    placeholder={t("choose_customer")}
                    classNamePrefix="rs"
                    noOptionsMessage={() => t("no_results")}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    value={options.find((item) => item.value === +selected)}
                    onChange={onChange}
                    {...props}
                />
            </SelectStyled>
        </QueryWrapper>
    );
};

export default CustomerSelectNoForm;

import toast from "react-hot-toast";
import { useQuery } from "react-query";
import sensorAPI from "../../../api/core/sensorAPI";

export const useSensorsForUnit = (unitId) =>
    useQuery(["sensors", "unit", unitId], () => sensorAPI.getSensorsForUnit(unitId), {
        enabled: !!unitId,
        onError: (error) => {
            toast.error(
                `Klarte ikke hente data. Det oppsto en feil: ${error.message || "Ukjent feil"}`
            );
            console.log("Loading error: ", error.message);
        },
    });

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { T } from "../../texts";
import { Col, Row, Vertical } from "../../layout/FlexGrid";
import { InUseIcon, DefectIcon, NotInUseIcon } from "../../icons";

const UnitUsing = ({ unit }) => {
    const { t } = useTranslation();

    return unit?.user ? (
        <Row>
            <Col size={1}>
                <StyledIcon>
                    <InUseIcon />
                </StyledIcon>
            </Col>
            <Col size={11}>
                <Vertical>
                    <T>
                        {t("unit_is_being_used_by")} {unit.user.first_name} {unit.user.last_name}
                    </T>
                </Vertical>
            </Col>
        </Row>
    ) : unit?.defect ? (
        <Row>
            <Col size={1}>
                <StyledIcon>
                    <DefectIcon />
                </StyledIcon>
            </Col>
            <Col size={11}>
                <T>{t("unit_is_defect")}</T>
            </Col>
        </Row>
    ) : !unit?.user && !unit?.defect ? (
        <Row>
            <Col size={1}>
                <StyledIcon>
                    <NotInUseIcon />
                </StyledIcon>
            </Col>
            <Col size={11}>
                <T>{t("unit_is_available")}</T>
            </Col>
        </Row>
    ) : null;
};

export default UnitUsing;

const StyledIcon = styled.div`
    margin-top: 0.25rem;
`;

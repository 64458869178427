import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { H2, T, TB } from "../../components/texts";
import { FlexGap } from "../../components/helpers/FlexGap";
import ServiceElement from "./ServiceElement";
import InspectionSelector from "./InspectionSelector";
import ReportPdfDownload from "./ReportPdfDownload";

const InspectionExecutionForm = ({ isInspectionDone, selectedTask }) => {
    const { t } = useTranslation();

    const templateInSelectedTask = JSON.parse(selectedTask?.task_check_template || null);

    const { watch, setValue } = useFormContext();
    const customerId = watch("customer_id");
    const taskCheckTemplate = watch("task_check_template");

    useEffect(() => {
        if (!customerId) setValue("task_check_template", "{}");
    }, [customerId]);

    function selectInspection(option) {
        if (option) {
            const parsedData = JSON.parse(option.inspection.data);
            if (taskCheckTemplate?.configurationId === parsedData?.configurationId) return;
            setValue("task_check_template", parsedData, { shouldDirty: true });
        } else {
            setValue("task_check_template", "{}", { shouldDirty: true });
        }
    }

    return (
        <Container>
            <Header>
                <SelectContainer hidden={isInspectionDone}>
                    <InspectionSelector handleChange={selectInspection} />
                </SelectContainer>

                {isInspectionDone ? (
                    <InspectionTitle>
                        <TB>{`${templateInSelectedTask?.configurationName}${
                            templateInSelectedTask?.customer_id === 1
                                ? ` (${t("system_template")})`
                                : ""
                        }`}</TB>
                    </InspectionTitle>
                ) : null}

                <WorkHours>
                    <T>
                        {taskCheckTemplate?.workhours
                            ? `${t("estimated_duration")}: ${taskCheckTemplate.workhours} ${t("hours")}`
                            : null}
                    </T>
                </WorkHours>
            </Header>

            {taskCheckTemplate?.schema?.elements?.length ? (
                <>
                    <TableHeader>
                        <FlexGap $count={3}>
                            <H2>{t("checkpoints")}</H2>
                            <H2>{t("result")}</H2>
                            <H2>{t("comment")}</H2>
                        </FlexGap>
                    </TableHeader>

                    {taskCheckTemplate?.schema?.elements?.map((group, groupIndex) =>
                        group.hidden ? null : (
                            <Category key={`${group.groupName}-${groupIndex}`}>
                                {group.groupName ? (
                                    <CategoryHeader>
                                        <TB>{group.groupName}</TB>
                                    </CategoryHeader>
                                ) : null}

                                {group.elements?.map((element, elementIndex) =>
                                    element.hidden ? null : (
                                        <ServiceElement
                                            key={`${element.id}-${groupIndex}-${elementIndex}`}
                                            element={element}
                                            isInspectionDone={isInspectionDone}
                                            groupIndex={groupIndex}
                                            elementIndex={elementIndex}
                                            version={taskCheckTemplate?.version}
                                        />
                                    )
                                )}
                            </Category>
                        )
                    )}

                    {!!selectedTask?.media?.length && (
                        <ReportPdfDownload media={selectedTask?.media[0]} />
                    )}
                </>
            ) : null}
        </Container>
    );
};

export default InspectionExecutionForm;

const Container = styled.article`
    background: #fff;
    padding: 1rem;
`;

const Header = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const SelectContainer = styled.section`
    width: 100%;
    max-width: 28rem;
    display: ${(props) => (props.hidden ? "none" : "block")};
`;

const InspectionTitle = styled.section`
    margin-top: 2rem;
    margin-bottom: 0.5rem;
`;

const WorkHours = styled.section`
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
`;

const TableHeader = styled.section`
    margin-top: 3rem;
    border-bottom: 1px solid ${(p) => p.theme.color.neutral.xlight};

    ${H2} {
        margin-bottom: 0.5rem;

        &:nth-child(2) {
            max-width: 12rem;
        }
    }
`;

const Category = styled.article`
    padding: 1.5rem 0;
`;

const CategoryHeader = styled.span`
    display: block;
    border-bottom: 1px solid ${(p) => p.theme.color.neutral.xlight};
`;

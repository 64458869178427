import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import PrecheckListCheckpoint from "./PrecheckListCheckpoint";
import { TB } from "../../../components/texts";
import { HamburgerIcon } from "../../../components/icons";
import { InputWithLabel } from "../../../components/inputs";

const Category = ({ category, index, addCheckpoint, deleteCheckpoint, deleteCategory }) => {
    const { t } = useTranslation();

    return (
        <Draggable draggableId={category.name} index={index}>
            {(provided) => (
                <Container {...provided.draggableProps} ref={provided.innerRef}>
                    <CategoryStyled>
                        <div {...provided.dragHandleProps}>
                            <HamburgerIcon />
                        </div>
                        <InputWithLabel
                            label={t("name_of_category")}
                            defaultValue={category.label}
                            name={category.name}
                        />
                        <TB $link $underline onClick={() => deleteCategory(index)}>
                            {t("delete")}
                        </TB>
                    </CategoryStyled>
                    <Droppable droppableId={category.name} type={"checkpoint"}>
                        {(provided) => (
                            <CheckpointList ref={provided.innerRef} {...provided.droppableProps}>
                                {category.checkpoints.map((checkpoint, index) => (
                                    <PrecheckListCheckpoint
                                        key={checkpoint.name}
                                        checkpoint={checkpoint}
                                        category={category}
                                        index={index}
                                        deleteCheckpoint={deleteCheckpoint}
                                    />
                                ))}
                                {provided.placeholder}
                            </CheckpointList>
                        )}
                    </Droppable>
                    <div className={"new"}>
                        <TB $link $underline onClick={() => addCheckpoint(category)}>
                            {t("add_checkpoint")}
                        </TB>
                    </div>
                </Container>
            )}
        </Draggable>
    );
};

export default Category;

const Container = styled.div`
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.color.primary.xxlight};

    .new {
        margin: 0 10px 20px 38px;
    }
`;

const CategoryStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: ${(props) => props.theme.color.primary.xlight};

    div:nth-child(2) {
        width: 100%;
        margin: 0 10px 0 10px;
    }
`;

const CheckpointList = styled.div`
    background-color: ${(props) => props.theme.color.primary.xxlight};
`;

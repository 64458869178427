import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import styled from "styled-components";

import { useSaveServiceCategory } from "./mutations/useSaveServiceCategory";
import { useServiceCategoriesForCustomer } from "./hooks/useServiceCategoriesForCustomer";

import { SelectForm, InputWithLabel } from "../../../components/inputs";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import { FlexGap } from "../../../components/helpers/FlexGap";
import { H1 } from "../../../components/texts";

const NewCategoryModal = ({ customerId, isOpen, onClose }) => {
    const [options, setOptions] = useState([]);

    const { t } = useTranslation();

    const methods = useForm({ mode: "onChange" });
    const { mutate: saveCategory, isLoading: isSaving } = useSaveServiceCategory({
        onSuccess: onClose,
    });
    const { data: categoriesData } = useServiceCategoriesForCustomer(customerId);

    useEffect(() => {
        if (categoriesData && categoriesData.length > 0)
            setOptions(
                categoriesData.map((category) => ({ value: category.id, label: category.name }))
            );
    }, [categoriesData]);

    function onSubmit(data) {
        const post = {
            customer_id: customerId,
            name: data.name,
            order: 0,
        };

        const categoryToCopyFrom = data.checkpoints?.value;

        if (categoryToCopyFrom) {
            const checkpointsToCopy = categoriesData.find(
                (category) => category.id === categoryToCopyFrom
            )?.checkpoints;

            post.checkpoints = checkpointsToCopy
                ? checkpointsToCopy.map((checkpoint) => checkpoint.id)
                : [];
        }

        saveCategory(post);
    }

    return (
        <ModalStyled
            isOpen={isOpen}
            onRequestClose={onClose}
            className={"Modal"}
            overlayClassName={"ModalOverlay"}
            shouldCloseOnOverlayClick={false}
        >
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <H1>{t("add_new_service_category")}</H1>

                    <FlexGap $count={2} gap="1.5rem">
                        <InputWithLabel
                            name="name"
                            label={t("name_of_category")}
                            validate={{ required: true }}
                            required
                        />

                        <SelectContainer>
                            <SelectForm
                                name="checkpoints"
                                label={t("fetch_checkpoints_from_existing_category")}
                                options={options}
                            />
                        </SelectContainer>
                    </FlexGap>

                    <SaveOrAbort onAbort={onClose} saving={isSaving} />
                </form>
            </FormProvider>
        </ModalStyled>
    );
};

export default NewCategoryModal;

const ModalStyled = styled(ReactModal)`
    margin-top: 12rem;
    padding: 2rem 1.5rem 5rem;
    max-width: 60rem;
    height: max-content;
`;

const SelectContainer = styled.section`
    min-width: 22rem;
`;

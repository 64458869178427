import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { T } from "../texts";

export const SelectForm = ({ name, label, options, validate, ...props }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <InputStyled>
            {label && (
                <label htmlFor={name}>
                    <T>{`${label}${validate?.required ? " *" : ""}`}</T>
                </label>
            )}

            <Controller
                control={control}
                name={name}
                rules={validate}
                render={({ field }) => (
                    <Select
                        {...field}
                        classNamePrefix={"rs"}
                        options={options}
                        placeholder={t("select")}
                        noOptionsMessage={() => t("no_results")}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        {...props}
                    />
                )}
            />
        </InputStyled>
    );
};

export default SelectForm;

const InputStyled = styled.div`
    display: flex;
    flex-direction: column;

    .rs__control,
    rs__control:hover {
        border: 2px solid #6d8ac5 !important;
        box-shadow: unset;
        border-radius: 0;
        height: 44px;
    }

    .rs__input {
        input {
            font-family: ${(p) => p.theme.font.t["font-family"]};
        }
    }

    .rs__single-value--is-disabled {
        color: ${(p) => p.theme.color.neutral.base};
    }

    .rs__control--is-disabled {
        background-color: #eee;
    }
`;

import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { InputWithLabel, SelectForm, DateTimeInputForm } from "../../components/inputs";
import { H1, TB } from "../../components/texts";
import { FlexGap } from "../../components/helpers/FlexGap";
import { CleanButton } from "../../components/buttons";
import MechanicSelect from "../../components/application/MechanicSelect";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { useExtra } from "./hooks/useExtra";
import { useSaveExtra } from "./mutations/useSaveExtra";
import { useDeleteExtra } from "./mutations/useDeleteExtra";

const ExtrasFormContainer = ({ onClose, workshopId, extraId }) => {
    const { t } = useTranslation();

    const {
        data: selectedExtra,
        isLoading: isFetchingExtraLoading,
        isError: isFetchingExtraError,
    } = useExtra(extraId);
    const extrasOptions = useMemo(() => [{ label: t("on_watch"), value: "on_watch" }], []);

    const { mutate: updateExtra } = useSaveExtra({ onSuccess: onClose });
    const { mutate: deleteExtra } = useDeleteExtra({ onSuccess: onClose });

    const methods = useForm({ mode: "onChange" });

    useEffect(() => {
        if (selectedExtra) {
            methods.reset({
                description: selectedExtra.description,
                type: extrasOptions.find((item) => item.value === selectedExtra.type) || null,
                start: new Date(selectedExtra.from),
                end: new Date(selectedExtra.to),
                mechanic_id: selectedExtra.user_id,
            });
        } else {
            methods.reset({
                description: "",
                type: extrasOptions[0],
                start: null,
                end: null,
                mechanic_id: null,
            });
        }
    }, [selectedExtra]);

    function onSubmit(data) {
        const post = {
            customer_id: workshopId,
            user_id: data.mechanic_id.value,
            description: data.description,
            from: data.start,
            to: data.end,
            type: data.type?.value,
        };

        if (extraId) {
            post.id = extraId;
            delete post.customer_id;
            delete post.user_id;
        }

        updateExtra(post);
    }

    function handleDeleteExtra() {
        const wantsToDelete = window.confirm(t("confirm_deleting_extra"));
        if (wantsToDelete) {
            deleteExtra(extraId);
        }
    }

    return (
        <Container>
            <TitleStyled>{t("add_new_extras")}</TitleStyled>

            {isFetchingExtraLoading ? (
                <LoadingSpinner />
            ) : isFetchingExtraError ? (
                "Det oppsto en feil ved henting av tillegg"
            ) : (
                <FormProvider {...methods}>
                    <FormStyled onSubmit={methods.handleSubmit(onSubmit)}>
                        <FlexGap $count={2}>
                            <section>
                                <InputWithLabel
                                    name="description"
                                    label={t("extra_title")}
                                    validate={{ maxLength: 200 }}
                                    required
                                />
                                <SelectForm
                                    placeholder={t("select")}
                                    name="type"
                                    label={t("extra_type")}
                                    options={extrasOptions}
                                    defaultValue={extrasOptions[0]}
                                    validate={{ required: true }}
                                />

                                <FlexGapDateStyled $count={2}>
                                    <DateTimeInputForm name="start" label={t("from")} required />
                                    <DateTimeInputForm name="end" label={t("to")} required />
                                </FlexGapDateStyled>

                                <MechanicSelect
                                    customer_id={workshopId}
                                    validate={{
                                        required: {
                                            value: true,
                                            message: t("mechanic_required"),
                                        },
                                    }}
                                    isDisabled={Boolean(extraId)}
                                />

                                <ButtonsFlex>
                                    <SaveOrAbortWrapper>
                                        <SaveOrAbort onAbort={onClose} />
                                    </SaveOrAbortWrapper>

                                    {extraId ? (
                                        <CleanButton type="button" onClick={handleDeleteExtra}>
                                            <TB $underline $link>
                                                {t("delete")}
                                            </TB>
                                        </CleanButton>
                                    ) : null}
                                </ButtonsFlex>
                            </section>
                        </FlexGap>
                    </FormStyled>
                </FormProvider>
            )}
        </Container>
    );
};

export default ExtrasFormContainer;

const Container = styled.article`
    max-width: 600px;
`;

const TitleStyled = styled(H1)`
    padding-left: 1rem;
`;

const FormStyled = styled.form`
    padding: 1rem;
`;

const FlexGapDateStyled = styled(FlexGap)`
    padding-top: 1.5rem;
    margin-bottom: -1rem;
`;

const ButtonsFlex = styled.section`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const SaveOrAbortWrapper = styled.section`
    margin-top: -0.5rem;
`;

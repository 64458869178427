export const precheckParse = (template) => {
    if (template.version === "1") return precheckParseV1(template);
    if (template.version === "2") return precheckParseV2(template);
};

const precheckParseV1 = (template) => {
    return [
        {
            label: "Førbrukskontroll",
            name: "category_1",
            checkpoints: template.schema.elements
                .flat()
                .map((element) => (element.elements ? element.elements : element))
                .flat()
                .filter((field) => field.type.includes("traffic"))
                .map((checkpoint) => {
                    return { label: checkpoint.label, name: checkpoint.name };
                }),
        },
    ];
};

const precheckParseV2 = (template) => {
    const data = [];
    for (const category of template.schema.elements.filter((e) => e.type === "group")) {
        const nc = { label: category.label, name: category.name, checkpoints: [] };
        nc.checkpoints = category.elements
            .filter((field) => field.type.includes("traffic"))
            .map((checkpoint) => {
                return { label: checkpoint.label, name: checkpoint.name };
            });
        data.push(nc);
    }
    return data;
};

export const precheckData2Template = (data, form) => {
    const template = {
        version: "2",
        schema: {
            elements: [],
        },
    };
    for (const category of data) {
        const group = {
            type: "group",
            name: category.name,
            label: form[category.name],
            elements: [
                {
                    type: "component:hr",
                },
            ],
        };
        for (const checkpoint of category.checkpoints) {
            group.elements.push({
                label: form[checkpoint.name],
                type: "field:traffic-1-2-3",
                name: checkpoint.name,
                value: "",
                possibleValues: ["1", "2", "3"],
            });
            group.elements.push({
                label: "Comment",
                type: "field:text",
                height: 1,
                value: "",
                name: `${checkpoint.name}:comment`,
                required: true,
                showOn: [
                    {
                        field: checkpoint.name,
                        value: ["2", "3"],
                    },
                ],
            });
            group.elements.push({
                type: "component:hr",
                dotted: true,
            });
        }
        template.schema.elements.push(group);
    }

    return template;
};

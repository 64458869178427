import { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { useCustomersWithPermission } from "../customers/hooks/useCustomersWithPermission";
import QueryWrapper from "../../../components/application/QueryWrapper";
import SelectStyled from "../../../components/application/styles/SelectStyled";

import { PERMISSIONS } from "../../auth/permissions";

const TaskPreChecksCustomerSelect = ({ ...props }) => {
    const [options, setOptions] = useState(null);
    const customers = useCustomersWithPermission({ permission: PERMISSIONS.task_pre_check_admin.name });

    const { t } = useTranslation();

    useEffect(() => {
        if (customers.data) setOptions(getOptionsFromData(customers.data));
    }, [customers?.data]);

    const getOptionsFromData = (data) => {
        return data.map((item) => ({ label: item.name, value: item.id }));
    };

    return (
        <QueryWrapper data={customers}>
            <SelectStyled>
                <Select
                    options={options}
                    placeholder={t("choose_customer")}
                    noOptionsMessage={() => t("no_results")}
                    classNamePrefix="rs"
                    name="customer_id"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    {...props}
                />
            </SelectStyled>
        </QueryWrapper>
    );
};

export default TaskPreChecksCustomerSelect;

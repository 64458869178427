import { useMemo } from "react";
import { useUnitAreas } from "./useUnitAreas";

export const useUnitAreasForCustomer = (customerId) => {
    const query = useUnitAreas();

    return {
        ...query,
        data: useMemo(
            () => query.data?.filter((item) => item.customer_id === customerId),
            [query.data, customerId]
        ),
    };
};

import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import styled from "styled-components";

import { useUpdateCustomerConfigMutation } from "./hooks/useUpdateCustomerConfigMutation";
import { useConfigForCustomer } from "./hooks/useConfigForCustomer";
import { PERMISSIONS } from "../../auth/permissions";
import CustomerSelect from "../../application/components/role/CustomerSelect";
import QueryWrapper from "../../../components/application/QueryWrapper";

import { InputCheckbox, InputWithLabel } from "../../../components/inputs";
import { H1, T } from "../../../components/texts";
import MainArea from "../../../components/layout/MainArea";
import SaveOrAbort from "../../../components/application/SaveOrAbort";

const UnitsSetup = () => {
    const { t } = useTranslation();
    const methods = useForm({ mode: "onChange" });
    const navigate = useNavigate();
    const autoFinishSelected = methods.watch("autoFinish");
    const customerId = methods.watch("customer_id");

    const existingConfig = useConfigForCustomer(customerId?.value);
    const { mutate: saveConfig, isLoading: isSaving } = useUpdateCustomerConfigMutation();

    useEffect(() => {
        if (existingConfig?.data) {
            methods.setValue("autoFinish_hours", existingConfig.data.vehicle_postcheck_timeout);
            methods.setValue("autoFinish", existingConfig.data.vehicle_postcheck_timeout > 0);
        }
    }, [existingConfig?.data]);

    function onSubmit(data) {
        const post = {
            ...existingConfig.data,
            vehicle_postcheck_timeout: data.autoFinish ? data.autoFinish_hours : 0,
        };

        saveConfig(post, {
            onSuccess: () => {
                toast.success(t("configuration_succesfully_saved"));
                navigate(`/administration/setup`);
            },
        });
    }

    return (
        <MainArea back>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Header>
                        <Title>{t("vehicle_use")}</Title>

                        <CustomerSelectContainer>
                            <CustomerSelect permission={PERMISSIONS.config_admin.name} />
                        </CustomerSelectContainer>
                    </Header>

                    <Description>
                        <T>{t("set_hours_before_automatic_vehicle_release")}</T>
                    </Description>

                    {customerId ? (
                        <QueryWrapper data={existingConfig}>
                            <FeatureWrapper>
                                <CheckboxContainer>
                                    <InputCheckbox
                                        name="autoFinish"
                                        label={t("release_vehicle_after_defined_time")}
                                    />
                                </CheckboxContainer>

                                <InputContainer style={{ opacity: !autoFinishSelected ? 0.8 : 1 }}>
                                    <InputWithLabel
                                        name="autoFinish_hours"
                                        label={t("number_of_hours")}
                                        type="number"
                                        min={0}
                                        style={{ textAlign: "right", paddingRight: "0.5rem" }}
                                        validate={{ min: autoFinishSelected ? 1 : 0 }}
                                        required={autoFinishSelected}
                                        disabled={!autoFinishSelected}
                                    />
                                </InputContainer>
                            </FeatureWrapper>

                            <SaveOrAbort saving={isSaving} />
                        </QueryWrapper>
                    ) : null}
                </form>
            </FormProvider>
        </MainArea>
    );
};

export default UnitsSetup;

const Description = styled.section`
    margin-bottom: 2rem;
`;

const Title = styled(H1)`
    margin-right: 2rem;
    margin-bottom: 0;
`;

const Header = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.5rem;
`;

const CustomerSelectContainer = styled.section`
    min-width: 20rem;
`;

const CheckboxContainer = styled.section`
    margin-bottom: 1rem;
`;

const InputContainer = styled.section`
    max-width: 7rem;
    margin-left: 1.5rem;
`;

const FeatureWrapper = styled.section`
    background: ${(p) => p.theme.color.primary.xxlight};
    padding: 1rem;
    max-width: 25rem;
`;

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { useCustomersWithPermission } from "../customers/hooks/useCustomersWithPermission";
import { PERMISSIONS } from "../../auth/permissions";
import CustomerSelectNoForm from "../../application/components/role/CustomerSelectNoForm";
import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import { H1 } from "../../../components/texts";

const InspectionSetup = () => {
    const { t } = useTranslation();
    const { customerId } = useParams();

    const navigate = useNavigate();
    const customers = useCustomersWithPermission({
        permission: PERMISSIONS.periodic_technical_inspection_admin.name,
    });

    return (
        <MainArea>
            <Header>
                <PageTitle>{t("periodic_technical_inspection")}</PageTitle>

                <QueryWrapper data={customers}>
                    {customers?.data?.length ? (
                        <SelectWrapper>
                            <CustomerSelectNoForm
                                permission={PERMISSIONS.periodic_technical_inspection_admin.name}
                                onChange={(e) => navigate(e.value.toString())}
                                selected={customerId}
                            />
                        </SelectWrapper>
                    ) : null}
                </QueryWrapper>
            </Header>

            <Outlet />
        </MainArea>
    );
};

export default InspectionSetup;

const Header = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const PageTitle = styled(H1)`
    margin: 1rem 0;
    margin-right: 1rem;
`;

const SelectWrapper = styled.section`
    width: min(25rem, 100%);
`;

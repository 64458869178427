import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";

export const useTasksWithAreaIds = (areaIds) => {
    const areaIdList = areaIds.join(",");
    const queryKey = ["tasks", "websocket-hooked", "all", areaIdList];

    return useQuery(
        queryKey,
        () =>
            taskAPI.getTasksWithConnectedWorkshops(
                `?area_ids=${areaIdList}&attributes=status&attributes=type_id&attributes=end&attributes=duration&attributes=parts_cost&attributes=invoice_total&attributes=task_check_template&attributes=parts.status&attributes=unit.id&attributes=unit.int_id&attributes=unit.manufacturer&attributes=unit.type`
            ),
        {
            enabled: areaIds && areaIds.length > 0,
            refetchOnWindowFocus: false,
            meta: {
                filterFn: (task) => {
                    if (!task.unit?.area_id) return false;
                    if (areaIdList && !areaIdList.includes(task.unit.area_id)) return false;
                    return true;
                },
            },
        }
    );
};

import { useQuery } from "react-query";
import toast from "react-hot-toast";
import absenceAPI from "../../../api/core/absenceAPI";

export const useAbsence = (id) =>
    useQuery(["absence", id], () => absenceAPI.fetchAbsence(id), {
        enabled: typeof id === "number" && !isNaN(id),
        onError: (error) => {
            toast.error(
                `Klarte ikke hente fravær: ${
                    error.response?.data?.message || error.message || "ukjent feil"
                }`
            );
            console.log("Fetching absence error: ", error.response?.data?.message || error.message);
        },
    });

import { useQueryClient, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import unitAPI from "../../../api/core/unitAPI";

export const useUnitFinishMutaton = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation((data) => unitAPI.postUnitFinished(data.id, data.comment, data.statusId), {
        onSuccess: (response) => {
            const unit = response?.data?.unit;
            toast.success(
                `${unit?.int_id} ${unit?.group?.name} ${unit?.manufacturer} ${t("isreleased")}`
            );

            queryClient.invalidateQueries("units");
        },
        onError: (error) => {
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            );
        },
    });
};

import { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import styled, { useTheme } from "styled-components";

import { useUnit } from "../unit/hooks/useUnit";
import { usePreCheckList } from "./hooks/usePreCheckList";
import { useSavePreCheck } from "./hooks/useSavePreCheck";
import { useUnitComments } from "../unit/hooks/useUnitComments";
import { useUnitStartMutation } from "../unit/mutations/useUnitStartMutation";
import QueryWrapper from "../../components/application/QueryWrapper";

import { H2, T, S, TB } from "../../components/texts";
import { Traffic123, Input, TextArea } from "../../components/inputs";
import { Button } from "../../components/buttons";
import { Row } from "../../components/layout/FlexGrid";
import Underline from "../../components/helpers/Underline";
import Spacer from "../../components/helpers/Spacer";
import MainArea from "../../components/layout/MainArea";

const isHidden = (props, getValues) => {
    if ("showOn" in props) {
        const showOn = props.showOn[0];
        const field = showOn.field;
        const value = getValues(field);
        if (!showOn.value.includes(value)) {
            return true;
        }
    }
    return false;
};

const InputWithLabelJson = ({
    name,
    rows,
    value,
    second,
    label,
    required,
    tentative,
    ...props
}) => {
    const { t } = useTranslation();
    const { register, getValues, watch } = useFormContext();
    const [hidden, setHidden] = useState("showOn" in props);
    const watchFields = "showOn" in props ? watch(props.showOn.map((field) => field.field)) : null;

    useEffect(() => {
        if (isHidden(props, getValues)) {
            !hidden && setHidden(true);
        } else {
            hidden && setHidden(false);
        }
    }, [watchFields]);

    return (
        <>
            <label htmlFor={name} hidden={hidden}>
                <T $second={second}>{t(label.toLowerCase(), label)}</T>
            </label>
            {(!rows || rows === 1) && (
                <Input
                    name={name}
                    hidden={hidden}
                    {...register(name)}
                    defaultValue={value}
                    required={hidden ? false : required}
                    {...props}
                />
            )}
            {rows > 1 && (
                <TextArea
                    name={name}
                    hidden={hidden}
                    rows={rows}
                    {...register(name)}
                    defaultValue={value}
                    required={hidden ? false : required}
                    {...props}
                />
            )}
            {tentative && <S>Antatt ordnet: {tentative}</S>}
        </>
    );
};

const Traffic123Json = (props) => {
    const defaultValue = props.defaultValue === "1" ? undefined : props.defaultValue;
    return <Traffic123 {...props} defaultValue={defaultValue ?? null} addLabelDecorator={true} />;
};

const JsonToGuiMap = ({ data, ...props }) => {
    const defaultValue = data && data[props.name];
    const tentative = data && data[`${props.name}:tentative`];
    switch (props.type) {
        case "component:h2":
            return <H2 {...props}>{props.content}</H2>;
        case "field:text":
            return (
                <InputWithLabelJson
                    {...props}
                    value={defaultValue}
                    label={props.label}
                    tentative={tentative}
                />
            );
        case "group":
            return (
                <div>
                    {props.label && <CategoryLabel>{props.label}</CategoryLabel>}
                    {props.elements && <JsonToGuiElements data={data} elements={props.elements} />}
                </div>
            );
        case "component:hr":
            return <Underline $second $dotted={props.dotted} />;
        case "field:traffic-1-2-3":
            return <Traffic123Json defaultValue={defaultValue} {...props} />;
        case "field:number":
            return <InputWithLabelJson {...props} value={defaultValue} label={props.label} />;
        default:
            return <span></span>;
    }
};

const JsonToGuiElements = ({ elements, data }) => {
    return (
        <>
            {elements &&
                elements.map((element, idx) => {
                    return <JsonToGuiMap key={idx} data={data} {...element} />;
                })}
        </>
    );
};

const JsonToGui = ({ schema, values, onSubmit, onCancelNavigateTo = "/" }) => {
    const [defect, setDefect] = useState(false);
    const [required, setRequired] = useState(false);

    const { t } = useTranslation();
    const methods = useForm();
    const navigate = useNavigate();
    const watch = methods.watch();

    useEffect(() => {
        if (values) {
            methods.reset(values);
        }
    }, [values]);

    useEffect(() => {
        const data = methods.getValues();
        const max = schema?.schema?.elements
            .flat()
            .map((element) => (element.elements ? element.elements : element))
            .flat()
            .filter((field) => field.type.includes("traffic"))
            .map((field) => data[field.name])
            .reduce((acc, val) => (val > acc ? val : acc), 0);
        setDefect(max === "3");

        const trafficLights = schema?.schema?.elements
            .flat()
            .map((element) => (element.elements ? element.elements : element))
            .flat()
            .filter((element) => element?.type === "field:traffic-1-2-3");

        setRequired(
            schema?.schema?.elements
                .flat()
                .map((element) => (element.elements ? element.elements : element))
                .flat()
                .some((field) => {
                    return (
                        field?.required &&
                        !isHidden(field, methods.getValues) &&
                        data[field.name] === ""
                    );
                }) ||
                (trafficLights.length > 0 &&
                    trafficLights.every((field) => data[field.name] === ""))
        );
    }, [watch]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
                <JsonToGuiElements elements={schema?.schema?.elements} data={values} />
                <Button disabled={required}>
                    {defect
                        ? t("save_as_defect", "Save as defect")
                        : t("save_and_use", "Save and start to use")}
                </Button>
                <span style={{ paddingLeft: "10px" }} />
                <TB
                    $underline
                    $link
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(onCancelNavigateTo)}
                >
                    {t("cancel", "Cancel")}
                </TB>
            </form>
        </FormProvider>
    );
};

const PreCheckCheckout = ({
    viewOnly = false,
    onCancelNavigateTo = "/",
    onSaveNavigateTo = "/",
}) => {
    const [data, setData] = useState({});

    const { t } = useTranslation();
    const params = useParams();
    const theme = useTheme();
    const navigate = useNavigate();

    const id = Number(params.id);
    const unit = useUnit(id);
    const comments = useUnitComments(id);
    const preCheckList = usePreCheckList(unit?.data?.pre_check_list_id);

    const { mutate: savePreCheck } = useSavePreCheck();
    const { mutate: startUnit } = useUnitStartMutation();

    useEffect(() => {
        const d = {};
        if (preCheckList?.data) {
            preCheckList.data?.template?.schema.elements
                .flat(1)
                .flatMap((e) => e.elements)
                .filter((e) => e !== undefined)
                .filter((e) => e.type === "field:traffic-1-2-3")
                .map((e) => e.name)
                .map((name) => (d[name] = ""));
        }

        if (comments.data) {
            comments.data.forEach((c) => {
                if (c?.item) {
                    d[c.item] = c.comment;
                    d[c.item.replace(":comment", "")] = "" + c.status;
                    if (c.tentative_fix) d[`${c.item}:tentative`] = c.tentative_fix;
                    if (c.id) d[`${c.item}:id`] = `${c.id}`;
                }
            });
        }
        setData(d);
    }, [comments?.data, preCheckList?.data]);

    const onSubmit = (data) => {
        const entry = Object.keys(data).map((key) => {
            return { name: key, value: data[key] };
        });
        savePreCheck(
            { id, entry },
            {
                onSuccess: (response) => {
                    const unit = response?.data?.unit;

                    if (unit?.defect) {
                        toast.error(
                            `${unit?.int_id} ${unit?.group?.name} ${unit?.manufacturer} ${t(
                                "savedAsDefect"
                            )}`
                        );
                        navigate(onSaveNavigateTo);
                    } else if (unit) {
                        startUnit(id, {
                            onSuccess: () => navigate(onSaveNavigateTo),
                        });
                    }
                },
            }
        );
    };

    return (
        <MainArea>
            <PreCheckStyled>
                <QueryWrapper data={[unit, preCheckList, comments]}>
                    <div className={"unit-name"}>
                        <TB>
                            {unit.data?.int_id} {unit.data?.group?.name} {unit.data?.name}{" "}
                            {unit.data?.type}
                        </TB>
                    </div>
                    <H2>{t("pre_check", "Pre Check")}</H2>
                    <Row>
                        <StatusStyled $typeColor={theme.color.ok.base}>
                            <T>{t("approved")}</T>
                        </StatusStyled>
                        <Spacer />
                        <StatusStyled $typeColor={theme.color.warning.base}>
                            <T>{t("deviation")}</T>
                        </StatusStyled>
                        <Spacer />
                        <StatusStyled $typeColor={theme.color.error.base}>
                            <T>{t("defect")}</T>
                        </StatusStyled>
                    </Row>
                    <section className={"list"}>
                        {!viewOnly && (
                            <JsonToGui
                                schema={preCheckList.data?.template}
                                values={data}
                                onSubmit={onSubmit}
                                onCancelNavigateTo={onCancelNavigateTo}
                            />
                        )}
                    </section>
                </QueryWrapper>
            </PreCheckStyled>
        </MainArea>
    );
};

export default PreCheckCheckout;

const PreCheckStyled = styled.div`
    .list {
        margin-top: 20px;
    }

    .unit-name {
        margin-bottom: 10px;
    }

    button {
        margin-top: 20px;
    }
`;

const StatusStyled = styled.span`
    border-left: 2px solid ${(props) => props.$typeColor};
    padding-left: 4px;
`;

const CategoryLabel = styled(H2)`
    margin-top: 10px;
`;

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useReactTable, getSortedRowModel, getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";

import StandardTableStyle from "../../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../../components/application/StandardTableContent";
import { TB } from "../../../../components/texts";
import { CleanButton } from "../../../../components/buttons";

const DietTable = ({ tasks, openEditorForTask }) => {
    const { t } = useTranslation();

    const data = useMemo(
        () => (tasks?.length ? tasks.filter((task) => !!task.living_expenses) : []),
        [tasks]
    );

    const columns = useMemo(
        () => [
            {
                header: t("date"),
                accessorKey: "start",
                cell: ({ getValue }) => formatDateForDisplay(getValue()) || "–",
            },
            {
                header: t("type"),
                accessorKey: "living_expenses",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? t(`living_expenses_${value.replace(".", ",")}`) : "-";
                },
            },
            {
                header: t("order"),
                accessorKey: "workorder.id",
                cell: ({ getValue, row }) => (
                    <CleanButton type="button" onClick={() => openEditorForTask(row.original.id)}>
                        <TB $link>{getValue()}</TB>
                    </CleanButton>
                ),
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    function formatDateForDisplay(date) {
        return date
            ? new Date(date).toLocaleDateString("no", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
              })
            : "";
    }

    return rowModel.rows.length ? (
        <Container>
            <TB>{`${t("diet")} (${t("quantity_unit_symbol")})`}</TB>

            <TableStyled>
                <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
            </TableStyled>
        </Container>
    ) : null;
};

export default DietTable;

const Container = styled.section`
    max-width: 35rem;
    margin: 3rem 0;
`;

const TableStyled = styled(StandardTableStyle)`
    th {
        border-bottom: 2px solid ${(p) => p.theme.color.neutral.base};

        > span {
            font-weight: normal;
        }
    }

    th,
    td {
        text-align: left;
    }

    th:first-child,
    td:first-child {
        width: 7rem;
        text-align: left;
    }

    th:nth-child(2),
    td:nth-child(2) {
        text-align: left;
    }

    th:last-child {
        text-align: center;
    }

    td:last-child {
        text-align: right;
        padding-right: 0;
    }
`;

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";

import LoadingSpinner from "../layout/LoadingSpinner";
import { CleanButton, Button } from "../buttons";
import { TB } from "../texts";

const SaveOrAbort = ({
    save = "save",
    abort = "abort",
    onSave,
    onAbort,
    noDisable,
    saving = false,
    className,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        formState: { dirtyFields, errors, isDirty, isValid },
    } = useFormContext();

    return (
        <Container className={className}>
            <Button
                onClick={() => onSave && onSave()}
                disabled={
                    saving
                        ? true
                        : noDisable
                        ? false
                        : (Object.keys(dirtyFields).length === 0 && !isDirty) ||
                          (Object.keys(errors).length > 0 && !isValid)
                }
            >
                {saving && <LoadingSpinner small={true} light={true} />}
                {t(save)}
            </Button>

            <CleanButton type="button" onClick={() => (onAbort ? onAbort() : navigate(-1))}>
                <TB $underline $link>
                    {t(abort)}
                </TB>
            </CleanButton>
        </Container>
    );
};

export default SaveOrAbort;

const Container = styled.div`
    padding-top: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    > button,
    button:active {
        margin-right: 1.25rem;
        display: flex;
        flex-direction: row;

        .lds-ring {
            margin-right: 0.625rem;
        }
    }
`;

import { useQuery } from "react-query";
import unitAPI from "../../../api/core/unitAPI";

export const useUnitDefectHistories = (from, to, enabled) => {
    const params = [];
    if (from) {
        params.push(`from=${from}`);
    }

    if (to) {
        params.push(`to=${to}`);
    }

    return useQuery(
        ["unitDefectHistories", from, to],
        () => unitAPI.getUnitDefectHistories(params.length > 0 ? `?${params.join("&")}` : ""),
        {
            enabled,
        }
    );
};

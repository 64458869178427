import { useQuery } from "react-query";
import taskAPI from "../../../api/core/taskAPI";
import { DateTime } from "luxon";

export const useTasksBetweenDates = (statusList, from, to, enabled = true) => {
    const joinedStatusList = statusList.join(",");
    const params = `?from=${from}&to=${to}&status=${joinedStatusList}`;

    // Define the query key
    const queryKey = ["tasks", "websocket-hooked", "all", from, to, joinedStatusList];
    return useQuery(queryKey, () => taskAPI.getTasksWithConnectedWorkshops(params), {
        refetchOnWindowFocus: false,
        meta: {
            filterFn: (task) => {
                if (from && DateTime.fromISO(from) >= DateTime.fromISO(task.start)) return false;
                if (to && DateTime.fromISO(to) <= DateTime.fromISO(task.end)) return false;
                if (statusList && !statusList.includes(task.status)) return false;
                return true;
            },
        },
        enabled,
    });
};

export function convertDateToMilitary(date) {
    const fullNum = (number) => (number < 10 ? `0${number}` : number);
    return `${fullNum(date.getHours())}:${fullNum(date.getMinutes())}`;
}

export function convertDateToMilitaryUTC(date) {
    const fullNum = (number) => (number < 10 ? `0${number}` : number);
    return `${fullNum(date.getUTCHours())}:${fullNum(date.getUTCMinutes())}`;
}

export function convertMilitaryToDate(string) {
    const split = string.split(":");

    return string ? new Date(new Date().setUTCHours(split[0] || 0, split[1] || 0, 0)) : "";
}

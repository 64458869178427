import styled from "styled-components";

export const TextArea = styled.textarea`
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.theme.color.primary.light};
    width: 100%;
    font-family: ${(props) => props.theme.font.t["font-family"]};
    font-style: ${(props) => props.theme.font.t["font-style"]};
    font-weight: ${(props) => props.theme.font.t["font-weight"]};
    font-size: ${(props) => props.theme.font.t["font-size"]};
    color: ${(props) => props.theme.font.t.color};
    padding-left: ${(props) => props.theme.input.padding};
    box-sizing: border-box;
    resize: none;

    &:focus {
        border-color: ${(props) => props.theme.color.neutral.base};
    }

    &:disabled {
        background-color: #eee;
        color: ${(p) => p.theme.color.neutral.base};
    }
`;

export default TextArea;

import { useQuery } from "react-query";
import preCheckAPI from "../../../api/core/preCheckAPI";

export const usePreCheckReportFromTo = (from, to) =>
    useQuery(
        ["prechecks", "report", "fromto", from, to],
        () => preCheckAPI.getReportFromTo(from, to),
        {
            enabled: !!from && !!to,
        }
    );

import { Route, Routes } from "react-router-dom";

import Login from "./features/auth/Login";
import ResetPassword from "./features/auth/ResetPassword";
import Footer from "./components/layout/Footer";

const PublicApp = () => {
    return (
        <>
            <Routes>
                <Route path={"/resetPassword/:token"} element={<ResetPassword />} />
                <Route path="*" element={<Login />} />
            </Routes>
            <Footer />
        </>
    );
};

export default PublicApp;

import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import preCheckAPI from "../../../api/core/preCheckAPI";

export const useSavePreCheck = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(({ id, entry }) => preCheckAPI.saveNew(id, entry), {
        onSuccess: () => {
            queryClient.invalidateQueries("prechecks");
            queryClient.invalidateQueries("units");
        },
        onError: (error) =>
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            ),
    });
};

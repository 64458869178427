import { useEffect, useState, useMemo } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { startOfMonth, add, endOfMonth } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import QueryWrapper from "../../components/application/QueryWrapper";
import { monthAndYear } from "../../common/date";

import MainArea from "../../components/layout/MainArea";
import { H1, TB } from "../../components/texts";
import { InputWithLabel } from "../../components/inputs";
import { CleanButton } from "../../components/buttons";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import { LesserThanIcon, GreaterThanIcon } from "../../components/icons";
import { usePreCheckReportFromTo } from "../pre-check/hooks/usePreCheckReportFromTo";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";

const PreCheckReports = () => {
    const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
    const { t } = useTranslation();
    const methods = useForm();

    const report = usePreCheckReportFromTo(fromDate, endOfMonth(fromDate));

    const search = methods.watch("search");

    const data = useMemo(() => report?.data || [], [report?.data]);
    const initialState = useMemo(() => ({ pagination: { pageSize: 100 } }), []);
    const columns = useMemo(
        () => [
            {
                header: t("unit"),
                accessorFn: (row) => `${row.int_id} ${row.manufacturer} ${row.type}`,
            },
            {
                header: t("prechecks_count"),
                accessorKey: "prechecks_count",
                cell: ({ getValue }) => getValue() || 0,
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        initialState,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function addMonthsToFromDate(amount) {
        setFromDate((currentDate) => add(currentDate, { months: amount }));
    }

    return (
        <MainArea>
            <H1>{t("reports_on_prechecks")}</H1>

            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        name="search"
                        label={t("searchforunit")}
                        style={{ maxWidth: "27rem" }}
                    />
                </form>
            </FormProvider>

            <TimeNav>
                <CleanButton onClick={() => addMonthsToFromDate(-1)}>
                    <LesserThanIcon className="lt" />
                </CleanButton>
                <CleanButton onClick={() => addMonthsToFromDate(1)}>
                    <GreaterThanIcon className="gt" />
                </CleanButton>
                <TB>{monthAndYear(fromDate)}</TB>
            </TimeNav>

            <QueryWrapper data={[report]}>
                {rowModel.rows.length > 0 ? (
                    <TableContainer>
                        <TableStyled>
                            <StandardTableContent headerGroups={headerGroups} rowModel={rowModel} />
                        </TableStyled>
                    </TableContainer>
                ) : (
                    <NothingToShow>
                        <TB>{t("no_vehicles_to_show")}</TB>
                    </NothingToShow>
                )}
                <TablePaginationNav
                    pageCount={table.getPageCount()}
                    previousPage={table.previousPage}
                    canPreviousPage={table.getCanPreviousPage()}
                    nextPage={table.nextPage}
                    canNextPage={table.getCanNextPage()}
                    pageOptions={table.getPageOptions()}
                    gotoPage={table.setPageIndex}
                    pageIndex={table.getState().pagination.pageIndex}
                />
            </QueryWrapper>
        </MainArea>
    );
};

export default PreCheckReports;

const TimeNav = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    .lt {
        margin-right: 1.25rem;
    }

    .gt {
        margin-left: 1.25rem;
        margin-right: 0.625rem;
    }
`;

const TableContainer = styled.article`
    overflow: auto;
    max-width: 35rem;
`;

const TableStyled = styled(StandardTableStyle)`
    tr {
        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0;
        }
    }
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

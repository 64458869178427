import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import styled from "styled-components";

import { useSaving } from "../../../components/application/hooks/useSaving";
import { useUnitGroup } from "../../unit/hooks/useUnitGroup";
import unitAPI from "../../../api/core/unitAPI";
import QueryWrapper from "../../../components/application/QueryWrapper";
import { device } from "../../../theme";

import MainArea from "../../../components/layout/MainArea";
import { H1 } from "../../../components/texts";
import { InputWithLabel } from "../../../components/inputs";
import CustomerSelect from "../../application/components/role/CustomerSelect";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import PreCheckListSelect from "./PreCheckListSelect";

const UnitGroup = () => {
    const params = useParams();
    const id = Number(params.id);
    const group = useUnitGroup(id);
    const { t } = useTranslation();
    const methods = useForm({ mode: "onChange" });
    const { saving, save } = useSaving();
    const customer = methods.watch("customer_id");

    useEffect(() => {
        if (id === 0 && group.data)
            methods.reset({
                customer_id: null,
                name: "",
                description: "",
                pre_check_list_id: null,
            });
    }, [id, group?.data]);

    useEffect(() => {
        if (group.data) methods.reset(group.data);
    }, [group?.data]);

    const onSubmit = (data) => {
        let post = { ...data };
        post.customer_id = post.customer_id?.value;
        post.pre_check_list_id = post.pre_check_list_id?.value || null;
        save({ api: unitAPI.updateGroup, post, ok: "/units/groups" });
    };

    return (
        <MainArea back>
            <H1>{id === 0 ? t("register_vehicle_group") : t("change_vehicle_group")}</H1>
            <QueryWrapper data={id !== 0 ? group : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Width33Styled>
                            <CustomerSelect
                                isDisabled={id !== 0}
                                validate={{ required: "Gruppen må være knyttet til en kunde" }}
                            />
                            {typeof customer === "object" && customer !== null && (
                                <>
                                    <InputWithLabel
                                        label={t("name", "Name")}
                                        name={"name"}
                                        validate={{ required: "Gruppen må ha et navn" }}
                                    />
                                    <InputWithLabel
                                        label={t("description", "Description")}
                                        name={"description"}
                                    />
                                    <PreCheckListSelect
                                        customer_id={customer.value}
                                        name={"pre_check_list_id"}
                                        label={"Standard førbrukskontroll på nye kjøretøy"}
                                    />
                                </>
                            )}
                        </Width33Styled>
                        <SaveOrAbort saving={saving} />
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default UnitGroup;

const Width33Styled = styled.div`
    width: 40%;

    @media ${device.mobile} {
        width: 100%;
    }
`;

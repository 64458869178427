import { useTranslation } from "react-i18next";
import styled from "styled-components";

import StandardTableStyle from "../../components/application/StandardTableStyle";
import { H1 } from "../../components/texts";

const PreventativeTable = ({ preventativeData }) => {
    const { t } = useTranslation();

    return (
        <TableStyled>
            <thead>
                <tr>
                    <th>
                        <H1>{t("preventative")}</H1>
                    </th>
                    <th>{t("count")}</th>
                    <th>{t("findings")}</th>
                    <th>{t("defects")}</th>
                    <th>{t("findings_count")}</th>
                </tr>
            </thead>
            {preventativeData != null ? (
                <>
                    <tbody>
                        <tr>
                            <td>{t("inspections")}</td>
                            <td>{preventativeData.inspections.month.count}</td>
                            <td>{preventativeData.inspections.month.totalFindings}</td>
                            <td>{preventativeData.inspections.month.defects}</td>
                            <td>
                                {preventativeData.inspections.month.count > 0
                                    ? (
                                          (100 * preventativeData.inspections.month.findings) /
                                          preventativeData.inspections.month.count
                                      ).toFixed(0)
                                    : 0}
                                %
                            </td>
                        </tr>
                        <tr>
                            <td>{t("services")}</td>
                            <td>{preventativeData.services.month.count}</td>
                            <td>{preventativeData.services.month.totalFindings}</td>
                            <td>{preventativeData.services.month.defects}</td>
                            <td>
                                {preventativeData.services.month.count > 0
                                    ? (
                                          (100 * preventativeData.services.month.findings) /
                                          preventativeData.services.month.count
                                      ).toFixed(0)
                                    : 0}
                                %
                            </td>
                        </tr>
                        <tr>
                            <td>{t("periodic_technical_inspection")}</td>
                            <td>{preventativeData.periodicTechnicalInspections.month.count}</td>
                            <td>
                                {preventativeData.periodicTechnicalInspections.month.totalFindings}
                            </td>
                            <td>{preventativeData.periodicTechnicalInspections.month.defects}</td>
                            <td>
                                {preventativeData.periodicTechnicalInspections.month.count > 0
                                    ? (
                                          (100 *
                                              preventativeData.periodicTechnicalInspections.month
                                                  .findings) /
                                          preventativeData.periodicTechnicalInspections.month.count
                                      ).toFixed(0)
                                    : 0}
                                %
                            </td>
                        </tr>
                    </tbody>
                </>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan={5}>
                            <NoAreasSelected>{t("no_areas_selected")}</NoAreasSelected>
                        </td>
                    </tr>
                </tbody>
            )}
        </TableStyled>
    );
};

export default PreventativeTable;

const NoAreasSelected = styled.div`
    text-align: center;
    padding: 2rem;
`;

const TableStyled = styled(StandardTableStyle)`
    tfoot tr td {
        font-weight: bold;
    }

    td,
    th {
        text-align: right;
        width: 16.25%;
    }

    td:first-child,
    th:first-child {
        text-align: left;
        width: 35%;
    }
`;

import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import styled from "styled-components";

import { useUserRoles } from "../admin/users/hooks/useUserRoles";
import { useCustomers } from "../admin/customers/hooks/useCustomers";
import { useSaving } from "../../components/application/hooks/useSaving";
import { useMyUser } from "../admin/users/hooks/useMyUser";
import { useIsLoggedIn } from "../auth/hooks/useIsLoggedIn";
import QueryWrapper from "../../components/application/QueryWrapper";
import userAPI from "../../api/core/userAPI";

import { InputWithLabel } from "../../components/inputs";
import { H1, TB, T } from "../../components/texts";
import { FlexGap } from "../../components/helpers/FlexGap";
import MainArea from "../../components/layout/MainArea";
import SaveOrAbort from "../../components/application/SaveOrAbort";
import Username from "../application/components/user/Username";
import EmailInput from "../application/components/user/Email";
import Password from "./Password";
import SignatureInput from "./SignatureInput";

const Profile = () => {
    const { t } = useTranslation();
    const methods = useForm({ mode: "onChange" });
    const { save, saving, postErrors } = useSaving();

    const isLoggedIn = useIsLoggedIn();
    const user = useMyUser();
    const roles = useUserRoles(user?.data?.id);
    const customers = useCustomers();

    const onSubmit = (data) => {
        const post = {};
        post.first_name = data.first_name;
        post.last_name = data.last_name;
        post.username = data.username;
        post.email = data.email;
        post.password = data.password;
        post.new_password = data.new_password;
        post.new_password2 = data.new_password2;
        post.data = { signature_media_id: data.signature_media_id || null };

        save({ api: userAPI.updateSelf, post, onOk: () => toast.success(t("profile_updated")) });
    };

    useEffect(() => {
        if (user?.data)
            methods.reset({ ...user.data, signature_media_id: user.data.data?.signature_media_id });
    }, [user?.data, isLoggedIn]);

    return (
        <MainArea>
            <H1>{t("my_profile")}</H1>

            <QueryWrapper data={user}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <FlexGap $count={3}>
                            <section>
                                <InputWithLabel
                                    name="first_name"
                                    validate={{ required: t("name_required") }}
                                    label={t("firstname")}
                                />
                                <InputWithLabel name="last_name" label={t("lastname")} />
                                <Username />
                                <Password />
                                <EmailInput />
                                <SignatureInput />
                            </section>
                        </FlexGap>

                        <RolesContainer>
                            <TB>{t("roles_and_access")}</TB>
                            <QueryWrapper data={roles}>
                                {roles?.data?.map((role, idx) => (
                                    <RoleText key={idx}>
                                        {`${role.role.name}, ${
                                            customers?.data?.find(
                                                (item) => item.id === role.customer_id
                                            )?.name || "–"
                                        }`}
                                    </RoleText>
                                ))}
                            </QueryWrapper>
                        </RolesContainer>
                        <SaveOrAbort save={"save_info"} saving={saving} />
                        <p role={"alert"}>{postErrors && postErrors}</p>
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default Profile;

const RolesContainer = styled.section`
    width: 66%;
`;

const RoleText = styled(T)`
    display: block;
    margin-top: 0.4rem;
`;

import { useQuery, useQueryClient } from "react-query";
import customerAPI from "../../../../api/core/customerAPI";

export const useCustomers = () => {
    const queryClient = useQueryClient();

    return useQuery("customers", customerAPI.getCustomers, {
        onSuccess: (data) => {
            data.forEach((item) => queryClient.setQueryData(["customers", item.id], item));
        },
    });
};

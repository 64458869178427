import styled from "styled-components";

const Underline = styled.div`
    border-bottom: 2px ${(props) => (props.$dotted ? "dotted" : "solid")}
        ${(props) =>
            props.$second
                ? props.theme.color.neutral.xlight
                : props.color
                  ? props.color
                  : "currentColor"};
    text-decoration: none;
`;

export default Underline;

import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import configAPI from "../../../../api/core/configAPI";

export const useUpdateCustomerConfigMutation = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation((data) => configAPI.updateCustomerConfig(data), {
        onSuccess: (response) => {
            queryClient.setQueryData(
                ["config", "customer", response.data.data.customer_id],
                response.data.data
            );
        },
        onError: (error) => {
            toast.error(
                `${t("error_when_saving")}: ${
                    error.response?.data?.message || error.message || t("unknown_error")
                }`
            );
        },
    });
};

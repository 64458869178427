import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import styled from "styled-components";

import { useAuth } from "./hooks/useAuth";

import { H1 } from "../../components/texts";
import { InputWithLabel } from "../../components/inputs";
import { DarkButton } from "../../components/buttons";
import { ErrorBox } from "../../components/layout/ErrorBox";
import { device } from "../../theme";

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const { token } = useParams();
    const navigate = useNavigate();
    const methods = useForm();

    const { resetPassword, logInError } = useAuth();

    const sendPassword = (e) => {
        e.preventDefault();
        resetPassword({ token, password }).then(() => navigate("/login"));
    };

    return (
        <StyledContainer>
            <H1 $second>Sett nytt passord</H1>
            <ErrorBox text={logInError} />
            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        type={"password"}
                        second
                        name={"password"}
                        label={"Nytt passord"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputWithLabel
                        type={"password"}
                        second
                        name={"password2"}
                        label={"Bekreft nytt passord"}
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    <DarkButton
                        type="submit"
                        disabled={password === "" || password !== password2}
                        onClick={(e) => sendPassword(e)}
                    >
                        Sett nytt passord
                    </DarkButton>
                </form>
            </FormProvider>
        </StyledContainer>
    );
};

export default ResetPassword;

const StyledContainer = styled.div`
    background-color: ${(props) => props.theme.color.primary.base};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
    min-height: calc(100vh - calc(${(props) => props.theme.footer.height}));

    form {
        width: 300px;
        @media only screen and ${device.mobile} {
            width: 100%;
        }

        > * {
            margin-top: 10px;
        }
    }
`;

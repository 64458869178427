import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import serviceAPI from "../../../../api/core/serviceAPI";

export const useSaveServiceCheckpoint = ({ onSuccess }) => {
    const queryClient = useQueryClient();

    return useMutation((data) => serviceAPI.updateCheckpoint(data), {
        onSuccess: (response) => {
            queryClient.invalidateQueries(["service", "category"]);

            if (onSuccess instanceof Function) onSuccess();
        },
        onError: (error) => {
            toast.error(`Error: ${error.response?.data?.message || error.message || "unknown"}`);
            console.log("Saving error: ", error);
        },
    });
};

import { useTranslation } from "react-i18next";
import { ExpandableSection } from "../../components/inputs";
import TaskPreCheck from "./TaskPreCheck";
import TaskPostCheck from "./TaskPostCheck";
import { T } from "../../components/texts";

const TaskPreAndPostCheckExpansion = ({
    parsedPreCheckTemplate,
    parsedPostCheckTemplate,
    setPreCheckTemplate,
    setPostCheckTemplate,
}) => {
    const { t } = useTranslation();
    const isValidPreCheck =
        parsedPreCheckTemplate?.type === "PreCheck" && !!parsedPreCheckTemplate?.done;
    const isValidPostCheck =
        parsedPostCheckTemplate?.type === "PostCheck" && !!parsedPostCheckTemplate?.done;

    if (!isValidPreCheck && !isValidPostCheck) return null;
    return (
        <ExpandableSection label={`${t("sja")}`}>
            {isValidPreCheck ? (
                <>
                    <T>{t("pre_check_type")}</T>
                    <TaskPreCheck
                        disabled={true}
                        preCheckTemplate={parsedPreCheckTemplate}
                        setPreCheckTemplate={setPreCheckTemplate}
                    />
                </>
            ) : null}
            {isValidPreCheck && isValidPostCheck ? (
                <>
                    <br />
                    <br />
                </>
            ) : null}
            {isValidPostCheck ? (
                <>
                    <T>{t("post_check_type")}</T>
                    <TaskPostCheck
                        disabled={true}
                        postCheckTemplate={parsedPostCheckTemplate}
                        setPostCheckTemplate={setPostCheckTemplate}
                    />
                </>
            ) : null}
        </ExpandableSection>
    );
};

export default TaskPreAndPostCheckExpansion;

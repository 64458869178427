import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { PERMISSIONS } from "../../auth/permissions";
import { usePreCheckList } from "../../pre-check/hooks/usePreCheckList";
import { useSavePreCheckList } from "../../pre-check/hooks/useSavePreCheckList";
import { precheckData2Template, precheckParse } from "./PrecheckFormat";
import QueryWrapper from "../../../components/application/QueryWrapper";

import MainArea from "../../../components/layout/MainArea";
import { H1 } from "../../../components/texts";
import { FlexGap } from "../../../components/helpers/FlexGap";
import { InputWithLabel } from "../../../components/inputs";
import CustomerSelect from "../../application/components/role/CustomerSelect";
import PrecheckListCategories from "./PrecheckListCategories";
import SaveOrAbort from "../../../components/application/SaveOrAbort";

const PrecheckList = () => {
    const [data, setData] = useState([]);
    const params = useParams();
    const id = Number(params.id);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const methods = useForm({ mode: "onChange" });

    const preCheckList = usePreCheckList(id);
    const { mutate: savePreCheckList, isLoading: isSaving } = useSavePreCheckList();

    useEffect(() => {
        if (id === 0) {
            methods.reset({
                name: "",
                template: {},
                hours_counter: null,
                hours_between_check: 12,
                customer_id: null,
            });
        }
    }, [id]);

    useEffect(() => {
        if (preCheckList?.data) {
            setData(precheckParse(preCheckList.data.template));
            methods.reset({ ...preCheckList.data });
        }
    }, [preCheckList?.data]);

    const onSubmit = (form) => {
        const post = {
            customer_id: form.customer_id.value,
            hours_between_check: Number(form.hours_between_check),
            hours_counter: false,
            name: form.name,
            template: JSON.stringify(precheckData2Template(data, form)),
        };
        if (id) post.id = id;

        savePreCheckList(post, { onSuccess: () => navigate("/units/prechecks") });
    };

    return (
        <MainArea back>
            <H1>{id === 0 ? t("register_prechecklist") : t("change_prechecklist")}</H1>

            <QueryWrapper data={id !== 0 ? preCheckList : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <FlexGap $count={3}>
                            <CustomerSelect permission={PERMISSIONS.precheck_admin.name} />
                        </FlexGap>
                        <FlexGap $count={3}>
                            <InputWithLabel
                                label={t("name_of_control")}
                                name={"name"}
                                validate={{ required: t("control_must_have_name") }}
                            />
                        </FlexGap>

                        {data && (
                            <FlexGap $count={3}>
                                <PrecheckListCategories data={data} setData={setData} />
                            </FlexGap>
                        )}

                        <FlexGap $count={3} className={"input"} style={{ marginTop: "20px" }}>
                            <InputWithLabel
                                label={t("control_to_be_repeated_every_hours")}
                                name={"hours_between_check"}
                                type={"number"}
                            />
                        </FlexGap>
                        <SaveOrAbort saving={isSaving} />
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default PrecheckList;

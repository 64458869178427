import { useTranslation } from "react-i18next";

import userAPI from "../../../../api/core/userAPI";
import useServerLookupBoolean from "../../hooks/useServerLookup";
import { InputWithLabel } from "../../../../components/inputs";

const Username = () => {
    const { t } = useTranslation();
    useServerLookupBoolean({
        name: "username",
        apiCall: userAPI.getUsernameExists,
        errorType: "taken",
        errorMessage: "Brukernavnet finnes allerede",
    });

    return (
        <InputWithLabel
            name={"username"}
            validate={{
                minLength: { value: 3, message: "Brukernavnet må være minst 3 bokstaver" },
            }}
            label={t("username", "Username")}
        />
    );
};

export default Username;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { T } from "../../../components/texts";
import { ExpandableSection } from "../../../components/inputs";
import CheckpointEditTable from "./CheckpointEditTable";

const CategoryList = ({ categories, searchValue, askToEditCategory, askToRemoveCategory }) => {
    const [categoryData, setCategoryData] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (categories) setCategoryData(categories);
    }, [categories]);

    useEffect(() => {
        filterCategoryDataOnSearch(searchValue);
    }, [searchValue]);

    function filterCategoryDataOnSearch(string) {
        if (!categoryData || !categories) return;

        const searchTerm = string.toLowerCase().trim();

        if (searchTerm === "") {
            setCategoryData(categories);
        } else {
            const dataCopy = JSON.parse(JSON.stringify(categories));

            const filteredData = dataCopy
                .map((category) => ({
                    ...category,
                    checkpoints: category.checkpoints?.filter((checkpoint) =>
                        checkpoint.name?.toLowerCase()?.includes(searchTerm)
                    ),
                }))
                .filter(
                    (category) =>
                        category.checkpoints.length > 0 ||
                        category.name.toLowerCase().includes(searchTerm)
                );

            setCategoryData(filteredData);
        }
    }

    return categoryData && categoryData.length > 0 ? (
        categoryData?.map((category, index) => (
            <ExpandableSection
                label={category.name}
                key={`${category.name}-${index}`}
                expanded={Boolean(searchValue)}
            >
                <CheckpointEditTable
                    categoryData={category}
                    editCategory={() => askToEditCategory(category.id)}
                    askToRemoveCategory={() => askToRemoveCategory(category.id)}
                />
            </ExpandableSection>
        ))
    ) : (
        <T>{t("found_no_categories")}</T>
    );
};

export default CategoryList;
